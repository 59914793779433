export const data = [
  { brandName: "Abacavir sulfate", genericName: "Abacavir sulfate" },
  { brandName: "Abarelix", genericName: "Abarelix" },
  { brandName: "Abatacept", genericName: "Abatacept" },
  { brandName: "Abciximab", genericName: "Abciximab" },
  { brandName: "Abelcet", genericName: "Abelcet" },
  { brandName: "Abilify", genericName: "Abilify" },
  { brandName: "Abraxane", genericName: "Abraxane" },
  { brandName: "Acamprosate calcium", genericName: "Acamprosate calcium" },
  { brandName: "Acarbose", genericName: "Acarbose" },
  { brandName: "Accolate", genericName: "Accolate" },
  { brandName: "Accretropin", genericName: "Accretropin" },
  { brandName: "Accuneb", genericName: "Accuneb" },
  { brandName: "Accupril", genericName: "Accupril" },
  { brandName: "Accutane", genericName: "Accutane" },
  { brandName: "Acebutolol", genericName: "Acebutolol" },
  { brandName: "Aceon", genericName: "Aceon" },
  { brandName: "Acetadote", genericName: "Acetadote" },
  { brandName: "Acetaminophen", genericName: "Acetaminophen" },
  { brandName: "Acetic acid", genericName: "Acetic acid" },
  { brandName: "Aci-jel", genericName: "Aci-jel" },
  { brandName: "Aciphex", genericName: "Aciphex" },
  { brandName: "Acitretin", genericName: "Acitretin" },
  { brandName: "Aclovate", genericName: "Aclovate" },
  {
    brandName: "Acrivastine and pseudoephedrine",
    genericName: "Acrivastine and pseudoephedrine",
  },
  { brandName: "Actemra", genericName: "Actemra" },
  { brandName: "Acthrel", genericName: "Acthrel" },
  { brandName: "Acticin", genericName: "Acticin" },
  { brandName: "Actimmune", genericName: "Actimmune" },
  { brandName: "Actiq", genericName: "Actiq" },
  { brandName: "Actisite", genericName: "Actisite" },
  { brandName: "Activase", genericName: "Activase" },
  { brandName: "Activella", genericName: "Activella" },
  { brandName: "Actonel", genericName: "Actonel" },
  { brandName: "Actos", genericName: "Actos" },
  { brandName: "Acular", genericName: "Acular" },
  { brandName: "Acular ls", genericName: "Acular ls" },
  { brandName: "Acuvail", genericName: "Acuvail" },
  { brandName: "Acyclovir", genericName: "Acyclovir" },
  { brandName: "Adacel", genericName: "Adacel" },
  { brandName: "Adagen", genericName: "Adagen" },
  { brandName: "Adalat cc", genericName: "Adalat cc" },
  { brandName: "Adalimumab", genericName: "Adalimumab" },
  { brandName: "Adapalene", genericName: "Adapalene" },
  { brandName: "Adcirca", genericName: "Adcirca" },
  { brandName: "Adderall", genericName: "Adderall" },
  { brandName: "Adderall xr", genericName: "Adderall xr" },
  { brandName: "Adefovir dipivoxil", genericName: "Adefovir dipivoxil" },
  { brandName: "Adenoscan", genericName: "Adenoscan" },
  { brandName: "Adenosine", genericName: "Adenosine" },
  { brandName: "Adipex-p", genericName: "Adipex-p" },
  { brandName: "Adoxa", genericName: "Adoxa" },
  { brandName: "Adrenalin", genericName: "Adrenalin" },
  { brandName: "Adreview", genericName: "Adreview" },
  { brandName: "Adriamycin pfs", genericName: "Adriamycin pfs" },
  { brandName: "Advair diskus", genericName: "Advair diskus" },
  { brandName: "Advair hfa", genericName: "Advair hfa" },
  { brandName: "Advicor", genericName: "Advicor" },
  { brandName: "Aerospan hfa", genericName: "Aerospan hfa" },
  { brandName: "Afinitor", genericName: "Afinitor" },
  { brandName: "Afluria", genericName: "Afluria" },
  { brandName: "Agalsidase beta", genericName: "Agalsidase beta" },
  { brandName: "Aggrastat", genericName: "Aggrastat" },
  { brandName: "Aggrenox", genericName: "Aggrenox" },
  { brandName: "Agrylin", genericName: "Agrylin" },
  { brandName: "Ak-fluor", genericName: "Ak-fluor" },
  { brandName: "Akineton", genericName: "Akineton" },
  { brandName: "Alamast", genericName: "Alamast" },
  { brandName: "Albendazole", genericName: "Albendazole" },
  { brandName: "Albenza", genericName: "Albenza" },
  { brandName: "Albumin, human", genericName: "Albumin, human" },
  { brandName: "Albuminar", genericName: "Albuminar" },
  { brandName: "Albuterol sulfate", genericName: "Albuterol sulfate" },
  {
    brandName: "Albuterol sulfate inhalation aerosol",
    genericName: "Albuterol sulfate inhalation aerosol",
  },
  { brandName: "Alcaine", genericName: "Alcaine" },
  {
    brandName: "Alclometasone dipropionate",
    genericName: "Alclometasone dipropionate",
  },
  { brandName: "Aldactazide", genericName: "Aldactazide" },
  { brandName: "Aldactone", genericName: "Aldactone" },
  { brandName: "Aldara", genericName: "Aldara" },
  { brandName: "Aldesleukin", genericName: "Aldesleukin" },
  { brandName: "Aldomet", genericName: "Aldomet" },
  { brandName: "Aldoril", genericName: "Aldoril" },
  { brandName: "Aldurazyme", genericName: "Aldurazyme" },
  { brandName: "Alefacept", genericName: "Alefacept" },
  { brandName: "Alemtuzumab", genericName: "Alemtuzumab" },
  { brandName: "Alendronate sodium", genericName: "Alendronate sodium" },
  { brandName: "Alesse", genericName: "Alesse" },
  { brandName: "Alfenta", genericName: "Alfenta" },
  { brandName: "Alfentanil", genericName: "Alfentanil" },
  { brandName: "Alfuzosin hcl", genericName: "Alfuzosin hcl" },
  { brandName: "Alglucosidase alfa", genericName: "Alglucosidase alfa" },
  { brandName: "Alimta", genericName: "Alimta" },
  { brandName: "Alinia", genericName: "Alinia" },
  { brandName: "Alitretinoin", genericName: "Alitretinoin" },
  { brandName: "Alkeran", genericName: "Alkeran" },
  { brandName: "Allegra", genericName: "Allegra" },
  { brandName: "Allegra-d", genericName: "Allegra-d" },
  { brandName: "Allegra d 24 hour", genericName: "Allegra d 24 hour" },
  { brandName: "Allernaze", genericName: "Allernaze" },
  { brandName: "Alli", genericName: "Alli" },
  { brandName: "Allopurinol", genericName: "Allopurinol" },
  { brandName: "Almotriptan malate", genericName: "Almotriptan malate" },
  { brandName: "Alocril", genericName: "Alocril" },
  { brandName: "Alomide", genericName: "Alomide" },
  { brandName: "Aloprim", genericName: "Aloprim" },
  { brandName: "Alora", genericName: "Alora" },
  {
    brandName: "Alosetron hydrochloride",
    genericName: "Alosetron hydrochloride",
  },
  { brandName: "Aloxi", genericName: "Aloxi" },
  { brandName: "Alphanate", genericName: "Alphanate" },
  { brandName: "Alprazolam", genericName: "Alprazolam" },
  { brandName: "Alprostadil", genericName: "Alprostadil" },
  { brandName: "Alrex", genericName: "Alrex" },
  { brandName: "Alsuma", genericName: "Alsuma" },
  { brandName: "Altabax", genericName: "Altabax" },
  { brandName: "Altace", genericName: "Altace" },
  { brandName: "Alteplase", genericName: "Alteplase" },
  { brandName: "Altocor", genericName: "Altocor" },
  { brandName: "Altoprev", genericName: "Altoprev" },
  { brandName: "Altretamine", genericName: "Altretamine" },
  { brandName: "Alupent", genericName: "Alupent" },
  { brandName: "Alvesco", genericName: "Alvesco" },
  {
    brandName: "Amantadine hydrochloride",
    genericName: "Amantadine hydrochloride",
  },
  { brandName: "Amaryl", genericName: "Amaryl" },
  { brandName: "Ambien", genericName: "Ambien" },
  { brandName: "Ambien cr", genericName: "Ambien cr" },
  { brandName: "Ambisome", genericName: "Ambisome" },
  { brandName: "Amerge", genericName: "Amerge" },
  { brandName: "Americaine", genericName: "Americaine" },
  { brandName: "Amevive", genericName: "Amevive" },
  { brandName: "Amicar", genericName: "Amicar" },
  { brandName: "Amifostine", genericName: "Amifostine" },
  { brandName: "Amikacin", genericName: "Amikacin" },
  { brandName: "Amikin", genericName: "Amikin" },
  { brandName: "Amiloride", genericName: "Amiloride" },
  {
    brandName: "Amiloride and hydrochlorothiazide",
    genericName: "Amiloride and hydrochlorothiazide",
  },
  {
    brandName: "Amiloride hydrochloride",
    genericName: "Amiloride hydrochloride",
  },
  { brandName: "Amino acids", genericName: "Amino acids" },
  { brandName: "Aminocaproic acid", genericName: "Aminocaproic acid" },
  { brandName: "Aminoglutethimide", genericName: "Aminoglutethimide" },
  { brandName: "Aminohippurate sodium", genericName: "Aminohippurate sodium" },
  { brandName: "Aminolevulinic acid", genericName: "Aminolevulinic acid" },
  { brandName: "Aminosalicylic acid", genericName: "Aminosalicylic acid" },
  { brandName: "Aminosyn ii 8.5%", genericName: "Aminosyn ii 8.5%" },
  {
    brandName: "Aminosyn ii in dextrose",
    genericName: "Aminosyn ii in dextrose",
  },
  { brandName: "Amitiza", genericName: "Amitiza" },
  { brandName: "Amitriptyline", genericName: "Amitriptyline" },
  { brandName: "Amlexanox", genericName: "Amlexanox" },
  { brandName: "Amlodipine besylate", genericName: "Amlodipine besylate" },
  { brandName: "Amoxapine", genericName: "Amoxapine" },
  { brandName: "Amoxicillin", genericName: "Amoxicillin" },
  {
    brandName: "Amoxicillin clavulanate",
    genericName: "Amoxicillin clavulanate",
  },
  { brandName: "Amoxil", genericName: "Amoxil" },
  { brandName: "Amphadase", genericName: "Amphadase" },
  { brandName: "Amphetamine", genericName: "Amphetamine" },
  { brandName: "Amphotericin b", genericName: "Amphotericin b" },
  { brandName: "Ampicillin", genericName: "Ampicillin" },
  { brandName: "Ampyra", genericName: "Ampyra" },
  { brandName: "Amrix", genericName: "Amrix" },
  { brandName: "Amyl nitrite", genericName: "Amyl nitrite" },
  { brandName: "Amytal sodium", genericName: "Amytal sodium" },
  { brandName: "Anabolic steroids", genericName: "Anabolic steroids" },
  { brandName: "Anadrol-50", genericName: "Anadrol-50" },
  { brandName: "Anafranil", genericName: "Anafranil" },
  { brandName: "Anagrelide", genericName: "Anagrelide" },
  { brandName: "Anakinra", genericName: "Anakinra" },
  { brandName: "Anastrozole", genericName: "Anastrozole" },
  { brandName: "Ancobon", genericName: "Ancobon" },
  { brandName: "Androderm", genericName: "Androderm" },
  { brandName: "Androgel", genericName: "Androgel" },
  { brandName: "Anectine", genericName: "Anectine" },
  { brandName: "Angeliq", genericName: "Angeliq" },
  { brandName: "Angiomax", genericName: "Angiomax" },
  { brandName: "Anidulafungin", genericName: "Anidulafungin" },
  { brandName: "Anisindione", genericName: "Anisindione" },
  { brandName: "Ansaid", genericName: "Ansaid" },
  { brandName: "Antabuse", genericName: "Antabuse" },
  { brandName: "Antara", genericName: "Antara" },
  { brandName: "Anthralin", genericName: "Anthralin" },
  { brandName: "Antihemophilic factor", genericName: "Antihemophilic factor" },
  { brandName: "Antithrombin", genericName: "Antithrombin" },
  { brandName: "Antivenin", genericName: "Antivenin" },
  { brandName: "Antivert", genericName: "Antivert" },
  { brandName: "Antizol", genericName: "Antizol" },
  { brandName: "Anturane", genericName: "Anturane" },
  { brandName: "Anusol hc", genericName: "Anusol hc" },
  { brandName: "Aphthasol", genericName: "Aphthasol" },
  { brandName: "Apidra", genericName: "Apidra" },
  { brandName: "Aplenzin", genericName: "Aplenzin" },
  { brandName: "Apokyn", genericName: "Apokyn" },
  { brandName: "Apomorphine", genericName: "Apomorphine" },
  { brandName: "Apraclonidine", genericName: "Apraclonidine" },
  { brandName: "Apresazide", genericName: "Apresazide" },
  { brandName: "Apresoline", genericName: "Apresoline" },
  { brandName: "Apri", genericName: "Apri" },
  { brandName: "Apriso", genericName: "Apriso" },
  { brandName: "Aprotinin", genericName: "Aprotinin" },
  { brandName: "Aptivus", genericName: "Aptivus" },
  { brandName: "Aquasol a", genericName: "Aquasol a" },
  { brandName: "Aralen", genericName: "Aralen" },
  { brandName: "Aramine", genericName: "Aramine" },
  { brandName: "Aranesp", genericName: "Aranesp" },
  { brandName: "Arava", genericName: "Arava" },
  { brandName: "Arcalyst", genericName: "Arcalyst" },
  { brandName: "Aredia", genericName: "Aredia" },
  { brandName: "Argatroban", genericName: "Argatroban" },
  { brandName: "Aricept", genericName: "Aricept" },
  { brandName: "Arimidex", genericName: "Arimidex" },
  { brandName: "Aripiprazole", genericName: "Aripiprazole" },
  { brandName: "Aristocort", genericName: "Aristocort" },
  { brandName: "Arixtra", genericName: "Arixtra" },
  { brandName: "Armodafinil", genericName: "Armodafinil" },
  { brandName: "Aromasin", genericName: "Aromasin" },
  { brandName: "Arranon", genericName: "Arranon" },
  { brandName: "Artane", genericName: "Artane" },
  { brandName: "Arthrotec", genericName: "Arthrotec" },
  { brandName: "Arzerra", genericName: "Arzerra" },
  { brandName: "Asacol", genericName: "Asacol" },
  { brandName: "Asacol hd", genericName: "Asacol hd" },
  { brandName: "Asclera", genericName: "Asclera" },
  { brandName: "Ascorbic acid", genericName: "Ascorbic acid" },
  { brandName: "Asmanex twisthaler", genericName: "Asmanex twisthaler" },
  { brandName: "Asparaginase", genericName: "Asparaginase" },
  { brandName: "Aspirin", genericName: "Aspirin" },
  { brandName: "Astelin", genericName: "Astelin" },
  { brandName: "Astemizole", genericName: "Astemizole" },
  { brandName: "Astepro", genericName: "Astepro" },
  { brandName: "Atacand", genericName: "Atacand" },
  { brandName: "Atacand hct", genericName: "Atacand hct" },
  { brandName: "Atazanavir sulfate", genericName: "Atazanavir sulfate" },
  {
    brandName: "Atenolol and chlorthalidone",
    genericName: "Atenolol and chlorthalidone",
  },
  { brandName: "Atgam", genericName: "Atgam" },
  { brandName: "Ativan", genericName: "Ativan" },
  { brandName: "Atomoxetine hcl", genericName: "Atomoxetine hcl" },
  { brandName: "Atorvastatin calcium", genericName: "Atorvastatin calcium" },
  { brandName: "Atovaquone", genericName: "Atovaquone" },
  { brandName: "Atracurium besylate", genericName: "Atracurium besylate" },
  { brandName: "Atralin", genericName: "Atralin" },
  { brandName: "Atridox", genericName: "Atridox" },
  { brandName: "Atripla", genericName: "Atripla" },
  { brandName: "Atromid-s", genericName: "Atromid-s" },
  { brandName: "Atropen", genericName: "Atropen" },
  { brandName: "Atropine", genericName: "Atropine" },
  { brandName: "Atrovent hfa", genericName: "Atrovent hfa" },
  { brandName: "Atryn", genericName: "Atryn" },
  { brandName: "Augmentin", genericName: "Augmentin" },
  { brandName: "Augmentin xr", genericName: "Augmentin xr" },
  { brandName: "Auralgan", genericName: "Auralgan" },
  { brandName: "Avage", genericName: "Avage" },
  { brandName: "Avalide", genericName: "Avalide" },
  { brandName: "Avandamet", genericName: "Avandamet" },
  { brandName: "Avandaryl", genericName: "Avandaryl" },
  { brandName: "Avandia", genericName: "Avandia" },
  { brandName: "Avapro", genericName: "Avapro" },
  { brandName: "Avastin", genericName: "Avastin" },
  { brandName: "Avelox", genericName: "Avelox" },
  { brandName: "Avinza", genericName: "Avinza" },
  { brandName: "Avodart", genericName: "Avodart" },
  { brandName: "Avonex", genericName: "Avonex" },
  { brandName: "Axert", genericName: "Axert" },
  { brandName: "Axid", genericName: "Axid" },
  { brandName: "Aygestin", genericName: "Aygestin" },
  { brandName: "Azacitidine", genericName: "Azacitidine" },
  { brandName: "Azasan", genericName: "Azasan" },
  { brandName: "Azasite", genericName: "Azasite" },
  { brandName: "Azathioprine", genericName: "Azathioprine" },
  { brandName: "Azelaic acid", genericName: "Azelaic acid" },
  {
    brandName: "Azelastine hydrochloride",
    genericName: "Azelastine hydrochloride",
  },
  { brandName: "Azelex", genericName: "Azelex" },
  { brandName: "Azilect", genericName: "Azilect" },
  { brandName: "Azithromycin", genericName: "Azithromycin" },
  { brandName: "Azmacort", genericName: "Azmacort" },
  { brandName: "Azopt", genericName: "Azopt" },
  { brandName: "Azor", genericName: "Azor" },
  { brandName: "Azulfidine en-tabs", genericName: "Azulfidine en-tabs" },
  { brandName: "Bacitracin", genericName: "Bacitracin" },
  { brandName: "Baclofen", genericName: "Baclofen" },
  { brandName: "Bactrim", genericName: "Bactrim" },
  { brandName: "Bactroban nasal", genericName: "Bactroban nasal" },
  { brandName: "Balsalazide", genericName: "Balsalazide" },
  { brandName: "Banzel", genericName: "Banzel" },
  { brandName: "Baraclude", genericName: "Baraclude" },
  { brandName: "Basiliximab", genericName: "Basiliximab" },
  { brandName: "Baycol", genericName: "Baycol" },
  { brandName: "Bayer", genericName: "Bayer" },
  { brandName: "Becaplermin", genericName: "Becaplermin" },
  { brandName: "Beconase", genericName: "Beconase" },
  { brandName: "Benadryl", genericName: "Benadryl" },
  { brandName: "Benazepril", genericName: "Benazepril" },
  { brandName: "Benefix", genericName: "Benefix" },
  { brandName: "Benicar", genericName: "Benicar" },
  { brandName: "Benicar hct", genericName: "Benicar hct" },
  { brandName: "Bentyl", genericName: "Bentyl" },
  { brandName: "Benzaclin", genericName: "Benzaclin" },
  { brandName: "Benzagel", genericName: "Benzagel" },
  { brandName: "Benzamycin", genericName: "Benzamycin" },
  { brandName: "Benzocaine", genericName: "Benzocaine" },
  { brandName: "Benzonatate", genericName: "Benzonatate" },
  { brandName: "Benzoyl peroxide gel", genericName: "Benzoyl peroxide gel" },
  { brandName: "Benzphetamine", genericName: "Benzphetamine" },
  { brandName: "Benztropine mesylate", genericName: "Benztropine mesylate" },
  { brandName: "Bepreve", genericName: "Bepreve" },
  { brandName: "Bepridil", genericName: "Bepridil" },
  { brandName: "Beractant", genericName: "Beractant" },
  { brandName: "Besivance", genericName: "Besivance" },
  { brandName: "Betagan", genericName: "Betagan" },
  { brandName: "Betamethasone", genericName: "Betamethasone" },
  {
    brandName: "Betamethasone dipropionate",
    genericName: "Betamethasone dipropionate",
  },
  { brandName: "Betapace", genericName: "Betapace" },
  { brandName: "Betapace af", genericName: "Betapace af" },
  { brandName: "Betaseron", genericName: "Betaseron" },
  {
    brandName: "Betaxolol hydrochloride",
    genericName: "Betaxolol hydrochloride",
  },
  { brandName: "Betaxon", genericName: "Betaxon" },
  { brandName: "Bethanechol", genericName: "Bethanechol" },
  { brandName: "Bethanechol chloride", genericName: "Bethanechol chloride" },
  { brandName: "Betimol", genericName: "Betimol" },
  { brandName: "Betoptic s", genericName: "Betoptic s" },
  { brandName: "Bevacizumab", genericName: "Bevacizumab" },
  { brandName: "Bexarotene", genericName: "Bexarotene" },
  { brandName: "Bextra", genericName: "Bextra" },
  { brandName: "Bexxar", genericName: "Bexxar" },
  { brandName: "Bicalutamide", genericName: "Bicalutamide" },
  { brandName: "Bicillin c-r 900/300", genericName: "Bicillin c-r 900/300" },
  { brandName: "Bicnu", genericName: "Bicnu" },
  { brandName: "Bidil", genericName: "Bidil" },
  { brandName: "Biltricide", genericName: "Biltricide" },
  { brandName: "Bioclate", genericName: "Bioclate" },
  { brandName: "Biothrax", genericName: "Biothrax" },
  { brandName: "Biperiden", genericName: "Biperiden" },
  {
    brandName: "Bismuth subcitrate potassium",
    genericName: "Bismuth subcitrate potassium",
  },
  { brandName: "Bismuth subsalicylate", genericName: "Bismuth subsalicylate" },
  { brandName: "Bisoprolol fumarate", genericName: "Bisoprolol fumarate" },
  { brandName: "Bivalirudin", genericName: "Bivalirudin" },
  { brandName: "Blenoxane", genericName: "Blenoxane" },
  { brandName: "Bleph 10", genericName: "Bleph 10" },
  { brandName: "Blocadren", genericName: "Blocadren" },
  { brandName: "Boniva", genericName: "Boniva" },
  { brandName: "Bontril", genericName: "Bontril" },
  { brandName: "Boostrix", genericName: "Boostrix" },
  { brandName: "Bortezomib", genericName: "Bortezomib" },
  { brandName: "Bosentan", genericName: "Bosentan" },
  { brandName: "Botox", genericName: "Botox" },
  { brandName: "Botox cosmetic", genericName: "Botox cosmetic" },
  {
    brandName: "Botulinum toxin type a",
    genericName: "Botulinum toxin type a",
  },
  {
    brandName: "Botulinum toxin type b",
    genericName: "Botulinum toxin type b",
  },
  { brandName: "Bravelle", genericName: "Bravelle" },
  { brandName: "Bretylium", genericName: "Bretylium" },
  { brandName: "Brevibloc", genericName: "Brevibloc" },
  { brandName: "Brevicon", genericName: "Brevicon" },
  { brandName: "Brevital sodium", genericName: "Brevital sodium" },
  { brandName: "Brimonidine tartrate", genericName: "Brimonidine tartrate" },
  {
    brandName: "Bromfenac ophthalmic solution",
    genericName: "Bromfenac ophthalmic solution",
  },
  {
    brandName: "Bromocriptine mesylate",
    genericName: "Bromocriptine mesylate",
  },
  { brandName: "Brovana", genericName: "Brovana" },
  { brandName: "Budesonide", genericName: "Budesonide" },
  { brandName: "Bumetanide", genericName: "Bumetanide" },
  { brandName: "Bumex", genericName: "Bumex" },
  { brandName: "Buphenyl", genericName: "Buphenyl" },
  { brandName: "Buprenex", genericName: "Buprenex" },
  { brandName: "Buprenorphine", genericName: "Buprenorphine" },
  { brandName: "Bupropion hcl", genericName: "Bupropion hcl" },
  { brandName: "Buspar", genericName: "Buspar" },
  { brandName: "Buspirone", genericName: "Buspirone" },
  { brandName: "Busulfan", genericName: "Busulfan" },
  { brandName: "Busulfex", genericName: "Busulfex" },
  { brandName: "Butenafine", genericName: "Butenafine" },
  { brandName: "Butisol", genericName: "Butisol" },
  { brandName: "Butoconazole", genericName: "Butoconazole" },
  { brandName: "Butorphanol tartrate", genericName: "Butorphanol tartrate" },
  { brandName: "Butrans", genericName: "Butrans" },
  { brandName: "Byetta", genericName: "Byetta" },
  { brandName: "Ca-dtpa", genericName: "Ca-dtpa" },
  { brandName: "Cabergoline", genericName: "Cabergoline" },
  { brandName: "Caduet", genericName: "Caduet" },
  { brandName: "Cafcit", genericName: "Cafcit" },
  { brandName: "Cafergot", genericName: "Cafergot" },
  { brandName: "Caffeine citrate", genericName: "Caffeine citrate" },
  { brandName: "Calan", genericName: "Calan" },
  { brandName: "Calciferol", genericName: "Calciferol" },
  { brandName: "Calcitonin-salmon", genericName: "Calcitonin-salmon" },
  { brandName: "Calcitriol", genericName: "Calcitriol" },
  { brandName: "Calcium chloride", genericName: "Calcium chloride" },
  {
    brandName: "Calcium disodium versenate",
    genericName: "Calcium disodium versenate",
  },
  { brandName: "Calcium gluconate", genericName: "Calcium gluconate" },
  { brandName: "Calfactant", genericName: "Calfactant" },
  { brandName: "Cambia", genericName: "Cambia" },
  { brandName: "Campath", genericName: "Campath" },
  { brandName: "Campral", genericName: "Campral" },
  { brandName: "Canasa", genericName: "Canasa" },
  { brandName: "Cancidas", genericName: "Cancidas" },
  { brandName: "Candesartan cilexetil", genericName: "Candesartan cilexetil" },
  { brandName: "Cantil", genericName: "Cantil" },
  { brandName: "Capastat sulfate", genericName: "Capastat sulfate" },
  { brandName: "Capecitabine", genericName: "Capecitabine" },
  { brandName: "Capoten", genericName: "Capoten" },
  { brandName: "Capozide", genericName: "Capozide" },
  { brandName: "Captopril", genericName: "Captopril" },
  {
    brandName: "Captopril and hydrochlorothiazide",
    genericName: "Captopril and hydrochlorothiazide",
  },
  { brandName: "Carac", genericName: "Carac" },
  { brandName: "Carbaglu", genericName: "Carbaglu" },
  { brandName: "Carbamazepine", genericName: "Carbamazepine" },
  { brandName: "Carbatrol", genericName: "Carbatrol" },
  {
    brandName: "Carbenicillin indanyl sodium",
    genericName: "Carbenicillin indanyl sodium",
  },
  { brandName: "Carbidopa", genericName: "Carbidopa" },
  { brandName: "Carbocaine", genericName: "Carbocaine" },
  { brandName: "Carboplatin", genericName: "Carboplatin" },
  {
    brandName: "Carboprost tromethamine",
    genericName: "Carboprost tromethamine",
  },
  { brandName: "Cardene sr", genericName: "Cardene sr" },
  { brandName: "Cardiolite", genericName: "Cardiolite" },
  { brandName: "Cardizem la", genericName: "Cardizem la" },
  { brandName: "Cardura", genericName: "Cardura" },
  { brandName: "Cardura xl", genericName: "Cardura xl" },
  { brandName: "Carisoprodol", genericName: "Carisoprodol" },
  {
    brandName: "Carisoprodol and aspirin",
    genericName: "Carisoprodol and aspirin",
  },
  { brandName: "Carmustine", genericName: "Carmustine" },
  { brandName: "Carnitor", genericName: "Carnitor" },
  { brandName: "Carteolol", genericName: "Carteolol" },
  {
    brandName: "Carteolol hydrochloride",
    genericName: "Carteolol hydrochloride",
  },
  { brandName: "Cartia xt", genericName: "Cartia xt" },
  { brandName: "Carvedilol", genericName: "Carvedilol" },
  { brandName: "Casodex", genericName: "Casodex" },
  { brandName: "Caspofungin acetate", genericName: "Caspofungin acetate" },
  { brandName: "Cataflam", genericName: "Cataflam" },
  { brandName: "Catapres", genericName: "Catapres" },
  { brandName: "Catapres-tts", genericName: "Catapres-tts" },
  { brandName: "Caverject", genericName: "Caverject" },
  { brandName: "Caverject impulse", genericName: "Caverject impulse" },
  { brandName: "Cayston", genericName: "Cayston" },
  { brandName: "Ceclor", genericName: "Ceclor" },
  { brandName: "Cedax", genericName: "Cedax" },
  { brandName: "Ceenu", genericName: "Ceenu" },
  { brandName: "Cefaclor", genericName: "Cefaclor" },
  { brandName: "Cefadroxil", genericName: "Cefadroxil" },
  {
    brandName: "Cefadroxil hemihydrate",
    genericName: "Cefadroxil hemihydrate",
  },
  { brandName: "Cefamandole", genericName: "Cefamandole" },
  { brandName: "Cefdinir", genericName: "Cefdinir" },
  { brandName: "Cefditoren pivoxil", genericName: "Cefditoren pivoxil" },
  { brandName: "Cefixime", genericName: "Cefixime" },
  { brandName: "Cefizox", genericName: "Cefizox" },
  { brandName: "Cefobid", genericName: "Cefobid" },
  { brandName: "Cefotan", genericName: "Cefotan" },
  { brandName: "Cefotaxime", genericName: "Cefotaxime" },
  { brandName: "Cefotetan", genericName: "Cefotetan" },
  { brandName: "Cefoxitin", genericName: "Cefoxitin" },
  { brandName: "Cefprozil", genericName: "Cefprozil" },
  { brandName: "Ceftazidime", genericName: "Ceftazidime" },
  { brandName: "Ceftibuten", genericName: "Ceftibuten" },
  { brandName: "Ceftin", genericName: "Ceftin" },
  { brandName: "Ceftizoxime", genericName: "Ceftizoxime" },
  { brandName: "Ceftriaxone", genericName: "Ceftriaxone" },
  { brandName: "Cefuroxime", genericName: "Cefuroxime" },
  { brandName: "Cefuroxime axetil", genericName: "Cefuroxime axetil" },
  { brandName: "Cefzil", genericName: "Cefzil" },
  { brandName: "Celebrex", genericName: "Celebrex" },
  { brandName: "Celecoxib", genericName: "Celecoxib" },
  { brandName: "Celestone soluspan", genericName: "Celestone soluspan" },
  { brandName: "Celexa", genericName: "Celexa" },
  { brandName: "Cellcept", genericName: "Cellcept" },
  { brandName: "Cellulose", genericName: "Cellulose" },
  { brandName: "Celontin", genericName: "Celontin" },
  { brandName: "Cenestin", genericName: "Cenestin" },
  { brandName: "Cephalexin", genericName: "Cephalexin" },
  { brandName: "Ceprotin", genericName: "Ceprotin" },
  { brandName: "Ceptaz", genericName: "Ceptaz" },
  { brandName: "Cerebyx", genericName: "Cerebyx" },
  { brandName: "Ceredase", genericName: "Ceredase" },
  { brandName: "Ceretec", genericName: "Ceretec" },
  { brandName: "Cerezyme", genericName: "Cerezyme" },
  { brandName: "Cerivastatin", genericName: "Cerivastatin" },
  { brandName: "Certolizumab pegol", genericName: "Certolizumab pegol" },
  { brandName: "Cerubidine", genericName: "Cerubidine" },
  { brandName: "Cerumenex", genericName: "Cerumenex" },
  { brandName: "Cervarix", genericName: "Cervarix" },
  { brandName: "Cervidil", genericName: "Cervidil" },
  { brandName: "Cesamet", genericName: "Cesamet" },
  { brandName: "Cetirizine", genericName: "Cetirizine" },
  { brandName: "Cetraxal", genericName: "Cetraxal" },
  { brandName: "Cetrorelix", genericName: "Cetrorelix" },
  { brandName: "Cetrotide", genericName: "Cetrotide" },
  { brandName: "Cetuximab", genericName: "Cetuximab" },
  { brandName: "Cevimeline hcl", genericName: "Cevimeline hcl" },
  { brandName: "Chantix", genericName: "Chantix" },
  { brandName: "Chemet", genericName: "Chemet" },
  { brandName: "Chibroxin", genericName: "Chibroxin" },
  { brandName: "Chirhostim", genericName: "Chirhostim" },
  { brandName: "Chlor-trimeton", genericName: "Chlor-trimeton" },
  { brandName: "Chloral hydrate", genericName: "Chloral hydrate" },
  { brandName: "Chlorambucil", genericName: "Chlorambucil" },
  { brandName: "Chloramphenicol", genericName: "Chloramphenicol" },
  {
    brandName: "Chloramphenicol sodium succinate",
    genericName: "Chloramphenicol sodium succinate",
  },
  { brandName: "Chlordiazepoxide", genericName: "Chlordiazepoxide" },
  { brandName: "Chlorhexidine", genericName: "Chlorhexidine" },
  { brandName: "Chloroprocaine", genericName: "Chloroprocaine" },
  { brandName: "Chloroptic", genericName: "Chloroptic" },
  { brandName: "Chloroquine", genericName: "Chloroquine" },
  { brandName: "Chlorothiazide", genericName: "Chlorothiazide" },
  {
    brandName: "Chlorpheniramine maleate",
    genericName: "Chlorpheniramine maleate",
  },
  { brandName: "Chlorpromazine", genericName: "Chlorpromazine" },
  { brandName: "Chlorpropamide", genericName: "Chlorpropamide" },
  { brandName: "Chlorthalidone", genericName: "Chlorthalidone" },
  { brandName: "Chlorzoxazone", genericName: "Chlorzoxazone" },
  { brandName: "Cholera vaccine", genericName: "Cholera vaccine" },
  { brandName: "Cholestyramine", genericName: "Cholestyramine" },
  {
    brandName: "Choline magnesium trisalicylate",
    genericName: "Choline magnesium trisalicylate",
  },
  {
    brandName: "Chorionic gonadotropin",
    genericName: "Chorionic gonadotropin",
  },
  { brandName: "Cialis", genericName: "Cialis" },
  { brandName: "Ciclopirox gel", genericName: "Ciclopirox gel" },
  { brandName: "Cidofovir", genericName: "Cidofovir" },
  { brandName: "Cilostazol", genericName: "Cilostazol" },
  { brandName: "Cimetidine", genericName: "Cimetidine" },
  { brandName: "Cimzia", genericName: "Cimzia" },
  { brandName: "Cinacalcet", genericName: "Cinacalcet" },
  { brandName: "Cinobac", genericName: "Cinobac" },
  { brandName: "Cinoxacin", genericName: "Cinoxacin" },
  { brandName: "Cinryze", genericName: "Cinryze" },
  { brandName: "Cipro", genericName: "Cipro" },
  { brandName: "Cipro xr", genericName: "Cipro xr" },
  { brandName: "Ciprodex", genericName: "Ciprodex" },
  { brandName: "Ciprofloxacin", genericName: "Ciprofloxacin" },
  {
    brandName: "Ciprofloxacin and dexamethasone",
    genericName: "Ciprofloxacin and dexamethasone",
  },
  { brandName: "Ciprofloxacin hcl", genericName: "Ciprofloxacin hcl" },
  { brandName: "Cisapride", genericName: "Cisapride" },
  {
    brandName: "Cisatracurium besylate",
    genericName: "Cisatracurium besylate",
  },
  { brandName: "Cisplatin", genericName: "Cisplatin" },
  {
    brandName: "Citalopram hydrobromide",
    genericName: "Citalopram hydrobromide",
  },
  { brandName: "Cladribine", genericName: "Cladribine" },
  { brandName: "Claforan", genericName: "Claforan" },
  { brandName: "Clarinex", genericName: "Clarinex" },
  { brandName: "Clarinex-d 12 hour", genericName: "Clarinex-d 12 hour" },
  { brandName: "Clarinex-d 24 hour", genericName: "Clarinex-d 24 hour" },
  { brandName: "Clarithromycin", genericName: "Clarithromycin" },
  { brandName: "Claritin", genericName: "Claritin" },
  { brandName: "Cleocin", genericName: "Cleocin" },
  { brandName: "Cleocin t", genericName: "Cleocin t" },
  { brandName: "Clevidipine butyrate", genericName: "Clevidipine butyrate" },
  { brandName: "Cleviprex", genericName: "Cleviprex" },
  { brandName: "Climara", genericName: "Climara" },
  { brandName: "Climara pro", genericName: "Climara pro" },
  { brandName: "Clindamycin", genericName: "Clindamycin" },
  { brandName: "Clindamycin phosphate", genericName: "Clindamycin phosphate" },
  { brandName: "Clindets", genericName: "Clindets" },
  { brandName: "Clinoril", genericName: "Clinoril" },
  { brandName: "Clobetasol propionate", genericName: "Clobetasol propionate" },
  {
    brandName: "Clobetasol propionate gel",
    genericName: "Clobetasol propionate gel",
  },
  { brandName: "Clocortolone", genericName: "Clocortolone" },
  { brandName: "Cloderm", genericName: "Cloderm" },
  { brandName: "Clofarabine", genericName: "Clofarabine" },
  { brandName: "Clofazimine", genericName: "Clofazimine" },
  { brandName: "Clofibrate", genericName: "Clofibrate" },
  { brandName: "Clolar", genericName: "Clolar" },
  { brandName: "Clomid", genericName: "Clomid" },
  { brandName: "Clomiphene", genericName: "Clomiphene" },
  { brandName: "Clomipramine hcl", genericName: "Clomipramine hcl" },
  { brandName: "Clonazepam", genericName: "Clonazepam" },
  { brandName: "Clonidine", genericName: "Clonidine" },
  { brandName: "Clopidogrel bisulfate", genericName: "Clopidogrel bisulfate" },
  {
    brandName: "Clorazepate dipotassium",
    genericName: "Clorazepate dipotassium",
  },
  { brandName: "Clorpres", genericName: "Clorpres" },
  { brandName: "Clotrimazole", genericName: "Clotrimazole" },
  { brandName: "Clozapine", genericName: "Clozapine" },
  { brandName: "Clozaril", genericName: "Clozaril" },
  { brandName: "Coartem", genericName: "Coartem" },
  { brandName: "Cocaine", genericName: "Cocaine" },
  { brandName: "Codeine", genericName: "Codeine" },
  { brandName: "Codeine phosphate", genericName: "Codeine phosphate" },
  { brandName: "Codeine sulfate", genericName: "Codeine sulfate" },
  { brandName: "Cogentin", genericName: "Cogentin" },
  { brandName: "Cognex", genericName: "Cognex" },
  { brandName: "Colazal", genericName: "Colazal" },
  { brandName: "Colchicine", genericName: "Colchicine" },
  { brandName: "Colcrys", genericName: "Colcrys" },
  { brandName: "Colesevelam hcl", genericName: "Colesevelam hcl" },
  { brandName: "Colestid", genericName: "Colestid" },
  { brandName: "Colestipol", genericName: "Colestipol" },
  { brandName: "Collagenase", genericName: "Collagenase" },
  { brandName: "Coly-mycin m", genericName: "Coly-mycin m" },
  { brandName: "Coly-mycin s otic", genericName: "Coly-mycin s otic" },
  { brandName: "Combigan", genericName: "Combigan" },
  { brandName: "Combipatch", genericName: "Combipatch" },
  { brandName: "Combivent", genericName: "Combivent" },
  { brandName: "Combivir", genericName: "Combivir" },
  { brandName: "Combunox", genericName: "Combunox" },
  { brandName: "Compazine", genericName: "Compazine" },
  { brandName: "Compro", genericName: "Compro" },
  { brandName: "Comtan", genericName: "Comtan" },
  { brandName: "Comvax", genericName: "Comvax" },
  { brandName: "Concerta", genericName: "Concerta" },
  { brandName: "Conjugated estrogens", genericName: "Conjugated estrogens" },
  { brandName: "Copaxone", genericName: "Copaxone" },
  { brandName: "Copegus", genericName: "Copegus" },
  { brandName: "Cordarone", genericName: "Cordarone" },
  { brandName: "Coreg", genericName: "Coreg" },
  { brandName: "Coreg cr", genericName: "Coreg cr" },
  { brandName: "Corgard", genericName: "Corgard" },
  { brandName: "Corlopam", genericName: "Corlopam" },
  { brandName: "Cortaid", genericName: "Cortaid" },
  { brandName: "Cortef", genericName: "Cortef" },
  { brandName: "Cortenema", genericName: "Cortenema" },
  { brandName: "Cortisone acetate", genericName: "Cortisone acetate" },
  { brandName: "Cortone", genericName: "Cortone" },
  { brandName: "Cortrosyn", genericName: "Cortrosyn" },
  { brandName: "Corvert", genericName: "Corvert" },
  { brandName: "Corzide", genericName: "Corzide" },
  { brandName: "Cosmegen", genericName: "Cosmegen" },
  { brandName: "Cosopt", genericName: "Cosopt" },
  { brandName: "Cosyntropin", genericName: "Cosyntropin" },
  { brandName: "Coumadin", genericName: "Coumadin" },
  { brandName: "Covera-hs", genericName: "Covera-hs" },
  { brandName: "Cozaar", genericName: "Cozaar" },
  { brandName: "Creon", genericName: "Creon" },
  { brandName: "Crestor", genericName: "Crestor" },
  { brandName: "Crixivan", genericName: "Crixivan" },
  { brandName: "Crofab", genericName: "Crofab" },
  { brandName: "Crolom", genericName: "Crolom" },
  { brandName: "Cromolyn sodium", genericName: "Cromolyn sodium" },
  { brandName: "Cubicin", genericName: "Cubicin" },
  { brandName: "Cuprimine", genericName: "Cuprimine" },
  { brandName: "Curosurf", genericName: "Curosurf" },
  { brandName: "Cuvposa", genericName: "Cuvposa" },
  { brandName: "Cyanocobalamin", genericName: "Cyanocobalamin" },
  { brandName: "Cyanokit", genericName: "Cyanokit" },
  { brandName: "Cyclessa", genericName: "Cyclessa" },
  { brandName: "Cyclobenzaprine hcl", genericName: "Cyclobenzaprine hcl" },
  { brandName: "Cyclophosphamide", genericName: "Cyclophosphamide" },
  { brandName: "Cycloset", genericName: "Cycloset" },
  { brandName: "Cyclosporine", genericName: "Cyclosporine" },
  { brandName: "Cyklokapron", genericName: "Cyklokapron" },
  { brandName: "Cylert", genericName: "Cylert" },
  { brandName: "Cymbalta", genericName: "Cymbalta" },
  { brandName: "Cyproheptadine", genericName: "Cyproheptadine" },
  {
    brandName: "Cyproheptadine hydrochloride",
    genericName: "Cyproheptadine hydrochloride",
  },
  { brandName: "Cystadane", genericName: "Cystadane" },
  { brandName: "Cystagon", genericName: "Cystagon" },
  { brandName: "Cysteamine bitartrate", genericName: "Cysteamine bitartrate" },
  { brandName: "Cysview", genericName: "Cysview" },
  { brandName: "Cytadren", genericName: "Cytadren" },
  { brandName: "Cytarabine", genericName: "Cytarabine" },
  { brandName: "Cytogam", genericName: "Cytogam" },
  { brandName: "Cytomel", genericName: "Cytomel" },
  { brandName: "Cytotec", genericName: "Cytotec" },
  { brandName: "Cytovene", genericName: "Cytovene" },
  { brandName: "Cytoxan", genericName: "Cytoxan" },
  { brandName: "Dacarbazine", genericName: "Dacarbazine" },
  { brandName: "Daclizumab", genericName: "Daclizumab" },
  { brandName: "Dacogen", genericName: "Dacogen" },
  { brandName: "Dactinomycin", genericName: "Dactinomycin" },
  { brandName: "Dalmane", genericName: "Dalmane" },
  { brandName: "Dalteparin", genericName: "Dalteparin" },
  { brandName: "Danazol", genericName: "Danazol" },
  { brandName: "Dapsone", genericName: "Dapsone" },
  { brandName: "Daptacel", genericName: "Daptacel" },
  { brandName: "Daraprim", genericName: "Daraprim" },
  { brandName: "Darbepoetin alfa", genericName: "Darbepoetin alfa" },
  { brandName: "Darunavir", genericName: "Darunavir" },
  { brandName: "Darvocet-n", genericName: "Darvocet-n" },
  { brandName: "Darvon", genericName: "Darvon" },
  { brandName: "Darvon compound", genericName: "Darvon compound" },
  { brandName: "Dasatinib", genericName: "Dasatinib" },
  { brandName: "Daunorubicin", genericName: "Daunorubicin" },
  { brandName: "Daypro", genericName: "Daypro" },
  { brandName: "Daypro alta", genericName: "Daypro alta" },
  { brandName: "Daytrana", genericName: "Daytrana" },
  { brandName: "Ddavp", genericName: "Ddavp" },
  { brandName: "Ddavp nasal spray", genericName: "Ddavp nasal spray" },
  { brandName: "Ddavp rhinal tube", genericName: "Ddavp rhinal tube" },
  { brandName: "Decadron", genericName: "Decadron" },
  { brandName: "Declomycin", genericName: "Declomycin" },
  { brandName: "Deferasirox", genericName: "Deferasirox" },
  { brandName: "Deferoxamine", genericName: "Deferoxamine" },
  { brandName: "Definity", genericName: "Definity" },
  { brandName: "Dehydrated alcohol", genericName: "Dehydrated alcohol" },
  { brandName: "Delatestryl", genericName: "Delatestryl" },
  { brandName: "Delavirdine mesylate", genericName: "Delavirdine mesylate" },
  { brandName: "Delestrogen", genericName: "Delestrogen" },
  { brandName: "Deltasone", genericName: "Deltasone" },
  { brandName: "Demadex", genericName: "Demadex" },
  { brandName: "Demeclocycline hcl", genericName: "Demeclocycline hcl" },
  { brandName: "Demerol", genericName: "Demerol" },
  { brandName: "Demser", genericName: "Demser" },
  { brandName: "Demulen", genericName: "Demulen" },
  { brandName: "Denavir", genericName: "Denavir" },
  { brandName: "Denileukin diftitox", genericName: "Denileukin diftitox" },
  { brandName: "Depacon", genericName: "Depacon" },
  { brandName: "Depakene", genericName: "Depakene" },
  { brandName: "Depakote", genericName: "Depakote" },
  { brandName: "Depakote er", genericName: "Depakote er" },
  { brandName: "Depo-estradiol", genericName: "Depo-estradiol" },
  { brandName: "Depo-subq provera", genericName: "Depo-subq provera" },
  { brandName: "Depo-testosterone", genericName: "Depo-testosterone" },
  { brandName: "Depocyt", genericName: "Depocyt" },
  { brandName: "Depodur", genericName: "Depodur" },
  { brandName: "Derma-smoothe/fs", genericName: "Derma-smoothe/fs" },
  { brandName: "Desferal", genericName: "Desferal" },
  { brandName: "Desflurane", genericName: "Desflurane" },
  {
    brandName: "Desipramine hydrochloride",
    genericName: "Desipramine hydrochloride",
  },
  { brandName: "Desloratadine", genericName: "Desloratadine" },
  {
    brandName: "Desloratadine and pseudoephedrine sulfate",
    genericName: "Desloratadine and pseudoephedrine sulfate",
  },
  { brandName: "Desmopressin acetate", genericName: "Desmopressin acetate" },
  { brandName: "Desogen", genericName: "Desogen" },
  {
    brandName: "Desogestrel and ethinyl estradiol",
    genericName: "Desogestrel and ethinyl estradiol",
  },
  { brandName: "Desonate", genericName: "Desonate" },
  { brandName: "Desowen", genericName: "Desowen" },
  { brandName: "Desoximetasone", genericName: "Desoximetasone" },
  { brandName: "Desoxyn", genericName: "Desoxyn" },
  { brandName: "Desyrel", genericName: "Desyrel" },
  { brandName: "Detrol", genericName: "Detrol" },
  { brandName: "Detrol la", genericName: "Detrol la" },
  { brandName: "Dexamethasone", genericName: "Dexamethasone" },
  { brandName: "Dexedrine", genericName: "Dexedrine" },
  { brandName: "Dexfenfluramine", genericName: "Dexfenfluramine" },
  { brandName: "Dexilant", genericName: "Dexilant" },
  { brandName: "Dexlansoprazole", genericName: "Dexlansoprazole" },
  {
    brandName: "Dexmedetomidine hydrochloride",
    genericName: "Dexmedetomidine hydrochloride",
  },
  {
    brandName: "Dexmethylphenidate hydrochloride",
    genericName: "Dexmethylphenidate hydrochloride",
  },
  { brandName: "Dexrazoxane", genericName: "Dexrazoxane" },
  { brandName: "Dextroamphetamine", genericName: "Dextroamphetamine" },
  { brandName: "Dht", genericName: "Dht" },
  { brandName: "Diabeta", genericName: "Diabeta" },
  { brandName: "Diabinese", genericName: "Diabinese" },
  { brandName: "Diamox sequels", genericName: "Diamox sequels" },
  { brandName: "Diastat", genericName: "Diastat" },
  { brandName: "Diazepam", genericName: "Diazepam" },
  { brandName: "Dibenzyline", genericName: "Dibenzyline" },
  { brandName: "Diclofenac sodium", genericName: "Diclofenac sodium" },
  { brandName: "Dicloxacillin", genericName: "Dicloxacillin" },
  { brandName: "Dicyclomine", genericName: "Dicyclomine" },
  { brandName: "Didanosine", genericName: "Didanosine" },
  { brandName: "Didrex", genericName: "Didrex" },
  { brandName: "Didronel", genericName: "Didronel" },
  { brandName: "Dienestrol", genericName: "Dienestrol" },
  { brandName: "Diethylpropion", genericName: "Diethylpropion" },
  { brandName: "Difenoxin", genericName: "Difenoxin" },
  { brandName: "Differin", genericName: "Differin" },
  { brandName: "Diflucan", genericName: "Diflucan" },
  { brandName: "Diflunisal", genericName: "Diflunisal" },
  { brandName: "Digibind", genericName: "Digibind" },
  { brandName: "Digitek", genericName: "Digitek" },
  { brandName: "Digoxin immune fab", genericName: "Digoxin immune fab" },
  { brandName: "Dihydroergotamine", genericName: "Dihydroergotamine" },
  { brandName: "Dihydrotachysterol", genericName: "Dihydrotachysterol" },
  { brandName: "Dilacor xr", genericName: "Dilacor xr" },
  { brandName: "Dilantin", genericName: "Dilantin" },
  { brandName: "Dilantin infatabs", genericName: "Dilantin infatabs" },
  { brandName: "Dilaudid", genericName: "Dilaudid" },
  { brandName: "Dilaudid-hp", genericName: "Dilaudid-hp" },
  { brandName: "Diltiazem", genericName: "Diltiazem" },
  { brandName: "Diltiazem hcl", genericName: "Diltiazem hcl" },
  { brandName: "Dimetane", genericName: "Dimetane" },
  { brandName: "Dinoprostone", genericName: "Dinoprostone" },
  { brandName: "Diovan", genericName: "Diovan" },
  { brandName: "Diovan hct", genericName: "Diovan hct" },
  { brandName: "Dipentum", genericName: "Dipentum" },
  { brandName: "Diphenhydramine", genericName: "Diphenhydramine" },
  {
    brandName: "Diphtheria-tetanus vaccine",
    genericName: "Diphtheria-tetanus vaccine",
  },
  { brandName: "Dipivefrin", genericName: "Dipivefrin" },
  { brandName: "Diprivan", genericName: "Diprivan" },
  { brandName: "Diprolene af", genericName: "Diprolene af" },
  { brandName: "Dipyridamole", genericName: "Dipyridamole" },
  { brandName: "Disalcid", genericName: "Disalcid" },
  {
    brandName: "Disopyramide phosphate",
    genericName: "Disopyramide phosphate",
  },
  { brandName: "Disulfiram", genericName: "Disulfiram" },
  { brandName: "Ditropan", genericName: "Ditropan" },
  { brandName: "Ditropan xl", genericName: "Ditropan xl" },
  { brandName: "Diucardin", genericName: "Diucardin" },
  { brandName: "Diuril", genericName: "Diuril" },
  { brandName: "Divalproex sodium", genericName: "Divalproex sodium" },
  { brandName: "Dmso", genericName: "Dmso" },
  { brandName: "Dobutamine", genericName: "Dobutamine" },
  { brandName: "Dofetilide", genericName: "Dofetilide" },
  { brandName: "Dolasetron", genericName: "Dolasetron" },
  { brandName: "Dolobid", genericName: "Dolobid" },
  { brandName: "Dolophine", genericName: "Dolophine" },
  {
    brandName: "Donepezil hydrochloride",
    genericName: "Donepezil hydrochloride",
  },
  { brandName: "Dopamine", genericName: "Dopamine" },
  {
    brandName: "Dopamine hydrochloride",
    genericName: "Dopamine hydrochloride",
  },
  { brandName: "Dopar", genericName: "Dopar" },
  { brandName: "Dopram", genericName: "Dopram" },
  { brandName: "Doral", genericName: "Doral" },
  { brandName: "Doribax", genericName: "Doribax" },
  { brandName: "Dornase alfa", genericName: "Dornase alfa" },
  { brandName: "Doryx", genericName: "Doryx" },
  { brandName: "Dorzolamide", genericName: "Dorzolamide" },
  { brandName: "Dostinex", genericName: "Dostinex" },
  { brandName: "Dovonex", genericName: "Dovonex" },
  { brandName: "Doxacurium chloride", genericName: "Doxacurium chloride" },
  { brandName: "Doxapram", genericName: "Doxapram" },
  { brandName: "Doxazosin mesylate", genericName: "Doxazosin mesylate" },
  { brandName: "Doxepin", genericName: "Doxepin" },
  { brandName: "Doxercalciferol", genericName: "Doxercalciferol" },
  { brandName: "Doxil", genericName: "Doxil" },
  {
    brandName: "Doxorubicin hydrochloride",
    genericName: "Doxorubicin hydrochloride",
  },
  { brandName: "Doxycycline", genericName: "Doxycycline" },
  { brandName: "Doxycycline hyclate", genericName: "Doxycycline hyclate" },
  { brandName: "Drisdol", genericName: "Drisdol" },
  { brandName: "Dronabinol", genericName: "Dronabinol" },
  { brandName: "Dronedarone", genericName: "Dronedarone" },
  { brandName: "Droperidol", genericName: "Droperidol" },
  {
    brandName: "Drospirenone and estradiol",
    genericName: "Drospirenone and estradiol",
  },
  { brandName: "Drotrecogin alfa", genericName: "Drotrecogin alfa" },
  { brandName: "Droxia", genericName: "Droxia" },
  { brandName: "Dtic-dome", genericName: "Dtic-dome" },
  { brandName: "Dtp", genericName: "Dtp" },
  { brandName: "Duetact", genericName: "Duetact" },
  { brandName: "Dulera", genericName: "Dulera" },
  { brandName: "Duloxetine hcl", genericName: "Duloxetine hcl" },
  { brandName: "Duoneb", genericName: "Duoneb" },
  { brandName: "Duraclon", genericName: "Duraclon" },
  { brandName: "Duragesic", genericName: "Duragesic" },
  { brandName: "Duramorph", genericName: "Duramorph" },
  { brandName: "Duranest", genericName: "Duranest" },
  { brandName: "Durezol", genericName: "Durezol" },
  { brandName: "Duricef", genericName: "Duricef" },
  { brandName: "Dutasteride", genericName: "Dutasteride" },
  { brandName: "Dyazide", genericName: "Dyazide" },
  { brandName: "Dynacirc", genericName: "Dynacirc" },
  { brandName: "Dynacirc cr", genericName: "Dynacirc cr" },
  { brandName: "Dynapen", genericName: "Dynapen" },
  { brandName: "Dyphylline", genericName: "Dyphylline" },
  { brandName: "Dyrenium", genericName: "Dyrenium" },
  { brandName: "Dysport", genericName: "Dysport" },
  { brandName: "Econazole nitrate", genericName: "Econazole nitrate" },
  { brandName: "Eculizumab", genericName: "Eculizumab" },
  { brandName: "Edecrin", genericName: "Edecrin" },
  { brandName: "Edetate calcium", genericName: "Edetate calcium" },
  { brandName: "Edex", genericName: "Edex" },
  { brandName: "Edluar", genericName: "Edluar" },
  { brandName: "Edrophonium", genericName: "Edrophonium" },
  { brandName: "Efalizumab", genericName: "Efalizumab" },
  { brandName: "Efavirenz", genericName: "Efavirenz" },
  { brandName: "Effexor", genericName: "Effexor" },
  { brandName: "Effexor xr", genericName: "Effexor xr" },
  { brandName: "Effient", genericName: "Effient" },
  { brandName: "Eflornithine", genericName: "Eflornithine" },
  { brandName: "Efudex", genericName: "Efudex" },
  { brandName: "Elaprase", genericName: "Elaprase" },
  { brandName: "Elavil", genericName: "Elavil" },
  { brandName: "Eldepryl", genericName: "Eldepryl" },
  { brandName: "Elestat", genericName: "Elestat" },
  {
    brandName: "Eletriptan hydrobromide",
    genericName: "Eletriptan hydrobromide",
  },
  { brandName: "Elidel", genericName: "Elidel" },
  { brandName: "Eligard", genericName: "Eligard" },
  { brandName: "Elimite", genericName: "Elimite" },
  { brandName: "Elitek", genericName: "Elitek" },
  { brandName: "Ellence", genericName: "Ellence" },
  { brandName: "Elmiron", genericName: "Elmiron" },
  { brandName: "Elocon", genericName: "Elocon" },
  { brandName: "Eloxatin", genericName: "Eloxatin" },
  { brandName: "Elspar", genericName: "Elspar" },
  { brandName: "Eltrombopag", genericName: "Eltrombopag" },
  { brandName: "Emadine", genericName: "Emadine" },
  { brandName: "Embeda", genericName: "Embeda" },
  { brandName: "Emcyt", genericName: "Emcyt" },
  { brandName: "Emedastine", genericName: "Emedastine" },
  { brandName: "Emend", genericName: "Emend" },
  { brandName: "Emgel", genericName: "Emgel" },
  { brandName: "Emla", genericName: "Emla" },
  { brandName: "Empirin", genericName: "Empirin" },
  { brandName: "Emsam", genericName: "Emsam" },
  { brandName: "Emtricitabine", genericName: "Emtricitabine" },
  { brandName: "Emtriva", genericName: "Emtriva" },
  { brandName: "Enablex", genericName: "Enablex" },
  { brandName: "Enalapril", genericName: "Enalapril" },
  { brandName: "Enbrel", genericName: "Enbrel" },
  { brandName: "Endocet", genericName: "Endocet" },
  { brandName: "Endometrin", genericName: "Endometrin" },
  { brandName: "Endrate", genericName: "Endrate" },
  { brandName: "Enflurane", genericName: "Enflurane" },
  { brandName: "Enfuvirtide", genericName: "Enfuvirtide" },
  { brandName: "Engerix-b", genericName: "Engerix-b" },
  { brandName: "Enjuvia", genericName: "Enjuvia" },
  { brandName: "Enlon", genericName: "Enlon" },
  { brandName: "Enoxacin", genericName: "Enoxacin" },
  { brandName: "Enoxaparin", genericName: "Enoxaparin" },
  { brandName: "Entacapone", genericName: "Entacapone" },
  { brandName: "Entecavir", genericName: "Entecavir" },
  { brandName: "Entereg", genericName: "Entereg" },
  { brandName: "Entex", genericName: "Entex" },
  { brandName: "Entocort ec", genericName: "Entocort ec" },
  { brandName: "Eovist", genericName: "Eovist" },
  { brandName: "Ephedrine", genericName: "Ephedrine" },
  { brandName: "Epiduo", genericName: "Epiduo" },
  { brandName: "Epinastine hcl", genericName: "Epinastine hcl" },
  { brandName: "Epinephrine", genericName: "Epinephrine" },
  { brandName: "Epipen", genericName: "Epipen" },
  {
    brandName: "Epirubicin hydrochloride",
    genericName: "Epirubicin hydrochloride",
  },
  { brandName: "Epivir", genericName: "Epivir" },
  { brandName: "Eplerenone", genericName: "Eplerenone" },
  { brandName: "Epoetin alfa", genericName: "Epoetin alfa" },
  { brandName: "Epogen", genericName: "Epogen" },
  { brandName: "Epoprostenol sodium", genericName: "Epoprostenol sodium" },
  { brandName: "Eprosartan mesylate", genericName: "Eprosartan mesylate" },
  { brandName: "Eptifibatide", genericName: "Eptifibatide" },
  { brandName: "Epzicom", genericName: "Epzicom" },
  { brandName: "Equagesic", genericName: "Equagesic" },
  { brandName: "Equetro", genericName: "Equetro" },
  { brandName: "Eraxis", genericName: "Eraxis" },
  { brandName: "Erbitux", genericName: "Erbitux" },
  { brandName: "Ergocalciferol", genericName: "Ergocalciferol" },
  { brandName: "Ergomar", genericName: "Ergomar" },
  {
    brandName: "Ergotamine tartrate and caffeine",
    genericName: "Ergotamine tartrate and caffeine",
  },
  { brandName: "Erlotinib", genericName: "Erlotinib" },
  { brandName: "Ertaczo", genericName: "Ertaczo" },
  { brandName: "Ertapenem", genericName: "Ertapenem" },
  { brandName: "Ery-tab", genericName: "Ery-tab" },
  { brandName: "Eryc", genericName: "Eryc" },
  { brandName: "Erygel", genericName: "Erygel" },
  { brandName: "Eryped", genericName: "Eryped" },
  { brandName: "Erythrocin stearate", genericName: "Erythrocin stearate" },
  { brandName: "Erythromycin", genericName: "Erythromycin" },
  {
    brandName: "Erythromycin ethylsuccinate",
    genericName: "Erythromycin ethylsuccinate",
  },
  {
    brandName: "Erythromycin lactobionate",
    genericName: "Erythromycin lactobionate",
  },
  { brandName: "Escitalopram oxalate", genericName: "Escitalopram oxalate" },
  { brandName: "Esclim", genericName: "Esclim" },
  { brandName: "Eskalith", genericName: "Eskalith" },
  { brandName: "Esmolol", genericName: "Esmolol" },
  {
    brandName: "Esomeprazole magnesium",
    genericName: "Esomeprazole magnesium",
  },
  { brandName: "Esomeprazole sodium", genericName: "Esomeprazole sodium" },
  { brandName: "Essential amino acids", genericName: "Essential amino acids" },
  { brandName: "Estazolam", genericName: "Estazolam" },
  { brandName: "Esterified estrogens", genericName: "Esterified estrogens" },
  { brandName: "Estrace", genericName: "Estrace" },
  { brandName: "Estraderm", genericName: "Estraderm" },
  { brandName: "Estradiol", genericName: "Estradiol" },
  { brandName: "Estradiol acetate", genericName: "Estradiol acetate" },
  { brandName: "Estradiol valerate", genericName: "Estradiol valerate" },
  { brandName: "Estramustine", genericName: "Estramustine" },
  { brandName: "Estratest", genericName: "Estratest" },
  { brandName: "Estring", genericName: "Estring" },
  { brandName: "Estrogel", genericName: "Estrogel" },
  { brandName: "Estrogens conjugated", genericName: "Estrogens conjugated" },
  { brandName: "Estropipate", genericName: "Estropipate" },
  { brandName: "Estrostep 21", genericName: "Estrostep 21" },
  { brandName: "Eszopiclone", genericName: "Eszopiclone" },
  { brandName: "Etanercept", genericName: "Etanercept" },
  { brandName: "Ethacrynic acid", genericName: "Ethacrynic acid" },
  { brandName: "Ethambutol", genericName: "Ethambutol" },
  { brandName: "Ethamolin", genericName: "Ethamolin" },
  { brandName: "Ethanolamine oleate", genericName: "Ethanolamine oleate" },
  { brandName: "Ethedent", genericName: "Ethedent" },
  { brandName: "Ethinyl estradiol", genericName: "Ethinyl estradiol" },
  { brandName: "Ethiodized oil", genericName: "Ethiodized oil" },
  { brandName: "Ethiodol", genericName: "Ethiodol" },
  { brandName: "Ethionamide", genericName: "Ethionamide" },
  { brandName: "Ethosuximide", genericName: "Ethosuximide" },
  { brandName: "Ethotoin", genericName: "Ethotoin" },
  { brandName: "Ethrane", genericName: "Ethrane" },
  { brandName: "Ethyl chloride", genericName: "Ethyl chloride" },
  { brandName: "Ethyol", genericName: "Ethyol" },
  { brandName: "Etidocaine hcl", genericName: "Etidocaine hcl" },
  { brandName: "Etidronate disodium", genericName: "Etidronate disodium" },
  { brandName: "Etodolac", genericName: "Etodolac" },
  { brandName: "Etonogestrel", genericName: "Etonogestrel" },
  { brandName: "Etopophos", genericName: "Etopophos" },
  { brandName: "Etoposide", genericName: "Etoposide" },
  { brandName: "Etoposide phosphate", genericName: "Etoposide phosphate" },
  { brandName: "Etrafon", genericName: "Etrafon" },
  { brandName: "Eulexin", genericName: "Eulexin" },
  { brandName: "Eurax", genericName: "Eurax" },
  { brandName: "Evamist", genericName: "Evamist" },
  { brandName: "Everolimus", genericName: "Everolimus" },
  { brandName: "Evista", genericName: "Evista" },
  { brandName: "Evoclin", genericName: "Evoclin" },
  { brandName: "Evoxac", genericName: "Evoxac" },
  { brandName: "Exalgo", genericName: "Exalgo" },
  { brandName: "Exelderm", genericName: "Exelderm" },
  { brandName: "Exelon", genericName: "Exelon" },
  { brandName: "Exemestane", genericName: "Exemestane" },
  { brandName: "Exenatide", genericName: "Exenatide" },
  { brandName: "Exforge", genericName: "Exforge" },
  { brandName: "Exforge hct", genericName: "Exforge hct" },
  { brandName: "Exjade", genericName: "Exjade" },
  { brandName: "Extavia", genericName: "Extavia" },
  {
    brandName: "Extended phenytoin sodium",
    genericName: "Extended phenytoin sodium",
  },
  { brandName: "Extina", genericName: "Extina" },
  { brandName: "Extraneal", genericName: "Extraneal" },
  { brandName: "Exubera", genericName: "Exubera" },
  { brandName: "Ezetimibe", genericName: "Ezetimibe" },
  {
    brandName: "Ezetimibe and simvastatin",
    genericName: "Ezetimibe and simvastatin",
  },
  { brandName: "Fabrazyme", genericName: "Fabrazyme" },
  { brandName: "Factive", genericName: "Factive" },
  { brandName: "Factor ix complex", genericName: "Factor ix complex" },
  { brandName: "Factrel", genericName: "Factrel" },
  { brandName: "Famciclovir", genericName: "Famciclovir" },
  { brandName: "Famotidine", genericName: "Famotidine" },
  { brandName: "Famotidine injection", genericName: "Famotidine injection" },
  { brandName: "Famvir", genericName: "Famvir" },
  { brandName: "Fanapt", genericName: "Fanapt" },
  { brandName: "Fansidar", genericName: "Fansidar" },
  { brandName: "Fareston", genericName: "Fareston" },
  { brandName: "Faslodex", genericName: "Faslodex" },
  { brandName: "Fastin", genericName: "Fastin" },
  { brandName: "Fazaclo", genericName: "Fazaclo" },
  { brandName: "Febuxostat", genericName: "Febuxostat" },
  { brandName: "Feiba-vh", genericName: "Feiba-vh" },
  { brandName: "Felbamate", genericName: "Felbamate" },
  { brandName: "Felbatol", genericName: "Felbatol" },
  { brandName: "Feldene", genericName: "Feldene" },
  { brandName: "Felodipine", genericName: "Felodipine" },
  { brandName: "Femara", genericName: "Femara" },
  { brandName: "Femcon fe", genericName: "Femcon fe" },
  { brandName: "Femhrt", genericName: "Femhrt" },
  { brandName: "Femring", genericName: "Femring" },
  { brandName: "Fenofibrate", genericName: "Fenofibrate" },
  { brandName: "Fenofibric acid", genericName: "Fenofibric acid" },
  { brandName: "Fenoglide", genericName: "Fenoglide" },
  { brandName: "Fenoldopam mesylate", genericName: "Fenoldopam mesylate" },
  { brandName: "Fenoprofen calcium", genericName: "Fenoprofen calcium" },
  { brandName: "Fentanyl buccal", genericName: "Fentanyl buccal" },
  { brandName: "Fentanyl citrate", genericName: "Fentanyl citrate" },
  { brandName: "Fentora", genericName: "Fentora" },
  { brandName: "Feraheme", genericName: "Feraheme" },
  { brandName: "Feridex i.v.", genericName: "Feridex i.v." },
  { brandName: "Ferrlecit", genericName: "Ferrlecit" },
  { brandName: "Fertinex", genericName: "Fertinex" },
  { brandName: "Ferumoxides", genericName: "Ferumoxides" },
  { brandName: "Ferumoxytol", genericName: "Ferumoxytol" },
  {
    brandName: "Fesoterodine fumarate extended",
    genericName: "Fesoterodine fumarate extended",
  },
  { brandName: "Fexofenadine hcl", genericName: "Fexofenadine hcl" },
  { brandName: "Filgrastim", genericName: "Filgrastim" },
  { brandName: "Finacea", genericName: "Finacea" },
  { brandName: "Finasteride", genericName: "Finasteride" },
  { brandName: "Fioricet", genericName: "Fioricet" },
  { brandName: "Fiorinal with codeine", genericName: "Fiorinal with codeine" },
  { brandName: "Flagyl", genericName: "Flagyl" },
  { brandName: "Flavoxate hcl", genericName: "Flavoxate hcl" },
  { brandName: "Flecainide", genericName: "Flecainide" },
  { brandName: "Flector", genericName: "Flector" },
  { brandName: "Flexeril", genericName: "Flexeril" },
  { brandName: "Flo-pred", genericName: "Flo-pred" },
  { brandName: "Flolan", genericName: "Flolan" },
  { brandName: "Flomax", genericName: "Flomax" },
  { brandName: "Flonase", genericName: "Flonase" },
  { brandName: "Florinef", genericName: "Florinef" },
  { brandName: "Flovent", genericName: "Flovent" },
  { brandName: "Flovent diskus", genericName: "Flovent diskus" },
  { brandName: "Flovent hfa", genericName: "Flovent hfa" },
  { brandName: "Floxin", genericName: "Floxin" },
  { brandName: "Floxin otic", genericName: "Floxin otic" },
  { brandName: "Floxuridine", genericName: "Floxuridine" },
  { brandName: "Fluconazole", genericName: "Fluconazole" },
  { brandName: "Flucytosine", genericName: "Flucytosine" },
  { brandName: "Fludara", genericName: "Fludara" },
  { brandName: "Fludarabine", genericName: "Fludarabine" },
  { brandName: "Fludarabine phosphate", genericName: "Fludarabine phosphate" },
  { brandName: "Fludeoxyglucose f 18", genericName: "Fludeoxyglucose f 18" },
  { brandName: "Fludrocortisone", genericName: "Fludrocortisone" },
  { brandName: "Flumadine", genericName: "Flumadine" },
  { brandName: "Flumazenil", genericName: "Flumazenil" },
  { brandName: "Flumist", genericName: "Flumist" },
  { brandName: "Flunisolide", genericName: "Flunisolide" },
  {
    brandName: "Fluocinolone acetonide",
    genericName: "Fluocinolone acetonide",
  },
  { brandName: "Fluocinonide", genericName: "Fluocinonide" },
  { brandName: "Fluorescein", genericName: "Fluorescein" },
  { brandName: "Fluorescite", genericName: "Fluorescite" },
  { brandName: "Fluoride", genericName: "Fluoride" },
  { brandName: "Fluorometholone", genericName: "Fluorometholone" },
  { brandName: "Fluoroplex", genericName: "Fluoroplex" },
  { brandName: "Fluorouracil", genericName: "Fluorouracil" },
  { brandName: "Fluothane", genericName: "Fluothane" },
  { brandName: "Fluoxetine hcl", genericName: "Fluoxetine hcl" },
  {
    brandName: "Fluoxetine hydrochloride",
    genericName: "Fluoxetine hydrochloride",
  },
  { brandName: "Fluoxymesterone", genericName: "Fluoxymesterone" },
  { brandName: "Fluphenazine", genericName: "Fluphenazine" },
  { brandName: "Flurandrenolide", genericName: "Flurandrenolide" },
  { brandName: "Flurazepam", genericName: "Flurazepam" },
  { brandName: "Flurbiprofen", genericName: "Flurbiprofen" },
  { brandName: "Fluress", genericName: "Fluress" },
  { brandName: "Flutamide", genericName: "Flutamide" },
  { brandName: "Fluticasone furoate", genericName: "Fluticasone furoate" },
  {
    brandName: "Fluticasone propionate",
    genericName: "Fluticasone propionate",
  },
  { brandName: "Fluvastatin sodium", genericName: "Fluvastatin sodium" },
  { brandName: "Fluvirin", genericName: "Fluvirin" },
  { brandName: "Fluvoxamine maleate", genericName: "Fluvoxamine maleate" },
  { brandName: "Fml", genericName: "Fml" },
  { brandName: "Fml forte", genericName: "Fml forte" },
  { brandName: "Focalin", genericName: "Focalin" },
  { brandName: "Focalin xr", genericName: "Focalin xr" },
  { brandName: "Folic acid", genericName: "Folic acid" },
  { brandName: "Follistim aq", genericName: "Follistim aq" },
  { brandName: "Follitropin alfa", genericName: "Follitropin alfa" },
  { brandName: "Follitropin beta", genericName: "Follitropin beta" },
  { brandName: "Folotyn", genericName: "Folotyn" },
  { brandName: "Foltx", genericName: "Foltx" },
  { brandName: "Folvite", genericName: "Folvite" },
  { brandName: "Fomepizole", genericName: "Fomepizole" },
  { brandName: "Fomivirsen", genericName: "Fomivirsen" },
  { brandName: "Fondaparinux sodium", genericName: "Fondaparinux sodium" },
  { brandName: "Foradil aerolizer", genericName: "Foradil aerolizer" },
  { brandName: "Foradil certihaler", genericName: "Foradil certihaler" },
  { brandName: "Forane", genericName: "Forane" },
  {
    brandName: "Formoterol fumarate inhalation powder",
    genericName: "Formoterol fumarate inhalation powder",
  },
  { brandName: "Fortamet", genericName: "Fortamet" },
  { brandName: "Fortaz", genericName: "Fortaz" },
  { brandName: "Forteo", genericName: "Forteo" },
  { brandName: "Fortical", genericName: "Fortical" },
  { brandName: "Fosamax", genericName: "Fosamax" },
  { brandName: "Fosamax plus d", genericName: "Fosamax plus d" },
  { brandName: "Fosamprenavir calcium", genericName: "Fosamprenavir calcium" },
  {
    brandName: "Fosaprepitant dimeglumine",
    genericName: "Fosaprepitant dimeglumine",
  },
  { brandName: "Foscarnet sodium", genericName: "Foscarnet sodium" },
  { brandName: "Foscavir", genericName: "Foscavir" },
  { brandName: "Fosfomycin", genericName: "Fosfomycin" },
  { brandName: "Fosinopril sodium", genericName: "Fosinopril sodium" },
  { brandName: "Fosphenytoin sodium", genericName: "Fosphenytoin sodium" },
  { brandName: "Fospropofol disodium", genericName: "Fospropofol disodium" },
  { brandName: "Fosrenol", genericName: "Fosrenol" },
  { brandName: "Fragmin", genericName: "Fragmin" },
  { brandName: "Frova", genericName: "Frova" },
  {
    brandName: "Frovatriptan succinate",
    genericName: "Frovatriptan succinate",
  },
  { brandName: "Fulvestrant", genericName: "Fulvestrant" },
  { brandName: "Fungizone", genericName: "Fungizone" },
  { brandName: "Furadantin", genericName: "Furadantin" },
  { brandName: "Furazolidone", genericName: "Furazolidone" },
  { brandName: "Furosemide", genericName: "Furosemide" },
  { brandName: "Furoxone", genericName: "Furoxone" },
  { brandName: "Fuzeon", genericName: "Fuzeon" },
  { brandName: "Gabapentin", genericName: "Gabapentin" },
  { brandName: "Gabitril", genericName: "Gabitril" },
  {
    brandName: "Gadobenate dimeglumine",
    genericName: "Gadobenate dimeglumine",
  },
  { brandName: "Gadodiamide", genericName: "Gadodiamide" },
  {
    brandName: "Gadofosveset trisodium",
    genericName: "Gadofosveset trisodium",
  },
  {
    brandName: "Gadopentetate dimeglumine",
    genericName: "Gadopentetate dimeglumine",
  },
  { brandName: "Gadoteridol", genericName: "Gadoteridol" },
  { brandName: "Gadoversetamide", genericName: "Gadoversetamide" },
  { brandName: "Gadoxetate disodium", genericName: "Gadoxetate disodium" },
  { brandName: "Galantamine", genericName: "Galantamine" },
  { brandName: "Galsulfase", genericName: "Galsulfase" },
  { brandName: "Gammagard", genericName: "Gammagard" },
  { brandName: "Gamunex", genericName: "Gamunex" },
  { brandName: "Ganciclovir", genericName: "Ganciclovir" },
  { brandName: "Ganirelix", genericName: "Ganirelix" },
  {
    brandName: "Ganirelix acetate injection",
    genericName: "Ganirelix acetate injection",
  },
  { brandName: "Gantanol", genericName: "Gantanol" },
  { brandName: "Gantrisin", genericName: "Gantrisin" },
  { brandName: "Gardasil", genericName: "Gardasil" },
  { brandName: "Gatifloxacin", genericName: "Gatifloxacin" },
  { brandName: "Gelnique", genericName: "Gelnique" },
  { brandName: "Gemcitabine hcl", genericName: "Gemcitabine hcl" },
  { brandName: "Gemfibrozil", genericName: "Gemfibrozil" },
  { brandName: "Gemifloxacin mesylate", genericName: "Gemifloxacin mesylate" },
  { brandName: "Gemtuzumab ozogamicin", genericName: "Gemtuzumab ozogamicin" },
  { brandName: "Gemzar", genericName: "Gemzar" },
  { brandName: "Gengraf", genericName: "Gengraf" },
  { brandName: "Genoptic", genericName: "Genoptic" },
  { brandName: "Genotropin", genericName: "Genotropin" },
  { brandName: "Gentak", genericName: "Gentak" },
  { brandName: "Gentamicin", genericName: "Gentamicin" },
  { brandName: "Geocillin", genericName: "Geocillin" },
  { brandName: "Geodon", genericName: "Geodon" },
  { brandName: "Glatiramer acetate", genericName: "Glatiramer acetate" },
  { brandName: "Gleevec", genericName: "Gleevec" },
  { brandName: "Gliadel", genericName: "Gliadel" },
  { brandName: "Glimepiride", genericName: "Glimepiride" },
  { brandName: "Glipizide", genericName: "Glipizide" },
  { brandName: "Glucagen", genericName: "Glucagen" },
  { brandName: "Glucagon", genericName: "Glucagon" },
  { brandName: "Glucophage xr", genericName: "Glucophage xr" },
  { brandName: "Glucotrol", genericName: "Glucotrol" },
  { brandName: "Glucotrol xl", genericName: "Glucotrol xl" },
  { brandName: "Glucovance", genericName: "Glucovance" },
  { brandName: "Glumetza", genericName: "Glumetza" },
  { brandName: "Glyburide", genericName: "Glyburide" },
  { brandName: "Glycopyrrolate", genericName: "Glycopyrrolate" },
  { brandName: "Glynase", genericName: "Glynase" },
  { brandName: "Glyset", genericName: "Glyset" },
  {
    brandName: "Gold sodium thiomalate",
    genericName: "Gold sodium thiomalate",
  },
  { brandName: "Golimumab", genericName: "Golimumab" },
  { brandName: "Gonadorelin", genericName: "Gonadorelin" },
  { brandName: "Gonal-f", genericName: "Gonal-f" },
  { brandName: "Gonal-f rff", genericName: "Gonal-f rff" },
  { brandName: "Goserelin acetate", genericName: "Goserelin acetate" },
  {
    brandName: "Goserelin acetate implant",
    genericName: "Goserelin acetate implant",
  },
  { brandName: "Granisetron", genericName: "Granisetron" },
  {
    brandName: "Granisetron hydrochloride",
    genericName: "Granisetron hydrochloride",
  },
  { brandName: "Grepafloxacin", genericName: "Grepafloxacin" },
  { brandName: "Grifulvin v", genericName: "Grifulvin v" },
  { brandName: "Gris-peg", genericName: "Gris-peg" },
  { brandName: "Griseofulvin", genericName: "Griseofulvin" },
  { brandName: "Guaifenesin", genericName: "Guaifenesin" },
  {
    brandName: "Guanethidine monosulfate",
    genericName: "Guanethidine monosulfate",
  },
  { brandName: "Guanfacine", genericName: "Guanfacine" },
  {
    brandName: "Guanfacine hydrochloride",
    genericName: "Guanfacine hydrochloride",
  },
  { brandName: "Gynazole", genericName: "Gynazole" },
  { brandName: "Gyne-lotrimin", genericName: "Gyne-lotrimin" },
  {
    brandName: "Haemophilus b conjugate vaccine",
    genericName: "Haemophilus b conjugate vaccine",
  },
  { brandName: "Halcinonide", genericName: "Halcinonide" },
  { brandName: "Halcion", genericName: "Halcion" },
  { brandName: "Haldol", genericName: "Haldol" },
  { brandName: "Haldol decanoate", genericName: "Haldol decanoate" },
  {
    brandName: "Halflytely and bisacodyl",
    genericName: "Halflytely and bisacodyl",
  },
  {
    brandName: "Halobetasol propionate",
    genericName: "Halobetasol propionate",
  },
  { brandName: "Halog", genericName: "Halog" },
  { brandName: "Haloperidol", genericName: "Haloperidol" },
  { brandName: "Haloperidol decanoate", genericName: "Haloperidol decanoate" },
  { brandName: "Halotestin", genericName: "Halotestin" },
  { brandName: "Halothane", genericName: "Halothane" },
  { brandName: "Havrix", genericName: "Havrix" },
  { brandName: "Healon", genericName: "Healon" },
  { brandName: "Hectorol", genericName: "Hectorol" },
  { brandName: "Helidac", genericName: "Helidac" },
  { brandName: "Hemabate", genericName: "Hemabate" },
  { brandName: "Hemin", genericName: "Hemin" },
  { brandName: "Hep-lock u/p", genericName: "Hep-lock u/p" },
  { brandName: "Hepagam b", genericName: "Hepagam b" },
  { brandName: "Heparin", genericName: "Heparin" },
  { brandName: "Heparin lock flush", genericName: "Heparin lock flush" },
  { brandName: "Heparin sodium", genericName: "Heparin sodium" },
  { brandName: "Hepatamine", genericName: "Hepatamine" },
  {
    brandName: "Hepatitis a vaccine, inactivated",
    genericName: "Hepatitis a vaccine, inactivated",
  },
  {
    brandName: "Hepatitis b immune globulin",
    genericName: "Hepatitis b immune globulin",
  },
  { brandName: "Hepatitis b vaccine", genericName: "Hepatitis b vaccine" },
  {
    brandName: "Hepatitis b vaccine recombinant",
    genericName: "Hepatitis b vaccine recombinant",
  },
  { brandName: "Hepflush-10", genericName: "Hepflush-10" },
  { brandName: "Hepsera", genericName: "Hepsera" },
  { brandName: "Herceptin", genericName: "Herceptin" },
  { brandName: "Hexachlorophene", genericName: "Hexachlorophene" },
  { brandName: "Hexalen", genericName: "Hexalen" },
  { brandName: "Hibtiter", genericName: "Hibtiter" },
  { brandName: "Hiprex", genericName: "Hiprex" },
  { brandName: "Hismanal", genericName: "Hismanal" },
  { brandName: "Hivid", genericName: "Hivid" },
  { brandName: "Hms", genericName: "Hms" },
  { brandName: "Humalog", genericName: "Humalog" },
  { brandName: "Human secretin", genericName: "Human secretin" },
  { brandName: "Humatrope", genericName: "Humatrope" },
  { brandName: "Humira", genericName: "Humira" },
  { brandName: "Humorsol", genericName: "Humorsol" },
  { brandName: "Humulin n", genericName: "Humulin n" },
  { brandName: "Humulin r", genericName: "Humulin r" },
  { brandName: "Hyalgan", genericName: "Hyalgan" },
  { brandName: "Hycamtin", genericName: "Hycamtin" },
  { brandName: "Hycodan", genericName: "Hycodan" },
  { brandName: "Hydralazine", genericName: "Hydralazine" },
  { brandName: "Hydrea", genericName: "Hydrea" },
  {
    brandName: "Hydrochlorothiazide and triamterene",
    genericName: "Hydrochlorothiazide and triamterene",
  },
  {
    brandName: "Hydrocodone and ibuprofen",
    genericName: "Hydrocodone and ibuprofen",
  },
  {
    brandName: "Hydrocodone bitartrate and acetaminophen",
    genericName: "Hydrocodone bitartrate and acetaminophen",
  },
  {
    brandName: "Hydrocodone bitartrate and acetaminophen tablets",
    genericName: "Hydrocodone bitartrate and acetaminophen tablets",
  },
  { brandName: "Hydrocortisone", genericName: "Hydrocortisone" },
  {
    brandName: "Hydrocortisone and acetic acid",
    genericName: "Hydrocortisone and acetic acid",
  },
  {
    brandName: "Hydrocortisone butyrate",
    genericName: "Hydrocortisone butyrate",
  },
  {
    brandName: "Hydrocortisone sodium succinate",
    genericName: "Hydrocortisone sodium succinate",
  },
  { brandName: "Hydroflumethiazide", genericName: "Hydroflumethiazide" },
  {
    brandName: "Hydromorphone hydrochloride",
    genericName: "Hydromorphone hydrochloride",
  },
  { brandName: "Hydroquinone", genericName: "Hydroquinone" },
  { brandName: "Hydroxychloroquine", genericName: "Hydroxychloroquine" },
  { brandName: "Hydroxyethyl starch", genericName: "Hydroxyethyl starch" },
  { brandName: "Hydroxyurea", genericName: "Hydroxyurea" },
  { brandName: "Hydroxyzine", genericName: "Hydroxyzine" },
  {
    brandName: "Hydroxyzine hydrochloride",
    genericName: "Hydroxyzine hydrochloride",
  },
  { brandName: "Hylan g-f 20", genericName: "Hylan g-f 20" },
  { brandName: "Hylenex", genericName: "Hylenex" },
  { brandName: "Hyoscyamine", genericName: "Hyoscyamine" },
  { brandName: "Hyoscyamine sulfate", genericName: "Hyoscyamine sulfate" },
  { brandName: "Hytrin", genericName: "Hytrin" },
  { brandName: "Hyzaar", genericName: "Hyzaar" },
  { brandName: "Ibandronate sodium", genericName: "Ibandronate sodium" },
  { brandName: "Ibritumomab tiuxetan", genericName: "Ibritumomab tiuxetan" },
  { brandName: "Ibuprofen", genericName: "Ibuprofen" },
  { brandName: "Ibuprofen lysine", genericName: "Ibuprofen lysine" },
  { brandName: "Ibutilide fumarate", genericName: "Ibutilide fumarate" },
  { brandName: "Ic-green", genericName: "Ic-green" },
  { brandName: "Icodextrin", genericName: "Icodextrin" },
  { brandName: "Idamycin", genericName: "Idamycin" },
  { brandName: "Idamycin pfs", genericName: "Idamycin pfs" },
  { brandName: "Idarubicin", genericName: "Idarubicin" },
  { brandName: "Ifex", genericName: "Ifex" },
  { brandName: "Ifosfamide", genericName: "Ifosfamide" },
  { brandName: "Ilaris", genericName: "Ilaris" },
  { brandName: "Iloperidone", genericName: "Iloperidone" },
  { brandName: "Iloprost", genericName: "Iloprost" },
  { brandName: "Ilotycin", genericName: "Ilotycin" },
  { brandName: "Imatinib mesylate", genericName: "Imatinib mesylate" },
  { brandName: "Imiglucerase", genericName: "Imiglucerase" },
  { brandName: "Imipenem / cilastatin", genericName: "Imipenem / cilastatin" },
  { brandName: "Imipramine", genericName: "Imipramine" },
  { brandName: "Imipramine pamoate", genericName: "Imipramine pamoate" },
  { brandName: "Imiquimod", genericName: "Imiquimod" },
  { brandName: "Imitrex", genericName: "Imitrex" },
  { brandName: "Immune globulin", genericName: "Immune globulin" },
  {
    brandName: "Immune globulin intravenous",
    genericName: "Immune globulin intravenous",
  },
  { brandName: "Imodium", genericName: "Imodium" },
  { brandName: "Imovax", genericName: "Imovax" },
  { brandName: "Implanon", genericName: "Implanon" },
  { brandName: "Imuran", genericName: "Imuran" },
  { brandName: "Inapsine", genericName: "Inapsine" },
  { brandName: "Increlex", genericName: "Increlex" },
  { brandName: "Indapamide", genericName: "Indapamide" },
  { brandName: "Inderal", genericName: "Inderal" },
  { brandName: "Inderal la", genericName: "Inderal la" },
  { brandName: "Inderide", genericName: "Inderide" },
  { brandName: "Indigo carmine", genericName: "Indigo carmine" },
  { brandName: "Indigotindisulfonate", genericName: "Indigotindisulfonate" },
  { brandName: "Indinavir sulfate", genericName: "Indinavir sulfate" },
  { brandName: "Indocin", genericName: "Indocin" },
  { brandName: "Indomethacin", genericName: "Indomethacin" },
  { brandName: "Infanrix", genericName: "Infanrix" },
  { brandName: "Infasurf", genericName: "Infasurf" },
  { brandName: "Infed", genericName: "Infed" },
  { brandName: "Infergen", genericName: "Infergen" },
  { brandName: "Infliximab", genericName: "Infliximab" },
  {
    brandName: "Influenza virus vaccine",
    genericName: "Influenza virus vaccine",
  },
  { brandName: "Innohep", genericName: "Innohep" },
  { brandName: "Innopran xl", genericName: "Innopran xl" },
  { brandName: "Inomax", genericName: "Inomax" },
  {
    brandName: "Insoluble prussian blue",
    genericName: "Insoluble prussian blue",
  },
  { brandName: "Inspra", genericName: "Inspra" },
  { brandName: "Insulin", genericName: "Insulin" },
  { brandName: "Insulin aspart", genericName: "Insulin aspart" },
  { brandName: "Insulin detemir", genericName: "Insulin detemir" },
  { brandName: "Insulin glulisine", genericName: "Insulin glulisine" },
  { brandName: "Insulin human", genericName: "Insulin human" },
  { brandName: "Insulin lispro", genericName: "Insulin lispro" },
  { brandName: "Intal nebulizer", genericName: "Intal nebulizer" },
  { brandName: "Integrilin", genericName: "Integrilin" },
  { brandName: "Intelence", genericName: "Intelence" },
  { brandName: "Interferon alfa-2a", genericName: "Interferon alfa-2a" },
  { brandName: "Interferon alfa-2b", genericName: "Interferon alfa-2b" },
  { brandName: "Interferon alfacon-1", genericName: "Interferon alfacon-1" },
  { brandName: "Interferon beta-1a", genericName: "Interferon beta-1a" },
  { brandName: "Interferon beta-1b", genericName: "Interferon beta-1b" },
  { brandName: "Interferon gamma 1-b", genericName: "Interferon gamma 1-b" },
  { brandName: "Intralipid 10%", genericName: "Intralipid 10%" },
  { brandName: "Intralipid 20%", genericName: "Intralipid 20%" },
  { brandName: "Intron a", genericName: "Intron a" },
  { brandName: "Intuniv", genericName: "Intuniv" },
  { brandName: "Invanz", genericName: "Invanz" },
  { brandName: "Invega", genericName: "Invega" },
  { brandName: "Invega sustenna", genericName: "Invega sustenna" },
  { brandName: "Inversine", genericName: "Inversine" },
  { brandName: "Invirase", genericName: "Invirase" },
  { brandName: "Iobenguane i 123", genericName: "Iobenguane i 123" },
  { brandName: "Ionamin", genericName: "Ionamin" },
  { brandName: "Ionsys", genericName: "Ionsys" },
  { brandName: "Ioxilan", genericName: "Ioxilan" },
  { brandName: "Iplex", genericName: "Iplex" },
  { brandName: "Ipol", genericName: "Ipol" },
  { brandName: "Irbesartan", genericName: "Irbesartan" },
  {
    brandName: "Irbesartan-hydrochlorothiazide",
    genericName: "Irbesartan-hydrochlorothiazide",
  },
  { brandName: "Iressa", genericName: "Iressa" },
  {
    brandName: "Irinotecan hydrochloride",
    genericName: "Irinotecan hydrochloride",
  },
  { brandName: "Iron dextran", genericName: "Iron dextran" },
  { brandName: "Isentress", genericName: "Isentress" },
  { brandName: "Ismelin", genericName: "Ismelin" },
  { brandName: "Ismo", genericName: "Ismo" },
  { brandName: "Isocarboxazid", genericName: "Isocarboxazid" },
  { brandName: "Isoflurane", genericName: "Isoflurane" },
  { brandName: "Isoniazid", genericName: "Isoniazid" },
  { brandName: "Isoproterenol", genericName: "Isoproterenol" },
  { brandName: "Isoptin sr", genericName: "Isoptin sr" },
  { brandName: "Isopto carpine", genericName: "Isopto carpine" },
  { brandName: "Isopto hyoscine", genericName: "Isopto hyoscine" },
  { brandName: "Isordil", genericName: "Isordil" },
  { brandName: "Isosorbide dinitrate", genericName: "Isosorbide dinitrate" },
  {
    brandName: "Isosorbide mononitrate",
    genericName: "Isosorbide mononitrate",
  },
  { brandName: "Isosulfan blue", genericName: "Isosulfan blue" },
  { brandName: "Isotretinoin", genericName: "Isotretinoin" },
  { brandName: "Isradipine", genericName: "Isradipine" },
  { brandName: "Istalol", genericName: "Istalol" },
  { brandName: "Istodax", genericName: "Istodax" },
  { brandName: "Isuprel", genericName: "Isuprel" },
  { brandName: "Ivermectin", genericName: "Ivermectin" },
  { brandName: "Ixabepilone", genericName: "Ixabepilone" },
  { brandName: "Ixempra", genericName: "Ixempra" },
  { brandName: "Ixiaro", genericName: "Ixiaro" },
  { brandName: "Jadelle", genericName: "Jadelle" },
  { brandName: "Jalyn", genericName: "Jalyn" },
  { brandName: "Jantoven", genericName: "Jantoven" },
  { brandName: "Janumet", genericName: "Janumet" },
  { brandName: "Januvia", genericName: "Januvia" },
  {
    brandName: "Japanese encephalitis vaccine",
    genericName: "Japanese encephalitis vaccine",
  },
  { brandName: "Je-vax", genericName: "Je-vax" },
  { brandName: "Jevtana", genericName: "Jevtana" },
  { brandName: "K-lor", genericName: "K-lor" },
  { brandName: "K-tab", genericName: "K-tab" },
  { brandName: "Kadian", genericName: "Kadian" },
  { brandName: "Kalbitor", genericName: "Kalbitor" },
  { brandName: "Kaletra", genericName: "Kaletra" },
  { brandName: "Kanamycin", genericName: "Kanamycin" },
  { brandName: "Kantrex", genericName: "Kantrex" },
  { brandName: "Kapidex", genericName: "Kapidex" },
  { brandName: "Kariva", genericName: "Kariva" },
  { brandName: "Kayexalate", genericName: "Kayexalate" },
  { brandName: "Keflex", genericName: "Keflex" },
  { brandName: "Kemstro", genericName: "Kemstro" },
  { brandName: "Kenalog", genericName: "Kenalog" },
  { brandName: "Kepivance", genericName: "Kepivance" },
  { brandName: "Keppra", genericName: "Keppra" },
  { brandName: "Keppra xr", genericName: "Keppra xr" },
  { brandName: "Kerlone", genericName: "Kerlone" },
  { brandName: "Ketamine hcl", genericName: "Ketamine hcl" },
  {
    brandName: "Ketamine hydrochloride",
    genericName: "Ketamine hydrochloride",
  },
  { brandName: "Ketek", genericName: "Ketek" },
  { brandName: "Ketoconazole", genericName: "Ketoconazole" },
  { brandName: "Ketoprofen", genericName: "Ketoprofen" },
  {
    brandName: "Ketorolac tromethamine",
    genericName: "Ketorolac tromethamine",
  },
  { brandName: "Ketotifen fumarate", genericName: "Ketotifen fumarate" },
  { brandName: "Kineret", genericName: "Kineret" },
  { brandName: "Kinevac", genericName: "Kinevac" },
  { brandName: "Kinlytic", genericName: "Kinlytic" },
  { brandName: "Kionex", genericName: "Kionex" },
  { brandName: "Klaron", genericName: "Klaron" },
  { brandName: "Klonopin", genericName: "Klonopin" },
  { brandName: "Klor-con", genericName: "Klor-con" },
  { brandName: "Kogenate fs", genericName: "Kogenate fs" },
  { brandName: "Kuvan", genericName: "Kuvan" },
  { brandName: "Kytril", genericName: "Kytril" },
  { brandName: "Labetalol", genericName: "Labetalol" },
  { brandName: "Lac-hydrin", genericName: "Lac-hydrin" },
  { brandName: "Lacosamide", genericName: "Lacosamide" },
  { brandName: "Lacrisert", genericName: "Lacrisert" },
  {
    brandName: "Lactated ringer's solution",
    genericName: "Lactated ringer's solution",
  },
  { brandName: "Lactic acid", genericName: "Lactic acid" },
  { brandName: "Lactulose", genericName: "Lactulose" },
  { brandName: "Lamictal", genericName: "Lamictal" },
  { brandName: "Lamisil", genericName: "Lamisil" },
  { brandName: "Lamivudine", genericName: "Lamivudine" },
  {
    brandName: "Lamivudine / zidovudine",
    genericName: "Lamivudine / zidovudine",
  },
  { brandName: "Lamotrigine", genericName: "Lamotrigine" },
  { brandName: "Lamprene", genericName: "Lamprene" },
  { brandName: "Lanoxin", genericName: "Lanoxin" },
  { brandName: "Lansoprazole", genericName: "Lansoprazole" },
  { brandName: "Lantus", genericName: "Lantus" },
  { brandName: "Lapatinib", genericName: "Lapatinib" },
  { brandName: "Lariam", genericName: "Lariam" },
  { brandName: "Laronidase", genericName: "Laronidase" },
  { brandName: "Lasix", genericName: "Lasix" },
  { brandName: "Lastacaft", genericName: "Lastacaft" },
  { brandName: "Latanoprost", genericName: "Latanoprost" },
  { brandName: "Latisse", genericName: "Latisse" },
  { brandName: "Leflunomide", genericName: "Leflunomide" },
  { brandName: "Lenalidomide", genericName: "Lenalidomide" },
  { brandName: "Lepirudin", genericName: "Lepirudin" },
  { brandName: "Lescol", genericName: "Lescol" },
  { brandName: "Letairis", genericName: "Letairis" },
  { brandName: "Letrozole", genericName: "Letrozole" },
  { brandName: "Leucovorin calcium", genericName: "Leucovorin calcium" },
  { brandName: "Leukeran", genericName: "Leukeran" },
  { brandName: "Leukine", genericName: "Leukine" },
  { brandName: "Leuprolide acetate", genericName: "Leuprolide acetate" },
  { brandName: "Leustatin", genericName: "Leustatin" },
  { brandName: "Levalbuterol", genericName: "Levalbuterol" },
  { brandName: "Levaquin", genericName: "Levaquin" },
  { brandName: "Levbid", genericName: "Levbid" },
  { brandName: "Levemir", genericName: "Levemir" },
  { brandName: "Levetiracetam", genericName: "Levetiracetam" },
  { brandName: "Levitra", genericName: "Levitra" },
  { brandName: "Levo-dromoran", genericName: "Levo-dromoran" },
  { brandName: "Levo-t", genericName: "Levo-t" },
  {
    brandName: "Levobetaxolol hydrochloride",
    genericName: "Levobetaxolol hydrochloride",
  },
  { brandName: "Levobunolol", genericName: "Levobunolol" },
  { brandName: "Levocabastine", genericName: "Levocabastine" },
  { brandName: "Levocarnitine", genericName: "Levocarnitine" },
  {
    brandName: "Levocetirizine dihydrochloride",
    genericName: "Levocetirizine dihydrochloride",
  },
  { brandName: "Levodopa", genericName: "Levodopa" },
  { brandName: "Levofloxacin", genericName: "Levofloxacin" },
  { brandName: "Levoleucovorin", genericName: "Levoleucovorin" },
  { brandName: "Levomethadyl acetate", genericName: "Levomethadyl acetate" },
  { brandName: "Levonorgestrel", genericName: "Levonorgestrel" },
  {
    brandName: "Levonorgestrel and ethinyl estradiol",
    genericName: "Levonorgestrel and ethinyl estradiol",
  },
  {
    brandName: "Levonorgestrel implants",
    genericName: "Levonorgestrel implants",
  },
  {
    brandName: "Levonorgestrel, ethinyl estradiol",
    genericName: "Levonorgestrel, ethinyl estradiol",
  },
  { brandName: "Levophed", genericName: "Levophed" },
  { brandName: "Levora", genericName: "Levora" },
  { brandName: "Levorphanol", genericName: "Levorphanol" },
  { brandName: "Levothroid", genericName: "Levothroid" },
  { brandName: "Levothyroxine sodium", genericName: "Levothyroxine sodium" },
  { brandName: "Levoxyl", genericName: "Levoxyl" },
  { brandName: "Levsin", genericName: "Levsin" },
  { brandName: "Levsin sl", genericName: "Levsin sl" },
  { brandName: "Levulan kerastick", genericName: "Levulan kerastick" },
  { brandName: "Lexapro", genericName: "Lexapro" },
  { brandName: "Lexiscan", genericName: "Lexiscan" },
  { brandName: "Lexiva", genericName: "Lexiva" },
  { brandName: "Lexxel", genericName: "Lexxel" },
  { brandName: "Lialda", genericName: "Lialda" },
  { brandName: "Librax", genericName: "Librax" },
  { brandName: "Librium", genericName: "Librium" },
  { brandName: "Lidex", genericName: "Lidex" },
  { brandName: "Lidocaine", genericName: "Lidocaine" },
  {
    brandName: "Lidocaine and prilocaine",
    genericName: "Lidocaine and prilocaine",
  },
  {
    brandName: "Lidocaine and tetracaine",
    genericName: "Lidocaine and tetracaine",
  },
  { brandName: "Lidoderm", genericName: "Lidoderm" },
  { brandName: "Limbitrol", genericName: "Limbitrol" },
  { brandName: "Lincocin", genericName: "Lincocin" },
  { brandName: "Lincomycin hcl", genericName: "Lincomycin hcl" },
  { brandName: "Lindane", genericName: "Lindane" },
  { brandName: "Linezolid", genericName: "Linezolid" },
  { brandName: "Lioresal intrathecal", genericName: "Lioresal intrathecal" },
  { brandName: "Liothyronine sodium", genericName: "Liothyronine sodium" },
  { brandName: "Liotrix", genericName: "Liotrix" },
  { brandName: "Lipitor", genericName: "Lipitor" },
  { brandName: "Lipofen", genericName: "Lipofen" },
  { brandName: "Liposyn ii", genericName: "Liposyn ii" },
  { brandName: "Liposyn iii", genericName: "Liposyn iii" },
  { brandName: "Liraglutide", genericName: "Liraglutide" },
  {
    brandName: "Lisdexamfetamine dimesylate",
    genericName: "Lisdexamfetamine dimesylate",
  },
  { brandName: "Lisinopril", genericName: "Lisinopril" },
  {
    brandName: "Lisinopril and hydrochlorothiazide",
    genericName: "Lisinopril and hydrochlorothiazide",
  },
  { brandName: "Lithium carbonate", genericName: "Lithium carbonate" },
  { brandName: "Livalo", genericName: "Livalo" },
  { brandName: "Livostin", genericName: "Livostin" },
  { brandName: "Locoid", genericName: "Locoid" },
  { brandName: "Locoid lipocream", genericName: "Locoid lipocream" },
  { brandName: "Lodine", genericName: "Lodine" },
  { brandName: "Lodosyn", genericName: "Lodosyn" },
  {
    brandName: "Lodoxamide tromethamine",
    genericName: "Lodoxamide tromethamine",
  },
  { brandName: "Loestrin 24 fe", genericName: "Loestrin 24 fe" },
  { brandName: "Lomefloxacin hcl", genericName: "Lomefloxacin hcl" },
  { brandName: "Lomotil", genericName: "Lomotil" },
  { brandName: "Lomustine", genericName: "Lomustine" },
  { brandName: "Loperamide hcl", genericName: "Loperamide hcl" },
  { brandName: "Lopid", genericName: "Lopid" },
  { brandName: "Lopinavir/ritonavir", genericName: "Lopinavir/ritonavir" },
  { brandName: "Lopressor", genericName: "Lopressor" },
  { brandName: "Lopressor hct", genericName: "Lopressor hct" },
  { brandName: "Loprox cream", genericName: "Loprox cream" },
  { brandName: "Loprox gel", genericName: "Loprox gel" },
  { brandName: "Lorabid", genericName: "Lorabid" },
  { brandName: "Loracarbef", genericName: "Loracarbef" },
  { brandName: "Loratadine", genericName: "Loratadine" },
  { brandName: "Lorazepam", genericName: "Lorazepam" },
  { brandName: "Lortab", genericName: "Lortab" },
  { brandName: "Losartan potassium", genericName: "Losartan potassium" },
  { brandName: "Lotemax", genericName: "Lotemax" },
  { brandName: "Lotensin", genericName: "Lotensin" },
  { brandName: "Lotensin hct", genericName: "Lotensin hct" },
  { brandName: "Loteprednol etabonate", genericName: "Loteprednol etabonate" },
  { brandName: "Lotrel", genericName: "Lotrel" },
  { brandName: "Lotrisone", genericName: "Lotrisone" },
  { brandName: "Lotronex", genericName: "Lotronex" },
  { brandName: "Lovastatin", genericName: "Lovastatin" },
  { brandName: "Lovaza", genericName: "Lovaza" },
  { brandName: "Lovenox", genericName: "Lovenox" },
  { brandName: "Low-ogestrel", genericName: "Low-ogestrel" },
  { brandName: "Loxapine", genericName: "Loxapine" },
  { brandName: "Loxapine succinate", genericName: "Loxapine succinate" },
  { brandName: "Loxitane", genericName: "Loxitane" },
  { brandName: "Lozol", genericName: "Lozol" },
  { brandName: "Lubiprostone", genericName: "Lubiprostone" },
  { brandName: "Lucentis", genericName: "Lucentis" },
  { brandName: "Lufyllin", genericName: "Lufyllin" },
  { brandName: "Lumigan", genericName: "Lumigan" },
  { brandName: "Lumizyme", genericName: "Lumizyme" },
  { brandName: "Lunesta", genericName: "Lunesta" },
  { brandName: "Lupron", genericName: "Lupron" },
  { brandName: "Lusedra", genericName: "Lusedra" },
  { brandName: "Luvox cr", genericName: "Luvox cr" },
  { brandName: "Luxiq", genericName: "Luxiq" },
  { brandName: "Lybrel", genericName: "Lybrel" },
  { brandName: "Lymphazurin", genericName: "Lymphazurin" },
  { brandName: "Lyrica", genericName: "Lyrica" },
  { brandName: "Lysodren", genericName: "Lysodren" },
  { brandName: "Lysteda", genericName: "Lysteda" },
  { brandName: "M-m-r", genericName: "M-m-r" },
  { brandName: "Macrobid", genericName: "Macrobid" },
  { brandName: "Macrodantin", genericName: "Macrodantin" },
  { brandName: "Macugen", genericName: "Macugen" },
  { brandName: "Mafenide acetate", genericName: "Mafenide acetate" },
  { brandName: "Magnesium sulfate", genericName: "Magnesium sulfate" },
  { brandName: "Magnevist", genericName: "Magnevist" },
  { brandName: "Malarone", genericName: "Malarone" },
  { brandName: "Malathion", genericName: "Malathion" },
  { brandName: "Mandol", genericName: "Mandol" },
  { brandName: "Mangafodipir", genericName: "Mangafodipir" },
  { brandName: "Mannitol", genericName: "Mannitol" },
  { brandName: "Maraviroc", genericName: "Maraviroc" },
  { brandName: "Marcaine", genericName: "Marcaine" },
  { brandName: "Marinol", genericName: "Marinol" },
  { brandName: "Marplan", genericName: "Marplan" },
  { brandName: "Matulane", genericName: "Matulane" },
  { brandName: "Mavik", genericName: "Mavik" },
  { brandName: "Maxair", genericName: "Maxair" },
  { brandName: "Maxalt", genericName: "Maxalt" },
  { brandName: "Maxaquin", genericName: "Maxaquin" },
  { brandName: "Maxipime", genericName: "Maxipime" },
  { brandName: "Maxitrol", genericName: "Maxitrol" },
  { brandName: "Measles vaccine", genericName: "Measles vaccine" },
  { brandName: "Mebaral", genericName: "Mebaral" },
  { brandName: "Mebendazole", genericName: "Mebendazole" },
  { brandName: "Mecamylamine", genericName: "Mecamylamine" },
  { brandName: "Mecasermin", genericName: "Mecasermin" },
  { brandName: "Mecasermin rinfabate", genericName: "Mecasermin rinfabate" },
  { brandName: "Mechlorethamine hcl", genericName: "Mechlorethamine hcl" },
  { brandName: "Meclizine", genericName: "Meclizine" },
  { brandName: "Meclofenamate", genericName: "Meclofenamate" },
  { brandName: "Medrol", genericName: "Medrol" },
  { brandName: "Medroxyprogesterone", genericName: "Medroxyprogesterone" },
  {
    brandName: "Medroxyprogesterone acetate",
    genericName: "Medroxyprogesterone acetate",
  },
  { brandName: "Medrysone", genericName: "Medrysone" },
  { brandName: "Mefenamic acid", genericName: "Mefenamic acid" },
  { brandName: "Mefloquine", genericName: "Mefloquine" },
  { brandName: "Mefoxin", genericName: "Mefoxin" },
  { brandName: "Megace", genericName: "Megace" },
  { brandName: "Megace es", genericName: "Megace es" },
  { brandName: "Megestrol acetate", genericName: "Megestrol acetate" },
  { brandName: "Mellaril", genericName: "Mellaril" },
  { brandName: "Meloxicam", genericName: "Meloxicam" },
  { brandName: "Melphalan", genericName: "Melphalan" },
  { brandName: "Memantine hcl", genericName: "Memantine hcl" },
  { brandName: "Menactra", genericName: "Menactra" },
  { brandName: "Menest", genericName: "Menest" },
  { brandName: "Meningococcal vaccine", genericName: "Meningococcal vaccine" },
  { brandName: "Menomune", genericName: "Menomune" },
  { brandName: "Menopur", genericName: "Menopur" },
  { brandName: "Menostar", genericName: "Menostar" },
  { brandName: "Menotropins", genericName: "Menotropins" },
  { brandName: "Mentax", genericName: "Mentax" },
  { brandName: "Mepenzolate bromide", genericName: "Mepenzolate bromide" },
  { brandName: "Meperidine", genericName: "Meperidine" },
  { brandName: "Mephobarbital", genericName: "Mephobarbital" },
  { brandName: "Mephyton", genericName: "Mephyton" },
  { brandName: "Mepivacaine", genericName: "Mepivacaine" },
  { brandName: "Meprobamate", genericName: "Meprobamate" },
  { brandName: "Meprobamate / aspirin", genericName: "Meprobamate / aspirin" },
  { brandName: "Mepron", genericName: "Mepron" },
  {
    brandName: "Mequinol and tretinoin",
    genericName: "Mequinol and tretinoin",
  },
  { brandName: "Mercaptopurine", genericName: "Mercaptopurine" },
  { brandName: "Meridia", genericName: "Meridia" },
  { brandName: "Meropenem", genericName: "Meropenem" },
  { brandName: "Merrem i.v.", genericName: "Merrem i.v." },
  { brandName: "Mesalamine", genericName: "Mesalamine" },
  { brandName: "Mesna", genericName: "Mesna" },
  { brandName: "Mesnex", genericName: "Mesnex" },
  { brandName: "Mestinon", genericName: "Mestinon" },
  { brandName: "Metadate cd", genericName: "Metadate cd" },
  { brandName: "Metadate er", genericName: "Metadate er" },
  { brandName: "Metaglip", genericName: "Metaglip" },
  {
    brandName: "Metaproterenol sulfate",
    genericName: "Metaproterenol sulfate",
  },
  { brandName: "Metaraminol", genericName: "Metaraminol" },
  { brandName: "Metastron", genericName: "Metastron" },
  { brandName: "Metaxalone", genericName: "Metaxalone" },
  { brandName: "Metformin hcl", genericName: "Metformin hcl" },
  { brandName: "Methacholine chloride", genericName: "Methacholine chloride" },
  { brandName: "Methadone", genericName: "Methadone" },
  {
    brandName: "Methadone hydrochloride",
    genericName: "Methadone hydrochloride",
  },
  {
    brandName: "Methadose oral concentrate",
    genericName: "Methadose oral concentrate",
  },
  {
    brandName: "Methamphetamine hydrochloride",
    genericName: "Methamphetamine hydrochloride",
  },
  { brandName: "Methazolamide", genericName: "Methazolamide" },
  { brandName: "Methenamine hippurate", genericName: "Methenamine hippurate" },
  { brandName: "Methergine", genericName: "Methergine" },
  { brandName: "Methimazole", genericName: "Methimazole" },
  { brandName: "Methocarbamol", genericName: "Methocarbamol" },
  { brandName: "Methohexital sodium", genericName: "Methohexital sodium" },
  { brandName: "Methotrexate", genericName: "Methotrexate" },
  { brandName: "Methoxsalen", genericName: "Methoxsalen" },
  { brandName: "Methsuximide", genericName: "Methsuximide" },
  { brandName: "Methyclothiazide", genericName: "Methyclothiazide" },
  { brandName: "Methyldopa", genericName: "Methyldopa" },
  { brandName: "Methyldopate hcl", genericName: "Methyldopate hcl" },
  { brandName: "Methylene blue", genericName: "Methylene blue" },
  {
    brandName: "Methylergonovine maleate",
    genericName: "Methylergonovine maleate",
  },
  { brandName: "Methylin", genericName: "Methylin" },
  {
    brandName: "Methylnaltrexone bromide",
    genericName: "Methylnaltrexone bromide",
  },
  { brandName: "Methylphenidate hcl", genericName: "Methylphenidate hcl" },
  { brandName: "Methylprednisolone", genericName: "Methylprednisolone" },
  {
    brandName: "Methylprednisolone sodium succinate",
    genericName: "Methylprednisolone sodium succinate",
  },
  { brandName: "Methyltestosterone", genericName: "Methyltestosterone" },
  { brandName: "Methysergide maleate", genericName: "Methysergide maleate" },
  { brandName: "Metipranolol", genericName: "Metipranolol" },
  { brandName: "Metoclopramide", genericName: "Metoclopramide" },
  { brandName: "Metolazone", genericName: "Metolazone" },
  { brandName: "Metopirone", genericName: "Metopirone" },
  { brandName: "Metoprolol succinate", genericName: "Metoprolol succinate" },
  { brandName: "Metoprolol tartrate", genericName: "Metoprolol tartrate" },
  { brandName: "Metozolv odt", genericName: "Metozolv odt" },
  { brandName: "Metrocream", genericName: "Metrocream" },
  { brandName: "Metrodin", genericName: "Metrodin" },
  { brandName: "Metrogel", genericName: "Metrogel" },
  { brandName: "Metrolotion", genericName: "Metrolotion" },
  { brandName: "Metronidazole", genericName: "Metronidazole" },
  { brandName: "Metvixia", genericName: "Metvixia" },
  { brandName: "Metyrapone", genericName: "Metyrapone" },
  { brandName: "Metyrosine", genericName: "Metyrosine" },
  { brandName: "Mevacor", genericName: "Mevacor" },
  { brandName: "Mexiletine hcl", genericName: "Mexiletine hcl" },
  { brandName: "Mexitil", genericName: "Mexitil" },
  { brandName: "Miacalcin", genericName: "Miacalcin" },
  { brandName: "Micafungin sodium", genericName: "Micafungin sodium" },
  { brandName: "Micardis", genericName: "Micardis" },
  { brandName: "Micardis hct", genericName: "Micardis hct" },
  { brandName: "Miconazole", genericName: "Miconazole" },
  { brandName: "Micro-k", genericName: "Micro-k" },
  { brandName: "Micronase", genericName: "Micronase" },
  { brandName: "Micronized glyburide", genericName: "Micronized glyburide" },
  { brandName: "Microzide", genericName: "Microzide" },
  { brandName: "Midamor", genericName: "Midamor" },
  { brandName: "Midazolam", genericName: "Midazolam" },
  {
    brandName: "Midodrine hydrochloride",
    genericName: "Midodrine hydrochloride",
  },
  { brandName: "Midrin", genericName: "Midrin" },
  { brandName: "Mifeprex", genericName: "Mifeprex" },
  { brandName: "Mifepristone", genericName: "Mifepristone" },
  { brandName: "Miglitol", genericName: "Miglitol" },
  { brandName: "Miglustat", genericName: "Miglustat" },
  { brandName: "Migranal", genericName: "Migranal" },
  { brandName: "Milnacipran hcl", genericName: "Milnacipran hcl" },
  { brandName: "Milrinone", genericName: "Milrinone" },
  { brandName: "Minipress", genericName: "Minipress" },
  { brandName: "Minocin", genericName: "Minocin" },
  { brandName: "Minocycline", genericName: "Minocycline" },
  {
    brandName: "Minocycline hydrochloride",
    genericName: "Minocycline hydrochloride",
  },
  { brandName: "Minoxidil", genericName: "Minoxidil" },
  { brandName: "Mintezol", genericName: "Mintezol" },
  { brandName: "Miochol-e", genericName: "Miochol-e" },
  { brandName: "Miostat", genericName: "Miostat" },
  { brandName: "Miradon", genericName: "Miradon" },
  { brandName: "Miralax", genericName: "Miralax" },
  { brandName: "Mirapex", genericName: "Mirapex" },
  { brandName: "Mirapex er", genericName: "Mirapex er" },
  { brandName: "Mircera", genericName: "Mircera" },
  { brandName: "Mircette", genericName: "Mircette" },
  { brandName: "Mirena", genericName: "Mirena" },
  { brandName: "Mirtazapine", genericName: "Mirtazapine" },
  { brandName: "Misoprostol", genericName: "Misoprostol" },
  { brandName: "Mithracin", genericName: "Mithracin" },
  { brandName: "Mitomycin", genericName: "Mitomycin" },
  { brandName: "Mitotane", genericName: "Mitotane" },
  { brandName: "Mitoxantrone", genericName: "Mitoxantrone" },
  { brandName: "Moban", genericName: "Moban" },
  { brandName: "Mobic", genericName: "Mobic" },
  { brandName: "Modafinil", genericName: "Modafinil" },
  { brandName: "Moduretic", genericName: "Moduretic" },
  { brandName: "Moexipril", genericName: "Moexipril" },
  { brandName: "Moexipril hcl", genericName: "Moexipril hcl" },
  {
    brandName: "Molindone hydrochloride",
    genericName: "Molindone hydrochloride",
  },
  { brandName: "Mometasone furoate", genericName: "Mometasone furoate" },
  { brandName: "Monistat", genericName: "Monistat" },
  { brandName: "Monistat-derm", genericName: "Monistat-derm" },
  { brandName: "Monoclate-p", genericName: "Monoclate-p" },
  { brandName: "Monodox", genericName: "Monodox" },
  { brandName: "Mononine", genericName: "Mononine" },
  { brandName: "Monopril", genericName: "Monopril" },
  { brandName: "Montelukast sodium", genericName: "Montelukast sodium" },
  { brandName: "Monurol", genericName: "Monurol" },
  { brandName: "Morphine sulfate", genericName: "Morphine sulfate" },
  { brandName: "Morrhuate sodium", genericName: "Morrhuate sodium" },
  { brandName: "Motofen", genericName: "Motofen" },
  { brandName: "Motrin", genericName: "Motrin" },
  { brandName: "Moxatag", genericName: "Moxatag" },
  { brandName: "Moxifloxacin", genericName: "Moxifloxacin" },
  { brandName: "Moxifloxacin hcl", genericName: "Moxifloxacin hcl" },
  { brandName: "Mozobil", genericName: "Mozobil" },
  { brandName: "Ms contin", genericName: "Ms contin" },
  { brandName: "Multaq", genericName: "Multaq" },
  { brandName: "Multi vitamin", genericName: "Multi vitamin" },
  { brandName: "Multihance", genericName: "Multihance" },
  { brandName: "Mumps vaccine", genericName: "Mumps vaccine" },
  { brandName: "Mupirocin", genericName: "Mupirocin" },
  { brandName: "Mustargen", genericName: "Mustargen" },
  { brandName: "Mutamycin", genericName: "Mutamycin" },
  { brandName: "Myambutol", genericName: "Myambutol" },
  { brandName: "Mycamine", genericName: "Mycamine" },
  { brandName: "Mycelex", genericName: "Mycelex" },
  { brandName: "Mycobutin", genericName: "Mycobutin" },
  { brandName: "Mycophenolate mofetil", genericName: "Mycophenolate mofetil" },
  { brandName: "Mycophenolic acid", genericName: "Mycophenolic acid" },
  { brandName: "Mycostatin", genericName: "Mycostatin" },
  { brandName: "Myfortic", genericName: "Myfortic" },
  { brandName: "Mykrox", genericName: "Mykrox" },
  { brandName: "Myleran", genericName: "Myleran" },
  { brandName: "Mylotarg", genericName: "Mylotarg" },
  { brandName: "Myobloc", genericName: "Myobloc" },
  { brandName: "Myochrysine", genericName: "Myochrysine" },
  { brandName: "Myozyme", genericName: "Myozyme" },
  { brandName: "Mysoline", genericName: "Mysoline" },
  { brandName: "Mytelase", genericName: "Mytelase" },
  { brandName: "Nabi-hb", genericName: "Nabi-hb" },
  { brandName: "Nabumetone", genericName: "Nabumetone" },
  { brandName: "Nadolol", genericName: "Nadolol" },
  {
    brandName: "Nadolol and bendroflumethiazide",
    genericName: "Nadolol and bendroflumethiazide",
  },
  { brandName: "Nafcillin sodium", genericName: "Nafcillin sodium" },
  { brandName: "Naftifine", genericName: "Naftifine" },
  { brandName: "Naftifine hcl", genericName: "Naftifine hcl" },
  { brandName: "Naglazyme", genericName: "Naglazyme" },
  {
    brandName: "Nalbuphine hydrochloride",
    genericName: "Nalbuphine hydrochloride",
  },
  { brandName: "Nalfon", genericName: "Nalfon" },
  { brandName: "Nalidixic acid", genericName: "Nalidixic acid" },
  {
    brandName: "Nalmefene hydrochloride",
    genericName: "Nalmefene hydrochloride",
  },
  { brandName: "Naloxone", genericName: "Naloxone" },
  { brandName: "Naltrexone", genericName: "Naltrexone" },
  { brandName: "Namenda", genericName: "Namenda" },
  { brandName: "Naprelan", genericName: "Naprelan" },
  { brandName: "Naproxen", genericName: "Naproxen" },
  { brandName: "Naproxen sodium", genericName: "Naproxen sodium" },
  { brandName: "Naratriptan", genericName: "Naratriptan" },
  { brandName: "Narcan", genericName: "Narcan" },
  { brandName: "Nardil", genericName: "Nardil" },
  { brandName: "Naropin", genericName: "Naropin" },
  { brandName: "Nasacort aq", genericName: "Nasacort aq" },
  { brandName: "Nasalcrom", genericName: "Nasalcrom" },
  { brandName: "Nasalide", genericName: "Nasalide" },
  { brandName: "Nascobal", genericName: "Nascobal" },
  { brandName: "Nasonex", genericName: "Nasonex" },
  { brandName: "Natacyn", genericName: "Natacyn" },
  { brandName: "Natalizumab", genericName: "Natalizumab" },
  { brandName: "Natamycin", genericName: "Natamycin" },
  { brandName: "Natazia", genericName: "Natazia" },
  { brandName: "Nateglinide", genericName: "Nateglinide" },
  { brandName: "Natrecor", genericName: "Natrecor" },
  { brandName: "Navane", genericName: "Navane" },
  { brandName: "Navelbine", genericName: "Navelbine" },
  { brandName: "Nebcin", genericName: "Nebcin" },
  { brandName: "Nebivolol tablets", genericName: "Nebivolol tablets" },
  { brandName: "Nebupent", genericName: "Nebupent" },
  { brandName: "Necon", genericName: "Necon" },
  { brandName: "Nedocromil", genericName: "Nedocromil" },
  {
    brandName: "Nedocromil inhalation aerosol",
    genericName: "Nedocromil inhalation aerosol",
  },
  { brandName: "Nefazodone", genericName: "Nefazodone" },
  { brandName: "Neggram", genericName: "Neggram" },
  { brandName: "Nelarabine", genericName: "Nelarabine" },
  { brandName: "Nelfinavir mesylate", genericName: "Nelfinavir mesylate" },
  { brandName: "Nembutal", genericName: "Nembutal" },
  { brandName: "Neo-synephrine", genericName: "Neo-synephrine" },
  { brandName: "Neodecadron", genericName: "Neodecadron" },
  { brandName: "Neomycin sulfate", genericName: "Neomycin sulfate" },
  { brandName: "Neoprofen", genericName: "Neoprofen" },
  { brandName: "Neoral", genericName: "Neoral" },
  { brandName: "Neostigmine", genericName: "Neostigmine" },
  {
    brandName: "Neostigmine methylsulfate",
    genericName: "Neostigmine methylsulfate",
  },
  { brandName: "Nephramine", genericName: "Nephramine" },
  { brandName: "Nesacaine", genericName: "Nesacaine" },
  { brandName: "Nesiritide", genericName: "Nesiritide" },
  { brandName: "Neulasta", genericName: "Neulasta" },
  { brandName: "Neumega", genericName: "Neumega" },
  { brandName: "Neupogen", genericName: "Neupogen" },
  { brandName: "Neupro", genericName: "Neupro" },
  { brandName: "Neurontin", genericName: "Neurontin" },
  { brandName: "Neutrexin", genericName: "Neutrexin" },
  { brandName: "Nevanac", genericName: "Nevanac" },
  { brandName: "Nevirapine", genericName: "Nevirapine" },
  { brandName: "Nexavar", genericName: "Nexavar" },
  { brandName: "Nexium", genericName: "Nexium" },
  { brandName: "Niacin", genericName: "Niacin" },
  { brandName: "Niacor", genericName: "Niacor" },
  { brandName: "Niaspan", genericName: "Niaspan" },
  {
    brandName: "Nicardipine hydrochloride",
    genericName: "Nicardipine hydrochloride",
  },
  { brandName: "Nicotrol", genericName: "Nicotrol" },
  { brandName: "Nicotrol ns", genericName: "Nicotrol ns" },
  { brandName: "Nifedipine", genericName: "Nifedipine" },
  { brandName: "Niferex-150", genericName: "Niferex-150" },
  { brandName: "Nilandron", genericName: "Nilandron" },
  { brandName: "Nilotinib capsules", genericName: "Nilotinib capsules" },
  { brandName: "Nilutamide", genericName: "Nilutamide" },
  { brandName: "Nimbex", genericName: "Nimbex" },
  { brandName: "Nimodipine", genericName: "Nimodipine" },
  { brandName: "Nimotop", genericName: "Nimotop" },
  { brandName: "Niravam", genericName: "Niravam" },
  { brandName: "Nisoldipine", genericName: "Nisoldipine" },
  { brandName: "Nitazoxanide", genericName: "Nitazoxanide" },
  { brandName: "Nitisinone", genericName: "Nitisinone" },
  { brandName: "Nitric oxide", genericName: "Nitric oxide" },
  { brandName: "Nitro-dur", genericName: "Nitro-dur" },
  { brandName: "Nitrofurantoin", genericName: "Nitrofurantoin" },
  { brandName: "Nitroglycerin", genericName: "Nitroglycerin" },
  {
    brandName: "Nitrolingual pumpspray",
    genericName: "Nitrolingual pumpspray",
  },
  { brandName: "Nitromist", genericName: "Nitromist" },
  { brandName: "Nitropress", genericName: "Nitropress" },
  { brandName: "Nitroprusside sodium", genericName: "Nitroprusside sodium" },
  { brandName: "Nitrostat", genericName: "Nitrostat" },
  { brandName: "Nizatidine", genericName: "Nizatidine" },
  { brandName: "Nizoral", genericName: "Nizoral" },
  { brandName: "Noctec", genericName: "Noctec" },
  { brandName: "Nolvadex", genericName: "Nolvadex" },
  { brandName: "Nor-qd", genericName: "Nor-qd" },
  { brandName: "Norco", genericName: "Norco" },
  { brandName: "Norditropin", genericName: "Norditropin" },
  {
    brandName: "Norepinephrine bitartrate",
    genericName: "Norepinephrine bitartrate",
  },
  { brandName: "Norethindrone", genericName: "Norethindrone" },
  {
    brandName: "Norethindrone and ethinyl estradiol",
    genericName: "Norethindrone and ethinyl estradiol",
  },
  { brandName: "Norflex", genericName: "Norflex" },
  { brandName: "Norfloxacin", genericName: "Norfloxacin" },
  { brandName: "Norgesic", genericName: "Norgesic" },
  {
    brandName: "Norgestimate and ethinyl estradiol",
    genericName: "Norgestimate and ethinyl estradiol",
  },
  { brandName: "Noritate", genericName: "Noritate" },
  { brandName: "Normal saline", genericName: "Normal saline" },
  { brandName: "Noroxin", genericName: "Noroxin" },
  { brandName: "Norpace", genericName: "Norpace" },
  { brandName: "Norplant", genericName: "Norplant" },
  { brandName: "Norpramin", genericName: "Norpramin" },
  { brandName: "Nortriptyline hcl", genericName: "Nortriptyline hcl" },
  {
    brandName: "Nortriptyline hydrochloride",
    genericName: "Nortriptyline hydrochloride",
  },
  { brandName: "Norvasc", genericName: "Norvasc" },
  { brandName: "Norvir", genericName: "Norvir" },
  { brandName: "Novantrone", genericName: "Novantrone" },
  { brandName: "Novolin r", genericName: "Novolin r" },
  { brandName: "Novolog", genericName: "Novolog" },
  { brandName: "Novolog mix 70/30", genericName: "Novolog mix 70/30" },
  { brandName: "Novoseven", genericName: "Novoseven" },
  { brandName: "Novothyrox", genericName: "Novothyrox" },
  { brandName: "Noxafil", genericName: "Noxafil" },
  { brandName: "Nplate", genericName: "Nplate" },
  { brandName: "Nubain", genericName: "Nubain" },
  { brandName: "Nucynta", genericName: "Nucynta" },
  { brandName: "Numorphan", genericName: "Numorphan" },
  { brandName: "Nuromax", genericName: "Nuromax" },
  { brandName: "Nutropin", genericName: "Nutropin" },
  { brandName: "Nutropin aq", genericName: "Nutropin aq" },
  { brandName: "Nutropin depot", genericName: "Nutropin depot" },
  { brandName: "Nuvaring", genericName: "Nuvaring" },
  { brandName: "Nuvigil", genericName: "Nuvigil" },
  { brandName: "Nydrazid", genericName: "Nydrazid" },
  { brandName: "Nystatin", genericName: "Nystatin" },
  { brandName: "Nystop", genericName: "Nystop" },
  { brandName: "Ocufen", genericName: "Ocufen" },
  { brandName: "Ocuflox", genericName: "Ocuflox" },
  { brandName: "Ofatumumab", genericName: "Ofatumumab" },
  { brandName: "Ofloxacin", genericName: "Ofloxacin" },
  { brandName: "Oforta", genericName: "Oforta" },
  { brandName: "Ogen", genericName: "Ogen" },
  { brandName: "Olanzapine", genericName: "Olanzapine" },
  { brandName: "Oleptro", genericName: "Oleptro" },
  { brandName: "Olmesartan medoxomil", genericName: "Olmesartan medoxomil" },
  { brandName: "Olopatadine", genericName: "Olopatadine" },
  { brandName: "Olux", genericName: "Olux" },
  { brandName: "Olux-e", genericName: "Olux-e" },
  { brandName: "Omalizumab", genericName: "Omalizumab" },
  {
    brandName: "Omega-3-acid ethyl esters",
    genericName: "Omega-3-acid ethyl esters",
  },
  { brandName: "Omeprazole", genericName: "Omeprazole" },
  { brandName: "Omnaris", genericName: "Omnaris" },
  { brandName: "Omnicef", genericName: "Omnicef" },
  { brandName: "Omnipred", genericName: "Omnipred" },
  { brandName: "Omniscan", genericName: "Omniscan" },
  { brandName: "Omnitrope", genericName: "Omnitrope" },
  { brandName: "Oncaspar", genericName: "Oncaspar" },
  {
    brandName: "Ondansetron hydrochloride",
    genericName: "Ondansetron hydrochloride",
  },
  { brandName: "Onglyza", genericName: "Onglyza" },
  { brandName: "Onsolis", genericName: "Onsolis" },
  { brandName: "Ontak", genericName: "Ontak" },
  { brandName: "Opana", genericName: "Opana" },
  { brandName: "Opana er", genericName: "Opana er" },
  { brandName: "Oprelvekin", genericName: "Oprelvekin" },
  { brandName: "Opticrom", genericName: "Opticrom" },
  { brandName: "Optimark", genericName: "Optimark" },
  { brandName: "Optipranolol", genericName: "Optipranolol" },
  { brandName: "Optiray injection", genericName: "Optiray injection" },
  { brandName: "Optison", genericName: "Optison" },
  { brandName: "Optivar", genericName: "Optivar" },
  { brandName: "Oracea", genericName: "Oracea" },
  { brandName: "Orap", genericName: "Orap" },
  { brandName: "Orapred odt", genericName: "Orapred odt" },
  { brandName: "Oraqix", genericName: "Oraqix" },
  { brandName: "Oravig", genericName: "Oravig" },
  { brandName: "Orencia", genericName: "Orencia" },
  { brandName: "Orfadin", genericName: "Orfadin" },
  { brandName: "Organidin nr", genericName: "Organidin nr" },
  { brandName: "Orimune", genericName: "Orimune" },
  { brandName: "Orlaam", genericName: "Orlaam" },
  { brandName: "Orlistat", genericName: "Orlistat" },
  { brandName: "Orphenadrine", genericName: "Orphenadrine" },
  { brandName: "Orphenadrine citrate", genericName: "Orphenadrine citrate" },
  { brandName: "Orphengesic", genericName: "Orphengesic" },
  { brandName: "Ortho evra", genericName: "Ortho evra" },
  { brandName: "Ortho tri cyclen", genericName: "Ortho tri cyclen" },
  { brandName: "Ortho-novum", genericName: "Ortho-novum" },
  { brandName: "Orudis", genericName: "Orudis" },
  { brandName: "Oseltamivir phosphate", genericName: "Oseltamivir phosphate" },
  { brandName: "Osmitrol", genericName: "Osmitrol" },
  { brandName: "Osmoprep", genericName: "Osmoprep" },
  { brandName: "Ovcon", genericName: "Ovcon" },
  { brandName: "Ovide", genericName: "Ovide" },
  { brandName: "Ovidrel", genericName: "Ovidrel" },
  { brandName: "Oxacillin", genericName: "Oxacillin" },
  { brandName: "Oxaliplatin", genericName: "Oxaliplatin" },
  { brandName: "Oxandrin", genericName: "Oxandrin" },
  { brandName: "Oxandrolone", genericName: "Oxandrolone" },
  { brandName: "Oxaprozin", genericName: "Oxaprozin" },
  { brandName: "Oxazepam", genericName: "Oxazepam" },
  { brandName: "Oxcarbazepine", genericName: "Oxcarbazepine" },
  { brandName: "Oxiconazole", genericName: "Oxiconazole" },
  { brandName: "Oxilan", genericName: "Oxilan" },
  { brandName: "Oxistat", genericName: "Oxistat" },
  { brandName: "Oxsoralen-ultra", genericName: "Oxsoralen-ultra" },
  { brandName: "Oxybutynin chloride", genericName: "Oxybutynin chloride" },
  {
    brandName: "Oxycodone and acetaminophen",
    genericName: "Oxycodone and acetaminophen",
  },
  { brandName: "Oxycodone hcl", genericName: "Oxycodone hcl" },
  {
    brandName: "Oxycodone hydrochloride",
    genericName: "Oxycodone hydrochloride",
  },
  { brandName: "Oxycontin", genericName: "Oxycontin" },
  { brandName: "Oxymetholone", genericName: "Oxymetholone" },
  { brandName: "Oxymorphone", genericName: "Oxymorphone" },
  {
    brandName: "Oxymorphone hydrochloride",
    genericName: "Oxymorphone hydrochloride",
  },
  {
    brandName: "Oxymorphone hydrochloride extended release",
    genericName: "Oxymorphone hydrochloride extended release",
  },
  { brandName: "Oxytetracycline", genericName: "Oxytetracycline" },
  { brandName: "Oxytocin", genericName: "Oxytocin" },
  { brandName: "Oxytrol", genericName: "Oxytrol" },
  { brandName: "Paclitaxel", genericName: "Paclitaxel" },
  { brandName: "Palifermin", genericName: "Palifermin" },
  { brandName: "Paliperidone", genericName: "Paliperidone" },
  { brandName: "Palivizumab", genericName: "Palivizumab" },
  { brandName: "Palonosetron", genericName: "Palonosetron" },
  {
    brandName: "Palonosetron hydrochloride",
    genericName: "Palonosetron hydrochloride",
  },
  { brandName: "Pamelor", genericName: "Pamelor" },
  { brandName: "Pamidronate disodium", genericName: "Pamidronate disodium" },
  { brandName: "Pancrecarb", genericName: "Pancrecarb" },
  { brandName: "Pancrelipase", genericName: "Pancrelipase" },
  { brandName: "Pandel", genericName: "Pandel" },
  { brandName: "Panhematin", genericName: "Panhematin" },
  { brandName: "Panretin", genericName: "Panretin" },
  { brandName: "Pantoprazole", genericName: "Pantoprazole" },
  { brandName: "Pantoprazole sodium", genericName: "Pantoprazole sodium" },
  { brandName: "Papain", genericName: "Papain" },
  { brandName: "Papaverine", genericName: "Papaverine" },
  { brandName: "Parafon forte", genericName: "Parafon forte" },
  { brandName: "Paraplatin", genericName: "Paraplatin" },
  { brandName: "Paregoric", genericName: "Paregoric" },
  { brandName: "Paricalcitol", genericName: "Paricalcitol" },
  { brandName: "Parlodel", genericName: "Parlodel" },
  { brandName: "Parnate", genericName: "Parnate" },
  { brandName: "Paromomycin sulfate", genericName: "Paromomycin sulfate" },
  {
    brandName: "Paroxetine hydrochloride",
    genericName: "Paroxetine hydrochloride",
  },
  { brandName: "Paroxetine mesylate", genericName: "Paroxetine mesylate" },
  { brandName: "Paser", genericName: "Paser" },
  { brandName: "Pataday", genericName: "Pataday" },
  { brandName: "Patanase", genericName: "Patanase" },
  { brandName: "Patanol", genericName: "Patanol" },
  { brandName: "Paxil", genericName: "Paxil" },
  { brandName: "Pazopanib", genericName: "Pazopanib" },
  { brandName: "Pce", genericName: "Pce" },
  { brandName: "Pediapred", genericName: "Pediapred" },
  { brandName: "Pediarix", genericName: "Pediarix" },
  { brandName: "Pediazole", genericName: "Pediazole" },
  { brandName: "Pediotic", genericName: "Pediotic" },
  { brandName: "Peg", genericName: "Peg" },
  { brandName: "Peg 3350", genericName: "Peg 3350" },
  { brandName: "Peg-intron", genericName: "Peg-intron" },
  { brandName: "Pegademase bovine", genericName: "Pegademase bovine" },
  { brandName: "Peganone", genericName: "Peganone" },
  { brandName: "Pegaptanib sodium", genericName: "Pegaptanib sodium" },
  { brandName: "Pegaspargase", genericName: "Pegaspargase" },
  { brandName: "Pegasys", genericName: "Pegasys" },
  { brandName: "Pegfilgrastim", genericName: "Pegfilgrastim" },
  { brandName: "Peginterferon alfa-2a", genericName: "Peginterferon alfa-2a" },
  { brandName: "Peginterferon alfa-2b", genericName: "Peginterferon alfa-2b" },
  { brandName: "Pegintron", genericName: "Pegintron" },
  { brandName: "Pegvisomant", genericName: "Pegvisomant" },
  { brandName: "Pemetrexed", genericName: "Pemetrexed" },
  { brandName: "Pemirolast potassium", genericName: "Pemirolast potassium" },
  { brandName: "Pemoline", genericName: "Pemoline" },
  { brandName: "Penciclovir", genericName: "Penciclovir" },
  { brandName: "Penetrex", genericName: "Penetrex" },
  { brandName: "Penicillamine", genericName: "Penicillamine" },
  {
    brandName: "Penicillin g benzathine",
    genericName: "Penicillin g benzathine",
  },
  {
    brandName: "Penicillin g potassium",
    genericName: "Penicillin g potassium",
  },
  {
    brandName: "Penicillin v potassium",
    genericName: "Penicillin v potassium",
  },
  { brandName: "Penicillin vk", genericName: "Penicillin vk" },
  { brandName: "Penlac", genericName: "Penlac" },
  { brandName: "Pennsaid", genericName: "Pennsaid" },
  {
    brandName: "Pentamidine isethionate",
    genericName: "Pentamidine isethionate",
  },
  { brandName: "Pentasa", genericName: "Pentasa" },
  {
    brandName: "Pentazocine and acetaminophen",
    genericName: "Pentazocine and acetaminophen",
  },
  {
    brandName: "Pentazocine and aspirin",
    genericName: "Pentazocine and aspirin",
  },
  {
    brandName: "Pentetate zinc trisodium",
    genericName: "Pentetate zinc trisodium",
  },
  { brandName: "Pentobarbital", genericName: "Pentobarbital" },
  {
    brandName: "Pentosan polysulfate sodium",
    genericName: "Pentosan polysulfate sodium",
  },
  { brandName: "Pentothal", genericName: "Pentothal" },
  { brandName: "Pentoxifylline", genericName: "Pentoxifylline" },
  { brandName: "Pepcid", genericName: "Pepcid" },
  { brandName: "Percocet", genericName: "Percocet" },
  { brandName: "Percodan", genericName: "Percodan" },
  { brandName: "Perflutren", genericName: "Perflutren" },
  { brandName: "Perforomist", genericName: "Perforomist" },
  { brandName: "Pergolide mesylate", genericName: "Pergolide mesylate" },
  { brandName: "Perindopril erbumine", genericName: "Perindopril erbumine" },
  { brandName: "Periochip", genericName: "Periochip" },
  { brandName: "Periostat", genericName: "Periostat" },
  { brandName: "Permax", genericName: "Permax" },
  { brandName: "Permethrin", genericName: "Permethrin" },
  { brandName: "Perphenazine", genericName: "Perphenazine" },
  { brandName: "Persantine", genericName: "Persantine" },
  { brandName: "Persantine iv", genericName: "Persantine iv" },
  { brandName: "Pexeva", genericName: "Pexeva" },
  { brandName: "Pfizerpen", genericName: "Pfizerpen" },
  { brandName: "Phenazopyridine", genericName: "Phenazopyridine" },
  {
    brandName: "Phendimetrazine tartrate",
    genericName: "Phendimetrazine tartrate",
  },
  { brandName: "Phenelzine", genericName: "Phenelzine" },
  { brandName: "Phenergan", genericName: "Phenergan" },
  { brandName: "Phenobarbital", genericName: "Phenobarbital" },
  { brandName: "Phenoxybenzamine", genericName: "Phenoxybenzamine" },
  { brandName: "Phentermine", genericName: "Phentermine" },
  {
    brandName: "Phentermine hydrochloride",
    genericName: "Phentermine hydrochloride",
  },
  { brandName: "Phentolamine mesylate", genericName: "Phentolamine mesylate" },
  { brandName: "Phenylephrine hcl", genericName: "Phenylephrine hcl" },
  {
    brandName: "Phenylephrine hydrochloride",
    genericName: "Phenylephrine hydrochloride",
  },
  { brandName: "Phenytoin", genericName: "Phenytoin" },
  { brandName: "Phisohex", genericName: "Phisohex" },
  { brandName: "Phoslo", genericName: "Phoslo" },
  { brandName: "Phosphate", genericName: "Phosphate" },
  { brandName: "Phosphocol", genericName: "Phosphocol" },
  { brandName: "Phospholine iodide", genericName: "Phospholine iodide" },
  { brandName: "Photofrin", genericName: "Photofrin" },
  {
    brandName: "Physostigmine salicylate",
    genericName: "Physostigmine salicylate",
  },
  { brandName: "Phytonadione", genericName: "Phytonadione" },
  { brandName: "Pilocarpine", genericName: "Pilocarpine" },
  {
    brandName: "Pilocarpine hydrochloride",
    genericName: "Pilocarpine hydrochloride",
  },
  { brandName: "Pilopine hs", genericName: "Pilopine hs" },
  { brandName: "Pimecrolimus", genericName: "Pimecrolimus" },
  { brandName: "Pimozide", genericName: "Pimozide" },
  { brandName: "Pindolol", genericName: "Pindolol" },
  { brandName: "Pioglitazone hcl", genericName: "Pioglitazone hcl" },
  {
    brandName: "Piperacillin / tazobactam",
    genericName: "Piperacillin / tazobactam",
  },
  { brandName: "Pipracil", genericName: "Pipracil" },
  { brandName: "Pirbuterol", genericName: "Pirbuterol" },
  { brandName: "Piroxicam", genericName: "Piroxicam" },
  { brandName: "Pitavastatin", genericName: "Pitavastatin" },
  { brandName: "Pitocin", genericName: "Pitocin" },
  { brandName: "Pitressin", genericName: "Pitressin" },
  { brandName: "Plan b", genericName: "Plan b" },
  { brandName: "Plaquenil", genericName: "Plaquenil" },
  { brandName: "Plasmalyte a", genericName: "Plasmalyte a" },
  { brandName: "Plavix", genericName: "Plavix" },
  { brandName: "Plenaxis", genericName: "Plenaxis" },
  { brandName: "Plendil", genericName: "Plendil" },
  { brandName: "Plerixafor", genericName: "Plerixafor" },
  { brandName: "Pletal", genericName: "Pletal" },
  { brandName: "Pliaglis", genericName: "Pliaglis" },
  { brandName: "Plicamycin", genericName: "Plicamycin" },
  {
    brandName: "Pneumococcal 7-valent vaccine",
    genericName: "Pneumococcal 7-valent vaccine",
  },
  {
    brandName: "Pneumococcal vaccine polyvalent",
    genericName: "Pneumococcal vaccine polyvalent",
  },
  { brandName: "Pneumovax", genericName: "Pneumovax" },
  { brandName: "Podocon-25", genericName: "Podocon-25" },
  { brandName: "Podofilox", genericName: "Podofilox" },
  { brandName: "Podophyllin", genericName: "Podophyllin" },
  { brandName: "Polidocanol", genericName: "Polidocanol" },
  { brandName: "Polifeprosan 20", genericName: "Polifeprosan 20" },
  { brandName: "Poliovirus vaccine", genericName: "Poliovirus vaccine" },
  {
    brandName: "Polyethylene glycol 3350",
    genericName: "Polyethylene glycol 3350",
  },
  { brandName: "Polymyxin b", genericName: "Polymyxin b" },
  { brandName: "Polymyxin b sulfate", genericName: "Polymyxin b sulfate" },
  { brandName: "Polysaccharides", genericName: "Polysaccharides" },
  { brandName: "Polythiazide", genericName: "Polythiazide" },
  { brandName: "Pondimin", genericName: "Pondimin" },
  { brandName: "Ponstel", genericName: "Ponstel" },
  { brandName: "Poractant alfa", genericName: "Poractant alfa" },
  { brandName: "Porfimer sodium", genericName: "Porfimer sodium" },
  { brandName: "Posaconazole", genericName: "Posaconazole" },
  { brandName: "Potassium acetate", genericName: "Potassium acetate" },
  { brandName: "Potassium chloride", genericName: "Potassium chloride" },
  { brandName: "Potassium citrate", genericName: "Potassium citrate" },
  { brandName: "Pralatrexate", genericName: "Pralatrexate" },
  { brandName: "Pralidoxime chloride", genericName: "Pralidoxime chloride" },
  { brandName: "Pramipexole", genericName: "Pramipexole" },
  { brandName: "Pramlintide acetate", genericName: "Pramlintide acetate" },
  { brandName: "Prandimet", genericName: "Prandimet" },
  { brandName: "Prandin", genericName: "Prandin" },
  { brandName: "Prasugrel", genericName: "Prasugrel" },
  { brandName: "Pravachol", genericName: "Pravachol" },
  { brandName: "Pravastatin sodium", genericName: "Pravastatin sodium" },
  { brandName: "Praziquantel", genericName: "Praziquantel" },
  { brandName: "Prazosin hcl", genericName: "Prazosin hcl" },
  { brandName: "Precedex", genericName: "Precedex" },
  { brandName: "Precose", genericName: "Precose" },
  { brandName: "Pred forte", genericName: "Pred forte" },
  { brandName: "Pred-g", genericName: "Pred-g" },
  { brandName: "Prednicarbate", genericName: "Prednicarbate" },
  { brandName: "Prednisolone", genericName: "Prednisolone" },
  { brandName: "Prednisolone acetate", genericName: "Prednisolone acetate" },
  {
    brandName: "Prednisolone sodium phosphate",
    genericName: "Prednisolone sodium phosphate",
  },
  { brandName: "Prednisone", genericName: "Prednisone" },
  { brandName: "Prefest", genericName: "Prefest" },
  { brandName: "Pregabalin", genericName: "Pregabalin" },
  { brandName: "Pregnyl", genericName: "Pregnyl" },
  { brandName: "Prelone", genericName: "Prelone" },
  { brandName: "Premarin", genericName: "Premarin" },
  { brandName: "Prempro, premphase", genericName: "Prempro, premphase" },
  { brandName: "Prepidil", genericName: "Prepidil" },
  { brandName: "Prevacid", genericName: "Prevacid" },
  { brandName: "Prevnar", genericName: "Prevnar" },
  { brandName: "Prevpac", genericName: "Prevpac" },
  { brandName: "Prezista", genericName: "Prezista" },
  { brandName: "Prialt", genericName: "Prialt" },
  { brandName: "Priftin", genericName: "Priftin" },
  { brandName: "Prilosec", genericName: "Prilosec" },
  { brandName: "Primacor", genericName: "Primacor" },
  { brandName: "Primaquine", genericName: "Primaquine" },
  { brandName: "Primatene mist", genericName: "Primatene mist" },
  { brandName: "Primaxin i.v.", genericName: "Primaxin i.v." },
  { brandName: "Primidone", genericName: "Primidone" },
  { brandName: "Principen", genericName: "Principen" },
  { brandName: "Prinivil", genericName: "Prinivil" },
  { brandName: "Prinzide", genericName: "Prinzide" },
  { brandName: "Pristiq", genericName: "Pristiq" },
  { brandName: "Privigen", genericName: "Privigen" },
  { brandName: "Proair hfa", genericName: "Proair hfa" },
  { brandName: "Proamatine", genericName: "Proamatine" },
  {
    brandName: "Probenecid and colchicine",
    genericName: "Probenecid and colchicine",
  },
  { brandName: "Procainamide", genericName: "Procainamide" },
  { brandName: "Procalamine", genericName: "Procalamine" },
  { brandName: "Procan sr", genericName: "Procan sr" },
  { brandName: "Procarbazine", genericName: "Procarbazine" },
  { brandName: "Procardia", genericName: "Procardia" },
  { brandName: "Prochlorperazine", genericName: "Prochlorperazine" },
  {
    brandName: "Prochlorperazine maleate",
    genericName: "Prochlorperazine maleate",
  },
  { brandName: "Procrit", genericName: "Procrit" },
  { brandName: "Proctofoam hc", genericName: "Proctofoam hc" },
  { brandName: "Progesterone", genericName: "Progesterone" },
  { brandName: "Proglycem", genericName: "Proglycem" },
  { brandName: "Prograf", genericName: "Prograf" },
  { brandName: "Prohance", genericName: "Prohance" },
  { brandName: "Prolastin", genericName: "Prolastin" },
  { brandName: "Proleukin", genericName: "Proleukin" },
  { brandName: "Prolia", genericName: "Prolia" },
  { brandName: "Prolixin", genericName: "Prolixin" },
  { brandName: "Promacta", genericName: "Promacta" },
  { brandName: "Promethazine", genericName: "Promethazine" },
  { brandName: "Promethazine hcl", genericName: "Promethazine hcl" },
  {
    brandName: "Promethazine hydrochloride",
    genericName: "Promethazine hydrochloride",
  },
  { brandName: "Prometrium", genericName: "Prometrium" },
  { brandName: "Pronestyl", genericName: "Pronestyl" },
  { brandName: "Propafenone", genericName: "Propafenone" },
  {
    brandName: "Proparacaine hydrochloride",
    genericName: "Proparacaine hydrochloride",
  },
  { brandName: "Propecia", genericName: "Propecia" },
  { brandName: "Propine", genericName: "Propine" },
  { brandName: "Propofol", genericName: "Propofol" },
  { brandName: "Propoxyphene", genericName: "Propoxyphene" },
  {
    brandName: "Propoxyphene napsylate",
    genericName: "Propoxyphene napsylate",
  },
  { brandName: "Propranolol", genericName: "Propranolol" },
  {
    brandName: "Propranolol hydrochloride",
    genericName: "Propranolol hydrochloride",
  },
  { brandName: "Propulsid", genericName: "Propulsid" },
  { brandName: "Propylthiouracil", genericName: "Propylthiouracil" },
  { brandName: "Proquin xr", genericName: "Proquin xr" },
  { brandName: "Proscar", genericName: "Proscar" },
  { brandName: "Prosom", genericName: "Prosom" },
  { brandName: "Prostigmin", genericName: "Prostigmin" },
  { brandName: "Prostin e2", genericName: "Prostin e2" },
  { brandName: "Prostin vr pediatric", genericName: "Prostin vr pediatric" },
  { brandName: "Protamine", genericName: "Protamine" },
  { brandName: "Protamines", genericName: "Protamines" },
  { brandName: "Protein c concentrate", genericName: "Protein c concentrate" },
  { brandName: "Protirelin", genericName: "Protirelin" },
  { brandName: "Protonix", genericName: "Protonix" },
  { brandName: "Protonix i.v.", genericName: "Protonix i.v." },
  { brandName: "Protopic", genericName: "Protopic" },
  {
    brandName: "Protriptyline hydrochloride",
    genericName: "Protriptyline hydrochloride",
  },
  { brandName: "Protropin", genericName: "Protropin" },
  { brandName: "Proventil hfa", genericName: "Proventil hfa" },
  { brandName: "Provera", genericName: "Provera" },
  { brandName: "Provigil", genericName: "Provigil" },
  { brandName: "Provisc", genericName: "Provisc" },
  { brandName: "Provocholine", genericName: "Provocholine" },
  { brandName: "Prozac", genericName: "Prozac" },
  { brandName: "Pseudoephedrine", genericName: "Pseudoephedrine" },
  {
    brandName: "Pseudoephedrine / guaifenesin",
    genericName: "Pseudoephedrine / guaifenesin",
  },
  { brandName: "Psorcon e", genericName: "Psorcon e" },
  { brandName: "Pulmicort flexhaler", genericName: "Pulmicort flexhaler" },
  { brandName: "Pulmozyme", genericName: "Pulmozyme" },
  { brandName: "Purinethol", genericName: "Purinethol" },
  { brandName: "Pylera", genericName: "Pylera" },
  { brandName: "Pyrazinamide", genericName: "Pyrazinamide" },
  { brandName: "Pyridium", genericName: "Pyridium" },
  { brandName: "Pyridostigmine", genericName: "Pyridostigmine" },
  { brandName: "Pyrimethamine", genericName: "Pyrimethamine" },
  { brandName: "Quadramet", genericName: "Quadramet" },
  { brandName: "Qualaquin", genericName: "Qualaquin" },
  { brandName: "Quazepam", genericName: "Quazepam" },
  { brandName: "Questran", genericName: "Questran" },
  { brandName: "Quetiapine fumarate", genericName: "Quetiapine fumarate" },
  {
    brandName: "Quinapril hydrochloride",
    genericName: "Quinapril hydrochloride",
  },
  { brandName: "Quinidex", genericName: "Quinidex" },
  { brandName: "Quinidine", genericName: "Quinidine" },
  { brandName: "Quinidine gluconate", genericName: "Quinidine gluconate" },
  { brandName: "Quinidine sulfate", genericName: "Quinidine sulfate" },
  {
    brandName: "Quinupristin and dalfopristin",
    genericName: "Quinupristin and dalfopristin",
  },
  { brandName: "Quixin", genericName: "Quixin" },
  { brandName: "Qutenza", genericName: "Qutenza" },
  { brandName: "Qvar", genericName: "Qvar" },
  { brandName: "R-gene 10", genericName: "R-gene 10" },
  { brandName: "Rabavert", genericName: "Rabavert" },
  { brandName: "Rabeprazole sodium", genericName: "Rabeprazole sodium" },
  {
    brandName: "Rabies immune globulin",
    genericName: "Rabies immune globulin",
  },
  { brandName: "Rabies vaccine", genericName: "Rabies vaccine" },
  { brandName: "Radiogardase", genericName: "Radiogardase" },
  { brandName: "Raloxifene", genericName: "Raloxifene" },
  { brandName: "Raltegravir", genericName: "Raltegravir" },
  { brandName: "Ramelteon", genericName: "Ramelteon" },
  { brandName: "Ramipril", genericName: "Ramipril" },
  { brandName: "Ranexa", genericName: "Ranexa" },
  { brandName: "Ranibizumab", genericName: "Ranibizumab" },
  {
    brandName: "Ranitidine bismuth citrate",
    genericName: "Ranitidine bismuth citrate",
  },
  { brandName: "Ranitidine hcl", genericName: "Ranitidine hcl" },
  {
    brandName: "Ranitidine hydrochloride",
    genericName: "Ranitidine hydrochloride",
  },
  { brandName: "Ranolazine", genericName: "Ranolazine" },
  { brandName: "Rapaflo", genericName: "Rapaflo" },
  { brandName: "Rapamune", genericName: "Rapamune" },
  { brandName: "Raplon", genericName: "Raplon" },
  { brandName: "Raptiva", genericName: "Raptiva" },
  { brandName: "Rasagiline", genericName: "Rasagiline" },
  { brandName: "Rasburicase", genericName: "Rasburicase" },
  { brandName: "Rattlesnake antivenin", genericName: "Rattlesnake antivenin" },
  { brandName: "Raxar", genericName: "Raxar" },
  { brandName: "Razadyne er", genericName: "Razadyne er" },
  { brandName: "Rebetol", genericName: "Rebetol" },
  { brandName: "Rebetron", genericName: "Rebetron" },
  { brandName: "Rebif", genericName: "Rebif" },
  { brandName: "Reclast", genericName: "Reclast" },
  { brandName: "Recombinate", genericName: "Recombinate" },
  { brandName: "Refacto", genericName: "Refacto" },
  { brandName: "Refludan", genericName: "Refludan" },
  { brandName: "Regadenoson", genericName: "Regadenoson" },
  { brandName: "Reglan", genericName: "Reglan" },
  { brandName: "Regranex", genericName: "Regranex" },
  { brandName: "Relafen", genericName: "Relafen" },
  { brandName: "Relenza", genericName: "Relenza" },
  { brandName: "Relistor", genericName: "Relistor" },
  { brandName: "Relpax", genericName: "Relpax" },
  { brandName: "Remeron", genericName: "Remeron" },
  { brandName: "Remicade", genericName: "Remicade" },
  { brandName: "Remifentanil", genericName: "Remifentanil" },
  { brandName: "Remodulin", genericName: "Remodulin" },
  { brandName: "Renagel", genericName: "Renagel" },
  { brandName: "Renese", genericName: "Renese" },
  { brandName: "Renova", genericName: "Renova" },
  { brandName: "Renvela", genericName: "Renvela" },
  { brandName: "Reopro", genericName: "Reopro" },
  { brandName: "Repaglinide", genericName: "Repaglinide" },
  { brandName: "Repronex", genericName: "Repronex" },
  { brandName: "Requip", genericName: "Requip" },
  { brandName: "Rescriptor", genericName: "Rescriptor" },
  { brandName: "Rescula", genericName: "Rescula" },
  { brandName: "Restasis", genericName: "Restasis" },
  { brandName: "Restoril", genericName: "Restoril" },
  { brandName: "Retapamulin", genericName: "Retapamulin" },
  { brandName: "Retavase", genericName: "Retavase" },
  { brandName: "Reteplase", genericName: "Reteplase" },
  { brandName: "Retin-a micro", genericName: "Retin-a micro" },
  { brandName: "Retisert", genericName: "Retisert" },
  { brandName: "Retrovir", genericName: "Retrovir" },
  { brandName: "Revatio", genericName: "Revatio" },
  { brandName: "Revex", genericName: "Revex" },
  { brandName: "Revia", genericName: "Revia" },
  { brandName: "Revlimid", genericName: "Revlimid" },
  { brandName: "Reyataz", genericName: "Reyataz" },
  { brandName: "Rezulin", genericName: "Rezulin" },
  { brandName: "Rheumatrex", genericName: "Rheumatrex" },
  { brandName: "Rhinocort aqua", genericName: "Rhinocort aqua" },
  { brandName: "Rhogam", genericName: "Rhogam" },
  {
    brandName: "Rhogam ultra-filtered plus",
    genericName: "Rhogam ultra-filtered plus",
  },
  { brandName: "Rhophylac", genericName: "Rhophylac" },
  { brandName: "Riastap", genericName: "Riastap" },
  { brandName: "Ribavirin", genericName: "Ribavirin" },
  { brandName: "Rifabutin", genericName: "Rifabutin" },
  { brandName: "Rifadin", genericName: "Rifadin" },
  { brandName: "Rifamate", genericName: "Rifamate" },
  { brandName: "Rifampin", genericName: "Rifampin" },
  {
    brandName: "Rifampin and isoniazid",
    genericName: "Rifampin and isoniazid",
  },
  { brandName: "Rifapentine", genericName: "Rifapentine" },
  { brandName: "Rifater", genericName: "Rifater" },
  { brandName: "Rifaximin", genericName: "Rifaximin" },
  { brandName: "Rilonacept", genericName: "Rilonacept" },
  { brandName: "Rilutek", genericName: "Rilutek" },
  { brandName: "Riluzole", genericName: "Riluzole" },
  { brandName: "Rimantadine", genericName: "Rimantadine" },
  { brandName: "Rimexolone", genericName: "Rimexolone" },
  { brandName: "Rimso-50", genericName: "Rimso-50" },
  { brandName: "Ringer's solution", genericName: "Ringer's solution" },
  { brandName: "Riomet", genericName: "Riomet" },
  { brandName: "Risedronate sodium", genericName: "Risedronate sodium" },
  { brandName: "Risperdal", genericName: "Risperdal" },
  { brandName: "Risperdal consta", genericName: "Risperdal consta" },
  { brandName: "Risperidone", genericName: "Risperidone" },
  { brandName: "Ritalin", genericName: "Ritalin" },
  { brandName: "Ritonavir", genericName: "Ritonavir" },
  { brandName: "Rituxan", genericName: "Rituxan" },
  { brandName: "Rituximab", genericName: "Rituximab" },
  { brandName: "Rivastigmine tartrate", genericName: "Rivastigmine tartrate" },
  { brandName: "Rizatriptan benzoate", genericName: "Rizatriptan benzoate" },
  { brandName: "Robaxin", genericName: "Robaxin" },
  { brandName: "Robaxisal", genericName: "Robaxisal" },
  { brandName: "Robinul", genericName: "Robinul" },
  { brandName: "Robitussin ac", genericName: "Robitussin ac" },
  { brandName: "Rocaltrol", genericName: "Rocaltrol" },
  { brandName: "Rocephin", genericName: "Rocephin" },
  { brandName: "Rocuronium bromide", genericName: "Rocuronium bromide" },
  { brandName: "Rofecoxib", genericName: "Rofecoxib" },
  { brandName: "Roferon-a", genericName: "Roferon-a" },
  { brandName: "Romazicon", genericName: "Romazicon" },
  { brandName: "Romidepsin", genericName: "Romidepsin" },
  { brandName: "Romiplostim", genericName: "Romiplostim" },
  { brandName: "Rondec", genericName: "Rondec" },
  { brandName: "Ropinirole", genericName: "Ropinirole" },
  { brandName: "Ropinirole hcl", genericName: "Ropinirole hcl" },
  { brandName: "Ropivacaine hcl", genericName: "Ropivacaine hcl" },
  { brandName: "Rosanil", genericName: "Rosanil" },
  { brandName: "Rosiglitazone maleate", genericName: "Rosiglitazone maleate" },
  { brandName: "Rosuvastatin calcium", genericName: "Rosuvastatin calcium" },
  { brandName: "Rotarix", genericName: "Rotarix" },
  { brandName: "Rotateq", genericName: "Rotateq" },
  {
    brandName: "Rotavirus vaccine, live, oral",
    genericName: "Rotavirus vaccine, live, oral",
  },
  { brandName: "Rotigotine", genericName: "Rotigotine" },
  { brandName: "Rowasa", genericName: "Rowasa" },
  { brandName: "Roxanol", genericName: "Roxanol" },
  { brandName: "Roxicet", genericName: "Roxicet" },
  { brandName: "Roxicodone", genericName: "Roxicodone" },
  { brandName: "Rozerem", genericName: "Rozerem" },
  { brandName: "Rubella vaccine", genericName: "Rubella vaccine" },
  { brandName: "Rufinamide", genericName: "Rufinamide" },
  { brandName: "Rythmol", genericName: "Rythmol" },
  { brandName: "Rythmol sr", genericName: "Rythmol sr" },
  { brandName: "Ryzolt", genericName: "Ryzolt" },
  { brandName: "Sabril", genericName: "Sabril" },
  { brandName: "Sacrosidase", genericName: "Sacrosidase" },
  { brandName: "Saizen", genericName: "Saizen" },
  { brandName: "Salagen", genericName: "Salagen" },
  { brandName: "Salmeterol xinafoate", genericName: "Salmeterol xinafoate" },
  { brandName: "Salsalate", genericName: "Salsalate" },
  {
    brandName: "Samarium sm 153 lexidronam",
    genericName: "Samarium sm 153 lexidronam",
  },
  { brandName: "Samsca", genericName: "Samsca" },
  { brandName: "Sanctura", genericName: "Sanctura" },
  { brandName: "Sanctura xr", genericName: "Sanctura xr" },
  { brandName: "Sancuso", genericName: "Sancuso" },
  { brandName: "Sandimmune", genericName: "Sandimmune" },
  { brandName: "Sandostatin", genericName: "Sandostatin" },
  { brandName: "Sandostatin lar", genericName: "Sandostatin lar" },
  { brandName: "Sansert", genericName: "Sansert" },
  { brandName: "Santyl", genericName: "Santyl" },
  { brandName: "Saphris", genericName: "Saphris" },
  { brandName: "Saquinavir mesylate", genericName: "Saquinavir mesylate" },
  { brandName: "Sarafem", genericName: "Sarafem" },
  { brandName: "Sargramostim", genericName: "Sargramostim" },
  { brandName: "Savella", genericName: "Savella" },
  { brandName: "Saxagliptin", genericName: "Saxagliptin" },
  { brandName: "Scopolamine", genericName: "Scopolamine" },
  { brandName: "Seasonale", genericName: "Seasonale" },
  { brandName: "Seasonique", genericName: "Seasonique" },
  { brandName: "Secobarbital sodium", genericName: "Secobarbital sodium" },
  { brandName: "Seconal sodium", genericName: "Seconal sodium" },
  { brandName: "Secreflo", genericName: "Secreflo" },
  { brandName: "Secretin", genericName: "Secretin" },
  { brandName: "Sectral", genericName: "Sectral" },
  { brandName: "Selegiline hcl", genericName: "Selegiline hcl" },
  {
    brandName: "Selegiline hydrochloride",
    genericName: "Selegiline hydrochloride",
  },
  {
    brandName: "Selegiline transdermal system",
    genericName: "Selegiline transdermal system",
  },
  { brandName: "Selenium", genericName: "Selenium" },
  { brandName: "Selsun", genericName: "Selsun" },
  { brandName: "Selzentry", genericName: "Selzentry" },
  { brandName: "Semprex d", genericName: "Semprex d" },
  { brandName: "Sensipar", genericName: "Sensipar" },
  { brandName: "Sensorcaine", genericName: "Sensorcaine" },
  { brandName: "Septocaine", genericName: "Septocaine" },
  { brandName: "Septra", genericName: "Septra" },
  { brandName: "Serax", genericName: "Serax" },
  { brandName: "Serevent diskus", genericName: "Serevent diskus" },
  { brandName: "Sermorelin", genericName: "Sermorelin" },
  { brandName: "Sermorelin acetate", genericName: "Sermorelin acetate" },
  { brandName: "Seroquel", genericName: "Seroquel" },
  { brandName: "Seroquel xr", genericName: "Seroquel xr" },
  { brandName: "Serostim", genericName: "Serostim" },
  { brandName: "Serostim lq", genericName: "Serostim lq" },
  { brandName: "Sertaconazole nitrate", genericName: "Sertaconazole nitrate" },
  { brandName: "Sertraline hcl", genericName: "Sertraline hcl" },
  { brandName: "Serzone", genericName: "Serzone" },
  { brandName: "Sevelamer carbonate", genericName: "Sevelamer carbonate" },
  { brandName: "Sevelamer hcl", genericName: "Sevelamer hcl" },
  { brandName: "Sevoflurane", genericName: "Sevoflurane" },
  {
    brandName: "Sibutramine hydrochloride monohydrate",
    genericName: "Sibutramine hydrochloride monohydrate",
  },
  { brandName: "Sildenafil citrate", genericName: "Sildenafil citrate" },
  { brandName: "Silenor", genericName: "Silenor" },
  { brandName: "Silodosin", genericName: "Silodosin" },
  { brandName: "Silvadene", genericName: "Silvadene" },
  { brandName: "Silver sulfadiazine", genericName: "Silver sulfadiazine" },
  { brandName: "Simcor", genericName: "Simcor" },
  { brandName: "Simponi", genericName: "Simponi" },
  { brandName: "Simulect", genericName: "Simulect" },
  { brandName: "Simvastatin", genericName: "Simvastatin" },
  { brandName: "Sincalide", genericName: "Sincalide" },
  { brandName: "Sinemet", genericName: "Sinemet" },
  { brandName: "Sinemet cr", genericName: "Sinemet cr" },
  { brandName: "Sinequan", genericName: "Sinequan" },
  { brandName: "Singulair", genericName: "Singulair" },
  { brandName: "Sirolimus", genericName: "Sirolimus" },
  {
    brandName: "Sitagliptin metformin hcl",
    genericName: "Sitagliptin metformin hcl",
  },
  { brandName: "Sitagliptin phosphate", genericName: "Sitagliptin phosphate" },
  { brandName: "Skelaxin", genericName: "Skelaxin" },
  { brandName: "Skelid", genericName: "Skelid" },
  { brandName: "Slo-phyllin", genericName: "Slo-phyllin" },
  { brandName: "Slow-k", genericName: "Slow-k" },
  { brandName: "Sodium acetate", genericName: "Sodium acetate" },
  { brandName: "Sodium bicarbonate", genericName: "Sodium bicarbonate" },
  {
    brandName: "Sodium ferric gluconate",
    genericName: "Sodium ferric gluconate",
  },
  { brandName: "Sodium fluoride", genericName: "Sodium fluoride" },
  { brandName: "Sodium hyaluronate", genericName: "Sodium hyaluronate" },
  { brandName: "Sodium iodide i 131", genericName: "Sodium iodide i 131" },
  { brandName: "Sodium lactate", genericName: "Sodium lactate" },
  { brandName: "Sodium oxybate", genericName: "Sodium oxybate" },
  { brandName: "Sodium phenylbutyrate", genericName: "Sodium phenylbutyrate" },
  {
    brandName: "Sodium phosphate monobasic",
    genericName: "Sodium phosphate monobasic",
  },
  {
    brandName: "Sodium polystyrene sulfonate",
    genericName: "Sodium polystyrene sulfonate",
  },
  { brandName: "Sodium sulfacetamide", genericName: "Sodium sulfacetamide" },
  { brandName: "Solage", genericName: "Solage" },
  { brandName: "Solaraze", genericName: "Solaraze" },
  { brandName: "Solifenacin succinate", genericName: "Solifenacin succinate" },
  { brandName: "Soliris", genericName: "Soliris" },
  { brandName: "Solodyn", genericName: "Solodyn" },
  { brandName: "Soltamox", genericName: "Soltamox" },
  { brandName: "Solu cortef", genericName: "Solu cortef" },
  { brandName: "Solu medrol", genericName: "Solu medrol" },
  { brandName: "Soma", genericName: "Soma" },
  { brandName: "Soma compound", genericName: "Soma compound" },
  { brandName: "Somatrem", genericName: "Somatrem" },
  { brandName: "Somatropin", genericName: "Somatropin" },
  { brandName: "Somavert", genericName: "Somavert" },
  { brandName: "Sonata", genericName: "Sonata" },
  { brandName: "Sorafenib", genericName: "Sorafenib" },
  { brandName: "Soriatane", genericName: "Soriatane" },
  { brandName: "Sotalol", genericName: "Sotalol" },
  { brandName: "Sotalol hcl", genericName: "Sotalol hcl" },
  { brandName: "Sotradecol", genericName: "Sotradecol" },
  { brandName: "Sparfloxacin", genericName: "Sparfloxacin" },
  { brandName: "Spectazole", genericName: "Spectazole" },
  { brandName: "Spectinomycin", genericName: "Spectinomycin" },
  { brandName: "Spectracef", genericName: "Spectracef" },
  { brandName: "Spiriva", genericName: "Spiriva" },
  { brandName: "Spironolactone", genericName: "Spironolactone" },
  {
    brandName: "Spironolactone and hydrochlorothiazide",
    genericName: "Spironolactone and hydrochlorothiazide",
  },
  { brandName: "Sporanox", genericName: "Sporanox" },
  {
    brandName: "Sporanox oral solution",
    genericName: "Sporanox oral solution",
  },
  { brandName: "Sprintec", genericName: "Sprintec" },
  { brandName: "Sprix", genericName: "Sprix" },
  { brandName: "Sprycel", genericName: "Sprycel" },
  { brandName: "Stadol", genericName: "Stadol" },
  { brandName: "Stalevo", genericName: "Stalevo" },
  { brandName: "Starlix", genericName: "Starlix" },
  { brandName: "Staticin", genericName: "Staticin" },
  { brandName: "Stavudine", genericName: "Stavudine" },
  { brandName: "Stavzor", genericName: "Stavzor" },
  { brandName: "Staxyn", genericName: "Staxyn" },
  { brandName: "Stelara", genericName: "Stelara" },
  { brandName: "Stelazine", genericName: "Stelazine" },
  { brandName: "Stimate", genericName: "Stimate" },
  { brandName: "Strattera", genericName: "Strattera" },
  { brandName: "Streptase", genericName: "Streptase" },
  { brandName: "Streptokinase", genericName: "Streptokinase" },
  { brandName: "Streptomycin", genericName: "Streptomycin" },
  { brandName: "Streptozocin", genericName: "Streptozocin" },
  { brandName: "Striant", genericName: "Striant" },
  { brandName: "Stromectol", genericName: "Stromectol" },
  { brandName: "Strontium-89", genericName: "Strontium-89" },
  { brandName: "Suboxone", genericName: "Suboxone" },
  { brandName: "Succimer", genericName: "Succimer" },
  {
    brandName: "Succinylcholine chloride",
    genericName: "Succinylcholine chloride",
  },
  { brandName: "Sucraid", genericName: "Sucraid" },
  { brandName: "Sucralfate", genericName: "Sucralfate" },
  { brandName: "Sudafed", genericName: "Sudafed" },
  { brandName: "Sufenta", genericName: "Sufenta" },
  { brandName: "Sufentanil citrate", genericName: "Sufentanil citrate" },
  { brandName: "Sular", genericName: "Sular" },
  { brandName: "Sulconazole", genericName: "Sulconazole" },
  { brandName: "Sulfacetamide", genericName: "Sulfacetamide" },
  {
    brandName: "Sulfadoxine / pyrimethamine",
    genericName: "Sulfadoxine / pyrimethamine",
  },
  { brandName: "Sulfamethoxazole", genericName: "Sulfamethoxazole" },
  { brandName: "Sulfamylon", genericName: "Sulfamylon" },
  { brandName: "Sulfasalazine", genericName: "Sulfasalazine" },
  { brandName: "Sulfinpyrazone", genericName: "Sulfinpyrazone" },
  { brandName: "Sulindac", genericName: "Sulindac" },
  { brandName: "Sultrin", genericName: "Sultrin" },
  { brandName: "Sumatriptan", genericName: "Sumatriptan" },
  { brandName: "Sumavel dosepro", genericName: "Sumavel dosepro" },
  { brandName: "Sumycin", genericName: "Sumycin" },
  { brandName: "Sunitinib malate", genericName: "Sunitinib malate" },
  { brandName: "Supprelin la", genericName: "Supprelin la" },
  { brandName: "Suprane", genericName: "Suprane" },
  { brandName: "Suprax", genericName: "Suprax" },
  { brandName: "Surmontil", genericName: "Surmontil" },
  { brandName: "Survanta", genericName: "Survanta" },
  { brandName: "Sustiva", genericName: "Sustiva" },
  { brandName: "Sutent", genericName: "Sutent" },
  { brandName: "Symbicort", genericName: "Symbicort" },
  { brandName: "Symbyax", genericName: "Symbyax" },
  { brandName: "Symlin", genericName: "Symlin" },
  { brandName: "Symmetrel", genericName: "Symmetrel" },
  { brandName: "Synagis", genericName: "Synagis" },
  { brandName: "Synalar", genericName: "Synalar" },
  { brandName: "Synarel", genericName: "Synarel" },
  { brandName: "Synera", genericName: "Synera" },
  { brandName: "Synercid", genericName: "Synercid" },
  { brandName: "Synthroid", genericName: "Synthroid" },
  { brandName: "Synvisc", genericName: "Synvisc" },
  { brandName: "Syprine", genericName: "Syprine" },
  { brandName: "Tabloid", genericName: "Tabloid" },
  { brandName: "Taclonex", genericName: "Taclonex" },
  { brandName: "Taclonex scalp", genericName: "Taclonex scalp" },
  { brandName: "Tacrine", genericName: "Tacrine" },
  { brandName: "Tacrolimus", genericName: "Tacrolimus" },
  { brandName: "Tadalafil", genericName: "Tadalafil" },
  { brandName: "Tagamet", genericName: "Tagamet" },
  { brandName: "Talacen", genericName: "Talacen" },
  { brandName: "Talwin", genericName: "Talwin" },
  { brandName: "Talwin nx", genericName: "Talwin nx" },
  { brandName: "Tambocor", genericName: "Tambocor" },
  { brandName: "Tamiflu", genericName: "Tamiflu" },
  { brandName: "Tamoxifen citrate", genericName: "Tamoxifen citrate" },
  {
    brandName: "Tamsulosin hydrochloride",
    genericName: "Tamsulosin hydrochloride",
  },
  { brandName: "Tao", genericName: "Tao" },
  { brandName: "Tapazole", genericName: "Tapazole" },
  { brandName: "Tapentadol", genericName: "Tapentadol" },
  { brandName: "Tarceva", genericName: "Tarceva" },
  { brandName: "Targretin", genericName: "Targretin" },
  { brandName: "Tarka", genericName: "Tarka" },
  { brandName: "Tasigna", genericName: "Tasigna" },
  { brandName: "Tasmar", genericName: "Tasmar" },
  { brandName: "Taxol", genericName: "Taxol" },
  { brandName: "Taxotere", genericName: "Taxotere" },
  { brandName: "Tazarotene", genericName: "Tazarotene" },
  { brandName: "Tazorac", genericName: "Tazorac" },
  { brandName: "Technetium tc 99m", genericName: "Technetium tc 99m" },
  { brandName: "Tegaserod maleate", genericName: "Tegaserod maleate" },
  { brandName: "Tegretol", genericName: "Tegretol" },
  { brandName: "Tekturna", genericName: "Tekturna" },
  { brandName: "Tekturna hct", genericName: "Tekturna hct" },
  { brandName: "Telavancin", genericName: "Telavancin" },
  { brandName: "Telbivudine", genericName: "Telbivudine" },
  { brandName: "Telithromycin", genericName: "Telithromycin" },
  { brandName: "Telmisartan", genericName: "Telmisartan" },
  {
    brandName: "Telmisartan and hydrochlorothiazide",
    genericName: "Telmisartan and hydrochlorothiazide",
  },
  { brandName: "Temazepam", genericName: "Temazepam" },
  { brandName: "Temodar", genericName: "Temodar" },
  { brandName: "Temovate", genericName: "Temovate" },
  { brandName: "Temovate scalp", genericName: "Temovate scalp" },
  { brandName: "Temozolomide", genericName: "Temozolomide" },
  { brandName: "Temsirolimus", genericName: "Temsirolimus" },
  { brandName: "Tenecteplase", genericName: "Tenecteplase" },
  { brandName: "Teniposide", genericName: "Teniposide" },
  {
    brandName: "Tenofovir disoproxil fumarate",
    genericName: "Tenofovir disoproxil fumarate",
  },
  { brandName: "Tenoretic", genericName: "Tenoretic" },
  { brandName: "Tenormin", genericName: "Tenormin" },
  { brandName: "Tenuate", genericName: "Tenuate" },
  { brandName: "Terazol 3, terazol 7", genericName: "Terazol 3, terazol 7" },
  { brandName: "Terazosin hcl", genericName: "Terazosin hcl" },
  { brandName: "Terbinafine", genericName: "Terbinafine" },
  {
    brandName: "Terbinafine hydrochloride",
    genericName: "Terbinafine hydrochloride",
  },
  { brandName: "Terbutaline sulfate", genericName: "Terbutaline sulfate" },
  { brandName: "Terconazole", genericName: "Terconazole" },
  { brandName: "Teriparatide", genericName: "Teriparatide" },
  { brandName: "Terra-cortril", genericName: "Terra-cortril" },
  { brandName: "Terramycin", genericName: "Terramycin" },
  { brandName: "Teslac", genericName: "Teslac" },
  { brandName: "Teslascan", genericName: "Teslascan" },
  { brandName: "Tessalon", genericName: "Tessalon" },
  { brandName: "Testim", genericName: "Testim" },
  { brandName: "Testoderm", genericName: "Testoderm" },
  { brandName: "Testolactone", genericName: "Testolactone" },
  { brandName: "Testosterone", genericName: "Testosterone" },
  { brandName: "Testred", genericName: "Testred" },
  { brandName: "Tetanus vaccine", genericName: "Tetanus vaccine" },
  { brandName: "Tetrabenazine", genericName: "Tetrabenazine" },
  { brandName: "Tetracycline", genericName: "Tetracycline" },
  { brandName: "Tev-tropin", genericName: "Tev-tropin" },
  { brandName: "Teveten", genericName: "Teveten" },
  { brandName: "Teveten hct", genericName: "Teveten hct" },
  { brandName: "Thalidomide", genericName: "Thalidomide" },
  { brandName: "Thalitone", genericName: "Thalitone" },
  { brandName: "Thalomid", genericName: "Thalomid" },
  { brandName: "Theo-24", genericName: "Theo-24" },
  { brandName: "Theolair", genericName: "Theolair" },
  { brandName: "Theophylline", genericName: "Theophylline" },
  {
    brandName: "Theophylline anhydrous",
    genericName: "Theophylline anhydrous",
  },
  { brandName: "Theracys", genericName: "Theracys" },
  { brandName: "Thiabendazole", genericName: "Thiabendazole" },
  { brandName: "Thiethylperazine", genericName: "Thiethylperazine" },
  { brandName: "Thioguanine", genericName: "Thioguanine" },
  { brandName: "Thiopental sodium", genericName: "Thiopental sodium" },
  { brandName: "Thioridazine", genericName: "Thioridazine" },
  { brandName: "Thioridazine hcl", genericName: "Thioridazine hcl" },
  { brandName: "Thiotepa", genericName: "Thiotepa" },
  { brandName: "Thiothixene hcl", genericName: "Thiothixene hcl" },
  { brandName: "Thorazine", genericName: "Thorazine" },
  { brandName: "Thrombin", genericName: "Thrombin" },
  { brandName: "Thymalfasin", genericName: "Thymalfasin" },
  { brandName: "Thyrel trh", genericName: "Thyrel trh" },
  { brandName: "Thyro-tabs", genericName: "Thyro-tabs" },
  { brandName: "Thyrogen", genericName: "Thyrogen" },
  { brandName: "Thyroid tablets", genericName: "Thyroid tablets" },
  { brandName: "Thyrolar", genericName: "Thyrolar" },
  { brandName: "Thyrotropin alfa", genericName: "Thyrotropin alfa" },
  {
    brandName: "Tiagabine hydrochloride",
    genericName: "Tiagabine hydrochloride",
  },
  { brandName: "Tiazac", genericName: "Tiazac" },
  { brandName: "Ticarcillin", genericName: "Ticarcillin" },
  { brandName: "Ticlid", genericName: "Ticlid" },
  { brandName: "Ticlopidine hcl", genericName: "Ticlopidine hcl" },
  { brandName: "Tigan", genericName: "Tigan" },
  { brandName: "Tigecycline", genericName: "Tigecycline" },
  { brandName: "Tikosyn", genericName: "Tikosyn" },
  { brandName: "Tilade", genericName: "Tilade" },
  { brandName: "Timentin", genericName: "Timentin" },
  { brandName: "Timolide", genericName: "Timolide" },
  { brandName: "Timolol", genericName: "Timolol" },
  { brandName: "Timolol maleate", genericName: "Timolol maleate" },
  { brandName: "Timoptic", genericName: "Timoptic" },
  { brandName: "Tindamax", genericName: "Tindamax" },
  { brandName: "Tinidazole", genericName: "Tinidazole" },
  { brandName: "Tinzaparin", genericName: "Tinzaparin" },
  { brandName: "Tioconazole", genericName: "Tioconazole" },
  { brandName: "Tiotropium bromide", genericName: "Tiotropium bromide" },
  { brandName: "Tipranavir", genericName: "Tipranavir" },
  { brandName: "Tirofiban", genericName: "Tirofiban" },
  { brandName: "Tirosint", genericName: "Tirosint" },
  { brandName: "Tizanidine", genericName: "Tizanidine" },
  { brandName: "Tnkase", genericName: "Tnkase" },
  { brandName: "Tobi", genericName: "Tobi" },
  { brandName: "Tobradex", genericName: "Tobradex" },
  { brandName: "Tobramycin", genericName: "Tobramycin" },
  { brandName: "Tobrex", genericName: "Tobrex" },
  { brandName: "Tocainide hcl", genericName: "Tocainide hcl" },
  { brandName: "Tocilizumab", genericName: "Tocilizumab" },
  { brandName: "Tofranil", genericName: "Tofranil" },
  { brandName: "Tofranil-pm", genericName: "Tofranil-pm" },
  { brandName: "Tolazamide", genericName: "Tolazamide" },
  { brandName: "Tolcapone", genericName: "Tolcapone" },
  { brandName: "Tolectin", genericName: "Tolectin" },
  { brandName: "Tolinase", genericName: "Tolinase" },
  { brandName: "Tolmetin sodium", genericName: "Tolmetin sodium" },
  { brandName: "Tolterodine tartrate", genericName: "Tolterodine tartrate" },
  { brandName: "Tolvaptan", genericName: "Tolvaptan" },
  { brandName: "Tonocard", genericName: "Tonocard" },
  { brandName: "Topamax", genericName: "Topamax" },
  { brandName: "Topicort", genericName: "Topicort" },
  { brandName: "Topiramate", genericName: "Topiramate" },
  { brandName: "Topotecan", genericName: "Topotecan" },
  {
    brandName: "Topotecan hydrochloride",
    genericName: "Topotecan hydrochloride",
  },
  { brandName: "Toprol xl", genericName: "Toprol xl" },
  { brandName: "Toradol", genericName: "Toradol" },
  { brandName: "Torecan", genericName: "Torecan" },
  { brandName: "Toremifene", genericName: "Toremifene" },
  { brandName: "Torisel", genericName: "Torisel" },
  { brandName: "Torsemide", genericName: "Torsemide" },
  { brandName: "Tositumomab i-131", genericName: "Tositumomab i-131" },
  { brandName: "Totect", genericName: "Totect" },
  { brandName: "Toviaz", genericName: "Toviaz" },
  { brandName: "Tracleer", genericName: "Tracleer" },
  { brandName: "Tracrium", genericName: "Tracrium" },
  { brandName: "Tramadol", genericName: "Tramadol" },
  { brandName: "Tramadol hcl", genericName: "Tramadol hcl" },
  { brandName: "Trandate", genericName: "Trandate" },
  { brandName: "Trandolapril", genericName: "Trandolapril" },
  {
    brandName: "Trandolapril and verapamil",
    genericName: "Trandolapril and verapamil",
  },
  { brandName: "Tranexamic acid", genericName: "Tranexamic acid" },
  { brandName: "Transderm nitro", genericName: "Transderm nitro" },
  { brandName: "Transderm scop", genericName: "Transderm scop" },
  { brandName: "Tranxene", genericName: "Tranxene" },
  { brandName: "Tranylcypromine", genericName: "Tranylcypromine" },
  { brandName: "Trastuzumab", genericName: "Trastuzumab" },
  { brandName: "Trasylol", genericName: "Trasylol" },
  { brandName: "Travasol", genericName: "Travasol" },
  { brandName: "Travatan", genericName: "Travatan" },
  { brandName: "Travoprost", genericName: "Travoprost" },
  {
    brandName: "Trazodone hydrochloride",
    genericName: "Trazodone hydrochloride",
  },
  { brandName: "Treanda", genericName: "Treanda" },
  { brandName: "Trecator", genericName: "Trecator" },
  { brandName: "Trelstar", genericName: "Trelstar" },
  { brandName: "Trental", genericName: "Trental" },
  { brandName: "Treprostinil", genericName: "Treprostinil" },
  { brandName: "Treprostinil sodium", genericName: "Treprostinil sodium" },
  { brandName: "Tretinoin", genericName: "Tretinoin" },
  { brandName: "Trexall", genericName: "Trexall" },
  { brandName: "Treximet", genericName: "Treximet" },
  { brandName: "Tri-luma", genericName: "Tri-luma" },
  { brandName: "Tri-sprintec", genericName: "Tri-sprintec" },
  { brandName: "Triamcinolone", genericName: "Triamcinolone" },
  {
    brandName: "Triamcinolone acetonide",
    genericName: "Triamcinolone acetonide",
  },
  { brandName: "Triamterene", genericName: "Triamterene" },
  { brandName: "Triazolam", genericName: "Triazolam" },
  { brandName: "Tribenzor", genericName: "Tribenzor" },
  { brandName: "Tricor", genericName: "Tricor" },
  { brandName: "Tridione", genericName: "Tridione" },
  { brandName: "Trientine", genericName: "Trientine" },
  { brandName: "Triesence", genericName: "Triesence" },
  { brandName: "Trifluoperazine", genericName: "Trifluoperazine" },
  { brandName: "Trifluridine", genericName: "Trifluridine" },
  { brandName: "Triglide", genericName: "Triglide" },
  { brandName: "Trihexyphenidyl", genericName: "Trihexyphenidyl" },
  { brandName: "Trileptal", genericName: "Trileptal" },
  { brandName: "Trilipix", genericName: "Trilipix" },
  { brandName: "Trilisate", genericName: "Trilisate" },
  { brandName: "Trilyte", genericName: "Trilyte" },
  { brandName: "Trimethadione", genericName: "Trimethadione" },
  {
    brandName: "Trimethobenzamide hydrochloride",
    genericName: "Trimethobenzamide hydrochloride",
  },
  { brandName: "Trimethoprim", genericName: "Trimethoprim" },
  {
    brandName: "Trimethoprim and sulfamethoxazole",
    genericName: "Trimethoprim and sulfamethoxazole",
  },
  {
    brandName: "Trimetrexate glucuronate",
    genericName: "Trimetrexate glucuronate",
  },
  { brandName: "Trimipramine", genericName: "Trimipramine" },
  { brandName: "Trinessa", genericName: "Trinessa" },
  { brandName: "Triptorelin pamoate", genericName: "Triptorelin pamoate" },
  { brandName: "Trisenox", genericName: "Trisenox" },
  { brandName: "Tritec", genericName: "Tritec" },
  { brandName: "Trivaris", genericName: "Trivaris" },
  { brandName: "Trivora-28", genericName: "Trivora-28" },
  { brandName: "Trizivir", genericName: "Trizivir" },
  { brandName: "Trobicin", genericName: "Trobicin" },
  { brandName: "Troleandomycin", genericName: "Troleandomycin" },
  { brandName: "Trophamine", genericName: "Trophamine" },
  { brandName: "Trospium", genericName: "Trospium" },
  { brandName: "Trovafloxacin", genericName: "Trovafloxacin" },
  { brandName: "Trovan", genericName: "Trovan" },
  { brandName: "Trusopt", genericName: "Trusopt" },
  { brandName: "Truvada", genericName: "Truvada" },
  { brandName: "Trypan blue", genericName: "Trypan blue" },
  { brandName: "Tubersol", genericName: "Tubersol" },
  { brandName: "Tussionex", genericName: "Tussionex" },
  { brandName: "Twinrix", genericName: "Twinrix" },
  { brandName: "Twynsta", genericName: "Twynsta" },
  { brandName: "Tygacil", genericName: "Tygacil" },
  { brandName: "Tykerb", genericName: "Tykerb" },
  { brandName: "Tylenol", genericName: "Tylenol" },
  { brandName: "Tylenol with codeine", genericName: "Tylenol with codeine" },
  { brandName: "Tylox", genericName: "Tylox" },
  { brandName: "Typhoid vaccine", genericName: "Typhoid vaccine" },
  { brandName: "Tysabri", genericName: "Tysabri" },
  { brandName: "Tyvaso", genericName: "Tyvaso" },
  { brandName: "Tyzeka", genericName: "Tyzeka" },
  { brandName: "Ulesfia", genericName: "Ulesfia" },
  { brandName: "Uloric", genericName: "Uloric" },
  { brandName: "Ultane", genericName: "Ultane" },
  { brandName: "Ultiva", genericName: "Ultiva" },
  { brandName: "Ultracet", genericName: "Ultracet" },
  { brandName: "Ultram", genericName: "Ultram" },
  { brandName: "Ultram er", genericName: "Ultram er" },
  { brandName: "Ultrase", genericName: "Ultrase" },
  { brandName: "Ultravate", genericName: "Ultravate" },
  { brandName: "Ultravist", genericName: "Ultravist" },
  { brandName: "Unasyn", genericName: "Unasyn" },
  { brandName: "Uniphyl", genericName: "Uniphyl" },
  { brandName: "Uniretic", genericName: "Uniretic" },
  { brandName: "Unithroid", genericName: "Unithroid" },
  { brandName: "Univasc", genericName: "Univasc" },
  { brandName: "Unoprostone isopropyl", genericName: "Unoprostone isopropyl" },
  { brandName: "Urex", genericName: "Urex" },
  { brandName: "Urispas", genericName: "Urispas" },
  { brandName: "Urobiotic", genericName: "Urobiotic" },
  { brandName: "Urofollitropin", genericName: "Urofollitropin" },
  { brandName: "Urokinase", genericName: "Urokinase" },
  { brandName: "Uroxatral", genericName: "Uroxatral" },
  { brandName: "Urso", genericName: "Urso" },
  { brandName: "Ursodiol", genericName: "Ursodiol" },
  { brandName: "Ustekinumab", genericName: "Ustekinumab" },
  { brandName: "Uvadex", genericName: "Uvadex" },
  {
    brandName: "Vaccinia immune globulin",
    genericName: "Vaccinia immune globulin",
  },
  { brandName: "Vagifem", genericName: "Vagifem" },
  { brandName: "Vagistat-1", genericName: "Vagistat-1" },
  {
    brandName: "Valacyclovir hydrochloride",
    genericName: "Valacyclovir hydrochloride",
  },
  { brandName: "Valcyte", genericName: "Valcyte" },
  { brandName: "Valdecoxib", genericName: "Valdecoxib" },
  { brandName: "Valganciclovir hcl", genericName: "Valganciclovir hcl" },
  { brandName: "Valium", genericName: "Valium" },
  { brandName: "Valproate sodium", genericName: "Valproate sodium" },
  { brandName: "Valproic acid", genericName: "Valproic acid" },
  { brandName: "Valrubicin", genericName: "Valrubicin" },
  { brandName: "Valsartan", genericName: "Valsartan" },
  {
    brandName: "Valsartan and hydrochlorothiazide",
    genericName: "Valsartan and hydrochlorothiazide",
  },
  { brandName: "Valstar", genericName: "Valstar" },
  { brandName: "Valtrex", genericName: "Valtrex" },
  { brandName: "Valtropin", genericName: "Valtropin" },
  { brandName: "Valturna", genericName: "Valturna" },
  {
    brandName: "Vancomycin hydrochloride",
    genericName: "Vancomycin hydrochloride",
  },
  { brandName: "Vaniqa", genericName: "Vaniqa" },
  { brandName: "Vanos", genericName: "Vanos" },
  { brandName: "Vantin", genericName: "Vantin" },
  { brandName: "Vaprisol", genericName: "Vaprisol" },
  { brandName: "Vaqta", genericName: "Vaqta" },
  { brandName: "Vardenafil hcl", genericName: "Vardenafil hcl" },
  { brandName: "Varenicline", genericName: "Varenicline" },
  {
    brandName: "Varicella virus vaccine live",
    genericName: "Varicella virus vaccine live",
  },
  { brandName: "Varivax", genericName: "Varivax" },
  { brandName: "Vascor", genericName: "Vascor" },
  { brandName: "Vaseretic", genericName: "Vaseretic" },
  { brandName: "Vasocidin", genericName: "Vasocidin" },
  { brandName: "Vasopressin", genericName: "Vasopressin" },
  { brandName: "Vasotec", genericName: "Vasotec" },
  { brandName: "Vasovist", genericName: "Vasovist" },
  { brandName: "Vectibix", genericName: "Vectibix" },
  { brandName: "Vectical", genericName: "Vectical" },
  { brandName: "Velcade", genericName: "Velcade" },
  { brandName: "Velosulin", genericName: "Velosulin" },
  { brandName: "Veltin", genericName: "Veltin" },
  {
    brandName: "Venlafaxine hydrochloride",
    genericName: "Venlafaxine hydrochloride",
  },
  { brandName: "Venofer", genericName: "Venofer" },
  { brandName: "Ventavis", genericName: "Ventavis" },
  { brandName: "Ventolin hfa", genericName: "Ventolin hfa" },
  { brandName: "Vepesid", genericName: "Vepesid" },
  { brandName: "Veramyst", genericName: "Veramyst" },
  { brandName: "Verapamil", genericName: "Verapamil" },
  { brandName: "Verapamil hcl", genericName: "Verapamil hcl" },
  {
    brandName: "Verapamil hydrochloride",
    genericName: "Verapamil hydrochloride",
  },
  { brandName: "Verdeso", genericName: "Verdeso" },
  { brandName: "Veregen", genericName: "Veregen" },
  { brandName: "Verelan pm", genericName: "Verelan pm" },
  { brandName: "Vermox", genericName: "Vermox" },
  { brandName: "Verteporfin", genericName: "Verteporfin" },
  { brandName: "Vesanoid", genericName: "Vesanoid" },
  { brandName: "Vesicare", genericName: "Vesicare" },
  { brandName: "Vexol", genericName: "Vexol" },
  { brandName: "Vfend", genericName: "Vfend" },
  { brandName: "Viadur", genericName: "Viadur" },
  { brandName: "Viagra", genericName: "Viagra" },
  { brandName: "Vibativ", genericName: "Vibativ" },
  { brandName: "Vibramycin", genericName: "Vibramycin" },
  {
    brandName: "Vibramycin intravenous",
    genericName: "Vibramycin intravenous",
  },
  { brandName: "Vicodin", genericName: "Vicodin" },
  { brandName: "Vicodin es", genericName: "Vicodin es" },
  { brandName: "Vicodin hp", genericName: "Vicodin hp" },
  { brandName: "Vicoprofen", genericName: "Vicoprofen" },
  { brandName: "Victoza", genericName: "Victoza" },
  { brandName: "Vidarabine", genericName: "Vidarabine" },
  { brandName: "Vidaza", genericName: "Vidaza" },
  { brandName: "Videx", genericName: "Videx" },
  { brandName: "Videx ec", genericName: "Videx ec" },
  { brandName: "Vigabatrin", genericName: "Vigabatrin" },
  { brandName: "Vigamox", genericName: "Vigamox" },
  { brandName: "Vimovo", genericName: "Vimovo" },
  { brandName: "Vimpat", genericName: "Vimpat" },
  { brandName: "Vinblastine sulfate", genericName: "Vinblastine sulfate" },
  { brandName: "Vincasar pfs", genericName: "Vincasar pfs" },
  { brandName: "Vincristine sulfate", genericName: "Vincristine sulfate" },
  { brandName: "Vinorelbine tartrate", genericName: "Vinorelbine tartrate" },
  { brandName: "Viokase", genericName: "Viokase" },
  { brandName: "Vioxx", genericName: "Vioxx" },
  { brandName: "Vira-a", genericName: "Vira-a" },
  { brandName: "Viracept", genericName: "Viracept" },
  { brandName: "Viramune", genericName: "Viramune" },
  { brandName: "Virazole", genericName: "Virazole" },
  { brandName: "Viread", genericName: "Viread" },
  { brandName: "Viroptic", genericName: "Viroptic" },
  { brandName: "Visicol", genericName: "Visicol" },
  { brandName: "Visionblue", genericName: "Visionblue" },
  { brandName: "Visken", genericName: "Visken" },
  { brandName: "Vistaril", genericName: "Vistaril" },
  { brandName: "Vistide", genericName: "Vistide" },
  { brandName: "Visudyne", genericName: "Visudyne" },
  { brandName: "Vitamin a", genericName: "Vitamin a" },
  { brandName: "Vitamin c", genericName: "Vitamin c" },
  { brandName: "Vitamin k1", genericName: "Vitamin k1" },
  { brandName: "Vitrasert", genericName: "Vitrasert" },
  { brandName: "Vitravene", genericName: "Vitravene" },
  { brandName: "Vivactil", genericName: "Vivactil" },
  { brandName: "Vivaglobin", genericName: "Vivaglobin" },
  { brandName: "Vivelle-dot", genericName: "Vivelle-dot" },
  { brandName: "Vivitrol", genericName: "Vivitrol" },
  { brandName: "Voltaren", genericName: "Voltaren" },
  { brandName: "Voltaren ophthalmic", genericName: "Voltaren ophthalmic" },
  { brandName: "Voluven", genericName: "Voluven" },
  { brandName: "Voriconazole", genericName: "Voriconazole" },
  { brandName: "Vorinostat", genericName: "Vorinostat" },
  { brandName: "Vosol hc", genericName: "Vosol hc" },
  { brandName: "Vospire er", genericName: "Vospire er" },
  { brandName: "Votrient", genericName: "Votrient" },
  { brandName: "Vumon", genericName: "Vumon" },
  { brandName: "Vusion", genericName: "Vusion" },
  { brandName: "Vytorin", genericName: "Vytorin" },
  { brandName: "Vyvanse", genericName: "Vyvanse" },
  { brandName: "Warfarin sodium", genericName: "Warfarin sodium" },
  { brandName: "Welchol", genericName: "Welchol" },
  { brandName: "Wellbutrin", genericName: "Wellbutrin" },
  { brandName: "Wellbutrin sr", genericName: "Wellbutrin sr" },
  { brandName: "Wellbutrin xl", genericName: "Wellbutrin xl" },
  { brandName: "Westcort", genericName: "Westcort" },
  { brandName: "Wigraine", genericName: "Wigraine" },
  { brandName: "Winstrol", genericName: "Winstrol" },
  { brandName: "Xalatan", genericName: "Xalatan" },
  { brandName: "Xanax", genericName: "Xanax" },
  { brandName: "Xanax xr", genericName: "Xanax xr" },
  { brandName: "Xeloda", genericName: "Xeloda" },
  { brandName: "Xenazine", genericName: "Xenazine" },
  { brandName: "Xenical", genericName: "Xenical" },
  { brandName: "Xeomin", genericName: "Xeomin" },
  { brandName: "Xerese", genericName: "Xerese" },
  { brandName: "Xibrom", genericName: "Xibrom" },
  { brandName: "Xifaxan", genericName: "Xifaxan" },
  { brandName: "Xigris", genericName: "Xigris" },
  { brandName: "Xolair", genericName: "Xolair" },
  { brandName: "Xolegel", genericName: "Xolegel" },
  { brandName: "Xopenex", genericName: "Xopenex" },
  { brandName: "Xopenex hfa", genericName: "Xopenex hfa" },
  { brandName: "Xylocaine", genericName: "Xylocaine" },
  { brandName: "Xylocaine viscous", genericName: "Xylocaine viscous" },
  { brandName: "Xyntha", genericName: "Xyntha" },
  { brandName: "Xyrem", genericName: "Xyrem" },
  { brandName: "Xyzal", genericName: "Xyzal" },
  { brandName: "Yasmin", genericName: "Yasmin" },
  { brandName: "Yaz", genericName: "Yaz" },
  { brandName: "Yellow fever vaccine", genericName: "Yellow fever vaccine" },
  { brandName: "Yf-vax", genericName: "Yf-vax" },
  { brandName: "Yohimbine", genericName: "Yohimbine" },
  {
    brandName: "Yohimbine hydrochloride",
    genericName: "Yohimbine hydrochloride",
  },
  { brandName: "Zadaxin", genericName: "Zadaxin" },
  { brandName: "Zaditor", genericName: "Zaditor" },
  { brandName: "Zafirlukast", genericName: "Zafirlukast" },
  { brandName: "Zagam", genericName: "Zagam" },
  { brandName: "Zalcitabine", genericName: "Zalcitabine" },
  { brandName: "Zaleplon", genericName: "Zaleplon" },
  { brandName: "Zanaflex", genericName: "Zanaflex" },
  { brandName: "Zanamivir", genericName: "Zanamivir" },
  { brandName: "Zanosar", genericName: "Zanosar" },
  { brandName: "Zantac", genericName: "Zantac" },
  { brandName: "Zarontin", genericName: "Zarontin" },
  { brandName: "Zavesca", genericName: "Zavesca" },
  { brandName: "Zebeta", genericName: "Zebeta" },
  { brandName: "Zegerid", genericName: "Zegerid" },
  { brandName: "Zelapar", genericName: "Zelapar" },
  { brandName: "Zelnorm", genericName: "Zelnorm" },
  { brandName: "Zemaira", genericName: "Zemaira" },
  { brandName: "Zemplar", genericName: "Zemplar" },
  { brandName: "Zemuron", genericName: "Zemuron" },
  { brandName: "Zenapax", genericName: "Zenapax" },
  { brandName: "Zenpep", genericName: "Zenpep" },
  { brandName: "Zerit", genericName: "Zerit" },
  { brandName: "Zestoretic", genericName: "Zestoretic" },
  { brandName: "Zestril", genericName: "Zestril" },
  { brandName: "Zetia", genericName: "Zetia" },
  { brandName: "Zevalin", genericName: "Zevalin" },
  { brandName: "Ziac", genericName: "Ziac" },
  { brandName: "Ziagen", genericName: "Ziagen" },
  { brandName: "Ziana", genericName: "Ziana" },
  { brandName: "Ziconotide", genericName: "Ziconotide" },
  { brandName: "Zidovudine", genericName: "Zidovudine" },
  { brandName: "Zinacef", genericName: "Zinacef" },
  { brandName: "Zinc", genericName: "Zinc" },
  { brandName: "Zinecard", genericName: "Zinecard" },
  { brandName: "Zingo", genericName: "Zingo" },
  { brandName: "Ziprasidone", genericName: "Ziprasidone" },
  { brandName: "Zirgan", genericName: "Zirgan" },
  { brandName: "Zithromax", genericName: "Zithromax" },
  { brandName: "Zmax", genericName: "Zmax" },
  { brandName: "Zn-dtpa", genericName: "Zn-dtpa" },
  { brandName: "Zocor", genericName: "Zocor" },
  { brandName: "Zofran", genericName: "Zofran" },
  { brandName: "Zoledronic acid", genericName: "Zoledronic acid" },
  { brandName: "Zolinza", genericName: "Zolinza" },
  { brandName: "Zolmitriptan", genericName: "Zolmitriptan" },
  { brandName: "Zoloft", genericName: "Zoloft" },
  { brandName: "Zolpidem tartrate", genericName: "Zolpidem tartrate" },
  { brandName: "Zolpimist", genericName: "Zolpimist" },
  { brandName: "Zometa", genericName: "Zometa" },
  { brandName: "Zomig", genericName: "Zomig" },
  { brandName: "Zonalon", genericName: "Zonalon" },
  { brandName: "Zonegran", genericName: "Zonegran" },
  { brandName: "Zonisamide", genericName: "Zonisamide" },
  { brandName: "Zortress", genericName: "Zortress" },
  { brandName: "Zostavax", genericName: "Zostavax" },
  { brandName: "Zoster vaccine live", genericName: "Zoster vaccine live" },
  { brandName: "Zosyn", genericName: "Zosyn" },
  { brandName: "Zovia", genericName: "Zovia" },
  { brandName: "Zovirax", genericName: "Zovirax" },
  { brandName: "Zyban", genericName: "Zyban" },
  { brandName: "Zyclara", genericName: "Zyclara" },
  { brandName: "Zydone", genericName: "Zydone" },
  { brandName: "Zyflo", genericName: "Zyflo" },
  { brandName: "Zyflo cr", genericName: "Zyflo cr" },
  { brandName: "Zylet", genericName: "Zylet" },
  { brandName: "Zyloprim", genericName: "Zyloprim" },
  { brandName: "Zymar", genericName: "Zymar" },
  { brandName: "Zymaxid", genericName: "Zymaxid" },
  { brandName: "Zyprexa", genericName: "Zyprexa" },
  { brandName: "Zyprexa relprevv", genericName: "Zyprexa relprevv" },
  { brandName: "Zyrtec", genericName: "Zyrtec" },
  { brandName: "Zyrtec-d", genericName: "Zyrtec-d" },
  { brandName: "Zyvox", genericName: "Zyvox" },
];
