import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

let startApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};
if (window.cordova) {
  //alert("window cordova");
  document.addEventListener("deviceready", startApp, false);
} else {
  startApp();
  //alert("not window cordova");
}
/*
if (!window.cordova) {
  startApp();
  console.log("nada");
} else {
  document.addEventListener("deviceready", startApp, false);
}
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
