import React, { useEffect, useRef } from "react";
import { useState, useContext } from "react";
import moment from "moment";
import {
  Name,
  SelectedOption,
  NameList,
  ShowAdd,
  ChooseTime,
  Times,
  EditMode,
  MedModalOpen,
  TempIndex,
  TempNamers,
  LabModalOpen,
  NotesModalOpen,
  ThePage,
} from "./App";
import {
  MdAccessibilityNew,
  MdAccessibleForward,
  MdAccountBalanceWallet,
  MdFavorite,
  MdMedicalServices,
  MdMedication,
  MdNoFood,
  MdOutlineEditNote,
  MdStickyNote2,
  MdWarning,
} from "react-icons/md";
import {
  FaPills,
  FaStethoscope,
  FaVial,
  FaBook,
  FaPlus,
  FaRegCheckCircle,
  FaTerminal,
  FaUserPlus,
  FaLungs,
  FaBrain,
  FaCheck,
} from "react-icons/fa";
import {
  Button,
  Modal,
  Alert,
  message,
  List,
  Popconfirm,
  Drawer,
  Empty,
  theme,
} from "antd";
import { GiConsoleController } from "react-icons/gi";
const Card = ({
  time,
  names,
  handleCardClick,
  component,
  handleNameChange,
  nameListChange,
  indy,
}) => {
  const [name, setName] = useContext(Name);
  const [selectedOption, setSelectedOption] = useContext(SelectedOption); //default option
  const [change, setChange] = useState(false);
  const [nameList, setNameList] = useContext(NameList);
  const [showAdd, setShowAdd] = useContext(ShowAdd);
  const [chooseTime, setChooseTime] = useContext(ChooseTime);
  const [nameTemp, setNameTemp] = useState("");
  const [times, setTimes] = useContext(Times);
  const [editMode, setEditMode] = useContext(EditMode);
  const [medModalOpen, setMedModalOpen] = useContext(MedModalOpen);
  const [tempIndex, setTempIndex] = useContext(TempIndex);
  const [tempNamers, setTempNamers] = useContext(TempNamers);
  const [labModalOpen, setLabModalOpen] = useContext(LabModalOpen);
  const [notesModalOpen, setNotesModalOpen] = useContext(NotesModalOpen);
  const [thePage, setThePage] = useContext(ThePage);
  const [tempName, setTempName] = useState("");
  const b = [1, 2, 3, { a: 1 }];

  useEffect(() => {
    console.log("this is names");
    console.log(names);
  }, [names]);

  function hasNoLettersNumbersOrSymbols(string) {
    // Check if the string is empty
    if (string.trim().length === 0) {
      return false;
    }

    // Use a regular expression to match at least one letter, number, or symbol
    const pattern = /[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]/;

    // Check if the string contains at least one letter, number, or symbol and is not just spaces
    return pattern.test(string.replace(/\s/g, ""));
  }

  const changeText = (t, i) => {
    let a = [...times];
    let daNames = a[indy].names.map((b) => b.name);
    //the index of the exact one we are changing
    let daInd = daNames.indexOf(name);
    console.log(daInd);
    console.log("this is the index" + indy);
    console.log(a[indy].names);
    a[indy].names[daInd].text = t;
    console.log(a);
    setTimes(a);
    localStorage.setItem("shift", JSON.stringify(a));
    //a[i].text=t
  };

  const changeToComplete = (index, i) => {
    let x = [...times];
    console.log(x[indy].names[index].selectedOption[i]);
    x[indy].names[index].selectedOption[i] = "CP";
    x[indy].names[index].selectedOption = [
      ...new Set(x[indy].names[index].selectedOption),
    ];
    setTimes(x);
    //x[index].selectedOption[i]='CP'
    //setNames
    //console.log(names[index].selectedOption[i])
    localStorage.setItem("times", JSON.stringify(x));
    localStorage.setItem("shift", JSON.stringify(x));
    localStorage.setItem("nameList", JSON.stringify(nameList));

    console.log(x);
    setChange(!change);
  };

  const removeComplete = (index, i) => {
    let x = [...times];
    console.log(x[indy].names[index].selectedOption);
    x[indy].names[index].selectedOption = x[indy].names[
      index
    ].selectedOption.filter((a) => a != "CP" && a != "");

    setTimes(x);
    //x[index].selectedOption[i]='CP'
    //setNames
    //console.log(names[index].selectedOption[i])
    localStorage.setItem("times", JSON.stringify(x));
    localStorage.setItem("shift", JSON.stringify(x));
    localStorage.setItem("nameList", JSON.stringify(nameList));

    console.log(x);
    setChange(!change);
  };

  const thisIsMeds = (index, i) => {
    let x = [...times];
    setName(x[indy].names[index].name);
    setSelectedOption("M");
    setChange(!change);
    //handleCardClick();
    handleCardClickMeds(index, x[indy].names[index].name, "M");
  };

  const showMedModal = (a, b) => {
    setMedModalOpen(true);
  };

  const thisIsLabs = (index, i) => {
    let x = [...times];
    console.log("THISI IS THE NAMEIHTIHAOGH");
    console.log(x[indy].names[index]);
    setName(x[indy].names[index].name);
    setSelectedOption("L");
    setChange(!change);
    //handleCardClick();
    handleCardClickMeds(index, x[indy].names[index].name, "L");
  };
  const showLabModal = (a, b) => {
    setLabModalOpen(true);
  };

  const thisIsNotes = (index, i) => {
    let x = [...times];
    console.log("THISI IS THE NAMEIHTIHAOGH");
    console.log(x[indy].names[index]);
    setName(x[indy].names[index].name);
    setSelectedOption("Txt");
    setChange(!change);
    //handleCardClick();
    handleCardClickMeds(index, x[indy].names[index].name, "Txt");
  };
  const showNotesModal = (a, b) => {
    setNotesModalOpen(true);
  };

  const handleCardClickMeds = (a, name, selectedOption) => {
    let index = indy;
    console.log("THIS IS THE SELECTED OPTION");
    console.log(selectedOption);
    //let selectedOption = b;
    if (nameList.length == 0) {
      alert(
        "Please add a patient to start. You can add a patient in the Menu."
      );
    }
    if (name != "") {
      const newTimes = [...times];

      const namers = newTimes[index].names.map((a) => a.name).indexOf(name);
      //SECTION IF ITS MEDICATIONS
      if (selectedOption == "M" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(namers);
        setChange(!change);
        //showMedModal();
        setThePage("TE");
      }
      if (selectedOption == "L" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(namers);
        setChange(!change);
        //showLabModal();
        setThePage("TE");
      }
      if (selectedOption == "Txt" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(namers);
        setChange(!change);
        //showNotesModal();
        setThePage("TE");
      }
      //SECTION IF ITS NOT MEDICATIONS

      if (namers > -1) {
        if (
          newTimes[index].names[namers].selectedOption.includes(
            selectedOption
          ) == false
        ) {
          if (selectedOption == "CP") {
            newTimes[index].names[namers].selectedOption = [selectedOption];
            console.log("This is the CP");
            console.log(newTimes[index].names[namers].selectedOption);
            setChange(!change);
          } else {
            console.log("its going to add something here");
            newTimes[index].names[namers].selectedOption = [
              ...newTimes[index].names[namers].selectedOption,
              selectedOption,
            ];
            console.log("greater than -1");
            console.log(newTimes[index].names[namers].selectedOption);
          }
          setChange(!change);
        } else {
          if (
            selectedOption != "Txt" &&
            selectedOption != "M" &&
            selectedOption != "L"
          ) {
            newTimes[index].names[namers] = [""];
            newTimes[index].names[namers].selectedOption = [""];
            console.log("NAMERS");
            console.log(newTimes[index].names[namers]);
            console.log("end namers");
            setChange(!change);
          }
        }
      } else {
        console.log(namers);

        newTimes[index].names.push({
          name: name,
          selectedOption: [selectedOption],
        });

        setTimes(newTimes);
        console.log(newTimes);
        localStorage.setItem("times", JSON.stringify(newTimes));
        localStorage.setItem("shift", JSON.stringify(newTimes));
        console.log("this is the new times");
        console.log(newTimes);
        setChange(!change);
      }
    }
  };
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  // const [cardIndex, setCardIndex] = useState();
  const selectModalClose = () => setSelectModalOpen(false);

  const selectModalOpener = () => setSelectModalOpen(true);

  /*useEffect(() => {
    if (selectModalOpen == false) {
      handleCardClick();
    }
  }, [selectModalOpen]);*/
  const [nameModalOpenCard, setNameModalOpenCard] = useState(false);
  // const [cardIndex, setCardIndex] = useState();
  const nameModalCloseCard = () => setNameModalOpenCard(false);
  const openModalName = () => setNameModalOpenCard(true);
  const changeName = (b) => {
    setName(b);
    setNameChange(!nameChange);
    //nameModalCloseCard();
  };
  const [nameChange, setNameChange] = useState(false);

  return (
    <>
      <Modal
        open={nameModalOpenCard}
        onOk={nameModalCloseCard}
        onCancel={() => {
          nameModalCloseCard();
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <h2
          style={{ textAlign: "center" }}
          className="font-bold p-2 mb-1 border-b-2 text-lg"
        >
          Choose a Patient
        </h2>
        <div class="bg-gray-200 rounded-lg p-2 mt-1">
          <h4 className="p-1 font-bold">Add Patients</h4>
          <input
            style={{ width: "100%", marginBottom: 0, marginTop: 0 }}
            type="text"
            id="orientationInput"
            onChange={({ target }) => setTempName(target.value.toUpperCase())}
            onKeyPress={(e) => {
              if (e.key == "Enter" || e.key == "Return") {
                nameList.includes(tempName.trim()) == false &&
                  nameList.length < 100 &&
                  hasNoLettersNumbersOrSymbols(tempName.trim()) &&
                  setNameList([...nameList, tempName.trim()]);
                e.target.value = "";
                setTempName("");
                console.log("this is the name");
                console.log(name);
              }
            }}
            onClick={(e) => setTempName("")}
            placeholder="Input Room #"
            autoCorrect="off"
            value={tempName}
          />
          <button
            className="bg-blue-500 rounded-lg w-full text-white p-2 mt-1 justify-center items-center"
            onClick={(e) => {
              nameList.includes(tempName.trim()) == false &&
                nameList.length < 100 &&
                hasNoLettersNumbersOrSymbols(tempName.trim()) &&
                setNameList([...nameList, tempName.trim()]);
              setTempName("");
              console.log("this is the name");
              console.log(name);
            }}
          >
            Add Patient
          </button>
        </div>
        <div>
          <div className="flex flex-wrap w-full pt-2">
            {nameList.length > 0 &&
              nameList.map((a) => (
                <button
                  className={`btn rounded-lg ${
                    name == a
                      ? "bg-blue-500"
                      : "bg-gray-300 border-black text-black"
                  } flex-grow flex-basis-1/4 max-w-1/2 p-4 text-lg font-bold`}
                  onClick={() => changeName(a)}
                >
                  <div className={`${name == a ? "text-white" : "text-black"}`}>
                    {a}
                  </div>
                </button>
              ))}
          </div>
          <div className=" mt-1 p-2 w-full">
            <div className="text-center font-bold border-b-2 p-2 text-lg">
              Task to Add
            </div>
            <div className="w-full grid grid-cols-2 gap-1 pt-2">
              <button
                className={`btn rounded-lg ${
                  selectedOption != "B"
                    ? "bg-gray-300 border-black text-black"
                    : "bg-blue-500"
                }  w-full text-lg font-bold flex flex-col items-center justify-center min-h-[70px]`}
                onClick={() => setSelectedOption("B")}
              >
                <FaBook
                  size={45}
                  color={selectedOption == "B" ? "white" : "black"}
                />
                <div
                  className={`text-sm mt-1 ${
                    selectedOption == "B" ? "text-white" : "text-black"
                  }`}
                >
                  Assess
                </div>
              </button>
              <button
                className={`btn rounded-lg ${
                  selectedOption == "M"
                    ? "bg-blue-500"
                    : "bg-gray-300 border-black text-black"
                }  w-full text-lg font-bold flex flex-col items-center justify-center min-h-[70px]`}
                onClick={() => setSelectedOption("M")}
              >
                <FaPills
                  size={45}
                  color={selectedOption == "M" ? "white" : "black"}
                />
                <div
                  className={`text-sm mt-1 ${
                    selectedOption == "M" ? "text-white" : "text-black"
                  }`}
                >
                  Medication
                </div>
              </button>
              <button
                className={`btn rounded-lg ${
                  selectedOption == "L"
                    ? "bg-blue-500"
                    : "bg-gray-300 border-black text-black"
                }  w-full text-lg font-bold flex flex-col items-center justify-center min-h-[70px]`}
                onClick={() => setSelectedOption("L")}
              >
                <FaVial
                  size={45}
                  color={selectedOption == "L" ? "white" : "black"}
                />
                <div
                  className={`text-sm mt-1 ${
                    selectedOption == "L" ? "text-white" : "text-black"
                  }`}
                >
                  Labs
                </div>
              </button>

              <button
                className={`btn rounded-lg ${
                  selectedOption == "V"
                    ? "bg-blue-500"
                    : "bg-gray-300 border-black text-black"
                }  w-full text-lg font-bold flex flex-col items-center justify-center min-h-[70px]`}
                onClick={() => setSelectedOption("V")}
              >
                <FaStethoscope
                  size={45}
                  color={selectedOption == "V" ? "white" : "black"}
                />
                <div
                  className={`text-sm mt-1 ${
                    selectedOption == "V" ? "text-white" : "text-black"
                  }`}
                >
                  Vitals
                </div>
              </button>
              <button
                className={`btn rounded-lg col-span-2 ${
                  selectedOption == "Txt"
                    ? "bg-blue-500"
                    : "bg-gray-300 border-black text-black"
                }  w-full text-lg font-bold flex flex-col items-center justify-center min-h-[70px]`}
                onClick={() => setSelectedOption("Txt")}
              >
                <MdStickyNote2
                  size={45}
                  color={selectedOption == "Txt" ? "white" : "black"}
                />
                <div
                  className={`text-sm mt-1  ${
                    selectedOption == "Txt" ? "text-white" : "text-black"
                  }`}
                >
                  Notes
                </div>
              </button>
            </div>
          </div>
          <div className=" mt-1 p-2 w-full border-t-2">
            <div className="flex w-full">
              <button
                onClick={() => {
                  handleCardClick();
                  nameModalCloseCard();
                }}
                className={`p-4 ${
                  selectedOption != "" && name != "" && name != undefined
                    ? "bg-blue-600"
                    : "bg-gray-500 opacity-30"
                } rounded-lg w-full text-lg text-white font-bold`}
              >
                {selectedOption != "" && name != "" && name != undefined
                  ? "Add"
                  : "Please select a Patient and Task"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={selectModalOpen}
        onOk={() => {
          selectModalClose();
          handleCardClick();
        }}
        onCancel={() => {
          selectModalClose();
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <h2 style={{ textAlign: "center" }}>Pick a Patient Room</h2>
        <hr />
        <div
          style={{
            display: "grid",
            width: "100%",
            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          {nameList.length > 0 &&
            nameList.map((a) => (
              <button
                className="btn-primary"
                style={{ width: "100%", height: "50px", marginBottom: "5px" }}
                onClick={() => {
                  setName(a);
                  selectModalClose();
                }}
              >
                {a}
              </button>
            ))}
          <div style={{ display: "flex" }}>
            <button
              className="btn btn-secondary"
              style={{ width: "100%" }}
              onClick={selectModalOpener}
            >
              <FaUserPlus size={20} />
            </button>{" "}
            <button
              className="btn btn-secondary"
              style={{ width: "100%" }}
              onClick={selectModalOpener}
            >
              <FaUserPlus size={20} />
            </button>{" "}
            <button
              className="btn btn-secondary"
              style={{ width: "100%" }}
              onClick={selectModalOpener}
            >
              <FaUserPlus size={20} />
            </button>
          </div>
        </div>
      </Modal>

      <div
        className={`card border-black border-1`}
        onClick={() => {
          editMode == true && handleCardClick();
        }}
      >
        <div className="card-time border-b-2 border-gray-200 mb-2">
          {time != "Add Patient" && time != "Delete Patient" ? (
            <div className="flex justify-around">
              <div className="w-full">{moment(time).format("h:mm a")}</div>
              <button
                className="w-1/4 bg-slate-200 rounded-lg mb-1"
                onClick={openModalName}
              >
                +
              </button>
            </div>
          ) : (
            time
          )}
        </div>

        {names.map(
          (name, index) =>
            name != "" &&
            nameList.includes(name.name) == true &&
            !component &&
            name.selectedOption.length > 0 && (
              <div
                key={index}
                className="bg-gray-200 p-2 rounded-lg mb-1 border-black border-1"
              >
                <div className="card-name bg-gray-300 rounded-lg mb-1 ">
                  {name.name}
                </div>
                <div className="card-options-container">
                  {name.selectedOption.map((option, i) =>
                    option == "Txt" ? (
                      editMode == false ? (
                        <button
                          key={i}
                          className="btn rounded-lg bg-cyan-600 "
                          onClick={() => thisIsNotes(index, i)}
                        >
                          <MdStickyNote2 size={30} />
                        </button>
                      ) : (
                        <button key={i} className="btn rounded-lg bg-cyan-600">
                          <MdStickyNote2 size={30} />
                        </button>
                      )
                    ) : option == "M" ? (
                      editMode == false ? (
                        <button
                          key={i}
                          className="btn rounded-lg bg-purple-600 "
                          onClick={() => thisIsMeds(index, i)}
                        >
                          <FaPills size={30} />
                        </button>
                      ) : (
                        <button
                          key={i}
                          className="btn rounded-lg bg-purple-600"
                        >
                          <FaPills size={30} />
                        </button>
                      )
                    ) : option == "B" ? (
                      editMode == false ? (
                        <button
                          key={i}
                          className="btn rounded-lg bg-orange-600 "
                          onClick={() => changeToComplete(index, i)}
                        >
                          <FaBook size={30} />
                        </button>
                      ) : (
                        <button
                          key={i}
                          className="btn rounded-lg bg-orange-600"
                        >
                          <FaBook size={30} />
                        </button>
                      )
                    ) : option == "L" ? (
                      editMode == false ? (
                        <button
                          key={i}
                          className="btn rounded-lg bg-red-600"
                          onClick={() => thisIsLabs(index, i)}
                        >
                          <FaVial size={30} />
                        </button>
                      ) : (
                        <button key={i} className="btn rounded-lg bg-red-600">
                          <FaVial size={30} />
                        </button>
                      )
                    ) : option == "V" ? (
                      editMode == false ? (
                        <button
                          key={i}
                          className="btn rounded-lg bg-blue-600"
                          onClick={() => changeToComplete(index, i)}
                        >
                          <FaStethoscope size={30} />
                        </button>
                      ) : (
                        <button key={i} className="btn rounded-lg bg-blue-600">
                          <FaStethoscope size={30} />
                        </button>
                      )
                    ) : option == "NC" ? (
                      editMode == false ? (
                        <button
                          key={i}
                          className="btn rounded-lg bg-pink-600"
                          onClick={() => changeToComplete(index, i)}
                        >
                          <div className="flex flex-col justify-center items-center p-4">
                            <FaBrain size={25} />
                            <FaCheck />
                          </div>
                        </button>
                      ) : (
                        <button key={i} className="btn rounded-lg bg-pink-600">
                          <FaBrain size={30} />
                        </button>
                      )
                    ) : (
                      option == "CP" && (
                        <button
                          key={i}
                          className="btn btn-success"
                          onClick={() => removeComplete(index, i)}
                        >
                          <FaRegCheckCircle size={30} />
                        </button>
                      )
                    )
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default Card;
