import React, { useEffect, useState, createContext } from "react";
import moment from "moment";
import "./App.css";
import {
  MdAccessibilityNew,
  MdAccessibleForward,
  MdAccountBalanceWallet,
  MdFavorite,
  MdMedicalServices,
  MdMedication,
} from "react-icons/md";
import Main from "./Main";
import { data } from "./medLister";

export const Name = createContext();
export const SelectedOption = createContext();
export const NameList = createContext();
export const ShowAdd = createContext();
export const ChooseTime = createContext();
export const ModalOpen = createContext();
export const Times = createContext();
export const EditMode = createContext();
export const FirstTime = createContext();
export const Loading = createContext();
export const MedList = createContext();
export const MedModalOpen = createContext();
export const TempIndex = createContext();
export const TempNamers = createContext();
export const LabModalOpen = createContext();
export const NotesModalOpen = createContext();
export const FluidsModalOpen = createContext();
export const DrainModalOpen = createContext();
export const PrecModalOpen = createContext();
export const DietModalOpen = createContext();
export const TxtsModalOpen = createContext();
export const ThePage = createContext();
export const DrainList = createContext();
export const ScheduledTimes = createContext();
export const Shift = createContext();
export const HandoffCheck = createContext();
export const SettingMenu = createContext();

const App = () => {
  const [settingMenu, setSettingMenu] = useState("M");
  const [handoffCheck, setHandoffCheck] = useState("G");
  const [drainList, setDrainList] = useState([]);
  const [tempIndex, setTempIndex] = useState();
  const [tempNamers, setTempNamers] = useState(-1);
  const [name, setName] = useState("");
  const [selectedOption, setSelectedOption] = useState("M"); //default option
  const [change, setChange] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [chooseTime, setChooseTime] = useState("7-7");
  const [nameTemp, setNameTemp] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [loading, setLoading] = useState(true);
  const [medList, setMedList] = useState([]);
  const [medModalOpen, setMedModalOpen] = useState(false);
  const [labModalOpen, setLabModalOpen] = useState(false);
  const [drainModalOpen, setDrainModalOpen] = useState(false);
  const [fluidsModalOpen, setFluidsModalOpen] = useState(false);
  const [precModalOpen, setPrecModalOpen] = useState(false);
  const [dietModalOpen, setDietModalOpen] = useState(false);
  const [txtsModalOpen, setTxtsModalOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [thePage, setThePage] = useState("P");
  const [shift, setShift] = useState("dayShift");

  const [scheduledTimes, setScheduledTimes] = useState(
    JSON.parse(localStorage.getItem("scheduledTimes"))
      ? JSON.parse(localStorage.getItem("scheduledTimes"))
      : {
          neuroChecks: 0,
          bloodGlucose: { AC: [8, 12, 4], HS: 10, Start: 0 },
          vitals: 8,
          shift: false,
        }
  );
  const [times, setTimes] = useState([
    {
      time: moment().hours(19).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(20).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(21).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(22).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(23).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(0).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(1).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(2).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(3).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(4).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(5).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(6).minutes(0).seconds(0),
      names: [],
    },
    {
      time: moment().hours(7).minutes(0).seconds(0),
      names: [],
    },

    // ...
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [thePage]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("nameList"))) {
      let b = JSON.parse(localStorage.getItem("nameList"));
      console.log("this is namelist");
      console.log(localStorage.getItem("nameList"));
      console.log(b);
      console.log("end of namelist");
      setNameList([b].flat());
    }
    if (JSON.parse(localStorage.getItem("drains"))) {
      let b = JSON.parse(localStorage.getItem("drains"));
      console.log("this is drainList");
      console.log(localStorage.getItem("drains"));
      console.log(b);
      console.log("end of namelist");
      setDrainList(b);
    }

    if (JSON.parse(localStorage.getItem("shift"))) {
      let c = JSON.parse(localStorage.getItem("shift"));
      console.log("GETTING THE TIMESSSSSSSSSSSSS");
      console.log(times);
      console.log([c].flat());
      console.log("SETTING THE TIMESSS");
      setTimes([c].flat());
    }

    if (JSON.parse(localStorage.getItem("scheduledTimes"))) {
      let c = JSON.parse(localStorage.getItem("scheduledTimes"));
      console.log("THIS IS THE SCHEDULED TIMES IN THE GET");
      console.log(scheduledTimes);
      setScheduledTimes(c);
    }

    if (JSON.parse(localStorage.getItem("shiftType"))) {
      setShift(JSON.parse(localStorage.getItem("shiftType")));
    } else {
      setShift("dayShift");
    }

    if (JSON.parse(localStorage.getItem("firstTime"))) {
      let c = JSON.parse(localStorage.getItem("firstTime"));
      setFirstTime(false);
    } else {
      setFirstTime(true);
    }
  }, []);

  useEffect(() => {
    setMedList(data);
    console.log("this is the fucken data");
    console.log(data);
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  useEffect(() => {
    console.log("this is the nameList");
    console.log(nameList);
  }, [nameList]);

  useEffect(() => {
    console.log("THIS IS THE TIMES FROM LOCALSTORAGE");
    console.log(JSON.parse(localStorage.getItem("shift")));
    console.log("THIS IS TIMES FROM SET BAR");
    console.log(times);
    console.log("THIS IS THE FIRSTTIME");
    console.log(JSON.parse(localStorage.getItem("firstTime")));
    console.log(times);
  }, [times]);

  useEffect(() => {
    console.log("THIS IS TIMES AFTER EVERYTHING");
    console.log(times);
    console.log("end times");
  }, [times]);

  useEffect(() => {
    const handleShake = () => {
      document.execCommand("insertText", false, ""); // Clear any typed text
    };

    window.addEventListener("devicemotion", handleShake);

    return () => {
      window.removeEventListener("devicemotion", handleShake);
    };
  }, []);

  return (
    <SettingMenu.Provider value={[settingMenu, setSettingMenu]}>
      <HandoffCheck.Provider value={[handoffCheck, setHandoffCheck]}>
        <Shift.Provider value={[shift, setShift]}>
          <ScheduledTimes.Provider value={[scheduledTimes, setScheduledTimes]}>
            <TxtsModalOpen.Provider value={[txtsModalOpen, setTxtsModalOpen]}>
              <DrainModalOpen.Provider
                value={[drainModalOpen, setDrainModalOpen]}
              >
                <PrecModalOpen.Provider
                  value={[precModalOpen, setPrecModalOpen]}
                >
                  <FluidsModalOpen.Provider
                    value={[fluidsModalOpen, setFluidsModalOpen]}
                  >
                    <DietModalOpen.Provider
                      value={[dietModalOpen, setDietModalOpen]}
                    >
                      <DrainList.Provider value={[drainList, setDrainList]}>
                        <ThePage.Provider value={[thePage, setThePage]}>
                          <LabModalOpen.Provider
                            value={[labModalOpen, setLabModalOpen]}
                          >
                            <NotesModalOpen.Provider
                              value={[notesModalOpen, setNotesModalOpen]}
                            >
                              <TempIndex.Provider
                                value={[tempIndex, setTempIndex]}
                              >
                                <TempNamers.Provider
                                  value={[tempNamers, setTempNamers]}
                                >
                                  <MedModalOpen.Provider
                                    value={[medModalOpen, setMedModalOpen]}
                                  >
                                    <MedList.Provider
                                      value={[medList, setMedList]}
                                    >
                                      <Loading.Provider
                                        value={[loading, setLoading]}
                                      >
                                        <FirstTime.Provider
                                          value={[firstTime, setFirstTime]}
                                        >
                                          <EditMode.Provider
                                            value={[editMode, setEditMode]}
                                          >
                                            <Times.Provider
                                              value={[times, setTimes]}
                                            >
                                              <ModalOpen.Provider
                                                value={[
                                                  isModalOpen,
                                                  setIsModalOpen,
                                                ]}
                                              >
                                                <Name.Provider
                                                  value={[name, setName]}
                                                >
                                                  <SelectedOption.Provider
                                                    value={[
                                                      selectedOption,
                                                      setSelectedOption,
                                                    ]}
                                                  >
                                                    <NameList.Provider
                                                      value={[
                                                        nameList,
                                                        setNameList,
                                                      ]}
                                                    >
                                                      <ShowAdd.Provider
                                                        value={[
                                                          showAdd,
                                                          setShowAdd,
                                                        ]}
                                                      >
                                                        <ChooseTime.Provider
                                                          value={[
                                                            chooseTime,
                                                            setChooseTime,
                                                          ]}
                                                        >
                                                          {loading == true ? (
                                                            <div className="center">
                                                              <div
                                                                style={{
                                                                  width:
                                                                    "100vw",
                                                                  height:
                                                                    "100vh",
                                                                  backgroundColor:
                                                                    "#0065fc",
                                                                }}
                                                              />
                                                              <img src="./assets/img/logo11.png" />
                                                            </div>
                                                          ) : (
                                                            <Main />
                                                          )}
                                                        </ChooseTime.Provider>
                                                      </ShowAdd.Provider>
                                                    </NameList.Provider>
                                                  </SelectedOption.Provider>
                                                </Name.Provider>
                                              </ModalOpen.Provider>
                                            </Times.Provider>
                                          </EditMode.Provider>
                                        </FirstTime.Provider>
                                      </Loading.Provider>
                                    </MedList.Provider>
                                  </MedModalOpen.Provider>
                                </TempNamers.Provider>
                              </TempIndex.Provider>
                            </NotesModalOpen.Provider>
                          </LabModalOpen.Provider>
                        </ThePage.Provider>
                      </DrainList.Provider>
                    </DietModalOpen.Provider>
                  </FluidsModalOpen.Provider>
                </PrecModalOpen.Provider>
              </DrainModalOpen.Provider>
            </TxtsModalOpen.Provider>
          </ScheduledTimes.Provider>
        </Shift.Provider>
      </HandoffCheck.Provider>
    </SettingMenu.Provider>
  );
};

export default App;
