import React, { useContext } from "react";
import "./App.css";
import moment from "moment";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  MdAccessibilityNew,
  MdAccessibleForward,
  MdAccountBalanceWallet,
  MdAdd,
  MdBedtime,
  MdBloodtype,
  MdCheck,
  MdClose,
  MdDelete,
  MdEdit,
  MdEditCalendar,
  MdFavorite,
  MdMedicalServices,
  MdMedication,
  MdNewspaper,
  MdNoFood,
  MdOutlineDomainDisabled,
  MdOutlineEditNote,
  MdOutlineWarning,
  MdPendingActions,
  MdPerson,
  MdPlusOne,
  MdSave,
  MdSettings,
  MdStickyNote2,
  MdThumbUp,
  MdTimer10Select,
  MdWarning,
} from "react-icons/md";
import {
  DrainList,
  EditMode,
  HandoffCheck,
  Name,
  NameList,
  SelectedOption,
  TempIndex,
  ThePage,
  Times,
} from "./App";
import {
  FaPills,
  FaStethoscope,
  FaVial,
  FaBook,
  FaSave,
  FaSlidersH,
  FaTextHeight,
  FaTerminal,
  FaCheck,
  FaCheckCircle,
  FaBars,
  FaPlusCircle,
  FaTint,
  FaRegCheckCircle,
  FaLungs,
  FaBrain,
  FaHeadSideCough,
  FaToilet,
  FaHandshakeAltSlash,
  FaHeadSideMask,
  FaHeadSideCoughSlash,
} from "react-icons/fa";
import {
  GiBrainstorm,
  GiFallingStar,
  GiGasMask,
  GiHeartBeats,
  GiMedicalDrip,
} from "react-icons/gi";
import { Alert } from "antd";
import { GrMenu } from "react-icons/gr";
import NewsTicker from "./NewsTicker";

export default function NavB({ addPat }) {
  const [editMode, setEditMode] = useContext(EditMode);
  const [times, setTimes] = useContext(Times);
  const [nameList, setNameList] = useContext(NameList);
  const [selectedOption, setSelectedOption] = useContext(SelectedOption);
  const [name, setName] = useContext(Name);
  const [thePage, setThePage] = useContext(ThePage);
  const [handoffCheck, setHandoffCheck] = useContext(HandoffCheck);
  const [tempIndex, setTempIndex] = useContext(TempIndex);
  const [drainList, setDrainList] = useContext(DrainList);

  const saveEverything = () => {
    localStorage.setItem("shift", JSON.stringify(times));
    localStorage.setItem("nameList", JSON.stringify(nameList));
    thePage == "P"
      ? setSelectedOption("M")
      : thePage == "D" && setSelectedOption("C");
  };
  const editModeOn = () => {
    setEditMode(true);
    thePage == "P"
      ? setSelectedOption("M")
      : thePage == "D" && setSelectedOption("C");
    setName(nameList[0]);
  };
  const editModeOff = () => {
    setEditMode(false);
    saveEverything();
  };
  const pageChanger = () => {
    switch (thePage) {
      case "P":
        setThePage("D");
        break;
      case "D":
        setThePage("P");
        break;
    }
  };

  const checkPrecautions = () => {
    let x = times;
    let drain = drainList;

    let findIndexofName = drain.findIndex((a) => a.name == name);
    let tempItems = [];
    let listtoReturn = {};
    let componentList = [];

    if (
      findIndexofName != -1 &&
      drain[findIndexofName].selectedOption.length > 0 &&
      drain[findIndexofName].selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log("THESE ARRE THE ITEMS");
      console.log(drain[findIndexofName].selectedOption);
      tempItems = drain[findIndexofName].selectedOption.filter((a) => a.Txts)[0]
        .Txts[0];
      if (tempItems.precautions != "" && tempItems.precautions != "Standard") {
        listtoReturn.precautions = tempItems.precautions;
        let y;
        console.log("This is the temp ITEMS");
        console.log(tempItems);
        console.log("END TEMP ITEMS");
        switch (tempItems.precautions) {
          case "Contact":
            y = (
              <button
                className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <FaHandshakeAltSlash size={25} />
                </div>
              </button>
            );
            break;
          case "S.Contact":
            y = (
              <button
                className="bg-yellow-500 flex justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <FaToilet size={25} />
                </div>
              </button>
            );
            break;
          case "Droplet":
            y = (
              <button
                className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <FaHeadSideMask size={25} />
                </div>
              </button>
            );
            break;
          case "Airborne":
            y = (
              <button
                className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <GiGasMask size={25} />
                </div>
              </button>
            );
            break;
        }
        componentList = [...componentList, y];
      }
      //IF FALLRISK
      if (tempItems.fallRisk == "Yes") {
        listtoReturn.fallRisk = true;
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <GiFallingStar size={35} />
          </button>,
        ];
      }

      //IF NPO PRECAUTIONS
      if (tempItems.diet == "NPO") {
        listtoReturn.diet = "NPO";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <MdNoFood size={35} />
          </button>,
        ];
      }

      //IF NPOAM PRECAUTIONS
      if (tempItems.diet == "NPOAM") {
        listtoReturn.diet = "NPOAM";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <MdNoFood size={35} className="custom-icon" />
              <MdBedtime size={15} className="custom-icon" />
            </div>
          </button>,
        ];
      }
      //IF ASPIRATION PRECAUTIONS
      if (tempItems.aspirationPrecautions == "Yes") {
        listtoReturn.aspirationPrecautions = true;
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <FaHeadSideCough size={25} />
            </div>
          </button>,
        ];
      }
      if (tempItems.seizureRisk == "Yes") {
        listtoReturn.seizureRisk = "Yes";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <GiBrainstorm size={25} />
            </div>
          </button>,
        ];
      }
      if (tempItems.glucose != "N/A" && tempItems.glucose != "") {
        listtoReturn.glucose = "Yes";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <MdBloodtype size={25} />
            </div>
          </button>,
        ];
      }
    }
    console.log(listtoReturn);
    console.log(componentList);

    if (componentList.length < 1) {
      return false;
    }
    return componentList;
  };

  const newsFeedData = [
    {
      title: "News article 1",
      content: "This is the content of news article 1.",
    },
    {
      title: "News article 2",
      content: "This is the content of news article 2.",
    },
    {
      title: "News article 3",
      content: "This is the content of news article 3.",
    },
  ];

  const checkerTitle = () => {
    switch (selectedOption) {
      case "M":
        return `Medication`;
      case "L":
        return "Labs";
      case "Txt":
        return "Notes";
    }
  };
  return (
    thePage != "TE" && (
      <div className="navbay text-light">
        {thePage == "M" ? (
          <div className="logoy font-bold text-2xl">Menu</div>
        ) : (
          <img className="logoy" src="./assets/img/logo11.png" alt="logo" />
        )}
        {thePage != "M" && thePage != "TE" && editMode == false ? (
          <div className="rightMenu" onClick={editModeOn}>
            <MdEdit style={{ color: "white" }} size={28} />
          </div>
        ) : (
          thePage != "M" &&
          thePage != "TE" &&
          editMode == true && (
            <div className="rightMenu" onClick={editModeOff}>
              <MdSave style={{ color: "white" }} size={28} />
            </div>
          )
        )}
        <div className="pageChanger" onClick={pageChanger}>
          {thePage == "D" ? (
            <MdPerson size={28} />
          ) : (
            thePage == "P" && <MdEditCalendar size={28} />
          )}
        </div>

        {thePage != "M" && (
          <div
            className="leftMenu"
            onClick={() => {
              setEditMode(false);
              setThePage("M");
            }}
          >
            <FaBars size={28} style={{ color: "white" }} />
          </div>
        )}
      </div>
    )
  );
}
