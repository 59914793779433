export const data = [
  { id: 0, BrandName: "Abacavir Sulfate", GenericName: "Abacavir Sulfate" },
  { id: 1, BrandName: "Abarelix", GenericName: "Abarelix" },
  { id: 2, BrandName: "Abatacept", GenericName: "Abatacept" },
  { id: 3, BrandName: "Abciximab", GenericName: "Abciximab" },
  { id: 4, BrandName: "Abelcet", GenericName: "Abelcet" },
  { id: 5, BrandName: "Abilify", GenericName: "Abilify" },
  { id: 6, BrandName: "Abraxane", GenericName: "Abraxane" },
  {
    id: 7,
    BrandName: "Acamprosate Calcium",
    GenericName: "Acamprosate Calcium",
  },
  { id: 8, BrandName: "Acarbose", GenericName: "Acarbose" },
  { id: 9, BrandName: "Accolate", GenericName: "Accolate" },
  { id: 10, BrandName: "Accretropin", GenericName: "Accretropin" },
  { id: 11, BrandName: "Accuneb", GenericName: "Accuneb" },
  { id: 12, BrandName: "Accupril", GenericName: "Accupril" },
  { id: 13, BrandName: "Accutane", GenericName: "Accutane" },
  { id: 14, BrandName: "Acebutolol", GenericName: "Acebutolol" },
  { id: 15, BrandName: "Aceon", GenericName: "Aceon" },
  { id: 16, BrandName: "Acetadote", GenericName: "Acetadote" },
  { id: 17, BrandName: "Acetaminophen", GenericName: "Acetaminophen" },
  { id: 18, BrandName: "Acetic Acid", GenericName: "Acetic Acid" },
  { id: 19, BrandName: "Aci-Jel", GenericName: "Aci-Jel" },
  { id: 20, BrandName: "Aciphex", GenericName: "Aciphex" },
  { id: 21, BrandName: "Acitretin", GenericName: "Acitretin" },
  { id: 22, BrandName: "Aclovate", GenericName: "Aclovate" },
  {
    id: 23,
    BrandName: "Acrivastine And Pseudoephedrine",
    GenericName: "Acrivastine And Pseudoephedrine",
  },
  { id: 24, BrandName: "Actemra", GenericName: "Actemra" },
  { id: 25, BrandName: "Acthrel", GenericName: "Acthrel" },
  { id: 26, BrandName: "Acticin", GenericName: "Acticin" },
  { id: 27, BrandName: "Actimmune", GenericName: "Actimmune" },
  { id: 28, BrandName: "Actiq", GenericName: "Actiq" },
  { id: 29, BrandName: "Actisite", GenericName: "Actisite" },
  { id: 30, BrandName: "Activase", GenericName: "Activase" },
  { id: 31, BrandName: "Activella", GenericName: "Activella" },
  { id: 32, BrandName: "Actonel", GenericName: "Actonel" },
  { id: 33, BrandName: "Actos", GenericName: "Actos" },
  { id: 34, BrandName: "Acular", GenericName: "Acular" },
  { id: 35, BrandName: "Acular Ls", GenericName: "Acular Ls" },
  { id: 36, BrandName: "Acuvail", GenericName: "Acuvail" },
  { id: 37, BrandName: "Acyclovir", GenericName: "Acyclovir" },
  { id: 38, BrandName: "Adacel", GenericName: "Adacel" },
  { id: 39, BrandName: "Adagen", GenericName: "Adagen" },
  { id: 40, BrandName: "Adalat Cc", GenericName: "Adalat Cc" },
  { id: 41, BrandName: "Adalimumab", GenericName: "Adalimumab" },
  { id: 42, BrandName: "Adapalene", GenericName: "Adapalene" },
  { id: 43, BrandName: "Adcirca", GenericName: "Adcirca" },
  { id: 44, BrandName: "Adderall", GenericName: "Adderall" },
  { id: 45, BrandName: "Adderall Xr", GenericName: "Adderall Xr" },
  {
    id: 46,
    BrandName: "Adefovir Dipivoxil",
    GenericName: "Adefovir Dipivoxil",
  },
  { id: 47, BrandName: "Adenoscan", GenericName: "Adenoscan" },
  { id: 48, BrandName: "Adenosine", GenericName: "Adenosine" },
  { id: 49, BrandName: "Adipex-P", GenericName: "Adipex-P" },
  { id: 50, BrandName: "Adoxa", GenericName: "Adoxa" },
  { id: 51, BrandName: "Adrenalin", GenericName: "Adrenalin" },
  { id: 52, BrandName: "Adreview", GenericName: "Adreview" },
  { id: 53, BrandName: "Adriamycin Pfs", GenericName: "Adriamycin Pfs" },
  { id: 54, BrandName: "Advair Diskus", GenericName: "Advair Diskus" },
  { id: 55, BrandName: "Advair Hfa", GenericName: "Advair Hfa" },
  { id: 56, BrandName: "Advicor", GenericName: "Advicor" },
  { id: 57, BrandName: "Aerospan Hfa", GenericName: "Aerospan Hfa" },
  { id: 58, BrandName: "Afinitor", GenericName: "Afinitor" },
  { id: 59, BrandName: "Afluria", GenericName: "Afluria" },
  { id: 60, BrandName: "Agalsidase Beta", GenericName: "Agalsidase Beta" },
  { id: 61, BrandName: "Aggrastat", GenericName: "Aggrastat" },
  { id: 62, BrandName: "Aggrenox", GenericName: "Aggrenox" },
  { id: 63, BrandName: "Agrylin", GenericName: "Agrylin" },
  { id: 64, BrandName: "Ak-Fluor", GenericName: "Ak-Fluor" },
  { id: 65, BrandName: "Akineton", GenericName: "Akineton" },
  { id: 66, BrandName: "Alamast", GenericName: "Alamast" },
  { id: 67, BrandName: "Albendazole", GenericName: "Albendazole" },
  { id: 68, BrandName: "Albenza", GenericName: "Albenza" },
  { id: 69, BrandName: "Albumin, Human", GenericName: "Albumin, Human" },
  { id: 70, BrandName: "Albuminar", GenericName: "Albuminar" },
  { id: 71, BrandName: "Albuterol Sulfate", GenericName: "Albuterol Sulfate" },
  {
    id: 72,
    BrandName: "Albuterol Sulfate Inhalation Aerosol",
    GenericName: "Albuterol Sulfate Inhalation Aerosol",
  },
  { id: 73, BrandName: "Alcaine", GenericName: "Alcaine" },
  {
    id: 74,
    BrandName: "Alclometasone Dipropionate",
    GenericName: "Alclometasone Dipropionate",
  },
  { id: 75, BrandName: "Aldactazide", GenericName: "Aldactazide" },
  { id: 76, BrandName: "Aldactone", GenericName: "Aldactone" },
  { id: 77, BrandName: "Aldara", GenericName: "Aldara" },
  { id: 78, BrandName: "Aldesleukin", GenericName: "Aldesleukin" },
  { id: 79, BrandName: "Aldomet", GenericName: "Aldomet" },
  { id: 80, BrandName: "Aldoril", GenericName: "Aldoril" },
  { id: 81, BrandName: "Aldurazyme", GenericName: "Aldurazyme" },
  { id: 82, BrandName: "Alefacept", GenericName: "Alefacept" },
  { id: 83, BrandName: "Alemtuzumab", GenericName: "Alemtuzumab" },
  {
    id: 84,
    BrandName: "Alendronate Sodium",
    GenericName: "Alendronate Sodium",
  },
  { id: 85, BrandName: "Alesse", GenericName: "Alesse" },
  { id: 86, BrandName: "Alfenta", GenericName: "Alfenta" },
  { id: 87, BrandName: "Alfentanil", GenericName: "Alfentanil" },
  { id: 88, BrandName: "Alfuzosin Hcl", GenericName: "Alfuzosin Hcl" },
  {
    id: 89,
    BrandName: "Alglucosidase Alfa",
    GenericName: "Alglucosidase Alfa",
  },
  { id: 90, BrandName: "Alimta", GenericName: "Alimta" },
  { id: 91, BrandName: "Alinia", GenericName: "Alinia" },
  { id: 92, BrandName: "Alitretinoin", GenericName: "Alitretinoin" },
  { id: 93, BrandName: "Alkeran", GenericName: "Alkeran" },
  { id: 94, BrandName: "Allegra", GenericName: "Allegra" },
  { id: 95, BrandName: "Allegra-D", GenericName: "Allegra-D" },
  { id: 96, BrandName: "Allegra D 24 Hour", GenericName: "Allegra D 24 Hour" },
  { id: 97, BrandName: "Allernaze", GenericName: "Allernaze" },
  { id: 98, BrandName: "Alli", GenericName: "Alli" },
  { id: 99, BrandName: "Allopurinol", GenericName: "Allopurinol" },
  {
    id: 100,
    BrandName: "Almotriptan Malate",
    GenericName: "Almotriptan Malate",
  },
  { id: 101, BrandName: "Alocril", GenericName: "Alocril" },
  { id: 102, BrandName: "Alomide", GenericName: "Alomide" },
  { id: 103, BrandName: "Aloprim", GenericName: "Aloprim" },
  { id: 104, BrandName: "Alora", GenericName: "Alora" },
  {
    id: 105,
    BrandName: "Alosetron Hydrochloride",
    GenericName: "Alosetron Hydrochloride",
  },
  { id: 106, BrandName: "Aloxi", GenericName: "Aloxi" },
  { id: 107, BrandName: "Alphanate", GenericName: "Alphanate" },
  { id: 108, BrandName: "Alprazolam", GenericName: "Alprazolam" },
  { id: 109, BrandName: "Alprostadil", GenericName: "Alprostadil" },
  { id: 110, BrandName: "Alrex", GenericName: "Alrex" },
  { id: 111, BrandName: "Alsuma", GenericName: "Alsuma" },
  { id: 112, BrandName: "Altabax", GenericName: "Altabax" },
  { id: 113, BrandName: "Altace", GenericName: "Altace" },
  { id: 114, BrandName: "Alteplase", GenericName: "Alteplase" },
  { id: 115, BrandName: "Altocor", GenericName: "Altocor" },
  { id: 116, BrandName: "Altoprev", GenericName: "Altoprev" },
  { id: 117, BrandName: "Altretamine", GenericName: "Altretamine" },
  { id: 118, BrandName: "Alupent", GenericName: "Alupent" },
  { id: 119, BrandName: "Alvesco", GenericName: "Alvesco" },
  {
    id: 120,
    BrandName: "Amantadine Hydrochloride",
    GenericName: "Amantadine Hydrochloride",
  },
  { id: 121, BrandName: "Amaryl", GenericName: "Amaryl" },
  { id: 122, BrandName: "Ambien", GenericName: "Ambien" },
  { id: 123, BrandName: "Ambien Cr", GenericName: "Ambien Cr" },
  { id: 124, BrandName: "Ambisome", GenericName: "Ambisome" },
  { id: 125, BrandName: "Amerge", GenericName: "Amerge" },
  { id: 126, BrandName: "Americaine", GenericName: "Americaine" },
  { id: 127, BrandName: "Amevive", GenericName: "Amevive" },
  { id: 128, BrandName: "Amicar", GenericName: "Amicar" },
  { id: 129, BrandName: "Amifostine", GenericName: "Amifostine" },
  { id: 130, BrandName: "Amikacin", GenericName: "Amikacin" },
  { id: 131, BrandName: "Amikin", GenericName: "Amikin" },
  { id: 132, BrandName: "Amiloride", GenericName: "Amiloride" },
  {
    id: 133,
    BrandName: "Amiloride And Hydrochlorothiazide",
    GenericName: "Amiloride And Hydrochlorothiazide",
  },
  {
    id: 134,
    BrandName: "Amiloride Hydrochloride",
    GenericName: "Amiloride Hydrochloride",
  },
  { id: 135, BrandName: "Amino Acids", GenericName: "Amino Acids" },
  { id: 136, BrandName: "Aminocaproic Acid", GenericName: "Aminocaproic Acid" },
  { id: 137, BrandName: "Aminoglutethimide", GenericName: "Aminoglutethimide" },
  {
    id: 138,
    BrandName: "Aminohippurate Sodium",
    GenericName: "Aminohippurate Sodium",
  },
  {
    id: 139,
    BrandName: "Aminolevulinic Acid",
    GenericName: "Aminolevulinic Acid",
  },
  {
    id: 140,
    BrandName: "Aminosalicylic Acid",
    GenericName: "Aminosalicylic Acid",
  },
  { id: 141, BrandName: "Aminosyn Ii 8.5%", GenericName: "Aminosyn Ii 8.5%" },
  {
    id: 142,
    BrandName: "Aminosyn Ii In Dextrose",
    GenericName: "Aminosyn Ii In Dextrose",
  },
  { id: 143, BrandName: "Amitiza", GenericName: "Amitiza" },
  { id: 144, BrandName: "Amitriptyline", GenericName: "Amitriptyline" },
  { id: 145, BrandName: "Amlexanox", GenericName: "Amlexanox" },
  {
    id: 146,
    BrandName: "Amlodipine Besylate",
    GenericName: "Amlodipine Besylate",
  },
  { id: 147, BrandName: "Amoxapine", GenericName: "Amoxapine" },
  { id: 148, BrandName: "Amoxicillin", GenericName: "Amoxicillin" },
  {
    id: 149,
    BrandName: "Amoxicillin Clavulanate",
    GenericName: "Amoxicillin Clavulanate",
  },
  { id: 150, BrandName: "Amoxil", GenericName: "Amoxil" },
  { id: 151, BrandName: "Amphadase", GenericName: "Amphadase" },
  { id: 152, BrandName: "Amphetamine", GenericName: "Amphetamine" },
  { id: 153, BrandName: "Amphotericin B", GenericName: "Amphotericin B" },
  { id: 154, BrandName: "Ampicillin", GenericName: "Ampicillin" },
  { id: 155, BrandName: "Ampyra", GenericName: "Ampyra" },
  { id: 156, BrandName: "Amrix", GenericName: "Amrix" },
  { id: 157, BrandName: "Amyl Nitrite", GenericName: "Amyl Nitrite" },
  { id: 158, BrandName: "Amytal Sodium", GenericName: "Amytal Sodium" },
  { id: 159, BrandName: "Anabolic Steroids", GenericName: "Anabolic Steroids" },
  { id: 160, BrandName: "Anadrol-50", GenericName: "Anadrol-50" },
  { id: 161, BrandName: "Anafranil", GenericName: "Anafranil" },
  { id: 162, BrandName: "Anagrelide", GenericName: "Anagrelide" },
  { id: 163, BrandName: "Anakinra", GenericName: "Anakinra" },
  { id: 164, BrandName: "Anastrozole", GenericName: "Anastrozole" },
  { id: 165, BrandName: "Ancobon", GenericName: "Ancobon" },
  { id: 166, BrandName: "Androderm", GenericName: "Androderm" },
  { id: 167, BrandName: "Androgel", GenericName: "Androgel" },
  { id: 168, BrandName: "Anectine", GenericName: "Anectine" },
  { id: 169, BrandName: "Angeliq", GenericName: "Angeliq" },
  { id: 170, BrandName: "Angiomax", GenericName: "Angiomax" },
  { id: 171, BrandName: "Anidulafungin", GenericName: "Anidulafungin" },
  { id: 172, BrandName: "Anisindione", GenericName: "Anisindione" },
  { id: 173, BrandName: "Ansaid", GenericName: "Ansaid" },
  { id: 174, BrandName: "Antabuse", GenericName: "Antabuse" },
  { id: 175, BrandName: "Antara", GenericName: "Antara" },
  { id: 176, BrandName: "Anthralin", GenericName: "Anthralin" },
  {
    id: 177,
    BrandName: "Antihemophilic Factor",
    GenericName: "Antihemophilic Factor",
  },
  { id: 178, BrandName: "Antithrombin", GenericName: "Antithrombin" },
  { id: 179, BrandName: "Antivenin", GenericName: "Antivenin" },
  { id: 180, BrandName: "Antivert", GenericName: "Antivert" },
  { id: 181, BrandName: "Antizol", GenericName: "Antizol" },
  { id: 182, BrandName: "Anturane", GenericName: "Anturane" },
  { id: 183, BrandName: "Anusol Hc", GenericName: "Anusol Hc" },
  { id: 184, BrandName: "Aphthasol", GenericName: "Aphthasol" },
  { id: 185, BrandName: "Apidra", GenericName: "Apidra" },
  { id: 186, BrandName: "Aplenzin", GenericName: "Aplenzin" },
  { id: 187, BrandName: "Apokyn", GenericName: "Apokyn" },
  { id: 188, BrandName: "Apomorphine", GenericName: "Apomorphine" },
  { id: 189, BrandName: "Apraclonidine", GenericName: "Apraclonidine" },
  { id: 190, BrandName: "Apresazide", GenericName: "Apresazide" },
  { id: 191, BrandName: "Apresoline", GenericName: "Apresoline" },
  { id: 192, BrandName: "Apri", GenericName: "Apri" },
  { id: 193, BrandName: "Apriso", GenericName: "Apriso" },
  { id: 194, BrandName: "Aprotinin", GenericName: "Aprotinin" },
  { id: 195, BrandName: "Aptivus", GenericName: "Aptivus" },
  { id: 196, BrandName: "Aquasol A", GenericName: "Aquasol A" },
  { id: 197, BrandName: "Aralen", GenericName: "Aralen" },
  { id: 198, BrandName: "Aramine", GenericName: "Aramine" },
  { id: 199, BrandName: "Aranesp", GenericName: "Aranesp" },
  { id: 200, BrandName: "Arava", GenericName: "Arava" },
  { id: 201, BrandName: "Arcalyst", GenericName: "Arcalyst" },
  { id: 202, BrandName: "Aredia", GenericName: "Aredia" },
  { id: 203, BrandName: "Argatroban", GenericName: "Argatroban" },
  { id: 204, BrandName: "Aricept", GenericName: "Aricept" },
  { id: 205, BrandName: "Arimidex", GenericName: "Arimidex" },
  { id: 206, BrandName: "Aripiprazole", GenericName: "Aripiprazole" },
  { id: 207, BrandName: "Aristocort", GenericName: "Aristocort" },
  { id: 208, BrandName: "Arixtra", GenericName: "Arixtra" },
  { id: 209, BrandName: "Armodafinil", GenericName: "Armodafinil" },
  { id: 210, BrandName: "Aromasin", GenericName: "Aromasin" },
  { id: 211, BrandName: "Arranon", GenericName: "Arranon" },
  { id: 212, BrandName: "Artane", GenericName: "Artane" },
  { id: 213, BrandName: "Arthrotec", GenericName: "Arthrotec" },
  { id: 214, BrandName: "Arzerra", GenericName: "Arzerra" },
  { id: 215, BrandName: "Asacol", GenericName: "Asacol" },
  { id: 216, BrandName: "Asacol Hd", GenericName: "Asacol Hd" },
  { id: 217, BrandName: "Asclera", GenericName: "Asclera" },
  { id: 218, BrandName: "Ascorbic Acid", GenericName: "Ascorbic Acid" },
  {
    id: 219,
    BrandName: "Asmanex Twisthaler",
    GenericName: "Asmanex Twisthaler",
  },
  { id: 220, BrandName: "Asparaginase", GenericName: "Asparaginase" },
  { id: 221, BrandName: "Aspirin", GenericName: "Aspirin" },
  { id: 222, BrandName: "Astelin", GenericName: "Astelin" },
  { id: 223, BrandName: "Astemizole", GenericName: "Astemizole" },
  { id: 224, BrandName: "Astepro", GenericName: "Astepro" },
  { id: 225, BrandName: "Atacand", GenericName: "Atacand" },
  { id: 226, BrandName: "Atacand Hct", GenericName: "Atacand Hct" },
  {
    id: 227,
    BrandName: "Atazanavir Sulfate",
    GenericName: "Atazanavir Sulfate",
  },
  {
    id: 228,
    BrandName: "Atenolol And Chlorthalidone",
    GenericName: "Atenolol And Chlorthalidone",
  },
  { id: 229, BrandName: "Atgam", GenericName: "Atgam" },
  { id: 230, BrandName: "Ativan", GenericName: "Ativan" },
  { id: 231, BrandName: "Atomoxetine Hcl", GenericName: "Atomoxetine Hcl" },
  {
    id: 232,
    BrandName: "Atorvastatin Calcium",
    GenericName: "Atorvastatin Calcium",
  },
  { id: 233, BrandName: "Atovaquone", GenericName: "Atovaquone" },
  {
    id: 234,
    BrandName: "Atracurium Besylate",
    GenericName: "Atracurium Besylate",
  },
  { id: 235, BrandName: "Atralin", GenericName: "Atralin" },
  { id: 236, BrandName: "Atridox", GenericName: "Atridox" },
  { id: 237, BrandName: "Atripla", GenericName: "Atripla" },
  { id: 238, BrandName: "Atromid-S", GenericName: "Atromid-S" },
  { id: 239, BrandName: "Atropen", GenericName: "Atropen" },
  { id: 240, BrandName: "Atropine", GenericName: "Atropine" },
  { id: 241, BrandName: "Atrovent Hfa", GenericName: "Atrovent Hfa" },
  { id: 242, BrandName: "Atryn", GenericName: "Atryn" },
  { id: 243, BrandName: "Augmentin", GenericName: "Augmentin" },
  { id: 244, BrandName: "Augmentin Xr", GenericName: "Augmentin Xr" },
  { id: 245, BrandName: "Auralgan", GenericName: "Auralgan" },
  { id: 246, BrandName: "Avage", GenericName: "Avage" },
  { id: 247, BrandName: "Avalide", GenericName: "Avalide" },
  { id: 248, BrandName: "Avandamet", GenericName: "Avandamet" },
  { id: 249, BrandName: "Avandaryl", GenericName: "Avandaryl" },
  { id: 250, BrandName: "Avandia", GenericName: "Avandia" },
  { id: 251, BrandName: "Avapro", GenericName: "Avapro" },
  { id: 252, BrandName: "Avastin", GenericName: "Avastin" },
  { id: 253, BrandName: "Avelox", GenericName: "Avelox" },
  { id: 254, BrandName: "Avinza", GenericName: "Avinza" },
  { id: 255, BrandName: "Avodart", GenericName: "Avodart" },
  { id: 256, BrandName: "Avonex", GenericName: "Avonex" },
  { id: 257, BrandName: "Axert", GenericName: "Axert" },
  { id: 258, BrandName: "Axid", GenericName: "Axid" },
  { id: 259, BrandName: "Aygestin", GenericName: "Aygestin" },
  { id: 260, BrandName: "Azacitidine", GenericName: "Azacitidine" },
  { id: 261, BrandName: "Azasan", GenericName: "Azasan" },
  { id: 262, BrandName: "Azasite", GenericName: "Azasite" },
  { id: 263, BrandName: "Azathioprine", GenericName: "Azathioprine" },
  { id: 264, BrandName: "Azelaic Acid", GenericName: "Azelaic Acid" },
  {
    id: 265,
    BrandName: "Azelastine Hydrochloride",
    GenericName: "Azelastine Hydrochloride",
  },
  { id: 266, BrandName: "Azelex", GenericName: "Azelex" },
  { id: 267, BrandName: "Azilect", GenericName: "Azilect" },
  { id: 268, BrandName: "Azithromycin", GenericName: "Azithromycin" },
  { id: 269, BrandName: "Azmacort", GenericName: "Azmacort" },
  { id: 270, BrandName: "Azopt", GenericName: "Azopt" },
  { id: 271, BrandName: "Azor", GenericName: "Azor" },
  {
    id: 272,
    BrandName: "Azulfidine En-Tabs",
    GenericName: "Azulfidine En-Tabs",
  },
  { id: 273, BrandName: "Bacitracin", GenericName: "Bacitracin" },
  { id: 274, BrandName: "Baclofen", GenericName: "Baclofen" },
  { id: 275, BrandName: "Bactrim", GenericName: "Bactrim" },
  { id: 276, BrandName: "Bactroban Nasal", GenericName: "Bactroban Nasal" },
  { id: 277, BrandName: "Balsalazide", GenericName: "Balsalazide" },
  { id: 278, BrandName: "Banzel", GenericName: "Banzel" },
  { id: 279, BrandName: "Baraclude", GenericName: "Baraclude" },
  { id: 280, BrandName: "Basiliximab", GenericName: "Basiliximab" },
  { id: 281, BrandName: "Baycol", GenericName: "Baycol" },
  { id: 282, BrandName: "Bayer", GenericName: "Bayer" },
  { id: 283, BrandName: "Becaplermin", GenericName: "Becaplermin" },
  { id: 284, BrandName: "Beconase", GenericName: "Beconase" },
  { id: 285, BrandName: "Benadryl", GenericName: "Benadryl" },
  { id: 286, BrandName: "Benazepril", GenericName: "Benazepril" },
  { id: 287, BrandName: "Benefix", GenericName: "Benefix" },
  { id: 288, BrandName: "Benicar", GenericName: "Benicar" },
  { id: 289, BrandName: "Benicar Hct", GenericName: "Benicar Hct" },
  { id: 290, BrandName: "Bentyl", GenericName: "Bentyl" },
  { id: 291, BrandName: "Benzaclin", GenericName: "Benzaclin" },
  { id: 292, BrandName: "Benzagel", GenericName: "Benzagel" },
  { id: 293, BrandName: "Benzamycin", GenericName: "Benzamycin" },
  { id: 294, BrandName: "Benzocaine", GenericName: "Benzocaine" },
  { id: 295, BrandName: "Benzonatate", GenericName: "Benzonatate" },
  {
    id: 296,
    BrandName: "Benzoyl Peroxide Gel",
    GenericName: "Benzoyl Peroxide Gel",
  },
  { id: 297, BrandName: "Benzphetamine", GenericName: "Benzphetamine" },
  {
    id: 298,
    BrandName: "Benztropine Mesylate",
    GenericName: "Benztropine Mesylate",
  },
  { id: 299, BrandName: "Bepreve", GenericName: "Bepreve" },
  { id: 300, BrandName: "Bepridil", GenericName: "Bepridil" },
  { id: 301, BrandName: "Beractant", GenericName: "Beractant" },
  { id: 302, BrandName: "Besivance", GenericName: "Besivance" },
  { id: 303, BrandName: "Betagan", GenericName: "Betagan" },
  { id: 304, BrandName: "Betamethasone", GenericName: "Betamethasone" },
  {
    id: 305,
    BrandName: "Betamethasone Dipropionate",
    GenericName: "Betamethasone Dipropionate",
  },
  { id: 306, BrandName: "Betapace", GenericName: "Betapace" },
  { id: 307, BrandName: "Betapace Af", GenericName: "Betapace Af" },
  { id: 308, BrandName: "Betaseron", GenericName: "Betaseron" },
  {
    id: 309,
    BrandName: "Betaxolol Hydrochloride",
    GenericName: "Betaxolol Hydrochloride",
  },
  { id: 310, BrandName: "Betaxon", GenericName: "Betaxon" },
  { id: 311, BrandName: "Bethanechol", GenericName: "Bethanechol" },
  {
    id: 312,
    BrandName: "Bethanechol Chloride",
    GenericName: "Bethanechol Chloride",
  },
  { id: 313, BrandName: "Betimol", GenericName: "Betimol" },
  { id: 314, BrandName: "Betoptic S", GenericName: "Betoptic S" },
  { id: 315, BrandName: "Bevacizumab", GenericName: "Bevacizumab" },
  { id: 316, BrandName: "Bexarotene", GenericName: "Bexarotene" },
  { id: 317, BrandName: "Bextra", GenericName: "Bextra" },
  { id: 318, BrandName: "Bexxar", GenericName: "Bexxar" },
  { id: 319, BrandName: "Bicalutamide", GenericName: "Bicalutamide" },
  {
    id: 320,
    BrandName: "Bicillin C-R 900/300",
    GenericName: "Bicillin C-R 900/300",
  },
  { id: 321, BrandName: "Bicnu", GenericName: "Bicnu" },
  { id: 322, BrandName: "Bidil", GenericName: "Bidil" },
  { id: 323, BrandName: "Biltricide", GenericName: "Biltricide" },
  { id: 324, BrandName: "Bioclate", GenericName: "Bioclate" },
  { id: 325, BrandName: "Biothrax", GenericName: "Biothrax" },
  { id: 326, BrandName: "Biperiden", GenericName: "Biperiden" },
  {
    id: 327,
    BrandName: "Bismuth Subcitrate Potassium",
    GenericName: "Bismuth Subcitrate Potassium",
  },
  {
    id: 328,
    BrandName: "Bismuth Subsalicylate",
    GenericName: "Bismuth Subsalicylate",
  },
  {
    id: 329,
    BrandName: "Bisoprolol Fumarate",
    GenericName: "Bisoprolol Fumarate",
  },
  { id: 330, BrandName: "Bivalirudin", GenericName: "Bivalirudin" },
  { id: 331, BrandName: "Blenoxane", GenericName: "Blenoxane" },
  { id: 332, BrandName: "Bleph 10", GenericName: "Bleph 10" },
  { id: 333, BrandName: "Blocadren", GenericName: "Blocadren" },
  { id: 334, BrandName: "Boniva", GenericName: "Boniva" },
  { id: 335, BrandName: "Bontril", GenericName: "Bontril" },
  { id: 336, BrandName: "Boostrix", GenericName: "Boostrix" },
  { id: 337, BrandName: "Bortezomib", GenericName: "Bortezomib" },
  { id: 338, BrandName: "Bosentan", GenericName: "Bosentan" },
  { id: 339, BrandName: "Botox", GenericName: "Botox" },
  { id: 340, BrandName: "Botox Cosmetic", GenericName: "Botox Cosmetic" },
  {
    id: 341,
    BrandName: "Botulinum Toxin Type A",
    GenericName: "Botulinum Toxin Type A",
  },
  {
    id: 342,
    BrandName: "Botulinum Toxin Type B",
    GenericName: "Botulinum Toxin Type B",
  },
  { id: 343, BrandName: "Bravelle", GenericName: "Bravelle" },
  { id: 344, BrandName: "Bretylium", GenericName: "Bretylium" },
  { id: 345, BrandName: "Brevibloc", GenericName: "Brevibloc" },
  { id: 346, BrandName: "Brevicon", GenericName: "Brevicon" },
  { id: 347, BrandName: "Brevital Sodium", GenericName: "Brevital Sodium" },
  {
    id: 348,
    BrandName: "Brimonidine Tartrate",
    GenericName: "Brimonidine Tartrate",
  },
  {
    id: 349,
    BrandName: "Bromfenac Ophthalmic Solution",
    GenericName: "Bromfenac Ophthalmic Solution",
  },
  {
    id: 350,
    BrandName: "Bromocriptine Mesylate",
    GenericName: "Bromocriptine Mesylate",
  },
  { id: 351, BrandName: "Brovana", GenericName: "Brovana" },
  { id: 352, BrandName: "Budesonide", GenericName: "Budesonide" },
  { id: 353, BrandName: "Bumetanide", GenericName: "Bumetanide" },
  { id: 354, BrandName: "Bumex", GenericName: "Bumex" },
  { id: 355, BrandName: "Buphenyl", GenericName: "Buphenyl" },
  { id: 356, BrandName: "Buprenex", GenericName: "Buprenex" },
  { id: 357, BrandName: "Buprenorphine", GenericName: "Buprenorphine" },
  { id: 358, BrandName: "Bupropion Hcl", GenericName: "Bupropion Hcl" },
  { id: 359, BrandName: "Buspar", GenericName: "Buspar" },
  { id: 360, BrandName: "Buspirone", GenericName: "Buspirone" },
  { id: 361, BrandName: "Busulfan", GenericName: "Busulfan" },
  { id: 362, BrandName: "Busulfex", GenericName: "Busulfex" },
  { id: 363, BrandName: "Butenafine", GenericName: "Butenafine" },
  { id: 364, BrandName: "Butisol", GenericName: "Butisol" },
  { id: 365, BrandName: "Butoconazole", GenericName: "Butoconazole" },
  {
    id: 366,
    BrandName: "Butorphanol Tartrate",
    GenericName: "Butorphanol Tartrate",
  },
  { id: 367, BrandName: "Butrans", GenericName: "Butrans" },
  { id: 368, BrandName: "Byetta", GenericName: "Byetta" },
  { id: 369, BrandName: "Ca-Dtpa", GenericName: "Ca-Dtpa" },
  { id: 370, BrandName: "Cabergoline", GenericName: "Cabergoline" },
  { id: 371, BrandName: "Caduet", GenericName: "Caduet" },
  { id: 372, BrandName: "Cafcit", GenericName: "Cafcit" },
  { id: 373, BrandName: "Cafergot", GenericName: "Cafergot" },
  { id: 374, BrandName: "Caffeine Citrate", GenericName: "Caffeine Citrate" },
  { id: 375, BrandName: "Calan", GenericName: "Calan" },
  { id: 376, BrandName: "Calciferol", GenericName: "Calciferol" },
  { id: 377, BrandName: "Calcitonin-Salmon", GenericName: "Calcitonin-Salmon" },
  { id: 378, BrandName: "Calcitriol", GenericName: "Calcitriol" },
  { id: 379, BrandName: "Calcium Chloride", GenericName: "Calcium Chloride" },
  {
    id: 380,
    BrandName: "Calcium Disodium Versenate",
    GenericName: "Calcium Disodium Versenate",
  },
  { id: 381, BrandName: "Calcium Gluconate", GenericName: "Calcium Gluconate" },
  { id: 382, BrandName: "Calfactant", GenericName: "Calfactant" },
  { id: 383, BrandName: "Cambia", GenericName: "Cambia" },
  { id: 384, BrandName: "Campath", GenericName: "Campath" },
  { id: 385, BrandName: "Campral", GenericName: "Campral" },
  { id: 386, BrandName: "Canasa", GenericName: "Canasa" },
  { id: 387, BrandName: "Cancidas", GenericName: "Cancidas" },
  {
    id: 388,
    BrandName: "Candesartan Cilexetil",
    GenericName: "Candesartan Cilexetil",
  },
  { id: 389, BrandName: "Cantil", GenericName: "Cantil" },
  { id: 390, BrandName: "Capastat Sulfate", GenericName: "Capastat Sulfate" },
  { id: 391, BrandName: "Capecitabine", GenericName: "Capecitabine" },
  { id: 392, BrandName: "Capoten", GenericName: "Capoten" },
  { id: 393, BrandName: "Capozide", GenericName: "Capozide" },
  { id: 394, BrandName: "Captopril", GenericName: "Captopril" },
  {
    id: 395,
    BrandName: "Captopril And Hydrochlorothiazide",
    GenericName: "Captopril And Hydrochlorothiazide",
  },
  { id: 396, BrandName: "Carac", GenericName: "Carac" },
  { id: 397, BrandName: "Carbaglu", GenericName: "Carbaglu" },
  { id: 398, BrandName: "Carbamazepine", GenericName: "Carbamazepine" },
  { id: 399, BrandName: "Carbatrol", GenericName: "Carbatrol" },
  {
    id: 400,
    BrandName: "Carbenicillin Indanyl Sodium",
    GenericName: "Carbenicillin Indanyl Sodium",
  },
  { id: 401, BrandName: "Carbidopa", GenericName: "Carbidopa" },
  { id: 402, BrandName: "Carbocaine", GenericName: "Carbocaine" },
  { id: 403, BrandName: "Carboplatin", GenericName: "Carboplatin" },
  {
    id: 404,
    BrandName: "Carboprost Tromethamine",
    GenericName: "Carboprost Tromethamine",
  },
  { id: 405, BrandName: "Cardene Sr", GenericName: "Cardene Sr" },
  { id: 406, BrandName: "Cardiolite", GenericName: "Cardiolite" },
  { id: 407, BrandName: "Cardizem La", GenericName: "Cardizem La" },
  { id: 408, BrandName: "Cardura", GenericName: "Cardura" },
  { id: 409, BrandName: "Cardura Xl", GenericName: "Cardura Xl" },
  { id: 410, BrandName: "Carisoprodol", GenericName: "Carisoprodol" },
  {
    id: 411,
    BrandName: "Carisoprodol And Aspirin",
    GenericName: "Carisoprodol And Aspirin",
  },
  { id: 412, BrandName: "Carmustine", GenericName: "Carmustine" },
  { id: 413, BrandName: "Carnitor", GenericName: "Carnitor" },
  { id: 414, BrandName: "Carteolol", GenericName: "Carteolol" },
  {
    id: 415,
    BrandName: "Carteolol Hydrochloride",
    GenericName: "Carteolol Hydrochloride",
  },
  { id: 416, BrandName: "Cartia Xt", GenericName: "Cartia Xt" },
  { id: 417, BrandName: "Carvedilol", GenericName: "Carvedilol" },
  { id: 418, BrandName: "Casodex", GenericName: "Casodex" },
  {
    id: 419,
    BrandName: "Caspofungin Acetate",
    GenericName: "Caspofungin Acetate",
  },
  { id: 420, BrandName: "Cataflam", GenericName: "Cataflam" },
  { id: 421, BrandName: "Catapres", GenericName: "Catapres" },
  { id: 422, BrandName: "Catapres-Tts", GenericName: "Catapres-Tts" },
  { id: 423, BrandName: "Caverject", GenericName: "Caverject" },
  { id: 424, BrandName: "Caverject Impulse", GenericName: "Caverject Impulse" },
  { id: 425, BrandName: "Cayston", GenericName: "Cayston" },
  { id: 426, BrandName: "Ceclor", GenericName: "Ceclor" },
  { id: 427, BrandName: "Cedax", GenericName: "Cedax" },
  { id: 428, BrandName: "Ceenu", GenericName: "Ceenu" },
  { id: 429, BrandName: "Cefaclor", GenericName: "Cefaclor" },
  { id: 430, BrandName: "Cefadroxil", GenericName: "Cefadroxil" },
  {
    id: 431,
    BrandName: "Cefadroxil Hemihydrate",
    GenericName: "Cefadroxil Hemihydrate",
  },
  { id: 432, BrandName: "Cefamandole", GenericName: "Cefamandole" },
  { id: 433, BrandName: "Cefdinir", GenericName: "Cefdinir" },
  {
    id: 434,
    BrandName: "Cefditoren Pivoxil",
    GenericName: "Cefditoren Pivoxil",
  },
  { id: 435, BrandName: "Cefixime", GenericName: "Cefixime" },
  { id: 436, BrandName: "Cefizox", GenericName: "Cefizox" },
  { id: 437, BrandName: "Cefobid", GenericName: "Cefobid" },
  { id: 438, BrandName: "Cefotan", GenericName: "Cefotan" },
  { id: 439, BrandName: "Cefotaxime", GenericName: "Cefotaxime" },
  { id: 440, BrandName: "Cefotetan", GenericName: "Cefotetan" },
  { id: 441, BrandName: "Cefoxitin", GenericName: "Cefoxitin" },
  { id: 442, BrandName: "Cefprozil", GenericName: "Cefprozil" },
  { id: 443, BrandName: "Ceftazidime", GenericName: "Ceftazidime" },
  { id: 444, BrandName: "Ceftibuten", GenericName: "Ceftibuten" },
  { id: 445, BrandName: "Ceftin", GenericName: "Ceftin" },
  { id: 446, BrandName: "Ceftizoxime", GenericName: "Ceftizoxime" },
  { id: 447, BrandName: "Ceftriaxone", GenericName: "Ceftriaxone" },
  { id: 448, BrandName: "Cefuroxime", GenericName: "Cefuroxime" },
  { id: 449, BrandName: "Cefuroxime Axetil", GenericName: "Cefuroxime Axetil" },
  { id: 450, BrandName: "Cefzil", GenericName: "Cefzil" },
  { id: 451, BrandName: "Celebrex", GenericName: "Celebrex" },
  { id: 452, BrandName: "Celecoxib", GenericName: "Celecoxib" },
  {
    id: 453,
    BrandName: "Celestone Soluspan",
    GenericName: "Celestone Soluspan",
  },
  { id: 454, BrandName: "Celexa", GenericName: "Celexa" },
  { id: 455, BrandName: "Cellcept", GenericName: "Cellcept" },
  { id: 456, BrandName: "Cellulose", GenericName: "Cellulose" },
  { id: 457, BrandName: "Celontin", GenericName: "Celontin" },
  { id: 458, BrandName: "Cenestin", GenericName: "Cenestin" },
  { id: 459, BrandName: "Cephalexin", GenericName: "Cephalexin" },
  { id: 460, BrandName: "Ceprotin", GenericName: "Ceprotin" },
  { id: 461, BrandName: "Ceptaz", GenericName: "Ceptaz" },
  { id: 462, BrandName: "Cerebyx", GenericName: "Cerebyx" },
  { id: 463, BrandName: "Ceredase", GenericName: "Ceredase" },
  { id: 464, BrandName: "Ceretec", GenericName: "Ceretec" },
  { id: 465, BrandName: "Cerezyme", GenericName: "Cerezyme" },
  { id: 466, BrandName: "Cerivastatin", GenericName: "Cerivastatin" },
  {
    id: 467,
    BrandName: "Certolizumab Pegol",
    GenericName: "Certolizumab Pegol",
  },
  { id: 468, BrandName: "Cerubidine", GenericName: "Cerubidine" },
  { id: 469, BrandName: "Cerumenex", GenericName: "Cerumenex" },
  { id: 470, BrandName: "Cervarix", GenericName: "Cervarix" },
  { id: 471, BrandName: "Cervidil", GenericName: "Cervidil" },
  { id: 472, BrandName: "Cesamet", GenericName: "Cesamet" },
  { id: 473, BrandName: "Cetirizine", GenericName: "Cetirizine" },
  { id: 474, BrandName: "Cetraxal", GenericName: "Cetraxal" },
  { id: 475, BrandName: "Cetrorelix", GenericName: "Cetrorelix" },
  { id: 476, BrandName: "Cetrotide", GenericName: "Cetrotide" },
  { id: 477, BrandName: "Cetuximab", GenericName: "Cetuximab" },
  { id: 478, BrandName: "Cevimeline Hcl", GenericName: "Cevimeline Hcl" },
  { id: 479, BrandName: "Chantix", GenericName: "Chantix" },
  { id: 480, BrandName: "Chemet", GenericName: "Chemet" },
  { id: 481, BrandName: "Chibroxin", GenericName: "Chibroxin" },
  { id: 482, BrandName: "Chirhostim", GenericName: "Chirhostim" },
  { id: 483, BrandName: "Chlor-Trimeton", GenericName: "Chlor-Trimeton" },
  { id: 484, BrandName: "Chloral Hydrate", GenericName: "Chloral Hydrate" },
  { id: 485, BrandName: "Chlorambucil", GenericName: "Chlorambucil" },
  { id: 486, BrandName: "Chloramphenicol", GenericName: "Chloramphenicol" },
  {
    id: 487,
    BrandName: "Chloramphenicol Sodium Succinate",
    GenericName: "Chloramphenicol Sodium Succinate",
  },
  { id: 488, BrandName: "Chlordiazepoxide", GenericName: "Chlordiazepoxide" },
  { id: 489, BrandName: "Chlorhexidine", GenericName: "Chlorhexidine" },
  { id: 490, BrandName: "Chloroprocaine", GenericName: "Chloroprocaine" },
  { id: 491, BrandName: "Chloroptic", GenericName: "Chloroptic" },
  { id: 492, BrandName: "Chloroquine", GenericName: "Chloroquine" },
  { id: 493, BrandName: "Chlorothiazide", GenericName: "Chlorothiazide" },
  {
    id: 494,
    BrandName: "Chlorpheniramine Maleate",
    GenericName: "Chlorpheniramine Maleate",
  },
  { id: 495, BrandName: "Chlorpromazine", GenericName: "Chlorpromazine" },
  { id: 496, BrandName: "Chlorpropamide", GenericName: "Chlorpropamide" },
  { id: 497, BrandName: "Chlorthalidone", GenericName: "Chlorthalidone" },
  { id: 498, BrandName: "Chlorzoxazone", GenericName: "Chlorzoxazone" },
  { id: 499, BrandName: "Cholera Vaccine", GenericName: "Cholera Vaccine" },
  { id: 500, BrandName: "Cholestyramine", GenericName: "Cholestyramine" },
  {
    id: 501,
    BrandName: "Choline Magnesium Trisalicylate",
    GenericName: "Choline Magnesium Trisalicylate",
  },
  {
    id: 502,
    BrandName: "Chorionic Gonadotropin",
    GenericName: "Chorionic Gonadotropin",
  },
  { id: 503, BrandName: "Cialis", GenericName: "Cialis" },
  { id: 504, BrandName: "Ciclopirox Gel", GenericName: "Ciclopirox Gel" },
  { id: 505, BrandName: "Cidofovir", GenericName: "Cidofovir" },
  { id: 506, BrandName: "Cilostazol", GenericName: "Cilostazol" },
  { id: 507, BrandName: "Cimetidine", GenericName: "Cimetidine" },
  { id: 508, BrandName: "Cimzia", GenericName: "Cimzia" },
  { id: 509, BrandName: "Cinacalcet", GenericName: "Cinacalcet" },
  { id: 510, BrandName: "Cinobac", GenericName: "Cinobac" },
  { id: 511, BrandName: "Cinoxacin", GenericName: "Cinoxacin" },
  { id: 512, BrandName: "Cinryze", GenericName: "Cinryze" },
  { id: 513, BrandName: "Cipro", GenericName: "Cipro" },
  { id: 514, BrandName: "Cipro Xr", GenericName: "Cipro Xr" },
  { id: 515, BrandName: "Ciprodex", GenericName: "Ciprodex" },
  { id: 516, BrandName: "Ciprofloxacin", GenericName: "Ciprofloxacin" },
  {
    id: 517,
    BrandName: "Ciprofloxacin And Dexamethasone",
    GenericName: "Ciprofloxacin And Dexamethasone",
  },
  { id: 518, BrandName: "Ciprofloxacin Hcl", GenericName: "Ciprofloxacin Hcl" },
  { id: 519, BrandName: "Cisapride", GenericName: "Cisapride" },
  {
    id: 520,
    BrandName: "Cisatracurium Besylate",
    GenericName: "Cisatracurium Besylate",
  },
  { id: 521, BrandName: "Cisplatin", GenericName: "Cisplatin" },
  {
    id: 522,
    BrandName: "Citalopram Hydrobromide",
    GenericName: "Citalopram Hydrobromide",
  },
  { id: 523, BrandName: "Cladribine", GenericName: "Cladribine" },
  { id: 524, BrandName: "Claforan", GenericName: "Claforan" },
  { id: 525, BrandName: "Clarinex", GenericName: "Clarinex" },
  {
    id: 526,
    BrandName: "Clarinex-D 12 Hour",
    GenericName: "Clarinex-D 12 Hour",
  },
  {
    id: 527,
    BrandName: "Clarinex-D 24 Hour",
    GenericName: "Clarinex-D 24 Hour",
  },
  { id: 528, BrandName: "Clarithromycin", GenericName: "Clarithromycin" },
  { id: 529, BrandName: "Claritin", GenericName: "Claritin" },
  { id: 530, BrandName: "Cleocin", GenericName: "Cleocin" },
  { id: 531, BrandName: "Cleocin T", GenericName: "Cleocin T" },
  {
    id: 532,
    BrandName: "Clevidipine Butyrate",
    GenericName: "Clevidipine Butyrate",
  },
  { id: 533, BrandName: "Cleviprex", GenericName: "Cleviprex" },
  { id: 534, BrandName: "Climara", GenericName: "Climara" },
  { id: 535, BrandName: "Climara Pro", GenericName: "Climara Pro" },
  { id: 536, BrandName: "Clindamycin", GenericName: "Clindamycin" },
  {
    id: 537,
    BrandName: "Clindamycin Phosphate",
    GenericName: "Clindamycin Phosphate",
  },
  { id: 538, BrandName: "Clindets", GenericName: "Clindets" },
  { id: 539, BrandName: "Clinoril", GenericName: "Clinoril" },
  {
    id: 540,
    BrandName: "Clobetasol Propionate",
    GenericName: "Clobetasol Propionate",
  },
  {
    id: 541,
    BrandName: "Clobetasol Propionate Gel",
    GenericName: "Clobetasol Propionate Gel",
  },
  { id: 542, BrandName: "Clocortolone", GenericName: "Clocortolone" },
  { id: 543, BrandName: "Cloderm", GenericName: "Cloderm" },
  { id: 544, BrandName: "Clofarabine", GenericName: "Clofarabine" },
  { id: 545, BrandName: "Clofazimine", GenericName: "Clofazimine" },
  { id: 546, BrandName: "Clofibrate", GenericName: "Clofibrate" },
  { id: 547, BrandName: "Clolar", GenericName: "Clolar" },
  { id: 548, BrandName: "Clomid", GenericName: "Clomid" },
  { id: 549, BrandName: "Clomiphene", GenericName: "Clomiphene" },
  { id: 550, BrandName: "Clomipramine Hcl", GenericName: "Clomipramine Hcl" },
  { id: 551, BrandName: "Clonazepam", GenericName: "Clonazepam" },
  { id: 552, BrandName: "Clonidine", GenericName: "Clonidine" },
  {
    id: 553,
    BrandName: "Clopidogrel Bisulfate",
    GenericName: "Clopidogrel Bisulfate",
  },
  {
    id: 554,
    BrandName: "Clorazepate Dipotassium",
    GenericName: "Clorazepate Dipotassium",
  },
  { id: 555, BrandName: "Clorpres", GenericName: "Clorpres" },
  { id: 556, BrandName: "Clotrimazole", GenericName: "Clotrimazole" },
  { id: 557, BrandName: "Clozapine", GenericName: "Clozapine" },
  { id: 558, BrandName: "Clozaril", GenericName: "Clozaril" },
  { id: 559, BrandName: "Coartem", GenericName: "Coartem" },
  { id: 560, BrandName: "Cocaine", GenericName: "Cocaine" },
  { id: 561, BrandName: "Codeine", GenericName: "Codeine" },
  { id: 562, BrandName: "Codeine Phosphate", GenericName: "Codeine Phosphate" },
  { id: 563, BrandName: "Codeine Sulfate", GenericName: "Codeine Sulfate" },
  { id: 564, BrandName: "Cogentin", GenericName: "Cogentin" },
  { id: 565, BrandName: "Cognex", GenericName: "Cognex" },
  { id: 566, BrandName: "Colazal", GenericName: "Colazal" },
  { id: 567, BrandName: "Colchicine", GenericName: "Colchicine" },
  { id: 568, BrandName: "Colcrys", GenericName: "Colcrys" },
  { id: 569, BrandName: "Colesevelam Hcl", GenericName: "Colesevelam Hcl" },
  { id: 570, BrandName: "Colestid", GenericName: "Colestid" },
  { id: 571, BrandName: "Colestipol", GenericName: "Colestipol" },
  { id: 572, BrandName: "Collagenase", GenericName: "Collagenase" },
  { id: 573, BrandName: "Coly-Mycin M", GenericName: "Coly-Mycin M" },
  { id: 574, BrandName: "Coly-Mycin S Otic", GenericName: "Coly-Mycin S Otic" },
  { id: 575, BrandName: "Combigan", GenericName: "Combigan" },
  { id: 576, BrandName: "Combipatch", GenericName: "Combipatch" },
  { id: 577, BrandName: "Combivent", GenericName: "Combivent" },
  { id: 578, BrandName: "Combivir", GenericName: "Combivir" },
  { id: 579, BrandName: "Combunox", GenericName: "Combunox" },
  { id: 580, BrandName: "Compazine", GenericName: "Compazine" },
  { id: 581, BrandName: "Compro", GenericName: "Compro" },
  { id: 582, BrandName: "Comtan", GenericName: "Comtan" },
  { id: 583, BrandName: "Comvax", GenericName: "Comvax" },
  { id: 584, BrandName: "Concerta", GenericName: "Concerta" },
  {
    id: 585,
    BrandName: "Conjugated Estrogens",
    GenericName: "Conjugated Estrogens",
  },
  { id: 586, BrandName: "Copaxone", GenericName: "Copaxone" },
  { id: 587, BrandName: "Copegus", GenericName: "Copegus" },
  { id: 588, BrandName: "Cordarone", GenericName: "Cordarone" },
  { id: 589, BrandName: "Coreg", GenericName: "Coreg" },
  { id: 590, BrandName: "Coreg Cr", GenericName: "Coreg Cr" },
  { id: 591, BrandName: "Corgard", GenericName: "Corgard" },
  { id: 592, BrandName: "Corlopam", GenericName: "Corlopam" },
  { id: 593, BrandName: "Cortaid", GenericName: "Cortaid" },
  { id: 594, BrandName: "Cortef", GenericName: "Cortef" },
  { id: 595, BrandName: "Cortenema", GenericName: "Cortenema" },
  { id: 596, BrandName: "Cortisone Acetate", GenericName: "Cortisone Acetate" },
  { id: 597, BrandName: "Cortone", GenericName: "Cortone" },
  { id: 598, BrandName: "Cortrosyn", GenericName: "Cortrosyn" },
  { id: 599, BrandName: "Corvert", GenericName: "Corvert" },
  { id: 600, BrandName: "Corzide", GenericName: "Corzide" },
  { id: 601, BrandName: "Cosmegen", GenericName: "Cosmegen" },
  { id: 602, BrandName: "Cosopt", GenericName: "Cosopt" },
  { id: 603, BrandName: "Cosyntropin", GenericName: "Cosyntropin" },
  { id: 604, BrandName: "Coumadin", GenericName: "Coumadin" },
  { id: 605, BrandName: "Covera-Hs", GenericName: "Covera-Hs" },
  { id: 606, BrandName: "Cozaar", GenericName: "Cozaar" },
  { id: 607, BrandName: "Creon", GenericName: "Creon" },
  { id: 608, BrandName: "Crestor", GenericName: "Crestor" },
  { id: 609, BrandName: "Crixivan", GenericName: "Crixivan" },
  { id: 610, BrandName: "Crofab", GenericName: "Crofab" },
  { id: 611, BrandName: "Crolom", GenericName: "Crolom" },
  { id: 612, BrandName: "Cromolyn Sodium", GenericName: "Cromolyn Sodium" },
  { id: 613, BrandName: "Cubicin", GenericName: "Cubicin" },
  { id: 614, BrandName: "Cuprimine", GenericName: "Cuprimine" },
  { id: 615, BrandName: "Curosurf", GenericName: "Curosurf" },
  { id: 616, BrandName: "Cuvposa", GenericName: "Cuvposa" },
  { id: 617, BrandName: "Cyanocobalamin", GenericName: "Cyanocobalamin" },
  { id: 618, BrandName: "Cyanokit", GenericName: "Cyanokit" },
  { id: 619, BrandName: "Cyclessa", GenericName: "Cyclessa" },
  {
    id: 620,
    BrandName: "Cyclobenzaprine Hcl",
    GenericName: "Cyclobenzaprine Hcl",
  },
  { id: 621, BrandName: "Cyclophosphamide", GenericName: "Cyclophosphamide" },
  { id: 622, BrandName: "Cycloset", GenericName: "Cycloset" },
  { id: 623, BrandName: "Cyclosporine", GenericName: "Cyclosporine" },
  { id: 624, BrandName: "Cyklokapron", GenericName: "Cyklokapron" },
  { id: 625, BrandName: "Cylert", GenericName: "Cylert" },
  { id: 626, BrandName: "Cymbalta", GenericName: "Cymbalta" },
  { id: 627, BrandName: "Cyproheptadine", GenericName: "Cyproheptadine" },
  {
    id: 628,
    BrandName: "Cyproheptadine Hydrochloride",
    GenericName: "Cyproheptadine Hydrochloride",
  },
  { id: 629, BrandName: "Cystadane", GenericName: "Cystadane" },
  { id: 630, BrandName: "Cystagon", GenericName: "Cystagon" },
  {
    id: 631,
    BrandName: "Cysteamine Bitartrate",
    GenericName: "Cysteamine Bitartrate",
  },
  { id: 632, BrandName: "Cysview", GenericName: "Cysview" },
  { id: 633, BrandName: "Cytadren", GenericName: "Cytadren" },
  { id: 634, BrandName: "Cytarabine", GenericName: "Cytarabine" },
  { id: 635, BrandName: "Cytogam", GenericName: "Cytogam" },
  { id: 636, BrandName: "Cytomel", GenericName: "Cytomel" },
  { id: 637, BrandName: "Cytotec", GenericName: "Cytotec" },
  { id: 638, BrandName: "Cytovene", GenericName: "Cytovene" },
  { id: 639, BrandName: "Cytoxan", GenericName: "Cytoxan" },
  { id: 640, BrandName: "Dacarbazine", GenericName: "Dacarbazine" },
  { id: 641, BrandName: "Daclizumab", GenericName: "Daclizumab" },
  { id: 642, BrandName: "Dacogen", GenericName: "Dacogen" },
  { id: 643, BrandName: "Dactinomycin", GenericName: "Dactinomycin" },
  { id: 644, BrandName: "Dalmane", GenericName: "Dalmane" },
  { id: 645, BrandName: "Dalteparin", GenericName: "Dalteparin" },
  { id: 646, BrandName: "Danazol", GenericName: "Danazol" },
  { id: 647, BrandName: "Dapsone", GenericName: "Dapsone" },
  { id: 648, BrandName: "Daptacel", GenericName: "Daptacel" },
  { id: 649, BrandName: "Daraprim", GenericName: "Daraprim" },
  { id: 650, BrandName: "Darbepoetin Alfa", GenericName: "Darbepoetin Alfa" },
  { id: 651, BrandName: "Darunavir", GenericName: "Darunavir" },
  { id: 652, BrandName: "Darvocet-N", GenericName: "Darvocet-N" },
  { id: 653, BrandName: "Darvon", GenericName: "Darvon" },
  { id: 654, BrandName: "Darvon Compound", GenericName: "Darvon Compound" },
  { id: 655, BrandName: "Dasatinib", GenericName: "Dasatinib" },
  { id: 656, BrandName: "Daunorubicin", GenericName: "Daunorubicin" },
  { id: 657, BrandName: "Daypro", GenericName: "Daypro" },
  { id: 658, BrandName: "Daypro Alta", GenericName: "Daypro Alta" },
  { id: 659, BrandName: "Daytrana", GenericName: "Daytrana" },
  { id: 660, BrandName: "Ddavp", GenericName: "Ddavp" },
  { id: 661, BrandName: "Ddavp Nasal Spray", GenericName: "Ddavp Nasal Spray" },
  { id: 662, BrandName: "Ddavp Rhinal Tube", GenericName: "Ddavp Rhinal Tube" },
  { id: 663, BrandName: "Decadron", GenericName: "Decadron" },
  { id: 664, BrandName: "Declomycin", GenericName: "Declomycin" },
  { id: 665, BrandName: "Deferasirox", GenericName: "Deferasirox" },
  { id: 666, BrandName: "Deferoxamine", GenericName: "Deferoxamine" },
  { id: 667, BrandName: "Definity", GenericName: "Definity" },
  {
    id: 668,
    BrandName: "Dehydrated Alcohol",
    GenericName: "Dehydrated Alcohol",
  },
  { id: 669, BrandName: "Delatestryl", GenericName: "Delatestryl" },
  {
    id: 670,
    BrandName: "Delavirdine Mesylate",
    GenericName: "Delavirdine Mesylate",
  },
  { id: 671, BrandName: "Delestrogen", GenericName: "Delestrogen" },
  { id: 672, BrandName: "Deltasone", GenericName: "Deltasone" },
  { id: 673, BrandName: "Demadex", GenericName: "Demadex" },
  {
    id: 674,
    BrandName: "Demeclocycline Hcl",
    GenericName: "Demeclocycline Hcl",
  },
  { id: 675, BrandName: "Demerol", GenericName: "Demerol" },
  { id: 676, BrandName: "Demser", GenericName: "Demser" },
  { id: 677, BrandName: "Demulen", GenericName: "Demulen" },
  { id: 678, BrandName: "Denavir", GenericName: "Denavir" },
  {
    id: 679,
    BrandName: "Denileukin Diftitox",
    GenericName: "Denileukin Diftitox",
  },
  { id: 680, BrandName: "Depacon", GenericName: "Depacon" },
  { id: 681, BrandName: "Depakene", GenericName: "Depakene" },
  { id: 682, BrandName: "Depakote", GenericName: "Depakote" },
  { id: 683, BrandName: "Depakote Er", GenericName: "Depakote Er" },
  { id: 684, BrandName: "Depo-Estradiol", GenericName: "Depo-Estradiol" },
  { id: 685, BrandName: "Depo-Subq Provera", GenericName: "Depo-Subq Provera" },
  { id: 686, BrandName: "Depo-Testosterone", GenericName: "Depo-Testosterone" },
  { id: 687, BrandName: "Depocyt", GenericName: "Depocyt" },
  { id: 688, BrandName: "Depodur", GenericName: "Depodur" },
  { id: 689, BrandName: "Derma-Smoothe/Fs", GenericName: "Derma-Smoothe/Fs" },
  { id: 690, BrandName: "Desferal", GenericName: "Desferal" },
  { id: 691, BrandName: "Desflurane", GenericName: "Desflurane" },
  {
    id: 692,
    BrandName: "Desipramine Hydrochloride",
    GenericName: "Desipramine Hydrochloride",
  },
  { id: 693, BrandName: "Desloratadine", GenericName: "Desloratadine" },
  {
    id: 694,
    BrandName: "Desloratadine And Pseudoephedrine Sulfate",
    GenericName: "Desloratadine And Pseudoephedrine Sulfate",
  },
  {
    id: 695,
    BrandName: "Desmopressin Acetate",
    GenericName: "Desmopressin Acetate",
  },
  { id: 696, BrandName: "Desogen", GenericName: "Desogen" },
  {
    id: 697,
    BrandName: "Desogestrel And Ethinyl Estradiol",
    GenericName: "Desogestrel And Ethinyl Estradiol",
  },
  { id: 698, BrandName: "Desonate", GenericName: "Desonate" },
  { id: 699, BrandName: "Desowen", GenericName: "Desowen" },
  { id: 700, BrandName: "Desoximetasone", GenericName: "Desoximetasone" },
  { id: 701, BrandName: "Desoxyn", GenericName: "Desoxyn" },
  { id: 702, BrandName: "Desyrel", GenericName: "Desyrel" },
  { id: 703, BrandName: "Detrol", GenericName: "Detrol" },
  { id: 704, BrandName: "Detrol La", GenericName: "Detrol La" },
  { id: 705, BrandName: "Dexamethasone", GenericName: "Dexamethasone" },
  { id: 706, BrandName: "Dexedrine", GenericName: "Dexedrine" },
  { id: 707, BrandName: "Dexfenfluramine", GenericName: "Dexfenfluramine" },
  { id: 708, BrandName: "Dexilant", GenericName: "Dexilant" },
  { id: 709, BrandName: "Dexlansoprazole", GenericName: "Dexlansoprazole" },
  {
    id: 710,
    BrandName: "Dexmedetomidine Hydrochloride",
    GenericName: "Dexmedetomidine Hydrochloride",
  },
  {
    id: 711,
    BrandName: "Dexmethylphenidate Hydrochloride",
    GenericName: "Dexmethylphenidate Hydrochloride",
  },
  { id: 712, BrandName: "Dexrazoxane", GenericName: "Dexrazoxane" },
  { id: 713, BrandName: "Dextroamphetamine", GenericName: "Dextroamphetamine" },
  { id: 714, BrandName: "Dht", GenericName: "Dht" },
  { id: 715, BrandName: "Diabeta", GenericName: "Diabeta" },
  { id: 716, BrandName: "Diabinese", GenericName: "Diabinese" },
  { id: 717, BrandName: "Diamox Sequels", GenericName: "Diamox Sequels" },
  { id: 718, BrandName: "Diastat", GenericName: "Diastat" },
  { id: 719, BrandName: "Diazepam", GenericName: "Diazepam" },
  { id: 720, BrandName: "Dibenzyline", GenericName: "Dibenzyline" },
  { id: 721, BrandName: "Diclofenac Sodium", GenericName: "Diclofenac Sodium" },
  { id: 722, BrandName: "Dicloxacillin", GenericName: "Dicloxacillin" },
  { id: 723, BrandName: "Dicyclomine", GenericName: "Dicyclomine" },
  { id: 724, BrandName: "Didanosine", GenericName: "Didanosine" },
  { id: 725, BrandName: "Didrex", GenericName: "Didrex" },
  { id: 726, BrandName: "Didronel", GenericName: "Didronel" },
  { id: 727, BrandName: "Dienestrol", GenericName: "Dienestrol" },
  { id: 728, BrandName: "Diethylpropion", GenericName: "Diethylpropion" },
  { id: 729, BrandName: "Difenoxin", GenericName: "Difenoxin" },
  { id: 730, BrandName: "Differin", GenericName: "Differin" },
  { id: 731, BrandName: "Diflucan", GenericName: "Diflucan" },
  { id: 732, BrandName: "Diflunisal", GenericName: "Diflunisal" },
  { id: 733, BrandName: "Digibind", GenericName: "Digibind" },
  { id: 734, BrandName: "Digitek", GenericName: "Digitek" },
  {
    id: 735,
    BrandName: "Digoxin Immune Fab",
    GenericName: "Digoxin Immune Fab",
  },
  { id: 736, BrandName: "Dihydroergotamine", GenericName: "Dihydroergotamine" },
  {
    id: 737,
    BrandName: "Dihydrotachysterol",
    GenericName: "Dihydrotachysterol",
  },
  { id: 738, BrandName: "Dilacor Xr", GenericName: "Dilacor Xr" },
  { id: 739, BrandName: "Dilantin", GenericName: "Dilantin" },
  { id: 740, BrandName: "Dilantin Infatabs", GenericName: "Dilantin Infatabs" },
  { id: 741, BrandName: "Dilaudid", GenericName: "Dilaudid" },
  { id: 742, BrandName: "Dilaudid-Hp", GenericName: "Dilaudid-Hp" },
  { id: 743, BrandName: "Diltiazem", GenericName: "Diltiazem" },
  { id: 744, BrandName: "Diltiazem Hcl", GenericName: "Diltiazem Hcl" },
  { id: 745, BrandName: "Dimetane", GenericName: "Dimetane" },
  { id: 746, BrandName: "Dinoprostone", GenericName: "Dinoprostone" },
  { id: 747, BrandName: "Diovan", GenericName: "Diovan" },
  { id: 748, BrandName: "Diovan Hct", GenericName: "Diovan Hct" },
  { id: 749, BrandName: "Dipentum", GenericName: "Dipentum" },
  { id: 750, BrandName: "Diphenhydramine", GenericName: "Diphenhydramine" },
  {
    id: 751,
    BrandName: "Diphtheria-Tetanus Vaccine",
    GenericName: "Diphtheria-Tetanus Vaccine",
  },
  { id: 752, BrandName: "Dipivefrin", GenericName: "Dipivefrin" },
  { id: 753, BrandName: "Diprivan", GenericName: "Diprivan" },
  { id: 754, BrandName: "Diprolene Af", GenericName: "Diprolene Af" },
  { id: 755, BrandName: "Dipyridamole", GenericName: "Dipyridamole" },
  { id: 756, BrandName: "Disalcid", GenericName: "Disalcid" },
  {
    id: 757,
    BrandName: "Disopyramide Phosphate",
    GenericName: "Disopyramide Phosphate",
  },
  { id: 758, BrandName: "Disulfiram", GenericName: "Disulfiram" },
  { id: 759, BrandName: "Ditropan", GenericName: "Ditropan" },
  { id: 760, BrandName: "Ditropan Xl", GenericName: "Ditropan Xl" },
  { id: 761, BrandName: "Diucardin", GenericName: "Diucardin" },
  { id: 762, BrandName: "Diuril", GenericName: "Diuril" },
  { id: 763, BrandName: "Divalproex Sodium", GenericName: "Divalproex Sodium" },
  { id: 764, BrandName: "Dmso", GenericName: "Dmso" },
  { id: 765, BrandName: "Dobutamine", GenericName: "Dobutamine" },
  { id: 766, BrandName: "Dofetilide", GenericName: "Dofetilide" },
  { id: 767, BrandName: "Dolasetron", GenericName: "Dolasetron" },
  { id: 768, BrandName: "Dolobid", GenericName: "Dolobid" },
  { id: 769, BrandName: "Dolophine", GenericName: "Dolophine" },
  {
    id: 770,
    BrandName: "Donepezil Hydrochloride",
    GenericName: "Donepezil Hydrochloride",
  },
  { id: 771, BrandName: "Dopamine", GenericName: "Dopamine" },
  {
    id: 772,
    BrandName: "Dopamine Hydrochloride",
    GenericName: "Dopamine Hydrochloride",
  },
  { id: 773, BrandName: "Dopar", GenericName: "Dopar" },
  { id: 774, BrandName: "Dopram", GenericName: "Dopram" },
  { id: 775, BrandName: "Doral", GenericName: "Doral" },
  { id: 776, BrandName: "Doribax", GenericName: "Doribax" },
  { id: 777, BrandName: "Dornase Alfa", GenericName: "Dornase Alfa" },
  { id: 778, BrandName: "Doryx", GenericName: "Doryx" },
  { id: 779, BrandName: "Dorzolamide", GenericName: "Dorzolamide" },
  { id: 780, BrandName: "Dostinex", GenericName: "Dostinex" },
  { id: 781, BrandName: "Dovonex", GenericName: "Dovonex" },
  {
    id: 782,
    BrandName: "Doxacurium Chloride",
    GenericName: "Doxacurium Chloride",
  },
  { id: 783, BrandName: "Doxapram", GenericName: "Doxapram" },
  {
    id: 784,
    BrandName: "Doxazosin Mesylate",
    GenericName: "Doxazosin Mesylate",
  },
  { id: 785, BrandName: "Doxepin", GenericName: "Doxepin" },
  { id: 786, BrandName: "Doxercalciferol", GenericName: "Doxercalciferol" },
  { id: 787, BrandName: "Doxil", GenericName: "Doxil" },
  {
    id: 788,
    BrandName: "Doxorubicin Hydrochloride",
    GenericName: "Doxorubicin Hydrochloride",
  },
  { id: 789, BrandName: "Doxycycline", GenericName: "Doxycycline" },
  {
    id: 790,
    BrandName: "Doxycycline Hyclate",
    GenericName: "Doxycycline Hyclate",
  },
  { id: 791, BrandName: "Drisdol", GenericName: "Drisdol" },
  { id: 792, BrandName: "Dronabinol", GenericName: "Dronabinol" },
  { id: 793, BrandName: "Dronedarone", GenericName: "Dronedarone" },
  { id: 794, BrandName: "Droperidol", GenericName: "Droperidol" },
  {
    id: 795,
    BrandName: "Drospirenone And Estradiol",
    GenericName: "Drospirenone And Estradiol",
  },
  { id: 796, BrandName: "Drotrecogin Alfa", GenericName: "Drotrecogin Alfa" },
  { id: 797, BrandName: "Droxia", GenericName: "Droxia" },
  { id: 798, BrandName: "Dtic-Dome", GenericName: "Dtic-Dome" },
  { id: 799, BrandName: "Dtp", GenericName: "Dtp" },
  { id: 800, BrandName: "Duetact", GenericName: "Duetact" },
  { id: 801, BrandName: "Dulera", GenericName: "Dulera" },
  { id: 802, BrandName: "Duloxetine Hcl", GenericName: "Duloxetine Hcl" },
  { id: 803, BrandName: "Duoneb", GenericName: "Duoneb" },
  { id: 804, BrandName: "Duraclon", GenericName: "Duraclon" },
  { id: 805, BrandName: "Duragesic", GenericName: "Duragesic" },
  { id: 806, BrandName: "Duramorph", GenericName: "Duramorph" },
  { id: 807, BrandName: "Duranest", GenericName: "Duranest" },
  { id: 808, BrandName: "Durezol", GenericName: "Durezol" },
  { id: 809, BrandName: "Duricef", GenericName: "Duricef" },
  { id: 810, BrandName: "Dutasteride", GenericName: "Dutasteride" },
  { id: 811, BrandName: "Dyazide", GenericName: "Dyazide" },
  { id: 812, BrandName: "Dynacirc", GenericName: "Dynacirc" },
  { id: 813, BrandName: "Dynacirc Cr", GenericName: "Dynacirc Cr" },
  { id: 814, BrandName: "Dynapen", GenericName: "Dynapen" },
  { id: 815, BrandName: "Dyphylline", GenericName: "Dyphylline" },
  { id: 816, BrandName: "Dyrenium", GenericName: "Dyrenium" },
  { id: 817, BrandName: "Dysport", GenericName: "Dysport" },
  { id: 818, BrandName: "Econazole Nitrate", GenericName: "Econazole Nitrate" },
  { id: 819, BrandName: "Eculizumab", GenericName: "Eculizumab" },
  { id: 820, BrandName: "Edecrin", GenericName: "Edecrin" },
  { id: 821, BrandName: "Edetate Calcium", GenericName: "Edetate Calcium" },
  { id: 822, BrandName: "Edex", GenericName: "Edex" },
  { id: 823, BrandName: "Edluar", GenericName: "Edluar" },
  { id: 824, BrandName: "Edrophonium", GenericName: "Edrophonium" },
  { id: 825, BrandName: "Efalizumab", GenericName: "Efalizumab" },
  { id: 826, BrandName: "Efavirenz", GenericName: "Efavirenz" },
  { id: 827, BrandName: "Effexor", GenericName: "Effexor" },
  { id: 828, BrandName: "Effexor Xr", GenericName: "Effexor Xr" },
  { id: 829, BrandName: "Effient", GenericName: "Effient" },
  { id: 830, BrandName: "Eflornithine", GenericName: "Eflornithine" },
  { id: 831, BrandName: "Efudex", GenericName: "Efudex" },
  { id: 832, BrandName: "Elaprase", GenericName: "Elaprase" },
  { id: 833, BrandName: "Elavil", GenericName: "Elavil" },
  { id: 834, BrandName: "Eldepryl", GenericName: "Eldepryl" },
  { id: 835, BrandName: "Elestat", GenericName: "Elestat" },
  {
    id: 836,
    BrandName: "Eletriptan Hydrobromide",
    GenericName: "Eletriptan Hydrobromide",
  },
  { id: 837, BrandName: "Elidel", GenericName: "Elidel" },
  { id: 838, BrandName: "Eligard", GenericName: "Eligard" },
  { id: 839, BrandName: "Elimite", GenericName: "Elimite" },
  { id: 840, BrandName: "Elitek", GenericName: "Elitek" },
  { id: 841, BrandName: "Ellence", GenericName: "Ellence" },
  { id: 842, BrandName: "Elmiron", GenericName: "Elmiron" },
  { id: 843, BrandName: "Elocon", GenericName: "Elocon" },
  { id: 844, BrandName: "Eloxatin", GenericName: "Eloxatin" },
  { id: 845, BrandName: "Elspar", GenericName: "Elspar" },
  { id: 846, BrandName: "Eltrombopag", GenericName: "Eltrombopag" },
  { id: 847, BrandName: "Emadine", GenericName: "Emadine" },
  { id: 848, BrandName: "Embeda", GenericName: "Embeda" },
  { id: 849, BrandName: "Emcyt", GenericName: "Emcyt" },
  { id: 850, BrandName: "Emedastine", GenericName: "Emedastine" },
  { id: 851, BrandName: "Emend", GenericName: "Emend" },
  { id: 852, BrandName: "Emgel", GenericName: "Emgel" },
  { id: 853, BrandName: "Emla", GenericName: "Emla" },
  { id: 854, BrandName: "Empirin", GenericName: "Empirin" },
  { id: 855, BrandName: "Emsam", GenericName: "Emsam" },
  { id: 856, BrandName: "Emtricitabine", GenericName: "Emtricitabine" },
  { id: 857, BrandName: "Emtriva", GenericName: "Emtriva" },
  { id: 858, BrandName: "Enablex", GenericName: "Enablex" },
  { id: 859, BrandName: "Enalapril", GenericName: "Enalapril" },
  { id: 860, BrandName: "Enbrel", GenericName: "Enbrel" },
  { id: 861, BrandName: "Endocet", GenericName: "Endocet" },
  { id: 862, BrandName: "Endometrin", GenericName: "Endometrin" },
  { id: 863, BrandName: "Endrate", GenericName: "Endrate" },
  { id: 864, BrandName: "Enflurane", GenericName: "Enflurane" },
  { id: 865, BrandName: "Enfuvirtide", GenericName: "Enfuvirtide" },
  { id: 866, BrandName: "Engerix-B", GenericName: "Engerix-B" },
  { id: 867, BrandName: "Enjuvia", GenericName: "Enjuvia" },
  { id: 868, BrandName: "Enlon", GenericName: "Enlon" },
  { id: 869, BrandName: "Enoxacin", GenericName: "Enoxacin" },
  { id: 870, BrandName: "Enoxaparin", GenericName: "Enoxaparin" },
  { id: 871, BrandName: "Entacapone", GenericName: "Entacapone" },
  { id: 872, BrandName: "Entecavir", GenericName: "Entecavir" },
  { id: 873, BrandName: "Entereg", GenericName: "Entereg" },
  { id: 874, BrandName: "Entex", GenericName: "Entex" },
  { id: 875, BrandName: "Entocort Ec", GenericName: "Entocort Ec" },
  { id: 876, BrandName: "Eovist", GenericName: "Eovist" },
  { id: 877, BrandName: "Ephedrine", GenericName: "Ephedrine" },
  { id: 878, BrandName: "Epiduo", GenericName: "Epiduo" },
  { id: 879, BrandName: "Epinastine Hcl", GenericName: "Epinastine Hcl" },
  { id: 880, BrandName: "Epinephrine", GenericName: "Epinephrine" },
  { id: 881, BrandName: "Epipen", GenericName: "Epipen" },
  {
    id: 882,
    BrandName: "Epirubicin Hydrochloride",
    GenericName: "Epirubicin Hydrochloride",
  },
  { id: 883, BrandName: "Epivir", GenericName: "Epivir" },
  { id: 884, BrandName: "Eplerenone", GenericName: "Eplerenone" },
  { id: 885, BrandName: "Epoetin Alfa", GenericName: "Epoetin Alfa" },
  { id: 886, BrandName: "Epogen", GenericName: "Epogen" },
  {
    id: 887,
    BrandName: "Epoprostenol Sodium",
    GenericName: "Epoprostenol Sodium",
  },
  {
    id: 888,
    BrandName: "Eprosartan Mesylate",
    GenericName: "Eprosartan Mesylate",
  },
  { id: 889, BrandName: "Eptifibatide", GenericName: "Eptifibatide" },
  { id: 890, BrandName: "Epzicom", GenericName: "Epzicom" },
  { id: 891, BrandName: "Equagesic", GenericName: "Equagesic" },
  { id: 892, BrandName: "Equetro", GenericName: "Equetro" },
  { id: 893, BrandName: "Eraxis", GenericName: "Eraxis" },
  { id: 894, BrandName: "Erbitux", GenericName: "Erbitux" },
  { id: 895, BrandName: "Ergocalciferol", GenericName: "Ergocalciferol" },
  { id: 896, BrandName: "Ergomar", GenericName: "Ergomar" },
  {
    id: 897,
    BrandName: "Ergotamine Tartrate And Caffeine",
    GenericName: "Ergotamine Tartrate And Caffeine",
  },
  { id: 898, BrandName: "Erlotinib", GenericName: "Erlotinib" },
  { id: 899, BrandName: "Ertaczo", GenericName: "Ertaczo" },
  { id: 900, BrandName: "Ertapenem", GenericName: "Ertapenem" },
  { id: 901, BrandName: "Ery-Tab", GenericName: "Ery-Tab" },
  { id: 902, BrandName: "Eryc", GenericName: "Eryc" },
  { id: 903, BrandName: "Erygel", GenericName: "Erygel" },
  { id: 904, BrandName: "Eryped", GenericName: "Eryped" },
  {
    id: 905,
    BrandName: "Erythrocin Stearate",
    GenericName: "Erythrocin Stearate",
  },
  { id: 906, BrandName: "Erythromycin", GenericName: "Erythromycin" },
  {
    id: 907,
    BrandName: "Erythromycin Ethylsuccinate",
    GenericName: "Erythromycin Ethylsuccinate",
  },
  {
    id: 908,
    BrandName: "Erythromycin Lactobionate",
    GenericName: "Erythromycin Lactobionate",
  },
  {
    id: 909,
    BrandName: "Escitalopram Oxalate",
    GenericName: "Escitalopram Oxalate",
  },
  { id: 910, BrandName: "Esclim", GenericName: "Esclim" },
  { id: 911, BrandName: "Eskalith", GenericName: "Eskalith" },
  { id: 912, BrandName: "Esmolol", GenericName: "Esmolol" },
  {
    id: 913,
    BrandName: "Esomeprazole Magnesium",
    GenericName: "Esomeprazole Magnesium",
  },
  {
    id: 914,
    BrandName: "Esomeprazole Sodium",
    GenericName: "Esomeprazole Sodium",
  },
  {
    id: 915,
    BrandName: "Essential Amino Acids",
    GenericName: "Essential Amino Acids",
  },
  { id: 916, BrandName: "Estazolam", GenericName: "Estazolam" },
  {
    id: 917,
    BrandName: "Esterified Estrogens",
    GenericName: "Esterified Estrogens",
  },
  { id: 918, BrandName: "Estrace", GenericName: "Estrace" },
  { id: 919, BrandName: "Estraderm", GenericName: "Estraderm" },
  { id: 920, BrandName: "Estradiol", GenericName: "Estradiol" },
  { id: 921, BrandName: "Estradiol Acetate", GenericName: "Estradiol Acetate" },
  {
    id: 922,
    BrandName: "Estradiol Valerate",
    GenericName: "Estradiol Valerate",
  },
  { id: 923, BrandName: "Estramustine", GenericName: "Estramustine" },
  { id: 924, BrandName: "Estratest", GenericName: "Estratest" },
  { id: 925, BrandName: "Estring", GenericName: "Estring" },
  { id: 926, BrandName: "Estrogel", GenericName: "Estrogel" },
  {
    id: 927,
    BrandName: "Estrogens Conjugated",
    GenericName: "Estrogens Conjugated",
  },
  { id: 928, BrandName: "Estropipate", GenericName: "Estropipate" },
  { id: 929, BrandName: "Estrostep 21", GenericName: "Estrostep 21" },
  { id: 930, BrandName: "Eszopiclone", GenericName: "Eszopiclone" },
  { id: 931, BrandName: "Etanercept", GenericName: "Etanercept" },
  { id: 932, BrandName: "Ethacrynic Acid", GenericName: "Ethacrynic Acid" },
  { id: 933, BrandName: "Ethambutol", GenericName: "Ethambutol" },
  { id: 934, BrandName: "Ethamolin", GenericName: "Ethamolin" },
  {
    id: 935,
    BrandName: "Ethanolamine Oleate",
    GenericName: "Ethanolamine Oleate",
  },
  { id: 936, BrandName: "Ethedent", GenericName: "Ethedent" },
  { id: 937, BrandName: "Ethinyl Estradiol", GenericName: "Ethinyl Estradiol" },
  { id: 938, BrandName: "Ethiodized Oil", GenericName: "Ethiodized Oil" },
  { id: 939, BrandName: "Ethiodol", GenericName: "Ethiodol" },
  { id: 940, BrandName: "Ethionamide", GenericName: "Ethionamide" },
  { id: 941, BrandName: "Ethosuximide", GenericName: "Ethosuximide" },
  { id: 942, BrandName: "Ethotoin", GenericName: "Ethotoin" },
  { id: 943, BrandName: "Ethrane", GenericName: "Ethrane" },
  { id: 944, BrandName: "Ethyl Chloride", GenericName: "Ethyl Chloride" },
  { id: 945, BrandName: "Ethyol", GenericName: "Ethyol" },
  { id: 946, BrandName: "Etidocaine Hcl", GenericName: "Etidocaine Hcl" },
  {
    id: 947,
    BrandName: "Etidronate Disodium",
    GenericName: "Etidronate Disodium",
  },
  { id: 948, BrandName: "Etodolac", GenericName: "Etodolac" },
  { id: 949, BrandName: "Etonogestrel", GenericName: "Etonogestrel" },
  { id: 950, BrandName: "Etopophos", GenericName: "Etopophos" },
  { id: 951, BrandName: "Etoposide", GenericName: "Etoposide" },
  {
    id: 952,
    BrandName: "Etoposide Phosphate",
    GenericName: "Etoposide Phosphate",
  },
  { id: 953, BrandName: "Etrafon", GenericName: "Etrafon" },
  { id: 954, BrandName: "Eulexin", GenericName: "Eulexin" },
  { id: 955, BrandName: "Eurax", GenericName: "Eurax" },
  { id: 956, BrandName: "Evamist", GenericName: "Evamist" },
  { id: 957, BrandName: "Everolimus", GenericName: "Everolimus" },
  { id: 958, BrandName: "Evista", GenericName: "Evista" },
  { id: 959, BrandName: "Evoclin", GenericName: "Evoclin" },
  { id: 960, BrandName: "Evoxac", GenericName: "Evoxac" },
  { id: 961, BrandName: "Exalgo", GenericName: "Exalgo" },
  { id: 962, BrandName: "Exelderm", GenericName: "Exelderm" },
  { id: 963, BrandName: "Exelon", GenericName: "Exelon" },
  { id: 964, BrandName: "Exemestane", GenericName: "Exemestane" },
  { id: 965, BrandName: "Exenatide", GenericName: "Exenatide" },
  { id: 966, BrandName: "Exforge", GenericName: "Exforge" },
  { id: 967, BrandName: "Exforge Hct", GenericName: "Exforge Hct" },
  { id: 968, BrandName: "Exjade", GenericName: "Exjade" },
  { id: 969, BrandName: "Extavia", GenericName: "Extavia" },
  {
    id: 970,
    BrandName: "Extended Phenytoin Sodium",
    GenericName: "Extended Phenytoin Sodium",
  },
  { id: 971, BrandName: "Extina", GenericName: "Extina" },
  { id: 972, BrandName: "Extraneal", GenericName: "Extraneal" },
  { id: 973, BrandName: "Exubera", GenericName: "Exubera" },
  { id: 974, BrandName: "Ezetimibe", GenericName: "Ezetimibe" },
  {
    id: 975,
    BrandName: "Ezetimibe And Simvastatin",
    GenericName: "Ezetimibe And Simvastatin",
  },
  { id: 976, BrandName: "Fabrazyme", GenericName: "Fabrazyme" },
  { id: 977, BrandName: "Factive", GenericName: "Factive" },
  { id: 978, BrandName: "Factor Ix Complex", GenericName: "Factor Ix Complex" },
  { id: 979, BrandName: "Factrel", GenericName: "Factrel" },
  { id: 980, BrandName: "Famciclovir", GenericName: "Famciclovir" },
  { id: 981, BrandName: "Famotidine", GenericName: "Famotidine" },
  {
    id: 982,
    BrandName: "Famotidine Injection",
    GenericName: "Famotidine Injection",
  },
  { id: 983, BrandName: "Famvir", GenericName: "Famvir" },
  { id: 984, BrandName: "Fanapt", GenericName: "Fanapt" },
  { id: 985, BrandName: "Fansidar", GenericName: "Fansidar" },
  { id: 986, BrandName: "Fareston", GenericName: "Fareston" },
  { id: 987, BrandName: "Faslodex", GenericName: "Faslodex" },
  { id: 988, BrandName: "Fastin", GenericName: "Fastin" },
  { id: 989, BrandName: "Fazaclo", GenericName: "Fazaclo" },
  { id: 990, BrandName: "Febuxostat", GenericName: "Febuxostat" },
  { id: 991, BrandName: "Feiba-Vh", GenericName: "Feiba-Vh" },
  { id: 992, BrandName: "Felbamate", GenericName: "Felbamate" },
  { id: 993, BrandName: "Felbatol", GenericName: "Felbatol" },
  { id: 994, BrandName: "Feldene", GenericName: "Feldene" },
  { id: 995, BrandName: "Felodipine", GenericName: "Felodipine" },
  { id: 996, BrandName: "Femara", GenericName: "Femara" },
  { id: 997, BrandName: "Femcon Fe", GenericName: "Femcon Fe" },
  { id: 998, BrandName: "Femhrt", GenericName: "Femhrt" },
  { id: 999, BrandName: "Femring", GenericName: "Femring" },
  { id: 1000, BrandName: "Fenofibrate", GenericName: "Fenofibrate" },
  { id: 1001, BrandName: "Fenofibric Acid", GenericName: "Fenofibric Acid" },
  { id: 1002, BrandName: "Fenoglide", GenericName: "Fenoglide" },
  {
    id: 1003,
    BrandName: "Fenoldopam Mesylate",
    GenericName: "Fenoldopam Mesylate",
  },
  {
    id: 1004,
    BrandName: "Fenoprofen Calcium",
    GenericName: "Fenoprofen Calcium",
  },
  { id: 1005, BrandName: "Fentanyl Buccal", GenericName: "Fentanyl Buccal" },
  { id: 1006, BrandName: "Fentanyl Citrate", GenericName: "Fentanyl Citrate" },
  { id: 1007, BrandName: "Fentora", GenericName: "Fentora" },
  { id: 1008, BrandName: "Feraheme", GenericName: "Feraheme" },
  { id: 1009, BrandName: "Feridex I.V.", GenericName: "Feridex I.V." },
  { id: 1010, BrandName: "Ferrlecit", GenericName: "Ferrlecit" },
  { id: 1011, BrandName: "Fertinex", GenericName: "Fertinex" },
  { id: 1012, BrandName: "Ferumoxides", GenericName: "Ferumoxides" },
  { id: 1013, BrandName: "Ferumoxytol", GenericName: "Ferumoxytol" },
  {
    id: 1014,
    BrandName: "Fesoterodine Fumarate Extended",
    GenericName: "Fesoterodine Fumarate Extended",
  },
  { id: 1015, BrandName: "Fexofenadine Hcl", GenericName: "Fexofenadine Hcl" },
  { id: 1016, BrandName: "Filgrastim", GenericName: "Filgrastim" },
  { id: 1017, BrandName: "Finacea", GenericName: "Finacea" },
  { id: 1018, BrandName: "Finasteride", GenericName: "Finasteride" },
  { id: 1019, BrandName: "Fioricet", GenericName: "Fioricet" },
  {
    id: 1020,
    BrandName: "Fiorinal With Codeine",
    GenericName: "Fiorinal With Codeine",
  },
  { id: 1021, BrandName: "Flagyl", GenericName: "Flagyl" },
  { id: 1022, BrandName: "Flavoxate Hcl", GenericName: "Flavoxate Hcl" },
  { id: 1023, BrandName: "Flecainide", GenericName: "Flecainide" },
  { id: 1024, BrandName: "Flector", GenericName: "Flector" },
  { id: 1025, BrandName: "Flexeril", GenericName: "Flexeril" },
  { id: 1026, BrandName: "Flo-Pred", GenericName: "Flo-Pred" },
  { id: 1027, BrandName: "Flolan", GenericName: "Flolan" },
  { id: 1028, BrandName: "Flomax", GenericName: "Flomax" },
  { id: 1029, BrandName: "Flonase", GenericName: "Flonase" },
  { id: 1030, BrandName: "Florinef", GenericName: "Florinef" },
  { id: 1031, BrandName: "Flovent", GenericName: "Flovent" },
  { id: 1032, BrandName: "Flovent Diskus", GenericName: "Flovent Diskus" },
  { id: 1033, BrandName: "Flovent Hfa", GenericName: "Flovent Hfa" },
  { id: 1034, BrandName: "Floxin", GenericName: "Floxin" },
  { id: 1035, BrandName: "Floxin Otic", GenericName: "Floxin Otic" },
  { id: 1036, BrandName: "Floxuridine", GenericName: "Floxuridine" },
  { id: 1037, BrandName: "Fluconazole", GenericName: "Fluconazole" },
  { id: 1038, BrandName: "Flucytosine", GenericName: "Flucytosine" },
  { id: 1039, BrandName: "Fludara", GenericName: "Fludara" },
  { id: 1040, BrandName: "Fludarabine", GenericName: "Fludarabine" },
  {
    id: 1041,
    BrandName: "Fludarabine Phosphate",
    GenericName: "Fludarabine Phosphate",
  },
  {
    id: 1042,
    BrandName: "Fludeoxyglucose F 18",
    GenericName: "Fludeoxyglucose F 18",
  },
  { id: 1043, BrandName: "Fludrocortisone", GenericName: "Fludrocortisone" },
  { id: 1044, BrandName: "Flumadine", GenericName: "Flumadine" },
  { id: 1045, BrandName: "Flumazenil", GenericName: "Flumazenil" },
  { id: 1046, BrandName: "Flumist", GenericName: "Flumist" },
  { id: 1047, BrandName: "Flunisolide", GenericName: "Flunisolide" },
  {
    id: 1048,
    BrandName: "Fluocinolone Acetonide",
    GenericName: "Fluocinolone Acetonide",
  },
  { id: 1049, BrandName: "Fluocinonide", GenericName: "Fluocinonide" },
  { id: 1050, BrandName: "Fluorescein", GenericName: "Fluorescein" },
  { id: 1051, BrandName: "Fluorescite", GenericName: "Fluorescite" },
  { id: 1052, BrandName: "Fluoride", GenericName: "Fluoride" },
  { id: 1053, BrandName: "Fluorometholone", GenericName: "Fluorometholone" },
  { id: 1054, BrandName: "Fluoroplex", GenericName: "Fluoroplex" },
  { id: 1055, BrandName: "Fluorouracil", GenericName: "Fluorouracil" },
  { id: 1056, BrandName: "Fluothane", GenericName: "Fluothane" },
  { id: 1057, BrandName: "Fluoxetine Hcl", GenericName: "Fluoxetine Hcl" },
  {
    id: 1058,
    BrandName: "Fluoxetine Hydrochloride",
    GenericName: "Fluoxetine Hydrochloride",
  },
  { id: 1059, BrandName: "Fluoxymesterone", GenericName: "Fluoxymesterone" },
  { id: 1060, BrandName: "Fluphenazine", GenericName: "Fluphenazine" },
  { id: 1061, BrandName: "Flurandrenolide", GenericName: "Flurandrenolide" },
  { id: 1062, BrandName: "Flurazepam", GenericName: "Flurazepam" },
  { id: 1063, BrandName: "Flurbiprofen", GenericName: "Flurbiprofen" },
  { id: 1064, BrandName: "Fluress", GenericName: "Fluress" },
  { id: 1065, BrandName: "Flutamide", GenericName: "Flutamide" },
  {
    id: 1066,
    BrandName: "Fluticasone Furoate",
    GenericName: "Fluticasone Furoate",
  },
  {
    id: 1067,
    BrandName: "Fluticasone Propionate",
    GenericName: "Fluticasone Propionate",
  },
  {
    id: 1068,
    BrandName: "Fluvastatin Sodium",
    GenericName: "Fluvastatin Sodium",
  },
  { id: 1069, BrandName: "Fluvirin", GenericName: "Fluvirin" },
  {
    id: 1070,
    BrandName: "Fluvoxamine Maleate",
    GenericName: "Fluvoxamine Maleate",
  },
  { id: 1071, BrandName: "Fml", GenericName: "Fml" },
  { id: 1072, BrandName: "Fml Forte", GenericName: "Fml Forte" },
  { id: 1073, BrandName: "Focalin", GenericName: "Focalin" },
  { id: 1074, BrandName: "Focalin Xr", GenericName: "Focalin Xr" },
  { id: 1075, BrandName: "Folic Acid", GenericName: "Folic Acid" },
  { id: 1076, BrandName: "Follistim Aq", GenericName: "Follistim Aq" },
  { id: 1077, BrandName: "Follitropin Alfa", GenericName: "Follitropin Alfa" },
  { id: 1078, BrandName: "Follitropin Beta", GenericName: "Follitropin Beta" },
  { id: 1079, BrandName: "Folotyn", GenericName: "Folotyn" },
  { id: 1080, BrandName: "Foltx", GenericName: "Foltx" },
  { id: 1081, BrandName: "Folvite", GenericName: "Folvite" },
  { id: 1082, BrandName: "Fomepizole", GenericName: "Fomepizole" },
  { id: 1083, BrandName: "Fomivirsen", GenericName: "Fomivirsen" },
  {
    id: 1084,
    BrandName: "Fondaparinux Sodium",
    GenericName: "Fondaparinux Sodium",
  },
  {
    id: 1085,
    BrandName: "Foradil Aerolizer",
    GenericName: "Foradil Aerolizer",
  },
  {
    id: 1086,
    BrandName: "Foradil Certihaler",
    GenericName: "Foradil Certihaler",
  },
  { id: 1087, BrandName: "Forane", GenericName: "Forane" },
  {
    id: 1088,
    BrandName: "Formoterol Fumarate Inhalation Powder",
    GenericName: "Formoterol Fumarate Inhalation Powder",
  },
  { id: 1089, BrandName: "Fortamet", GenericName: "Fortamet" },
  { id: 1090, BrandName: "Fortaz", GenericName: "Fortaz" },
  { id: 1091, BrandName: "Forteo", GenericName: "Forteo" },
  { id: 1092, BrandName: "Fortical", GenericName: "Fortical" },
  { id: 1093, BrandName: "Fosamax", GenericName: "Fosamax" },
  { id: 1094, BrandName: "Fosamax Plus D", GenericName: "Fosamax Plus D" },
  {
    id: 1095,
    BrandName: "Fosamprenavir Calcium",
    GenericName: "Fosamprenavir Calcium",
  },
  {
    id: 1096,
    BrandName: "Fosaprepitant Dimeglumine",
    GenericName: "Fosaprepitant Dimeglumine",
  },
  { id: 1097, BrandName: "Foscarnet Sodium", GenericName: "Foscarnet Sodium" },
  { id: 1098, BrandName: "Foscavir", GenericName: "Foscavir" },
  { id: 1099, BrandName: "Fosfomycin", GenericName: "Fosfomycin" },
  {
    id: 1100,
    BrandName: "Fosinopril Sodium",
    GenericName: "Fosinopril Sodium",
  },
  {
    id: 1101,
    BrandName: "Fosphenytoin Sodium",
    GenericName: "Fosphenytoin Sodium",
  },
  {
    id: 1102,
    BrandName: "Fospropofol Disodium",
    GenericName: "Fospropofol Disodium",
  },
  { id: 1103, BrandName: "Fosrenol", GenericName: "Fosrenol" },
  { id: 1104, BrandName: "Fragmin", GenericName: "Fragmin" },
  { id: 1105, BrandName: "Frova", GenericName: "Frova" },
  {
    id: 1106,
    BrandName: "Frovatriptan Succinate",
    GenericName: "Frovatriptan Succinate",
  },
  { id: 1107, BrandName: "Fulvestrant", GenericName: "Fulvestrant" },
  { id: 1108, BrandName: "Fungizone", GenericName: "Fungizone" },
  { id: 1109, BrandName: "Furadantin", GenericName: "Furadantin" },
  { id: 1110, BrandName: "Furazolidone", GenericName: "Furazolidone" },
  { id: 1111, BrandName: "Furosemide", GenericName: "Furosemide" },
  { id: 1112, BrandName: "Furoxone", GenericName: "Furoxone" },
  { id: 1113, BrandName: "Fuzeon", GenericName: "Fuzeon" },
  { id: 1114, BrandName: "Gabapentin", GenericName: "Gabapentin" },
  { id: 1115, BrandName: "Gabitril", GenericName: "Gabitril" },
  {
    id: 1116,
    BrandName: "Gadobenate Dimeglumine",
    GenericName: "Gadobenate Dimeglumine",
  },
  { id: 1117, BrandName: "Gadodiamide", GenericName: "Gadodiamide" },
  {
    id: 1118,
    BrandName: "Gadofosveset Trisodium",
    GenericName: "Gadofosveset Trisodium",
  },
  {
    id: 1119,
    BrandName: "Gadopentetate Dimeglumine",
    GenericName: "Gadopentetate Dimeglumine",
  },
  { id: 1120, BrandName: "Gadoteridol", GenericName: "Gadoteridol" },
  { id: 1121, BrandName: "Gadoversetamide", GenericName: "Gadoversetamide" },
  {
    id: 1122,
    BrandName: "Gadoxetate Disodium",
    GenericName: "Gadoxetate Disodium",
  },
  { id: 1123, BrandName: "Galantamine", GenericName: "Galantamine" },
  { id: 1124, BrandName: "Galsulfase", GenericName: "Galsulfase" },
  { id: 1125, BrandName: "Gammagard", GenericName: "Gammagard" },
  { id: 1126, BrandName: "Gamunex", GenericName: "Gamunex" },
  { id: 1127, BrandName: "Ganciclovir", GenericName: "Ganciclovir" },
  { id: 1128, BrandName: "Ganirelix", GenericName: "Ganirelix" },
  {
    id: 1129,
    BrandName: "Ganirelix Acetate Injection",
    GenericName: "Ganirelix Acetate Injection",
  },
  { id: 1130, BrandName: "Gantanol", GenericName: "Gantanol" },
  { id: 1131, BrandName: "Gantrisin", GenericName: "Gantrisin" },
  { id: 1132, BrandName: "Gardasil", GenericName: "Gardasil" },
  { id: 1133, BrandName: "Gatifloxacin", GenericName: "Gatifloxacin" },
  { id: 1134, BrandName: "Gelnique", GenericName: "Gelnique" },
  { id: 1135, BrandName: "Gemcitabine Hcl", GenericName: "Gemcitabine Hcl" },
  { id: 1136, BrandName: "Gemfibrozil", GenericName: "Gemfibrozil" },
  {
    id: 1137,
    BrandName: "Gemifloxacin Mesylate",
    GenericName: "Gemifloxacin Mesylate",
  },
  {
    id: 1138,
    BrandName: "Gemtuzumab Ozogamicin",
    GenericName: "Gemtuzumab Ozogamicin",
  },
  { id: 1139, BrandName: "Gemzar", GenericName: "Gemzar" },
  { id: 1140, BrandName: "Gengraf", GenericName: "Gengraf" },
  { id: 1141, BrandName: "Genoptic", GenericName: "Genoptic" },
  { id: 1142, BrandName: "Genotropin", GenericName: "Genotropin" },
  { id: 1143, BrandName: "Gentak", GenericName: "Gentak" },
  { id: 1144, BrandName: "Gentamicin", GenericName: "Gentamicin" },
  { id: 1145, BrandName: "Geocillin", GenericName: "Geocillin" },
  { id: 1146, BrandName: "Geodon", GenericName: "Geodon" },
  {
    id: 1147,
    BrandName: "Glatiramer Acetate",
    GenericName: "Glatiramer Acetate",
  },
  { id: 1148, BrandName: "Gleevec", GenericName: "Gleevec" },
  { id: 1149, BrandName: "Gliadel", GenericName: "Gliadel" },
  { id: 1150, BrandName: "Glimepiride", GenericName: "Glimepiride" },
  { id: 1151, BrandName: "Glipizide", GenericName: "Glipizide" },
  { id: 1152, BrandName: "Glucagen", GenericName: "Glucagen" },
  { id: 1153, BrandName: "Glucagon", GenericName: "Glucagon" },
  { id: 1154, BrandName: "Glucophage Xr", GenericName: "Glucophage Xr" },
  { id: 1155, BrandName: "Glucotrol", GenericName: "Glucotrol" },
  { id: 1156, BrandName: "Glucotrol Xl", GenericName: "Glucotrol Xl" },
  { id: 1157, BrandName: "Glucovance", GenericName: "Glucovance" },
  { id: 1158, BrandName: "Glumetza", GenericName: "Glumetza" },
  { id: 1159, BrandName: "Glyburide", GenericName: "Glyburide" },
  { id: 1160, BrandName: "Glycopyrrolate", GenericName: "Glycopyrrolate" },
  { id: 1161, BrandName: "Glynase", GenericName: "Glynase" },
  { id: 1162, BrandName: "Glyset", GenericName: "Glyset" },
  {
    id: 1163,
    BrandName: "Gold Sodium Thiomalate",
    GenericName: "Gold Sodium Thiomalate",
  },
  { id: 1164, BrandName: "Golimumab", GenericName: "Golimumab" },
  { id: 1165, BrandName: "Gonadorelin", GenericName: "Gonadorelin" },
  { id: 1166, BrandName: "Gonal-F", GenericName: "Gonal-F" },
  { id: 1167, BrandName: "Gonal-F Rff", GenericName: "Gonal-F Rff" },
  {
    id: 1168,
    BrandName: "Goserelin Acetate",
    GenericName: "Goserelin Acetate",
  },
  {
    id: 1169,
    BrandName: "Goserelin Acetate Implant",
    GenericName: "Goserelin Acetate Implant",
  },
  { id: 1170, BrandName: "Granisetron", GenericName: "Granisetron" },
  {
    id: 1171,
    BrandName: "Granisetron Hydrochloride",
    GenericName: "Granisetron Hydrochloride",
  },
  { id: 1172, BrandName: "Grepafloxacin", GenericName: "Grepafloxacin" },
  { id: 1173, BrandName: "Grifulvin V", GenericName: "Grifulvin V" },
  { id: 1174, BrandName: "Gris-Peg", GenericName: "Gris-Peg" },
  { id: 1175, BrandName: "Griseofulvin", GenericName: "Griseofulvin" },
  { id: 1176, BrandName: "Guaifenesin", GenericName: "Guaifenesin" },
  {
    id: 1177,
    BrandName: "Guanethidine Monosulfate",
    GenericName: "Guanethidine Monosulfate",
  },
  { id: 1178, BrandName: "Guanfacine", GenericName: "Guanfacine" },
  {
    id: 1179,
    BrandName: "Guanfacine Hydrochloride",
    GenericName: "Guanfacine Hydrochloride",
  },
  { id: 1180, BrandName: "Gynazole", GenericName: "Gynazole" },
  { id: 1181, BrandName: "Gyne-Lotrimin", GenericName: "Gyne-Lotrimin" },
  {
    id: 1182,
    BrandName: "Haemophilus B Conjugate Vaccine",
    GenericName: "Haemophilus B Conjugate Vaccine",
  },
  { id: 1183, BrandName: "Halcinonide", GenericName: "Halcinonide" },
  { id: 1184, BrandName: "Halcion", GenericName: "Halcion" },
  { id: 1185, BrandName: "Haldol", GenericName: "Haldol" },
  { id: 1186, BrandName: "Haldol Decanoate", GenericName: "Haldol Decanoate" },
  {
    id: 1187,
    BrandName: "Halflytely And Bisacodyl",
    GenericName: "Halflytely And Bisacodyl",
  },
  {
    id: 1188,
    BrandName: "Halobetasol Propionate",
    GenericName: "Halobetasol Propionate",
  },
  { id: 1189, BrandName: "Halog", GenericName: "Halog" },
  { id: 1190, BrandName: "Haloperidol", GenericName: "Haloperidol" },
  {
    id: 1191,
    BrandName: "Haloperidol Decanoate",
    GenericName: "Haloperidol Decanoate",
  },
  { id: 1192, BrandName: "Halotestin", GenericName: "Halotestin" },
  { id: 1193, BrandName: "Halothane", GenericName: "Halothane" },
  { id: 1194, BrandName: "Havrix", GenericName: "Havrix" },
  { id: 1195, BrandName: "Healon", GenericName: "Healon" },
  { id: 1196, BrandName: "Hectorol", GenericName: "Hectorol" },
  { id: 1197, BrandName: "Helidac", GenericName: "Helidac" },
  { id: 1198, BrandName: "Hemabate", GenericName: "Hemabate" },
  { id: 1199, BrandName: "Hemin", GenericName: "Hemin" },
  { id: 1200, BrandName: "Hep-Lock U/P", GenericName: "Hep-Lock U/P" },
  { id: 1201, BrandName: "Hepagam B", GenericName: "Hepagam B" },
  { id: 1202, BrandName: "Heparin", GenericName: "Heparin" },
  {
    id: 1203,
    BrandName: "Heparin Lock Flush",
    GenericName: "Heparin Lock Flush",
  },
  { id: 1204, BrandName: "Heparin Sodium", GenericName: "Heparin Sodium" },
  { id: 1205, BrandName: "Hepatamine", GenericName: "Hepatamine" },
  {
    id: 1206,
    BrandName: "Hepatitis A Vaccine, Inactivated",
    GenericName: "Hepatitis A Vaccine, Inactivated",
  },
  {
    id: 1207,
    BrandName: "Hepatitis B Immune Globulin",
    GenericName: "Hepatitis B Immune Globulin",
  },
  {
    id: 1208,
    BrandName: "Hepatitis B Vaccine",
    GenericName: "Hepatitis B Vaccine",
  },
  {
    id: 1209,
    BrandName: "Hepatitis B Vaccine Recombinant",
    GenericName: "Hepatitis B Vaccine Recombinant",
  },
  { id: 1210, BrandName: "Hepflush-10", GenericName: "Hepflush-10" },
  { id: 1211, BrandName: "Hepsera", GenericName: "Hepsera" },
  { id: 1212, BrandName: "Herceptin", GenericName: "Herceptin" },
  { id: 1213, BrandName: "Hexachlorophene", GenericName: "Hexachlorophene" },
  { id: 1214, BrandName: "Hexalen", GenericName: "Hexalen" },
  { id: 1215, BrandName: "Hibtiter", GenericName: "Hibtiter" },
  { id: 1216, BrandName: "Hiprex", GenericName: "Hiprex" },
  { id: 1217, BrandName: "Hismanal", GenericName: "Hismanal" },
  { id: 1218, BrandName: "Hivid", GenericName: "Hivid" },
  { id: 1219, BrandName: "Hms", GenericName: "Hms" },
  { id: 1220, BrandName: "Humalog", GenericName: "Humalog" },
  { id: 1221, BrandName: "Human Secretin", GenericName: "Human Secretin" },
  { id: 1222, BrandName: "Humatrope", GenericName: "Humatrope" },
  { id: 1223, BrandName: "Humira", GenericName: "Humira" },
  { id: 1224, BrandName: "Humorsol", GenericName: "Humorsol" },
  { id: 1225, BrandName: "Humulin N", GenericName: "Humulin N" },
  { id: 1226, BrandName: "Humulin R", GenericName: "Humulin R" },
  { id: 1227, BrandName: "Hyalgan", GenericName: "Hyalgan" },
  { id: 1228, BrandName: "Hycamtin", GenericName: "Hycamtin" },
  { id: 1229, BrandName: "Hycodan", GenericName: "Hycodan" },
  { id: 1230, BrandName: "Hydralazine", GenericName: "Hydralazine" },
  { id: 1231, BrandName: "Hydrea", GenericName: "Hydrea" },
  {
    id: 1232,
    BrandName: "Hydrochlorothiazide And Triamterene",
    GenericName: "Hydrochlorothiazide And Triamterene",
  },
  {
    id: 1233,
    BrandName: "Hydrocodone And Ibuprofen",
    GenericName: "Hydrocodone And Ibuprofen",
  },
  {
    id: 1234,
    BrandName: "Hydrocodone Bitartrate And Acetaminophen",
    GenericName: "Hydrocodone Bitartrate And Acetaminophen",
  },
  {
    id: 1235,
    BrandName: "Hydrocodone Bitartrate And Acetaminophen Tablets",
    GenericName: "Hydrocodone Bitartrate And Acetaminophen Tablets",
  },
  { id: 1236, BrandName: "Hydrocortisone", GenericName: "Hydrocortisone" },
  {
    id: 1237,
    BrandName: "Hydrocortisone And Acetic Acid",
    GenericName: "Hydrocortisone And Acetic Acid",
  },
  {
    id: 1238,
    BrandName: "Hydrocortisone Butyrate",
    GenericName: "Hydrocortisone Butyrate",
  },
  {
    id: 1239,
    BrandName: "Hydrocortisone Sodium Succinate",
    GenericName: "Hydrocortisone Sodium Succinate",
  },
  {
    id: 1240,
    BrandName: "Hydroflumethiazide",
    GenericName: "Hydroflumethiazide",
  },
  {
    id: 1241,
    BrandName: "Hydromorphone Hydrochloride",
    GenericName: "Hydromorphone Hydrochloride",
  },
  { id: 1242, BrandName: "Hydroquinone", GenericName: "Hydroquinone" },
  {
    id: 1243,
    BrandName: "Hydroxychloroquine",
    GenericName: "Hydroxychloroquine",
  },
  {
    id: 1244,
    BrandName: "Hydroxyethyl Starch",
    GenericName: "Hydroxyethyl Starch",
  },
  { id: 1245, BrandName: "Hydroxyurea", GenericName: "Hydroxyurea" },
  { id: 1246, BrandName: "Hydroxyzine", GenericName: "Hydroxyzine" },
  {
    id: 1247,
    BrandName: "Hydroxyzine Hydrochloride",
    GenericName: "Hydroxyzine Hydrochloride",
  },
  { id: 1248, BrandName: "Hylan G-F 20", GenericName: "Hylan G-F 20" },
  { id: 1249, BrandName: "Hylenex", GenericName: "Hylenex" },
  { id: 1250, BrandName: "Hyoscyamine", GenericName: "Hyoscyamine" },
  {
    id: 1251,
    BrandName: "Hyoscyamine Sulfate",
    GenericName: "Hyoscyamine Sulfate",
  },
  { id: 1252, BrandName: "Hytrin", GenericName: "Hytrin" },
  { id: 1253, BrandName: "Hyzaar", GenericName: "Hyzaar" },
  {
    id: 1254,
    BrandName: "Ibandronate Sodium",
    GenericName: "Ibandronate Sodium",
  },
  {
    id: 1255,
    BrandName: "Ibritumomab Tiuxetan",
    GenericName: "Ibritumomab Tiuxetan",
  },
  { id: 1256, BrandName: "Ibuprofen", GenericName: "Ibuprofen" },
  { id: 1257, BrandName: "Ibuprofen Lysine", GenericName: "Ibuprofen Lysine" },
  {
    id: 1258,
    BrandName: "Ibutilide Fumarate",
    GenericName: "Ibutilide Fumarate",
  },
  { id: 1259, BrandName: "Ic-Green", GenericName: "Ic-Green" },
  { id: 1260, BrandName: "Icodextrin", GenericName: "Icodextrin" },
  { id: 1261, BrandName: "Idamycin", GenericName: "Idamycin" },
  { id: 1262, BrandName: "Idamycin Pfs", GenericName: "Idamycin Pfs" },
  { id: 1263, BrandName: "Idarubicin", GenericName: "Idarubicin" },
  { id: 1264, BrandName: "Ifex", GenericName: "Ifex" },
  { id: 1265, BrandName: "Ifosfamide", GenericName: "Ifosfamide" },
  { id: 1266, BrandName: "Ilaris", GenericName: "Ilaris" },
  { id: 1267, BrandName: "Iloperidone", GenericName: "Iloperidone" },
  { id: 1268, BrandName: "Iloprost", GenericName: "Iloprost" },
  { id: 1269, BrandName: "Ilotycin", GenericName: "Ilotycin" },
  {
    id: 1270,
    BrandName: "Imatinib Mesylate",
    GenericName: "Imatinib Mesylate",
  },
  { id: 1271, BrandName: "Imiglucerase", GenericName: "Imiglucerase" },
  {
    id: 1272,
    BrandName: "Imipenem / Cilastatin",
    GenericName: "Imipenem / Cilastatin",
  },
  { id: 1273, BrandName: "Imipramine", GenericName: "Imipramine" },
  {
    id: 1274,
    BrandName: "Imipramine Pamoate",
    GenericName: "Imipramine Pamoate",
  },
  { id: 1275, BrandName: "Imiquimod", GenericName: "Imiquimod" },
  { id: 1276, BrandName: "Imitrex", GenericName: "Imitrex" },
  { id: 1277, BrandName: "Immune Globulin", GenericName: "Immune Globulin" },
  {
    id: 1278,
    BrandName: "Immune Globulin Intravenous",
    GenericName: "Immune Globulin Intravenous",
  },
  { id: 1279, BrandName: "Imodium", GenericName: "Imodium" },
  { id: 1280, BrandName: "Imovax", GenericName: "Imovax" },
  { id: 1281, BrandName: "Implanon", GenericName: "Implanon" },
  { id: 1282, BrandName: "Imuran", GenericName: "Imuran" },
  { id: 1283, BrandName: "Inapsine", GenericName: "Inapsine" },
  { id: 1284, BrandName: "Increlex", GenericName: "Increlex" },
  { id: 1285, BrandName: "Indapamide", GenericName: "Indapamide" },
  { id: 1286, BrandName: "Inderal", GenericName: "Inderal" },
  { id: 1287, BrandName: "Inderal La", GenericName: "Inderal La" },
  { id: 1288, BrandName: "Inderide", GenericName: "Inderide" },
  { id: 1289, BrandName: "Indigo Carmine", GenericName: "Indigo Carmine" },
  {
    id: 1290,
    BrandName: "Indigotindisulfonate",
    GenericName: "Indigotindisulfonate",
  },
  {
    id: 1291,
    BrandName: "Indinavir Sulfate",
    GenericName: "Indinavir Sulfate",
  },
  { id: 1292, BrandName: "Indocin", GenericName: "Indocin" },
  { id: 1293, BrandName: "Indomethacin", GenericName: "Indomethacin" },
  { id: 1294, BrandName: "Infanrix", GenericName: "Infanrix" },
  { id: 1295, BrandName: "Infasurf", GenericName: "Infasurf" },
  { id: 1296, BrandName: "Infed", GenericName: "Infed" },
  { id: 1297, BrandName: "Infergen", GenericName: "Infergen" },
  { id: 1298, BrandName: "Infliximab", GenericName: "Infliximab" },
  {
    id: 1299,
    BrandName: "Influenza Virus Vaccine",
    GenericName: "Influenza Virus Vaccine",
  },
  { id: 1300, BrandName: "Innohep", GenericName: "Innohep" },
  { id: 1301, BrandName: "Innopran Xl", GenericName: "Innopran Xl" },
  { id: 1302, BrandName: "Inomax", GenericName: "Inomax" },
  {
    id: 1303,
    BrandName: "Insoluble Prussian Blue",
    GenericName: "Insoluble Prussian Blue",
  },
  { id: 1304, BrandName: "Inspra", GenericName: "Inspra" },
  { id: 1305, BrandName: "Insulin", GenericName: "Insulin" },
  { id: 1306, BrandName: "Insulin Aspart", GenericName: "Insulin Aspart" },
  { id: 1307, BrandName: "Insulin Detemir", GenericName: "Insulin Detemir" },
  {
    id: 1308,
    BrandName: "Insulin Glulisine",
    GenericName: "Insulin Glulisine",
  },
  { id: 1309, BrandName: "Insulin Human", GenericName: "Insulin Human" },
  { id: 1310, BrandName: "Insulin Lispro", GenericName: "Insulin Lispro" },
  { id: 1311, BrandName: "Intal Nebulizer", GenericName: "Intal Nebulizer" },
  { id: 1312, BrandName: "Integrilin", GenericName: "Integrilin" },
  { id: 1313, BrandName: "Intelence", GenericName: "Intelence" },
  {
    id: 1314,
    BrandName: "Interferon Alfa-2A",
    GenericName: "Interferon Alfa-2A",
  },
  {
    id: 1315,
    BrandName: "Interferon Alfa-2B",
    GenericName: "Interferon Alfa-2B",
  },
  {
    id: 1316,
    BrandName: "Interferon Alfacon-1",
    GenericName: "Interferon Alfacon-1",
  },
  {
    id: 1317,
    BrandName: "Interferon Beta-1A",
    GenericName: "Interferon Beta-1A",
  },
  {
    id: 1318,
    BrandName: "Interferon Beta-1B",
    GenericName: "Interferon Beta-1B",
  },
  {
    id: 1319,
    BrandName: "Interferon Gamma 1-B",
    GenericName: "Interferon Gamma 1-B",
  },
  { id: 1320, BrandName: "Intralipid 10%", GenericName: "Intralipid 10%" },
  { id: 1321, BrandName: "Intralipid 20%", GenericName: "Intralipid 20%" },
  { id: 1322, BrandName: "Intron A", GenericName: "Intron A" },
  { id: 1323, BrandName: "Intuniv", GenericName: "Intuniv" },
  { id: 1324, BrandName: "Invanz", GenericName: "Invanz" },
  { id: 1325, BrandName: "Invega", GenericName: "Invega" },
  { id: 1326, BrandName: "Invega Sustenna", GenericName: "Invega Sustenna" },
  { id: 1327, BrandName: "Inversine", GenericName: "Inversine" },
  { id: 1328, BrandName: "Invirase", GenericName: "Invirase" },
  { id: 1329, BrandName: "Iobenguane I 123", GenericName: "Iobenguane I 123" },
  { id: 1330, BrandName: "Ionamin", GenericName: "Ionamin" },
  { id: 1331, BrandName: "Ionsys", GenericName: "Ionsys" },
  { id: 1332, BrandName: "Ioxilan", GenericName: "Ioxilan" },
  { id: 1333, BrandName: "Iplex", GenericName: "Iplex" },
  { id: 1334, BrandName: "Ipol", GenericName: "Ipol" },
  { id: 1335, BrandName: "Irbesartan", GenericName: "Irbesartan" },
  {
    id: 1336,
    BrandName: "Irbesartan-Hydrochlorothiazide",
    GenericName: "Irbesartan-Hydrochlorothiazide",
  },
  { id: 1337, BrandName: "Iressa", GenericName: "Iressa" },
  {
    id: 1338,
    BrandName: "Irinotecan Hydrochloride",
    GenericName: "Irinotecan Hydrochloride",
  },
  { id: 1339, BrandName: "Iron Dextran", GenericName: "Iron Dextran" },
  { id: 1340, BrandName: "Isentress", GenericName: "Isentress" },
  { id: 1341, BrandName: "Ismelin", GenericName: "Ismelin" },
  { id: 1342, BrandName: "Ismo", GenericName: "Ismo" },
  { id: 1343, BrandName: "Isocarboxazid", GenericName: "Isocarboxazid" },
  { id: 1344, BrandName: "Isoflurane", GenericName: "Isoflurane" },
  { id: 1345, BrandName: "Isoniazid", GenericName: "Isoniazid" },
  { id: 1346, BrandName: "Isoproterenol", GenericName: "Isoproterenol" },
  { id: 1347, BrandName: "Isoptin Sr", GenericName: "Isoptin Sr" },
  { id: 1348, BrandName: "Isopto Carpine", GenericName: "Isopto Carpine" },
  { id: 1349, BrandName: "Isopto Hyoscine", GenericName: "Isopto Hyoscine" },
  { id: 1350, BrandName: "Isordil", GenericName: "Isordil" },
  {
    id: 1351,
    BrandName: "Isosorbide Dinitrate",
    GenericName: "Isosorbide Dinitrate",
  },
  {
    id: 1352,
    BrandName: "Isosorbide Mononitrate",
    GenericName: "Isosorbide Mononitrate",
  },
  { id: 1353, BrandName: "Isosulfan Blue", GenericName: "Isosulfan Blue" },
  { id: 1354, BrandName: "Isotretinoin", GenericName: "Isotretinoin" },
  { id: 1355, BrandName: "Isradipine", GenericName: "Isradipine" },
  { id: 1356, BrandName: "Istalol", GenericName: "Istalol" },
  { id: 1357, BrandName: "Istodax", GenericName: "Istodax" },
  { id: 1358, BrandName: "Isuprel", GenericName: "Isuprel" },
  { id: 1359, BrandName: "Ivermectin", GenericName: "Ivermectin" },
  { id: 1360, BrandName: "Ixabepilone", GenericName: "Ixabepilone" },
  { id: 1361, BrandName: "Ixempra", GenericName: "Ixempra" },
  { id: 1362, BrandName: "Ixiaro", GenericName: "Ixiaro" },
  { id: 1363, BrandName: "Jadelle", GenericName: "Jadelle" },
  { id: 1364, BrandName: "Jalyn", GenericName: "Jalyn" },
  { id: 1365, BrandName: "Jantoven", GenericName: "Jantoven" },
  { id: 1366, BrandName: "Janumet", GenericName: "Janumet" },
  { id: 1367, BrandName: "Januvia", GenericName: "Januvia" },
  {
    id: 1368,
    BrandName: "Japanese Encephalitis Vaccine",
    GenericName: "Japanese Encephalitis Vaccine",
  },
  { id: 1369, BrandName: "Je-Vax", GenericName: "Je-Vax" },
  { id: 1370, BrandName: "Jevtana", GenericName: "Jevtana" },
  { id: 1371, BrandName: "K-Lor", GenericName: "K-Lor" },
  { id: 1372, BrandName: "K-Tab", GenericName: "K-Tab" },
  { id: 1373, BrandName: "Kadian", GenericName: "Kadian" },
  { id: 1374, BrandName: "Kalbitor", GenericName: "Kalbitor" },
  { id: 1375, BrandName: "Kaletra", GenericName: "Kaletra" },
  { id: 1376, BrandName: "Kanamycin", GenericName: "Kanamycin" },
  { id: 1377, BrandName: "Kantrex", GenericName: "Kantrex" },
  { id: 1378, BrandName: "Kapidex", GenericName: "Kapidex" },
  { id: 1379, BrandName: "Kariva", GenericName: "Kariva" },
  { id: 1380, BrandName: "Kayexalate", GenericName: "Kayexalate" },
  { id: 1381, BrandName: "Keflex", GenericName: "Keflex" },
  { id: 1382, BrandName: "Kemstro", GenericName: "Kemstro" },
  { id: 1383, BrandName: "Kenalog", GenericName: "Kenalog" },
  { id: 1384, BrandName: "Kepivance", GenericName: "Kepivance" },
  { id: 1385, BrandName: "Keppra", GenericName: "Keppra" },
  { id: 1386, BrandName: "Keppra Xr", GenericName: "Keppra Xr" },
  { id: 1387, BrandName: "Kerlone", GenericName: "Kerlone" },
  { id: 1388, BrandName: "Ketamine Hcl", GenericName: "Ketamine Hcl" },
  {
    id: 1389,
    BrandName: "Ketamine Hydrochloride",
    GenericName: "Ketamine Hydrochloride",
  },
  { id: 1390, BrandName: "Ketek", GenericName: "Ketek" },
  { id: 1391, BrandName: "Ketoconazole", GenericName: "Ketoconazole" },
  { id: 1392, BrandName: "Ketoprofen", GenericName: "Ketoprofen" },
  {
    id: 1393,
    BrandName: "Ketorolac Tromethamine",
    GenericName: "Ketorolac Tromethamine",
  },
  {
    id: 1394,
    BrandName: "Ketotifen Fumarate",
    GenericName: "Ketotifen Fumarate",
  },
  { id: 1395, BrandName: "Kineret", GenericName: "Kineret" },
  { id: 1396, BrandName: "Kinevac", GenericName: "Kinevac" },
  { id: 1397, BrandName: "Kinlytic", GenericName: "Kinlytic" },
  { id: 1398, BrandName: "Kionex", GenericName: "Kionex" },
  { id: 1399, BrandName: "Klaron", GenericName: "Klaron" },
  { id: 1400, BrandName: "Klonopin", GenericName: "Klonopin" },
  { id: 1401, BrandName: "Klor-Con", GenericName: "Klor-Con" },
  { id: 1402, BrandName: "Kogenate Fs", GenericName: "Kogenate Fs" },
  { id: 1403, BrandName: "Kuvan", GenericName: "Kuvan" },
  { id: 1404, BrandName: "Kytril", GenericName: "Kytril" },
  { id: 1405, BrandName: "Labetalol", GenericName: "Labetalol" },
  { id: 1406, BrandName: "Lac-Hydrin", GenericName: "Lac-Hydrin" },
  { id: 1407, BrandName: "Lacosamide", GenericName: "Lacosamide" },
  { id: 1408, BrandName: "Lacrisert", GenericName: "Lacrisert" },
  {
    id: 1409,
    BrandName: "Lactated Ringer'S Solution",
    GenericName: "Lactated Ringer'S Solution",
  },
  { id: 1410, BrandName: "Lactic Acid", GenericName: "Lactic Acid" },
  { id: 1411, BrandName: "Lactulose", GenericName: "Lactulose" },
  { id: 1412, BrandName: "Lamictal", GenericName: "Lamictal" },
  { id: 1413, BrandName: "Lamisil", GenericName: "Lamisil" },
  { id: 1414, BrandName: "Lamivudine", GenericName: "Lamivudine" },
  {
    id: 1415,
    BrandName: "Lamivudine / Zidovudine",
    GenericName: "Lamivudine / Zidovudine",
  },
  { id: 1416, BrandName: "Lamotrigine", GenericName: "Lamotrigine" },
  { id: 1417, BrandName: "Lamprene", GenericName: "Lamprene" },
  { id: 1418, BrandName: "Lanoxin", GenericName: "Lanoxin" },
  { id: 1419, BrandName: "Lansoprazole", GenericName: "Lansoprazole" },
  { id: 1420, BrandName: "Lantus", GenericName: "Lantus" },
  { id: 1421, BrandName: "Lapatinib", GenericName: "Lapatinib" },
  { id: 1422, BrandName: "Lariam", GenericName: "Lariam" },
  { id: 1423, BrandName: "Laronidase", GenericName: "Laronidase" },
  { id: 1424, BrandName: "Lasix", GenericName: "Lasix" },
  { id: 1425, BrandName: "Lastacaft", GenericName: "Lastacaft" },
  { id: 1426, BrandName: "Latanoprost", GenericName: "Latanoprost" },
  { id: 1427, BrandName: "Latisse", GenericName: "Latisse" },
  { id: 1428, BrandName: "Leflunomide", GenericName: "Leflunomide" },
  { id: 1429, BrandName: "Lenalidomide", GenericName: "Lenalidomide" },
  { id: 1430, BrandName: "Lepirudin", GenericName: "Lepirudin" },
  { id: 1431, BrandName: "Lescol", GenericName: "Lescol" },
  { id: 1432, BrandName: "Letairis", GenericName: "Letairis" },
  { id: 1433, BrandName: "Letrozole", GenericName: "Letrozole" },
  {
    id: 1434,
    BrandName: "Leucovorin Calcium",
    GenericName: "Leucovorin Calcium",
  },
  { id: 1435, BrandName: "Leukeran", GenericName: "Leukeran" },
  { id: 1436, BrandName: "Leukine", GenericName: "Leukine" },
  {
    id: 1437,
    BrandName: "Leuprolide Acetate",
    GenericName: "Leuprolide Acetate",
  },
  { id: 1438, BrandName: "Leustatin", GenericName: "Leustatin" },
  { id: 1439, BrandName: "Levalbuterol", GenericName: "Levalbuterol" },
  { id: 1440, BrandName: "Levaquin", GenericName: "Levaquin" },
  { id: 1441, BrandName: "Levbid", GenericName: "Levbid" },
  { id: 1442, BrandName: "Levemir", GenericName: "Levemir" },
  { id: 1443, BrandName: "Levetiracetam", GenericName: "Levetiracetam" },
  { id: 1444, BrandName: "Levitra", GenericName: "Levitra" },
  { id: 1445, BrandName: "Levo-Dromoran", GenericName: "Levo-Dromoran" },
  { id: 1446, BrandName: "Levo-T", GenericName: "Levo-T" },
  {
    id: 1447,
    BrandName: "Levobetaxolol Hydrochloride",
    GenericName: "Levobetaxolol Hydrochloride",
  },
  { id: 1448, BrandName: "Levobunolol", GenericName: "Levobunolol" },
  { id: 1449, BrandName: "Levocabastine", GenericName: "Levocabastine" },
  { id: 1450, BrandName: "Levocarnitine", GenericName: "Levocarnitine" },
  {
    id: 1451,
    BrandName: "Levocetirizine Dihydrochloride",
    GenericName: "Levocetirizine Dihydrochloride",
  },
  { id: 1452, BrandName: "Levodopa", GenericName: "Levodopa" },
  { id: 1453, BrandName: "Levofloxacin", GenericName: "Levofloxacin" },
  { id: 1454, BrandName: "Levoleucovorin", GenericName: "Levoleucovorin" },
  {
    id: 1455,
    BrandName: "Levomethadyl Acetate",
    GenericName: "Levomethadyl Acetate",
  },
  { id: 1456, BrandName: "Levonorgestrel", GenericName: "Levonorgestrel" },
  {
    id: 1457,
    BrandName: "Levonorgestrel And Ethinyl Estradiol",
    GenericName: "Levonorgestrel And Ethinyl Estradiol",
  },
  {
    id: 1458,
    BrandName: "Levonorgestrel Implants",
    GenericName: "Levonorgestrel Implants",
  },
  {
    id: 1459,
    BrandName: "Levonorgestrel, Ethinyl Estradiol",
    GenericName: "Levonorgestrel, Ethinyl Estradiol",
  },
  { id: 1460, BrandName: "Levophed", GenericName: "Levophed" },
  { id: 1461, BrandName: "Levora", GenericName: "Levora" },
  { id: 1462, BrandName: "Levorphanol", GenericName: "Levorphanol" },
  { id: 1463, BrandName: "Levothroid", GenericName: "Levothroid" },
  {
    id: 1464,
    BrandName: "Levothyroxine Sodium",
    GenericName: "Levothyroxine Sodium",
  },
  { id: 1465, BrandName: "Levoxyl", GenericName: "Levoxyl" },
  { id: 1466, BrandName: "Levsin", GenericName: "Levsin" },
  { id: 1467, BrandName: "Levsin Sl", GenericName: "Levsin Sl" },
  {
    id: 1468,
    BrandName: "Levulan Kerastick",
    GenericName: "Levulan Kerastick",
  },
  { id: 1469, BrandName: "Lexapro", GenericName: "Lexapro" },
  { id: 1470, BrandName: "Lexiscan", GenericName: "Lexiscan" },
  { id: 1471, BrandName: "Lexiva", GenericName: "Lexiva" },
  { id: 1472, BrandName: "Lexxel", GenericName: "Lexxel" },
  { id: 1473, BrandName: "Lialda", GenericName: "Lialda" },
  { id: 1474, BrandName: "Librax", GenericName: "Librax" },
  { id: 1475, BrandName: "Librium", GenericName: "Librium" },
  { id: 1476, BrandName: "Lidex", GenericName: "Lidex" },
  { id: 1477, BrandName: "Lidocaine", GenericName: "Lidocaine" },
  {
    id: 1478,
    BrandName: "Lidocaine And Prilocaine",
    GenericName: "Lidocaine And Prilocaine",
  },
  {
    id: 1479,
    BrandName: "Lidocaine And Tetracaine",
    GenericName: "Lidocaine And Tetracaine",
  },
  { id: 1480, BrandName: "Lidoderm", GenericName: "Lidoderm" },
  { id: 1481, BrandName: "Limbitrol", GenericName: "Limbitrol" },
  { id: 1482, BrandName: "Lincocin", GenericName: "Lincocin" },
  { id: 1483, BrandName: "Lincomycin Hcl", GenericName: "Lincomycin Hcl" },
  { id: 1484, BrandName: "Lindane", GenericName: "Lindane" },
  { id: 1485, BrandName: "Linezolid", GenericName: "Linezolid" },
  {
    id: 1486,
    BrandName: "Lioresal Intrathecal",
    GenericName: "Lioresal Intrathecal",
  },
  {
    id: 1487,
    BrandName: "Liothyronine Sodium",
    GenericName: "Liothyronine Sodium",
  },
  { id: 1488, BrandName: "Liotrix", GenericName: "Liotrix" },
  { id: 1489, BrandName: "Lipitor", GenericName: "Lipitor" },
  { id: 1490, BrandName: "Lipofen", GenericName: "Lipofen" },
  { id: 1491, BrandName: "Liposyn Ii", GenericName: "Liposyn Ii" },
  { id: 1492, BrandName: "Liposyn Iii", GenericName: "Liposyn Iii" },
  { id: 1493, BrandName: "Liraglutide", GenericName: "Liraglutide" },
  {
    id: 1494,
    BrandName: "Lisdexamfetamine Dimesylate",
    GenericName: "Lisdexamfetamine Dimesylate",
  },
  { id: 1495, BrandName: "Lisinopril", GenericName: "Lisinopril" },
  {
    id: 1496,
    BrandName: "Lisinopril And Hydrochlorothiazide",
    GenericName: "Lisinopril And Hydrochlorothiazide",
  },
  {
    id: 1497,
    BrandName: "Lithium Carbonate",
    GenericName: "Lithium Carbonate",
  },
  { id: 1498, BrandName: "Livalo", GenericName: "Livalo" },
  { id: 1499, BrandName: "Livostin", GenericName: "Livostin" },
  { id: 1500, BrandName: "Locoid", GenericName: "Locoid" },
  { id: 1501, BrandName: "Locoid Lipocream", GenericName: "Locoid Lipocream" },
  { id: 1502, BrandName: "Lodine", GenericName: "Lodine" },
  { id: 1503, BrandName: "Lodosyn", GenericName: "Lodosyn" },
  {
    id: 1504,
    BrandName: "Lodoxamide Tromethamine",
    GenericName: "Lodoxamide Tromethamine",
  },
  { id: 1505, BrandName: "Loestrin 24 Fe", GenericName: "Loestrin 24 Fe" },
  { id: 1506, BrandName: "Lomefloxacin Hcl", GenericName: "Lomefloxacin Hcl" },
  { id: 1507, BrandName: "Lomotil", GenericName: "Lomotil" },
  { id: 1508, BrandName: "Lomustine", GenericName: "Lomustine" },
  { id: 1509, BrandName: "Loperamide Hcl", GenericName: "Loperamide Hcl" },
  { id: 1510, BrandName: "Lopid", GenericName: "Lopid" },
  {
    id: 1511,
    BrandName: "Lopinavir/Ritonavir",
    GenericName: "Lopinavir/Ritonavir",
  },
  { id: 1512, BrandName: "Lopressor", GenericName: "Lopressor" },
  { id: 1513, BrandName: "Lopressor Hct", GenericName: "Lopressor Hct" },
  { id: 1514, BrandName: "Loprox Cream", GenericName: "Loprox Cream" },
  { id: 1515, BrandName: "Loprox Gel", GenericName: "Loprox Gel" },
  { id: 1516, BrandName: "Lorabid", GenericName: "Lorabid" },
  { id: 1517, BrandName: "Loracarbef", GenericName: "Loracarbef" },
  { id: 1518, BrandName: "Loratadine", GenericName: "Loratadine" },
  { id: 1519, BrandName: "Lorazepam", GenericName: "Lorazepam" },
  { id: 1520, BrandName: "Lortab", GenericName: "Lortab" },
  {
    id: 1521,
    BrandName: "Losartan Potassium",
    GenericName: "Losartan Potassium",
  },
  { id: 1522, BrandName: "Lotemax", GenericName: "Lotemax" },
  { id: 1523, BrandName: "Lotensin", GenericName: "Lotensin" },
  { id: 1524, BrandName: "Lotensin Hct", GenericName: "Lotensin Hct" },
  {
    id: 1525,
    BrandName: "Loteprednol Etabonate",
    GenericName: "Loteprednol Etabonate",
  },
  { id: 1526, BrandName: "Lotrel", GenericName: "Lotrel" },
  { id: 1527, BrandName: "Lotrisone", GenericName: "Lotrisone" },
  { id: 1528, BrandName: "Lotronex", GenericName: "Lotronex" },
  { id: 1529, BrandName: "Lovastatin", GenericName: "Lovastatin" },
  { id: 1530, BrandName: "Lovaza", GenericName: "Lovaza" },
  { id: 1531, BrandName: "Lovenox", GenericName: "Lovenox" },
  { id: 1532, BrandName: "Low-Ogestrel", GenericName: "Low-Ogestrel" },
  { id: 1533, BrandName: "Loxapine", GenericName: "Loxapine" },
  {
    id: 1534,
    BrandName: "Loxapine Succinate",
    GenericName: "Loxapine Succinate",
  },
  { id: 1535, BrandName: "Loxitane", GenericName: "Loxitane" },
  { id: 1536, BrandName: "Lozol", GenericName: "Lozol" },
  { id: 1537, BrandName: "Lubiprostone", GenericName: "Lubiprostone" },
  { id: 1538, BrandName: "Lucentis", GenericName: "Lucentis" },
  { id: 1539, BrandName: "Lufyllin", GenericName: "Lufyllin" },
  { id: 1540, BrandName: "Lumigan", GenericName: "Lumigan" },
  { id: 1541, BrandName: "Lumizyme", GenericName: "Lumizyme" },
  { id: 1542, BrandName: "Lunesta", GenericName: "Lunesta" },
  { id: 1543, BrandName: "Lupron", GenericName: "Lupron" },
  { id: 1544, BrandName: "Lusedra", GenericName: "Lusedra" },
  { id: 1545, BrandName: "Luvox Cr", GenericName: "Luvox Cr" },
  { id: 1546, BrandName: "Luxiq", GenericName: "Luxiq" },
  { id: 1547, BrandName: "Lybrel", GenericName: "Lybrel" },
  { id: 1548, BrandName: "Lymphazurin", GenericName: "Lymphazurin" },
  { id: 1549, BrandName: "Lyrica", GenericName: "Lyrica" },
  { id: 1550, BrandName: "Lysodren", GenericName: "Lysodren" },
  { id: 1551, BrandName: "Lysteda", GenericName: "Lysteda" },
  { id: 1552, BrandName: "M-M-R", GenericName: "M-M-R" },
  { id: 1553, BrandName: "Macrobid", GenericName: "Macrobid" },
  { id: 1554, BrandName: "Macrodantin", GenericName: "Macrodantin" },
  { id: 1555, BrandName: "Macugen", GenericName: "Macugen" },
  { id: 1556, BrandName: "Mafenide Acetate", GenericName: "Mafenide Acetate" },
  {
    id: 1557,
    BrandName: "Magnesium Sulfate",
    GenericName: "Magnesium Sulfate",
  },
  { id: 1558, BrandName: "Magnevist", GenericName: "Magnevist" },
  { id: 1559, BrandName: "Malarone", GenericName: "Malarone" },
  { id: 1560, BrandName: "Malathion", GenericName: "Malathion" },
  { id: 1561, BrandName: "Mandol", GenericName: "Mandol" },
  { id: 1562, BrandName: "Mangafodipir", GenericName: "Mangafodipir" },
  { id: 1563, BrandName: "Mannitol", GenericName: "Mannitol" },
  { id: 1564, BrandName: "Maraviroc", GenericName: "Maraviroc" },
  { id: 1565, BrandName: "Marcaine", GenericName: "Marcaine" },
  { id: 1566, BrandName: "Marinol", GenericName: "Marinol" },
  { id: 1567, BrandName: "Marplan", GenericName: "Marplan" },
  { id: 1568, BrandName: "Matulane", GenericName: "Matulane" },
  { id: 1569, BrandName: "Mavik", GenericName: "Mavik" },
  { id: 1570, BrandName: "Maxair", GenericName: "Maxair" },
  { id: 1571, BrandName: "Maxalt", GenericName: "Maxalt" },
  { id: 1572, BrandName: "Maxaquin", GenericName: "Maxaquin" },
  { id: 1573, BrandName: "Maxipime", GenericName: "Maxipime" },
  { id: 1574, BrandName: "Maxitrol", GenericName: "Maxitrol" },
  { id: 1575, BrandName: "Measles Vaccine", GenericName: "Measles Vaccine" },
  { id: 1576, BrandName: "Mebaral", GenericName: "Mebaral" },
  { id: 1577, BrandName: "Mebendazole", GenericName: "Mebendazole" },
  { id: 1578, BrandName: "Mecamylamine", GenericName: "Mecamylamine" },
  { id: 1579, BrandName: "Mecasermin", GenericName: "Mecasermin" },
  {
    id: 1580,
    BrandName: "Mecasermin Rinfabate",
    GenericName: "Mecasermin Rinfabate",
  },
  {
    id: 1581,
    BrandName: "Mechlorethamine Hcl",
    GenericName: "Mechlorethamine Hcl",
  },
  { id: 1582, BrandName: "Meclizine", GenericName: "Meclizine" },
  { id: 1583, BrandName: "Meclofenamate", GenericName: "Meclofenamate" },
  { id: 1584, BrandName: "Medrol", GenericName: "Medrol" },
  {
    id: 1585,
    BrandName: "Medroxyprogesterone",
    GenericName: "Medroxyprogesterone",
  },
  {
    id: 1586,
    BrandName: "Medroxyprogesterone Acetate",
    GenericName: "Medroxyprogesterone Acetate",
  },
  { id: 1587, BrandName: "Medrysone", GenericName: "Medrysone" },
  { id: 1588, BrandName: "Mefenamic Acid", GenericName: "Mefenamic Acid" },
  { id: 1589, BrandName: "Mefloquine", GenericName: "Mefloquine" },
  { id: 1590, BrandName: "Mefoxin", GenericName: "Mefoxin" },
  { id: 1591, BrandName: "Megace", GenericName: "Megace" },
  { id: 1592, BrandName: "Megace Es", GenericName: "Megace Es" },
  {
    id: 1593,
    BrandName: "Megestrol Acetate",
    GenericName: "Megestrol Acetate",
  },
  { id: 1594, BrandName: "Mellaril", GenericName: "Mellaril" },
  { id: 1595, BrandName: "Meloxicam", GenericName: "Meloxicam" },
  { id: 1596, BrandName: "Melphalan", GenericName: "Melphalan" },
  { id: 1597, BrandName: "Memantine Hcl", GenericName: "Memantine Hcl" },
  { id: 1598, BrandName: "Menactra", GenericName: "Menactra" },
  { id: 1599, BrandName: "Menest", GenericName: "Menest" },
  {
    id: 1600,
    BrandName: "Meningococcal Vaccine",
    GenericName: "Meningococcal Vaccine",
  },
  { id: 1601, BrandName: "Menomune", GenericName: "Menomune" },
  { id: 1602, BrandName: "Menopur", GenericName: "Menopur" },
  { id: 1603, BrandName: "Menostar", GenericName: "Menostar" },
  { id: 1604, BrandName: "Menotropins", GenericName: "Menotropins" },
  { id: 1605, BrandName: "Mentax", GenericName: "Mentax" },
  {
    id: 1606,
    BrandName: "Mepenzolate Bromide",
    GenericName: "Mepenzolate Bromide",
  },
  { id: 1607, BrandName: "Meperidine", GenericName: "Meperidine" },
  { id: 1608, BrandName: "Mephobarbital", GenericName: "Mephobarbital" },
  { id: 1609, BrandName: "Mephyton", GenericName: "Mephyton" },
  { id: 1610, BrandName: "Mepivacaine", GenericName: "Mepivacaine" },
  { id: 1611, BrandName: "Meprobamate", GenericName: "Meprobamate" },
  {
    id: 1612,
    BrandName: "Meprobamate / Aspirin",
    GenericName: "Meprobamate / Aspirin",
  },
  { id: 1613, BrandName: "Mepron", GenericName: "Mepron" },
  {
    id: 1614,
    BrandName: "Mequinol And Tretinoin",
    GenericName: "Mequinol And Tretinoin",
  },
  { id: 1615, BrandName: "Mercaptopurine", GenericName: "Mercaptopurine" },
  { id: 1616, BrandName: "Meridia", GenericName: "Meridia" },
  { id: 1617, BrandName: "Meropenem", GenericName: "Meropenem" },
  { id: 1618, BrandName: "Merrem I.V.", GenericName: "Merrem I.V." },
  { id: 1619, BrandName: "Mesalamine", GenericName: "Mesalamine" },
  { id: 1620, BrandName: "Mesna", GenericName: "Mesna" },
  { id: 1621, BrandName: "Mesnex", GenericName: "Mesnex" },
  { id: 1622, BrandName: "Mestinon", GenericName: "Mestinon" },
  { id: 1623, BrandName: "Metadate Cd", GenericName: "Metadate Cd" },
  { id: 1624, BrandName: "Metadate Er", GenericName: "Metadate Er" },
  { id: 1625, BrandName: "Metaglip", GenericName: "Metaglip" },
  {
    id: 1626,
    BrandName: "Metaproterenol Sulfate",
    GenericName: "Metaproterenol Sulfate",
  },
  { id: 1627, BrandName: "Metaraminol", GenericName: "Metaraminol" },
  { id: 1628, BrandName: "Metastron", GenericName: "Metastron" },
  { id: 1629, BrandName: "Metaxalone", GenericName: "Metaxalone" },
  { id: 1630, BrandName: "Metformin Hcl", GenericName: "Metformin Hcl" },
  {
    id: 1631,
    BrandName: "Methacholine Chloride",
    GenericName: "Methacholine Chloride",
  },
  { id: 1632, BrandName: "Methadone", GenericName: "Methadone" },
  {
    id: 1633,
    BrandName: "Methadone Hydrochloride",
    GenericName: "Methadone Hydrochloride",
  },
  {
    id: 1634,
    BrandName: "Methadose Oral Concentrate",
    GenericName: "Methadose Oral Concentrate",
  },
  {
    id: 1635,
    BrandName: "Methamphetamine Hydrochloride",
    GenericName: "Methamphetamine Hydrochloride",
  },
  { id: 1636, BrandName: "Methazolamide", GenericName: "Methazolamide" },
  {
    id: 1637,
    BrandName: "Methenamine Hippurate",
    GenericName: "Methenamine Hippurate",
  },
  { id: 1638, BrandName: "Methergine", GenericName: "Methergine" },
  { id: 1639, BrandName: "Methimazole", GenericName: "Methimazole" },
  { id: 1640, BrandName: "Methocarbamol", GenericName: "Methocarbamol" },
  {
    id: 1641,
    BrandName: "Methohexital Sodium",
    GenericName: "Methohexital Sodium",
  },
  { id: 1642, BrandName: "Methotrexate", GenericName: "Methotrexate" },
  { id: 1643, BrandName: "Methoxsalen", GenericName: "Methoxsalen" },
  { id: 1644, BrandName: "Methsuximide", GenericName: "Methsuximide" },
  { id: 1645, BrandName: "Methyclothiazide", GenericName: "Methyclothiazide" },
  { id: 1646, BrandName: "Methyldopa", GenericName: "Methyldopa" },
  { id: 1647, BrandName: "Methyldopate Hcl", GenericName: "Methyldopate Hcl" },
  { id: 1648, BrandName: "Methylene Blue", GenericName: "Methylene Blue" },
  {
    id: 1649,
    BrandName: "Methylergonovine Maleate",
    GenericName: "Methylergonovine Maleate",
  },
  { id: 1650, BrandName: "Methylin", GenericName: "Methylin" },
  {
    id: 1651,
    BrandName: "Methylnaltrexone Bromide",
    GenericName: "Methylnaltrexone Bromide",
  },
  {
    id: 1652,
    BrandName: "Methylphenidate Hcl",
    GenericName: "Methylphenidate Hcl",
  },
  {
    id: 1653,
    BrandName: "Methylprednisolone",
    GenericName: "Methylprednisolone",
  },
  {
    id: 1654,
    BrandName: "Methylprednisolone Sodium Succinate",
    GenericName: "Methylprednisolone Sodium Succinate",
  },
  {
    id: 1655,
    BrandName: "Methyltestosterone",
    GenericName: "Methyltestosterone",
  },
  {
    id: 1656,
    BrandName: "Methysergide Maleate",
    GenericName: "Methysergide Maleate",
  },
  { id: 1657, BrandName: "Metipranolol", GenericName: "Metipranolol" },
  { id: 1658, BrandName: "Metoclopramide", GenericName: "Metoclopramide" },
  { id: 1659, BrandName: "Metolazone", GenericName: "Metolazone" },
  { id: 1660, BrandName: "Metopirone", GenericName: "Metopirone" },
  {
    id: 1661,
    BrandName: "Metoprolol Succinate",
    GenericName: "Metoprolol Succinate",
  },
  {
    id: 1662,
    BrandName: "Metoprolol Tartrate",
    GenericName: "Metoprolol Tartrate",
  },
  { id: 1663, BrandName: "Metozolv Odt", GenericName: "Metozolv Odt" },
  { id: 1664, BrandName: "Metrocream", GenericName: "Metrocream" },
  { id: 1665, BrandName: "Metrodin", GenericName: "Metrodin" },
  { id: 1666, BrandName: "Metrogel", GenericName: "Metrogel" },
  { id: 1667, BrandName: "Metrolotion", GenericName: "Metrolotion" },
  { id: 1668, BrandName: "Metronidazole", GenericName: "Metronidazole" },
  { id: 1669, BrandName: "Metvixia", GenericName: "Metvixia" },
  { id: 1670, BrandName: "Metyrapone", GenericName: "Metyrapone" },
  { id: 1671, BrandName: "Metyrosine", GenericName: "Metyrosine" },
  { id: 1672, BrandName: "Mevacor", GenericName: "Mevacor" },
  { id: 1673, BrandName: "Mexiletine Hcl", GenericName: "Mexiletine Hcl" },
  { id: 1674, BrandName: "Mexitil", GenericName: "Mexitil" },
  { id: 1675, BrandName: "Miacalcin", GenericName: "Miacalcin" },
  {
    id: 1676,
    BrandName: "Micafungin Sodium",
    GenericName: "Micafungin Sodium",
  },
  { id: 1677, BrandName: "Micardis", GenericName: "Micardis" },
  { id: 1678, BrandName: "Micardis Hct", GenericName: "Micardis Hct" },
  { id: 1679, BrandName: "Miconazole", GenericName: "Miconazole" },
  { id: 1680, BrandName: "Micro-K", GenericName: "Micro-K" },
  { id: 1681, BrandName: "Micronase", GenericName: "Micronase" },
  {
    id: 1682,
    BrandName: "Micronized Glyburide",
    GenericName: "Micronized Glyburide",
  },
  { id: 1683, BrandName: "Microzide", GenericName: "Microzide" },
  { id: 1684, BrandName: "Midamor", GenericName: "Midamor" },
  { id: 1685, BrandName: "Midazolam", GenericName: "Midazolam" },
  {
    id: 1686,
    BrandName: "Midodrine Hydrochloride",
    GenericName: "Midodrine Hydrochloride",
  },
  { id: 1687, BrandName: "Midrin", GenericName: "Midrin" },
  { id: 1688, BrandName: "Mifeprex", GenericName: "Mifeprex" },
  { id: 1689, BrandName: "Mifepristone", GenericName: "Mifepristone" },
  { id: 1690, BrandName: "Miglitol", GenericName: "Miglitol" },
  { id: 1691, BrandName: "Miglustat", GenericName: "Miglustat" },
  { id: 1692, BrandName: "Migranal", GenericName: "Migranal" },
  { id: 1693, BrandName: "Milnacipran Hcl", GenericName: "Milnacipran Hcl" },
  { id: 1694, BrandName: "Milrinone", GenericName: "Milrinone" },
  { id: 1695, BrandName: "Minipress", GenericName: "Minipress" },
  { id: 1696, BrandName: "Minocin", GenericName: "Minocin" },
  { id: 1697, BrandName: "Minocycline", GenericName: "Minocycline" },
  {
    id: 1698,
    BrandName: "Minocycline Hydrochloride",
    GenericName: "Minocycline Hydrochloride",
  },
  { id: 1699, BrandName: "Minoxidil", GenericName: "Minoxidil" },
  { id: 1700, BrandName: "Mintezol", GenericName: "Mintezol" },
  { id: 1701, BrandName: "Miochol-E", GenericName: "Miochol-E" },
  { id: 1702, BrandName: "Miostat", GenericName: "Miostat" },
  { id: 1703, BrandName: "Miradon", GenericName: "Miradon" },
  { id: 1704, BrandName: "Miralax", GenericName: "Miralax" },
  { id: 1705, BrandName: "Mirapex", GenericName: "Mirapex" },
  { id: 1706, BrandName: "Mirapex Er", GenericName: "Mirapex Er" },
  { id: 1707, BrandName: "Mircera", GenericName: "Mircera" },
  { id: 1708, BrandName: "Mircette", GenericName: "Mircette" },
  { id: 1709, BrandName: "Mirena", GenericName: "Mirena" },
  { id: 1710, BrandName: "Mirtazapine", GenericName: "Mirtazapine" },
  { id: 1711, BrandName: "Misoprostol", GenericName: "Misoprostol" },
  { id: 1712, BrandName: "Mithracin", GenericName: "Mithracin" },
  { id: 1713, BrandName: "Mitomycin", GenericName: "Mitomycin" },
  { id: 1714, BrandName: "Mitotane", GenericName: "Mitotane" },
  { id: 1715, BrandName: "Mitoxantrone", GenericName: "Mitoxantrone" },
  { id: 1716, BrandName: "Moban", GenericName: "Moban" },
  { id: 1717, BrandName: "Mobic", GenericName: "Mobic" },
  { id: 1718, BrandName: "Modafinil", GenericName: "Modafinil" },
  { id: 1719, BrandName: "Moduretic", GenericName: "Moduretic" },
  { id: 1720, BrandName: "Moexipril", GenericName: "Moexipril" },
  { id: 1721, BrandName: "Moexipril Hcl", GenericName: "Moexipril Hcl" },
  {
    id: 1722,
    BrandName: "Molindone Hydrochloride",
    GenericName: "Molindone Hydrochloride",
  },
  {
    id: 1723,
    BrandName: "Mometasone Furoate",
    GenericName: "Mometasone Furoate",
  },
  { id: 1724, BrandName: "Monistat", GenericName: "Monistat" },
  { id: 1725, BrandName: "Monistat-Derm", GenericName: "Monistat-Derm" },
  { id: 1726, BrandName: "Monoclate-P", GenericName: "Monoclate-P" },
  { id: 1727, BrandName: "Monodox", GenericName: "Monodox" },
  { id: 1728, BrandName: "Mononine", GenericName: "Mononine" },
  { id: 1729, BrandName: "Monopril", GenericName: "Monopril" },
  {
    id: 1730,
    BrandName: "Montelukast Sodium",
    GenericName: "Montelukast Sodium",
  },
  { id: 1731, BrandName: "Monurol", GenericName: "Monurol" },
  { id: 1732, BrandName: "Morphine Sulfate", GenericName: "Morphine Sulfate" },
  { id: 1733, BrandName: "Morrhuate Sodium", GenericName: "Morrhuate Sodium" },
  { id: 1734, BrandName: "Motofen", GenericName: "Motofen" },
  { id: 1735, BrandName: "Motrin", GenericName: "Motrin" },
  { id: 1736, BrandName: "Moxatag", GenericName: "Moxatag" },
  { id: 1737, BrandName: "Moxifloxacin", GenericName: "Moxifloxacin" },
  { id: 1738, BrandName: "Moxifloxacin Hcl", GenericName: "Moxifloxacin Hcl" },
  { id: 1739, BrandName: "Mozobil", GenericName: "Mozobil" },
  { id: 1740, BrandName: "Ms Contin", GenericName: "Ms Contin" },
  { id: 1741, BrandName: "Multaq", GenericName: "Multaq" },
  { id: 1742, BrandName: "Multi Vitamin", GenericName: "Multi Vitamin" },
  { id: 1743, BrandName: "Multihance", GenericName: "Multihance" },
  { id: 1744, BrandName: "Mumps Vaccine", GenericName: "Mumps Vaccine" },
  { id: 1745, BrandName: "Mupirocin", GenericName: "Mupirocin" },
  { id: 1746, BrandName: "Mustargen", GenericName: "Mustargen" },
  { id: 1747, BrandName: "Mutamycin", GenericName: "Mutamycin" },
  { id: 1748, BrandName: "Myambutol", GenericName: "Myambutol" },
  { id: 1749, BrandName: "Mycamine", GenericName: "Mycamine" },
  { id: 1750, BrandName: "Mycelex", GenericName: "Mycelex" },
  { id: 1751, BrandName: "Mycobutin", GenericName: "Mycobutin" },
  {
    id: 1752,
    BrandName: "Mycophenolate Mofetil",
    GenericName: "Mycophenolate Mofetil",
  },
  {
    id: 1753,
    BrandName: "Mycophenolic Acid",
    GenericName: "Mycophenolic Acid",
  },
  { id: 1754, BrandName: "Mycostatin", GenericName: "Mycostatin" },
  { id: 1755, BrandName: "Myfortic", GenericName: "Myfortic" },
  { id: 1756, BrandName: "Mykrox", GenericName: "Mykrox" },
  { id: 1757, BrandName: "Myleran", GenericName: "Myleran" },
  { id: 1758, BrandName: "Mylotarg", GenericName: "Mylotarg" },
  { id: 1759, BrandName: "Myobloc", GenericName: "Myobloc" },
  { id: 1760, BrandName: "Myochrysine", GenericName: "Myochrysine" },
  { id: 1761, BrandName: "Myozyme", GenericName: "Myozyme" },
  { id: 1762, BrandName: "Mysoline", GenericName: "Mysoline" },
  { id: 1763, BrandName: "Mytelase", GenericName: "Mytelase" },
  { id: 1764, BrandName: "Nabi-Hb", GenericName: "Nabi-Hb" },
  { id: 1765, BrandName: "Nabumetone", GenericName: "Nabumetone" },
  { id: 1766, BrandName: "Nadolol", GenericName: "Nadolol" },
  {
    id: 1767,
    BrandName: "Nadolol And Bendroflumethiazide",
    GenericName: "Nadolol And Bendroflumethiazide",
  },
  { id: 1768, BrandName: "Nafcillin Sodium", GenericName: "Nafcillin Sodium" },
  { id: 1769, BrandName: "Naftifine", GenericName: "Naftifine" },
  { id: 1770, BrandName: "Naftifine Hcl", GenericName: "Naftifine Hcl" },
  { id: 1771, BrandName: "Naglazyme", GenericName: "Naglazyme" },
  {
    id: 1772,
    BrandName: "Nalbuphine Hydrochloride",
    GenericName: "Nalbuphine Hydrochloride",
  },
  { id: 1773, BrandName: "Nalfon", GenericName: "Nalfon" },
  { id: 1774, BrandName: "Nalidixic Acid", GenericName: "Nalidixic Acid" },
  {
    id: 1775,
    BrandName: "Nalmefene Hydrochloride",
    GenericName: "Nalmefene Hydrochloride",
  },
  { id: 1776, BrandName: "Naloxone", GenericName: "Naloxone" },
  { id: 1777, BrandName: "Naltrexone", GenericName: "Naltrexone" },
  { id: 1778, BrandName: "Namenda", GenericName: "Namenda" },
  { id: 1779, BrandName: "Naprelan", GenericName: "Naprelan" },
  { id: 1780, BrandName: "Naproxen", GenericName: "Naproxen" },
  { id: 1781, BrandName: "Naproxen Sodium", GenericName: "Naproxen Sodium" },
  { id: 1782, BrandName: "Naratriptan", GenericName: "Naratriptan" },
  { id: 1783, BrandName: "Narcan", GenericName: "Narcan" },
  { id: 1784, BrandName: "Nardil", GenericName: "Nardil" },
  { id: 1785, BrandName: "Naropin", GenericName: "Naropin" },
  { id: 1786, BrandName: "Nasacort Aq", GenericName: "Nasacort Aq" },
  { id: 1787, BrandName: "Nasalcrom", GenericName: "Nasalcrom" },
  { id: 1788, BrandName: "Nasalide", GenericName: "Nasalide" },
  { id: 1789, BrandName: "Nascobal", GenericName: "Nascobal" },
  { id: 1790, BrandName: "Nasonex", GenericName: "Nasonex" },
  { id: 1791, BrandName: "Natacyn", GenericName: "Natacyn" },
  { id: 1792, BrandName: "Natalizumab", GenericName: "Natalizumab" },
  { id: 1793, BrandName: "Natamycin", GenericName: "Natamycin" },
  { id: 1794, BrandName: "Natazia", GenericName: "Natazia" },
  { id: 1795, BrandName: "Nateglinide", GenericName: "Nateglinide" },
  { id: 1796, BrandName: "Natrecor", GenericName: "Natrecor" },
  { id: 1797, BrandName: "Navane", GenericName: "Navane" },
  { id: 1798, BrandName: "Navelbine", GenericName: "Navelbine" },
  { id: 1799, BrandName: "Nebcin", GenericName: "Nebcin" },
  {
    id: 1800,
    BrandName: "Nebivolol Tablets",
    GenericName: "Nebivolol Tablets",
  },
  { id: 1801, BrandName: "Nebupent", GenericName: "Nebupent" },
  { id: 1802, BrandName: "Necon", GenericName: "Necon" },
  { id: 1803, BrandName: "Nedocromil", GenericName: "Nedocromil" },
  {
    id: 1804,
    BrandName: "Nedocromil Inhalation Aerosol",
    GenericName: "Nedocromil Inhalation Aerosol",
  },
  { id: 1805, BrandName: "Nefazodone", GenericName: "Nefazodone" },
  { id: 1806, BrandName: "Neggram", GenericName: "Neggram" },
  { id: 1807, BrandName: "Nelarabine", GenericName: "Nelarabine" },
  {
    id: 1808,
    BrandName: "Nelfinavir Mesylate",
    GenericName: "Nelfinavir Mesylate",
  },
  { id: 1809, BrandName: "Nembutal", GenericName: "Nembutal" },
  { id: 1810, BrandName: "Neo-Synephrine", GenericName: "Neo-Synephrine" },
  { id: 1811, BrandName: "Neodecadron", GenericName: "Neodecadron" },
  { id: 1812, BrandName: "Neomycin Sulfate", GenericName: "Neomycin Sulfate" },
  { id: 1813, BrandName: "Neoprofen", GenericName: "Neoprofen" },
  { id: 1814, BrandName: "Neoral", GenericName: "Neoral" },
  { id: 1815, BrandName: "Neostigmine", GenericName: "Neostigmine" },
  {
    id: 1816,
    BrandName: "Neostigmine Methylsulfate",
    GenericName: "Neostigmine Methylsulfate",
  },
  { id: 1817, BrandName: "Nephramine", GenericName: "Nephramine" },
  { id: 1818, BrandName: "Nesacaine", GenericName: "Nesacaine" },
  { id: 1819, BrandName: "Nesiritide", GenericName: "Nesiritide" },
  { id: 1820, BrandName: "Neulasta", GenericName: "Neulasta" },
  { id: 1821, BrandName: "Neumega", GenericName: "Neumega" },
  { id: 1822, BrandName: "Neupogen", GenericName: "Neupogen" },
  { id: 1823, BrandName: "Neupro", GenericName: "Neupro" },
  { id: 1824, BrandName: "Neurontin", GenericName: "Neurontin" },
  { id: 1825, BrandName: "Neutrexin", GenericName: "Neutrexin" },
  { id: 1826, BrandName: "Nevanac", GenericName: "Nevanac" },
  { id: 1827, BrandName: "Nevirapine", GenericName: "Nevirapine" },
  { id: 1828, BrandName: "Nexavar", GenericName: "Nexavar" },
  { id: 1829, BrandName: "Nexium", GenericName: "Nexium" },
  { id: 1830, BrandName: "Niacin", GenericName: "Niacin" },
  { id: 1831, BrandName: "Niacor", GenericName: "Niacor" },
  { id: 1832, BrandName: "Niaspan", GenericName: "Niaspan" },
  {
    id: 1833,
    BrandName: "Nicardipine Hydrochloride",
    GenericName: "Nicardipine Hydrochloride",
  },
  { id: 1834, BrandName: "Nicotrol", GenericName: "Nicotrol" },
  { id: 1835, BrandName: "Nicotrol Ns", GenericName: "Nicotrol Ns" },
  { id: 1836, BrandName: "Nifedipine", GenericName: "Nifedipine" },
  { id: 1837, BrandName: "Niferex-150", GenericName: "Niferex-150" },
  { id: 1838, BrandName: "Nilandron", GenericName: "Nilandron" },
  {
    id: 1839,
    BrandName: "Nilotinib Capsules",
    GenericName: "Nilotinib Capsules",
  },
  { id: 1840, BrandName: "Nilutamide", GenericName: "Nilutamide" },
  { id: 1841, BrandName: "Nimbex", GenericName: "Nimbex" },
  { id: 1842, BrandName: "Nimodipine", GenericName: "Nimodipine" },
  { id: 1843, BrandName: "Nimotop", GenericName: "Nimotop" },
  { id: 1844, BrandName: "Niravam", GenericName: "Niravam" },
  { id: 1845, BrandName: "Nisoldipine", GenericName: "Nisoldipine" },
  { id: 1846, BrandName: "Nitazoxanide", GenericName: "Nitazoxanide" },
  { id: 1847, BrandName: "Nitisinone", GenericName: "Nitisinone" },
  { id: 1848, BrandName: "Nitric Oxide", GenericName: "Nitric Oxide" },
  { id: 1849, BrandName: "Nitro-Dur", GenericName: "Nitro-Dur" },
  { id: 1850, BrandName: "Nitrofurantoin", GenericName: "Nitrofurantoin" },
  { id: 1851, BrandName: "Nitroglycerin", GenericName: "Nitroglycerin" },
  {
    id: 1852,
    BrandName: "Nitrolingual Pumpspray",
    GenericName: "Nitrolingual Pumpspray",
  },
  { id: 1853, BrandName: "Nitromist", GenericName: "Nitromist" },
  { id: 1854, BrandName: "Nitropress", GenericName: "Nitropress" },
  {
    id: 1855,
    BrandName: "Nitroprusside Sodium",
    GenericName: "Nitroprusside Sodium",
  },
  { id: 1856, BrandName: "Nitrostat", GenericName: "Nitrostat" },
  { id: 1857, BrandName: "Nizatidine", GenericName: "Nizatidine" },
  { id: 1858, BrandName: "Nizoral", GenericName: "Nizoral" },
  { id: 1859, BrandName: "Noctec", GenericName: "Noctec" },
  { id: 1860, BrandName: "Nolvadex", GenericName: "Nolvadex" },
  { id: 1861, BrandName: "Nor-Qd", GenericName: "Nor-Qd" },
  { id: 1862, BrandName: "Norco", GenericName: "Norco" },
  { id: 1863, BrandName: "Norditropin", GenericName: "Norditropin" },
  {
    id: 1864,
    BrandName: "Norepinephrine Bitartrate",
    GenericName: "Norepinephrine Bitartrate",
  },
  { id: 1865, BrandName: "Norethindrone", GenericName: "Norethindrone" },
  {
    id: 1866,
    BrandName: "Norethindrone And Ethinyl Estradiol",
    GenericName: "Norethindrone And Ethinyl Estradiol",
  },
  { id: 1867, BrandName: "Norflex", GenericName: "Norflex" },
  { id: 1868, BrandName: "Norfloxacin", GenericName: "Norfloxacin" },
  { id: 1869, BrandName: "Norgesic", GenericName: "Norgesic" },
  {
    id: 1870,
    BrandName: "Norgestimate And Ethinyl Estradiol",
    GenericName: "Norgestimate And Ethinyl Estradiol",
  },
  { id: 1871, BrandName: "Noritate", GenericName: "Noritate" },
  { id: 1872, BrandName: "Normal Saline", GenericName: "Normal Saline" },
  { id: 1873, BrandName: "Noroxin", GenericName: "Noroxin" },
  { id: 1874, BrandName: "Norpace", GenericName: "Norpace" },
  { id: 1875, BrandName: "Norplant", GenericName: "Norplant" },
  { id: 1876, BrandName: "Norpramin", GenericName: "Norpramin" },
  {
    id: 1877,
    BrandName: "Nortriptyline Hcl",
    GenericName: "Nortriptyline Hcl",
  },
  {
    id: 1878,
    BrandName: "Nortriptyline Hydrochloride",
    GenericName: "Nortriptyline Hydrochloride",
  },
  { id: 1879, BrandName: "Norvasc", GenericName: "Norvasc" },
  { id: 1880, BrandName: "Norvir", GenericName: "Norvir" },
  { id: 1881, BrandName: "Novantrone", GenericName: "Novantrone" },
  { id: 1882, BrandName: "Novolin R", GenericName: "Novolin R" },
  { id: 1883, BrandName: "Novolog", GenericName: "Novolog" },
  {
    id: 1884,
    BrandName: "Novolog Mix 70/30",
    GenericName: "Novolog Mix 70/30",
  },
  { id: 1885, BrandName: "Novoseven", GenericName: "Novoseven" },
  { id: 1886, BrandName: "Novothyrox", GenericName: "Novothyrox" },
  { id: 1887, BrandName: "Noxafil", GenericName: "Noxafil" },
  { id: 1888, BrandName: "Nplate", GenericName: "Nplate" },
  { id: 1889, BrandName: "Nubain", GenericName: "Nubain" },
  { id: 1890, BrandName: "Nucynta", GenericName: "Nucynta" },
  { id: 1891, BrandName: "Numorphan", GenericName: "Numorphan" },
  { id: 1892, BrandName: "Nuromax", GenericName: "Nuromax" },
  { id: 1893, BrandName: "Nutropin", GenericName: "Nutropin" },
  { id: 1894, BrandName: "Nutropin Aq", GenericName: "Nutropin Aq" },
  { id: 1895, BrandName: "Nutropin Depot", GenericName: "Nutropin Depot" },
  { id: 1896, BrandName: "Nuvaring", GenericName: "Nuvaring" },
  { id: 1897, BrandName: "Nuvigil", GenericName: "Nuvigil" },
  { id: 1898, BrandName: "Nydrazid", GenericName: "Nydrazid" },
  { id: 1899, BrandName: "Nystatin", GenericName: "Nystatin" },
  { id: 1900, BrandName: "Nystop", GenericName: "Nystop" },
  { id: 1901, BrandName: "Ocufen", GenericName: "Ocufen" },
  { id: 1902, BrandName: "Ocuflox", GenericName: "Ocuflox" },
  { id: 1903, BrandName: "Ofatumumab", GenericName: "Ofatumumab" },
  { id: 1904, BrandName: "Ofloxacin", GenericName: "Ofloxacin" },
  { id: 1905, BrandName: "Oforta", GenericName: "Oforta" },
  { id: 1906, BrandName: "Ogen", GenericName: "Ogen" },
  { id: 1907, BrandName: "Olanzapine", GenericName: "Olanzapine" },
  { id: 1908, BrandName: "Oleptro", GenericName: "Oleptro" },
  {
    id: 1909,
    BrandName: "Olmesartan Medoxomil",
    GenericName: "Olmesartan Medoxomil",
  },
  { id: 1910, BrandName: "Olopatadine", GenericName: "Olopatadine" },
  { id: 1911, BrandName: "Olux", GenericName: "Olux" },
  { id: 1912, BrandName: "Olux-E", GenericName: "Olux-E" },
  { id: 1913, BrandName: "Omalizumab", GenericName: "Omalizumab" },
  {
    id: 1914,
    BrandName: "Omega-3-Acid Ethyl Esters",
    GenericName: "Omega-3-Acid Ethyl Esters",
  },
  { id: 1915, BrandName: "Omeprazole", GenericName: "Omeprazole" },
  { id: 1916, BrandName: "Omnaris", GenericName: "Omnaris" },
  { id: 1917, BrandName: "Omnicef", GenericName: "Omnicef" },
  { id: 1918, BrandName: "Omnipred", GenericName: "Omnipred" },
  { id: 1919, BrandName: "Omniscan", GenericName: "Omniscan" },
  { id: 1920, BrandName: "Omnitrope", GenericName: "Omnitrope" },
  { id: 1921, BrandName: "Oncaspar", GenericName: "Oncaspar" },
  {
    id: 1922,
    BrandName: "Ondansetron Hydrochloride",
    GenericName: "Ondansetron Hydrochloride",
  },
  { id: 1923, BrandName: "Onglyza", GenericName: "Onglyza" },
  { id: 1924, BrandName: "Onsolis", GenericName: "Onsolis" },
  { id: 1925, BrandName: "Ontak", GenericName: "Ontak" },
  { id: 1926, BrandName: "Opana", GenericName: "Opana" },
  { id: 1927, BrandName: "Opana Er", GenericName: "Opana Er" },
  { id: 1928, BrandName: "Oprelvekin", GenericName: "Oprelvekin" },
  { id: 1929, BrandName: "Opticrom", GenericName: "Opticrom" },
  { id: 1930, BrandName: "Optimark", GenericName: "Optimark" },
  { id: 1931, BrandName: "Optipranolol", GenericName: "Optipranolol" },
  {
    id: 1932,
    BrandName: "Optiray Injection",
    GenericName: "Optiray Injection",
  },
  { id: 1933, BrandName: "Optison", GenericName: "Optison" },
  { id: 1934, BrandName: "Optivar", GenericName: "Optivar" },
  { id: 1935, BrandName: "Oracea", GenericName: "Oracea" },
  { id: 1936, BrandName: "Orap", GenericName: "Orap" },
  { id: 1937, BrandName: "Orapred Odt", GenericName: "Orapred Odt" },
  { id: 1938, BrandName: "Oraqix", GenericName: "Oraqix" },
  { id: 1939, BrandName: "Oravig", GenericName: "Oravig" },
  { id: 1940, BrandName: "Orencia", GenericName: "Orencia" },
  { id: 1941, BrandName: "Orfadin", GenericName: "Orfadin" },
  { id: 1942, BrandName: "Organidin Nr", GenericName: "Organidin Nr" },
  { id: 1943, BrandName: "Orimune", GenericName: "Orimune" },
  { id: 1944, BrandName: "Orlaam", GenericName: "Orlaam" },
  { id: 1945, BrandName: "Orlistat", GenericName: "Orlistat" },
  { id: 1946, BrandName: "Orphenadrine", GenericName: "Orphenadrine" },
  {
    id: 1947,
    BrandName: "Orphenadrine Citrate",
    GenericName: "Orphenadrine Citrate",
  },
  { id: 1948, BrandName: "Orphengesic", GenericName: "Orphengesic" },
  { id: 1949, BrandName: "Ortho Evra", GenericName: "Ortho Evra" },
  { id: 1950, BrandName: "Ortho Tri Cyclen", GenericName: "Ortho Tri Cyclen" },
  { id: 1951, BrandName: "Ortho-Novum", GenericName: "Ortho-Novum" },
  { id: 1952, BrandName: "Orudis", GenericName: "Orudis" },
  {
    id: 1953,
    BrandName: "Oseltamivir Phosphate",
    GenericName: "Oseltamivir Phosphate",
  },
  { id: 1954, BrandName: "Osmitrol", GenericName: "Osmitrol" },
  { id: 1955, BrandName: "Osmoprep", GenericName: "Osmoprep" },
  { id: 1956, BrandName: "Ovcon", GenericName: "Ovcon" },
  { id: 1957, BrandName: "Ovide", GenericName: "Ovide" },
  { id: 1958, BrandName: "Ovidrel", GenericName: "Ovidrel" },
  { id: 1959, BrandName: "Oxacillin", GenericName: "Oxacillin" },
  { id: 1960, BrandName: "Oxaliplatin", GenericName: "Oxaliplatin" },
  { id: 1961, BrandName: "Oxandrin", GenericName: "Oxandrin" },
  { id: 1962, BrandName: "Oxandrolone", GenericName: "Oxandrolone" },
  { id: 1963, BrandName: "Oxaprozin", GenericName: "Oxaprozin" },
  { id: 1964, BrandName: "Oxazepam", GenericName: "Oxazepam" },
  { id: 1965, BrandName: "Oxcarbazepine", GenericName: "Oxcarbazepine" },
  { id: 1966, BrandName: "Oxiconazole", GenericName: "Oxiconazole" },
  { id: 1967, BrandName: "Oxilan", GenericName: "Oxilan" },
  { id: 1968, BrandName: "Oxistat", GenericName: "Oxistat" },
  { id: 1969, BrandName: "Oxsoralen-Ultra", GenericName: "Oxsoralen-Ultra" },
  {
    id: 1970,
    BrandName: "Oxybutynin Chloride",
    GenericName: "Oxybutynin Chloride",
  },
  {
    id: 1971,
    BrandName: "Oxycodone And Acetaminophen",
    GenericName: "Oxycodone And Acetaminophen",
  },
  { id: 1972, BrandName: "Oxycodone Hcl", GenericName: "Oxycodone Hcl" },
  {
    id: 1973,
    BrandName: "Oxycodone Hydrochloride",
    GenericName: "Oxycodone Hydrochloride",
  },
  { id: 1974, BrandName: "Oxycontin", GenericName: "Oxycontin" },
  { id: 1975, BrandName: "Oxymetholone", GenericName: "Oxymetholone" },
  { id: 1976, BrandName: "Oxymorphone", GenericName: "Oxymorphone" },
  {
    id: 1977,
    BrandName: "Oxymorphone Hydrochloride",
    GenericName: "Oxymorphone Hydrochloride",
  },
  {
    id: 1978,
    BrandName: "Oxymorphone Hydrochloride Extended Release",
    GenericName: "Oxymorphone Hydrochloride Extended Release",
  },
  { id: 1979, BrandName: "Oxytetracycline", GenericName: "Oxytetracycline" },
  { id: 1980, BrandName: "Oxytocin", GenericName: "Oxytocin" },
  { id: 1981, BrandName: "Oxytrol", GenericName: "Oxytrol" },
  { id: 1982, BrandName: "Paclitaxel", GenericName: "Paclitaxel" },
  { id: 1983, BrandName: "Palifermin", GenericName: "Palifermin" },
  { id: 1984, BrandName: "Paliperidone", GenericName: "Paliperidone" },
  { id: 1985, BrandName: "Palivizumab", GenericName: "Palivizumab" },
  { id: 1986, BrandName: "Palonosetron", GenericName: "Palonosetron" },
  {
    id: 1987,
    BrandName: "Palonosetron Hydrochloride",
    GenericName: "Palonosetron Hydrochloride",
  },
  { id: 1988, BrandName: "Pamelor", GenericName: "Pamelor" },
  {
    id: 1989,
    BrandName: "Pamidronate Disodium",
    GenericName: "Pamidronate Disodium",
  },
  { id: 1990, BrandName: "Pancrecarb", GenericName: "Pancrecarb" },
  { id: 1991, BrandName: "Pancrelipase", GenericName: "Pancrelipase" },
  { id: 1992, BrandName: "Pandel", GenericName: "Pandel" },
  { id: 1993, BrandName: "Panhematin", GenericName: "Panhematin" },
  { id: 1994, BrandName: "Panretin", GenericName: "Panretin" },
  { id: 1995, BrandName: "Pantoprazole", GenericName: "Pantoprazole" },
  {
    id: 1996,
    BrandName: "Pantoprazole Sodium",
    GenericName: "Pantoprazole Sodium",
  },
  { id: 1997, BrandName: "Papain", GenericName: "Papain" },
  { id: 1998, BrandName: "Papaverine", GenericName: "Papaverine" },
  { id: 1999, BrandName: "Parafon Forte", GenericName: "Parafon Forte" },
  { id: 2000, BrandName: "Paraplatin", GenericName: "Paraplatin" },
  { id: 2001, BrandName: "Paregoric", GenericName: "Paregoric" },
  { id: 2002, BrandName: "Paricalcitol", GenericName: "Paricalcitol" },
  { id: 2003, BrandName: "Parlodel", GenericName: "Parlodel" },
  { id: 2004, BrandName: "Parnate", GenericName: "Parnate" },
  {
    id: 2005,
    BrandName: "Paromomycin Sulfate",
    GenericName: "Paromomycin Sulfate",
  },
  {
    id: 2006,
    BrandName: "Paroxetine Hydrochloride",
    GenericName: "Paroxetine Hydrochloride",
  },
  {
    id: 2007,
    BrandName: "Paroxetine Mesylate",
    GenericName: "Paroxetine Mesylate",
  },
  { id: 2008, BrandName: "Paser", GenericName: "Paser" },
  { id: 2009, BrandName: "Pataday", GenericName: "Pataday" },
  { id: 2010, BrandName: "Patanase", GenericName: "Patanase" },
  { id: 2011, BrandName: "Patanol", GenericName: "Patanol" },
  { id: 2012, BrandName: "Paxil", GenericName: "Paxil" },
  { id: 2013, BrandName: "Pazopanib", GenericName: "Pazopanib" },
  { id: 2014, BrandName: "Pce", GenericName: "Pce" },
  { id: 2015, BrandName: "Pediapred", GenericName: "Pediapred" },
  { id: 2016, BrandName: "Pediarix", GenericName: "Pediarix" },
  { id: 2017, BrandName: "Pediazole", GenericName: "Pediazole" },
  { id: 2018, BrandName: "Pediotic", GenericName: "Pediotic" },
  { id: 2019, BrandName: "Peg", GenericName: "Peg" },
  { id: 2020, BrandName: "Peg 3350", GenericName: "Peg 3350" },
  { id: 2021, BrandName: "Peg-Intron", GenericName: "Peg-Intron" },
  {
    id: 2022,
    BrandName: "Pegademase Bovine",
    GenericName: "Pegademase Bovine",
  },
  { id: 2023, BrandName: "Peganone", GenericName: "Peganone" },
  {
    id: 2024,
    BrandName: "Pegaptanib Sodium",
    GenericName: "Pegaptanib Sodium",
  },
  { id: 2025, BrandName: "Pegaspargase", GenericName: "Pegaspargase" },
  { id: 2026, BrandName: "Pegasys", GenericName: "Pegasys" },
  { id: 2027, BrandName: "Pegfilgrastim", GenericName: "Pegfilgrastim" },
  {
    id: 2028,
    BrandName: "Peginterferon Alfa-2A",
    GenericName: "Peginterferon Alfa-2A",
  },
  {
    id: 2029,
    BrandName: "Peginterferon Alfa-2B",
    GenericName: "Peginterferon Alfa-2B",
  },
  { id: 2030, BrandName: "Pegintron", GenericName: "Pegintron" },
  { id: 2031, BrandName: "Pegvisomant", GenericName: "Pegvisomant" },
  { id: 2032, BrandName: "Pemetrexed", GenericName: "Pemetrexed" },
  {
    id: 2033,
    BrandName: "Pemirolast Potassium",
    GenericName: "Pemirolast Potassium",
  },
  { id: 2034, BrandName: "Pemoline", GenericName: "Pemoline" },
  { id: 2035, BrandName: "Penciclovir", GenericName: "Penciclovir" },
  { id: 2036, BrandName: "Penetrex", GenericName: "Penetrex" },
  { id: 2037, BrandName: "Penicillamine", GenericName: "Penicillamine" },
  {
    id: 2038,
    BrandName: "Penicillin G Benzathine",
    GenericName: "Penicillin G Benzathine",
  },
  {
    id: 2039,
    BrandName: "Penicillin G Potassium",
    GenericName: "Penicillin G Potassium",
  },
  {
    id: 2040,
    BrandName: "Penicillin V Potassium",
    GenericName: "Penicillin V Potassium",
  },
  { id: 2041, BrandName: "Penicillin Vk", GenericName: "Penicillin Vk" },
  { id: 2042, BrandName: "Penlac", GenericName: "Penlac" },
  { id: 2043, BrandName: "Pennsaid", GenericName: "Pennsaid" },
  {
    id: 2044,
    BrandName: "Pentamidine Isethionate",
    GenericName: "Pentamidine Isethionate",
  },
  { id: 2045, BrandName: "Pentasa", GenericName: "Pentasa" },
  {
    id: 2046,
    BrandName: "Pentazocine And Acetaminophen",
    GenericName: "Pentazocine And Acetaminophen",
  },
  {
    id: 2047,
    BrandName: "Pentazocine And Aspirin",
    GenericName: "Pentazocine And Aspirin",
  },
  {
    id: 2048,
    BrandName: "Pentetate Zinc Trisodium",
    GenericName: "Pentetate Zinc Trisodium",
  },
  { id: 2049, BrandName: "Pentobarbital", GenericName: "Pentobarbital" },
  {
    id: 2050,
    BrandName: "Pentosan Polysulfate Sodium",
    GenericName: "Pentosan Polysulfate Sodium",
  },
  { id: 2051, BrandName: "Pentothal", GenericName: "Pentothal" },
  { id: 2052, BrandName: "Pentoxifylline", GenericName: "Pentoxifylline" },
  { id: 2053, BrandName: "Pepcid", GenericName: "Pepcid" },
  { id: 2054, BrandName: "Percocet", GenericName: "Percocet" },
  { id: 2055, BrandName: "Percodan", GenericName: "Percodan" },
  { id: 2056, BrandName: "Perflutren", GenericName: "Perflutren" },
  { id: 2057, BrandName: "Perforomist", GenericName: "Perforomist" },
  {
    id: 2058,
    BrandName: "Pergolide Mesylate",
    GenericName: "Pergolide Mesylate",
  },
  {
    id: 2059,
    BrandName: "Perindopril Erbumine",
    GenericName: "Perindopril Erbumine",
  },
  { id: 2060, BrandName: "Periochip", GenericName: "Periochip" },
  { id: 2061, BrandName: "Periostat", GenericName: "Periostat" },
  { id: 2062, BrandName: "Permax", GenericName: "Permax" },
  { id: 2063, BrandName: "Permethrin", GenericName: "Permethrin" },
  { id: 2064, BrandName: "Perphenazine", GenericName: "Perphenazine" },
  { id: 2065, BrandName: "Persantine", GenericName: "Persantine" },
  { id: 2066, BrandName: "Persantine Iv", GenericName: "Persantine Iv" },
  { id: 2067, BrandName: "Pexeva", GenericName: "Pexeva" },
  { id: 2068, BrandName: "Pfizerpen", GenericName: "Pfizerpen" },
  { id: 2069, BrandName: "Phenazopyridine", GenericName: "Phenazopyridine" },
  {
    id: 2070,
    BrandName: "Phendimetrazine Tartrate",
    GenericName: "Phendimetrazine Tartrate",
  },
  { id: 2071, BrandName: "Phenelzine", GenericName: "Phenelzine" },
  { id: 2072, BrandName: "Phenergan", GenericName: "Phenergan" },
  { id: 2073, BrandName: "Phenobarbital", GenericName: "Phenobarbital" },
  { id: 2074, BrandName: "Phenoxybenzamine", GenericName: "Phenoxybenzamine" },
  { id: 2075, BrandName: "Phentermine", GenericName: "Phentermine" },
  {
    id: 2076,
    BrandName: "Phentermine Hydrochloride",
    GenericName: "Phentermine Hydrochloride",
  },
  {
    id: 2077,
    BrandName: "Phentolamine Mesylate",
    GenericName: "Phentolamine Mesylate",
  },
  {
    id: 2078,
    BrandName: "Phenylephrine Hcl",
    GenericName: "Phenylephrine Hcl",
  },
  {
    id: 2079,
    BrandName: "Phenylephrine Hydrochloride",
    GenericName: "Phenylephrine Hydrochloride",
  },
  { id: 2080, BrandName: "Phenytoin", GenericName: "Phenytoin" },
  { id: 2081, BrandName: "Phisohex", GenericName: "Phisohex" },
  { id: 2082, BrandName: "Phoslo", GenericName: "Phoslo" },
  { id: 2083, BrandName: "Phosphate", GenericName: "Phosphate" },
  { id: 2084, BrandName: "Phosphocol", GenericName: "Phosphocol" },
  {
    id: 2085,
    BrandName: "Phospholine Iodide",
    GenericName: "Phospholine Iodide",
  },
  { id: 2086, BrandName: "Photofrin", GenericName: "Photofrin" },
  {
    id: 2087,
    BrandName: "Physostigmine Salicylate",
    GenericName: "Physostigmine Salicylate",
  },
  { id: 2088, BrandName: "Phytonadione", GenericName: "Phytonadione" },
  { id: 2089, BrandName: "Pilocarpine", GenericName: "Pilocarpine" },
  {
    id: 2090,
    BrandName: "Pilocarpine Hydrochloride",
    GenericName: "Pilocarpine Hydrochloride",
  },
  { id: 2091, BrandName: "Pilopine Hs", GenericName: "Pilopine Hs" },
  { id: 2092, BrandName: "Pimecrolimus", GenericName: "Pimecrolimus" },
  { id: 2093, BrandName: "Pimozide", GenericName: "Pimozide" },
  { id: 2094, BrandName: "Pindolol", GenericName: "Pindolol" },
  { id: 2095, BrandName: "Pioglitazone Hcl", GenericName: "Pioglitazone Hcl" },
  {
    id: 2096,
    BrandName: "Piperacillin / Tazobactam",
    GenericName: "Piperacillin / Tazobactam",
  },
  { id: 2097, BrandName: "Pipracil", GenericName: "Pipracil" },
  { id: 2098, BrandName: "Pirbuterol", GenericName: "Pirbuterol" },
  { id: 2099, BrandName: "Piroxicam", GenericName: "Piroxicam" },
  { id: 2100, BrandName: "Pitavastatin", GenericName: "Pitavastatin" },
  { id: 2101, BrandName: "Pitocin", GenericName: "Pitocin" },
  { id: 2102, BrandName: "Pitressin", GenericName: "Pitressin" },
  { id: 2103, BrandName: "Plan B", GenericName: "Plan B" },
  { id: 2104, BrandName: "Plaquenil", GenericName: "Plaquenil" },
  { id: 2105, BrandName: "Plasmalyte A", GenericName: "Plasmalyte A" },
  { id: 2106, BrandName: "Plavix", GenericName: "Plavix" },
  { id: 2107, BrandName: "Plenaxis", GenericName: "Plenaxis" },
  { id: 2108, BrandName: "Plendil", GenericName: "Plendil" },
  { id: 2109, BrandName: "Plerixafor", GenericName: "Plerixafor" },
  { id: 2110, BrandName: "Pletal", GenericName: "Pletal" },
  { id: 2111, BrandName: "Pliaglis", GenericName: "Pliaglis" },
  { id: 2112, BrandName: "Plicamycin", GenericName: "Plicamycin" },
  {
    id: 2113,
    BrandName: "Pneumococcal 7-Valent Vaccine",
    GenericName: "Pneumococcal 7-Valent Vaccine",
  },
  {
    id: 2114,
    BrandName: "Pneumococcal Vaccine Polyvalent",
    GenericName: "Pneumococcal Vaccine Polyvalent",
  },
  { id: 2115, BrandName: "Pneumovax", GenericName: "Pneumovax" },
  { id: 2116, BrandName: "Podocon-25", GenericName: "Podocon-25" },
  { id: 2117, BrandName: "Podofilox", GenericName: "Podofilox" },
  { id: 2118, BrandName: "Podophyllin", GenericName: "Podophyllin" },
  { id: 2119, BrandName: "Polidocanol", GenericName: "Polidocanol" },
  { id: 2120, BrandName: "Polifeprosan 20", GenericName: "Polifeprosan 20" },
  {
    id: 2121,
    BrandName: "Poliovirus Vaccine",
    GenericName: "Poliovirus Vaccine",
  },
  {
    id: 2122,
    BrandName: "Polyethylene Glycol 3350",
    GenericName: "Polyethylene Glycol 3350",
  },
  { id: 2123, BrandName: "Polymyxin B", GenericName: "Polymyxin B" },
  {
    id: 2124,
    BrandName: "Polymyxin B Sulfate",
    GenericName: "Polymyxin B Sulfate",
  },
  { id: 2125, BrandName: "Polysaccharides", GenericName: "Polysaccharides" },
  { id: 2126, BrandName: "Polythiazide", GenericName: "Polythiazide" },
  { id: 2127, BrandName: "Pondimin", GenericName: "Pondimin" },
  { id: 2128, BrandName: "Ponstel", GenericName: "Ponstel" },
  { id: 2129, BrandName: "Poractant Alfa", GenericName: "Poractant Alfa" },
  { id: 2130, BrandName: "Porfimer Sodium", GenericName: "Porfimer Sodium" },
  { id: 2131, BrandName: "Posaconazole", GenericName: "Posaconazole" },
  {
    id: 2132,
    BrandName: "Potassium Acetate",
    GenericName: "Potassium Acetate",
  },
  {
    id: 2133,
    BrandName: "Potassium Chloride",
    GenericName: "Potassium Chloride",
  },
  {
    id: 2134,
    BrandName: "Potassium Citrate",
    GenericName: "Potassium Citrate",
  },
  { id: 2135, BrandName: "Pralatrexate", GenericName: "Pralatrexate" },
  {
    id: 2136,
    BrandName: "Pralidoxime Chloride",
    GenericName: "Pralidoxime Chloride",
  },
  { id: 2137, BrandName: "Pramipexole", GenericName: "Pramipexole" },
  {
    id: 2138,
    BrandName: "Pramlintide Acetate",
    GenericName: "Pramlintide Acetate",
  },
  { id: 2139, BrandName: "Prandimet", GenericName: "Prandimet" },
  { id: 2140, BrandName: "Prandin", GenericName: "Prandin" },
  { id: 2141, BrandName: "Prasugrel", GenericName: "Prasugrel" },
  { id: 2142, BrandName: "Pravachol", GenericName: "Pravachol" },
  {
    id: 2143,
    BrandName: "Pravastatin Sodium",
    GenericName: "Pravastatin Sodium",
  },
  { id: 2144, BrandName: "Praziquantel", GenericName: "Praziquantel" },
  { id: 2145, BrandName: "Prazosin Hcl", GenericName: "Prazosin Hcl" },
  { id: 2146, BrandName: "Precedex", GenericName: "Precedex" },
  { id: 2147, BrandName: "Precose", GenericName: "Precose" },
  { id: 2148, BrandName: "Pred Forte", GenericName: "Pred Forte" },
  { id: 2149, BrandName: "Pred-G", GenericName: "Pred-G" },
  { id: 2150, BrandName: "Prednicarbate", GenericName: "Prednicarbate" },
  { id: 2151, BrandName: "Prednisolone", GenericName: "Prednisolone" },
  {
    id: 2152,
    BrandName: "Prednisolone Acetate",
    GenericName: "Prednisolone Acetate",
  },
  {
    id: 2153,
    BrandName: "Prednisolone Sodium Phosphate",
    GenericName: "Prednisolone Sodium Phosphate",
  },
  { id: 2154, BrandName: "Prednisone", GenericName: "Prednisone" },
  { id: 2155, BrandName: "Prefest", GenericName: "Prefest" },
  { id: 2156, BrandName: "Pregabalin", GenericName: "Pregabalin" },
  { id: 2157, BrandName: "Pregnyl", GenericName: "Pregnyl" },
  { id: 2158, BrandName: "Prelone", GenericName: "Prelone" },
  { id: 2159, BrandName: "Premarin", GenericName: "Premarin" },
  {
    id: 2160,
    BrandName: "Prempro, Premphase",
    GenericName: "Prempro, Premphase",
  },
  { id: 2161, BrandName: "Prepidil", GenericName: "Prepidil" },
  { id: 2162, BrandName: "Prevacid", GenericName: "Prevacid" },
  { id: 2163, BrandName: "Prevnar", GenericName: "Prevnar" },
  { id: 2164, BrandName: "Prevpac", GenericName: "Prevpac" },
  { id: 2165, BrandName: "Prezista", GenericName: "Prezista" },
  { id: 2166, BrandName: "Prialt", GenericName: "Prialt" },
  { id: 2167, BrandName: "Priftin", GenericName: "Priftin" },
  { id: 2168, BrandName: "Prilosec", GenericName: "Prilosec" },
  { id: 2169, BrandName: "Primacor", GenericName: "Primacor" },
  { id: 2170, BrandName: "Primaquine", GenericName: "Primaquine" },
  { id: 2171, BrandName: "Primatene Mist", GenericName: "Primatene Mist" },
  { id: 2172, BrandName: "Primaxin I.V.", GenericName: "Primaxin I.V." },
  { id: 2173, BrandName: "Primidone", GenericName: "Primidone" },
  { id: 2174, BrandName: "Principen", GenericName: "Principen" },
  { id: 2175, BrandName: "Prinivil", GenericName: "Prinivil" },
  { id: 2176, BrandName: "Prinzide", GenericName: "Prinzide" },
  { id: 2177, BrandName: "Pristiq", GenericName: "Pristiq" },
  { id: 2178, BrandName: "Privigen", GenericName: "Privigen" },
  { id: 2179, BrandName: "Proair Hfa", GenericName: "Proair Hfa" },
  { id: 2180, BrandName: "Proamatine", GenericName: "Proamatine" },
  {
    id: 2181,
    BrandName: "Probenecid And Colchicine",
    GenericName: "Probenecid And Colchicine",
  },
  { id: 2182, BrandName: "Procainamide", GenericName: "Procainamide" },
  { id: 2183, BrandName: "Procalamine", GenericName: "Procalamine" },
  { id: 2184, BrandName: "Procan Sr", GenericName: "Procan Sr" },
  { id: 2185, BrandName: "Procarbazine", GenericName: "Procarbazine" },
  { id: 2186, BrandName: "Procardia", GenericName: "Procardia" },
  { id: 2187, BrandName: "Prochlorperazine", GenericName: "Prochlorperazine" },
  {
    id: 2188,
    BrandName: "Prochlorperazine Maleate",
    GenericName: "Prochlorperazine Maleate",
  },
  { id: 2189, BrandName: "Procrit", GenericName: "Procrit" },
  { id: 2190, BrandName: "Proctofoam Hc", GenericName: "Proctofoam Hc" },
  { id: 2191, BrandName: "Progesterone", GenericName: "Progesterone" },
  { id: 2192, BrandName: "Proglycem", GenericName: "Proglycem" },
  { id: 2193, BrandName: "Prograf", GenericName: "Prograf" },
  { id: 2194, BrandName: "Prohance", GenericName: "Prohance" },
  { id: 2195, BrandName: "Prolastin", GenericName: "Prolastin" },
  { id: 2196, BrandName: "Proleukin", GenericName: "Proleukin" },
  { id: 2197, BrandName: "Prolia", GenericName: "Prolia" },
  { id: 2198, BrandName: "Prolixin", GenericName: "Prolixin" },
  { id: 2199, BrandName: "Promacta", GenericName: "Promacta" },
  { id: 2200, BrandName: "Promethazine", GenericName: "Promethazine" },
  { id: 2201, BrandName: "Promethazine Hcl", GenericName: "Promethazine Hcl" },
  {
    id: 2202,
    BrandName: "Promethazine Hydrochloride",
    GenericName: "Promethazine Hydrochloride",
  },
  { id: 2203, BrandName: "Prometrium", GenericName: "Prometrium" },
  { id: 2204, BrandName: "Pronestyl", GenericName: "Pronestyl" },
  { id: 2205, BrandName: "Propafenone", GenericName: "Propafenone" },
  {
    id: 2206,
    BrandName: "Proparacaine Hydrochloride",
    GenericName: "Proparacaine Hydrochloride",
  },
  { id: 2207, BrandName: "Propecia", GenericName: "Propecia" },
  { id: 2208, BrandName: "Propine", GenericName: "Propine" },
  { id: 2209, BrandName: "Propofol", GenericName: "Propofol" },
  { id: 2210, BrandName: "Propoxyphene", GenericName: "Propoxyphene" },
  {
    id: 2211,
    BrandName: "Propoxyphene Napsylate",
    GenericName: "Propoxyphene Napsylate",
  },
  { id: 2212, BrandName: "Propranolol", GenericName: "Propranolol" },
  {
    id: 2213,
    BrandName: "Propranolol Hydrochloride",
    GenericName: "Propranolol Hydrochloride",
  },
  { id: 2214, BrandName: "Propulsid", GenericName: "Propulsid" },
  { id: 2215, BrandName: "Propylthiouracil", GenericName: "Propylthiouracil" },
  { id: 2216, BrandName: "Proquin Xr", GenericName: "Proquin Xr" },
  { id: 2217, BrandName: "Proscar", GenericName: "Proscar" },
  { id: 2218, BrandName: "Prosom", GenericName: "Prosom" },
  { id: 2219, BrandName: "Prostigmin", GenericName: "Prostigmin" },
  { id: 2220, BrandName: "Prostin E2", GenericName: "Prostin E2" },
  {
    id: 2221,
    BrandName: "Prostin Vr Pediatric",
    GenericName: "Prostin Vr Pediatric",
  },
  { id: 2222, BrandName: "Protamine", GenericName: "Protamine" },
  { id: 2223, BrandName: "Protamines", GenericName: "Protamines" },
  {
    id: 2224,
    BrandName: "Protein C Concentrate",
    GenericName: "Protein C Concentrate",
  },
  { id: 2225, BrandName: "Protirelin", GenericName: "Protirelin" },
  { id: 2226, BrandName: "Protonix", GenericName: "Protonix" },
  { id: 2227, BrandName: "Protonix I.V.", GenericName: "Protonix I.V." },
  { id: 2228, BrandName: "Protopic", GenericName: "Protopic" },
  {
    id: 2229,
    BrandName: "Protriptyline Hydrochloride",
    GenericName: "Protriptyline Hydrochloride",
  },
  { id: 2230, BrandName: "Protropin", GenericName: "Protropin" },
  { id: 2231, BrandName: "Proventil Hfa", GenericName: "Proventil Hfa" },
  { id: 2232, BrandName: "Provera", GenericName: "Provera" },
  { id: 2233, BrandName: "Provigil", GenericName: "Provigil" },
  { id: 2234, BrandName: "Provisc", GenericName: "Provisc" },
  { id: 2235, BrandName: "Provocholine", GenericName: "Provocholine" },
  { id: 2236, BrandName: "Prozac", GenericName: "Prozac" },
  { id: 2237, BrandName: "Pseudoephedrine", GenericName: "Pseudoephedrine" },
  {
    id: 2238,
    BrandName: "Pseudoephedrine / Guaifenesin",
    GenericName: "Pseudoephedrine / Guaifenesin",
  },
  { id: 2239, BrandName: "Psorcon E", GenericName: "Psorcon E" },
  {
    id: 2240,
    BrandName: "Pulmicort Flexhaler",
    GenericName: "Pulmicort Flexhaler",
  },
  { id: 2241, BrandName: "Pulmozyme", GenericName: "Pulmozyme" },
  { id: 2242, BrandName: "Purinethol", GenericName: "Purinethol" },
  { id: 2243, BrandName: "Pylera", GenericName: "Pylera" },
  { id: 2244, BrandName: "Pyrazinamide", GenericName: "Pyrazinamide" },
  { id: 2245, BrandName: "Pyridium", GenericName: "Pyridium" },
  { id: 2246, BrandName: "Pyridostigmine", GenericName: "Pyridostigmine" },
  { id: 2247, BrandName: "Pyrimethamine", GenericName: "Pyrimethamine" },
  { id: 2248, BrandName: "Quadramet", GenericName: "Quadramet" },
  { id: 2249, BrandName: "Qualaquin", GenericName: "Qualaquin" },
  { id: 2250, BrandName: "Quazepam", GenericName: "Quazepam" },
  { id: 2251, BrandName: "Questran", GenericName: "Questran" },
  {
    id: 2252,
    BrandName: "Quetiapine Fumarate",
    GenericName: "Quetiapine Fumarate",
  },
  {
    id: 2253,
    BrandName: "Quinapril Hydrochloride",
    GenericName: "Quinapril Hydrochloride",
  },
  { id: 2254, BrandName: "Quinidex", GenericName: "Quinidex" },
  { id: 2255, BrandName: "Quinidine", GenericName: "Quinidine" },
  {
    id: 2256,
    BrandName: "Quinidine Gluconate",
    GenericName: "Quinidine Gluconate",
  },
  {
    id: 2257,
    BrandName: "Quinidine Sulfate",
    GenericName: "Quinidine Sulfate",
  },
  {
    id: 2258,
    BrandName: "Quinupristin And Dalfopristin",
    GenericName: "Quinupristin And Dalfopristin",
  },
  { id: 2259, BrandName: "Quixin", GenericName: "Quixin" },
  { id: 2260, BrandName: "Qutenza", GenericName: "Qutenza" },
  { id: 2261, BrandName: "Qvar", GenericName: "Qvar" },
  { id: 2262, BrandName: "R-Gene 10", GenericName: "R-Gene 10" },
  { id: 2263, BrandName: "Rabavert", GenericName: "Rabavert" },
  {
    id: 2264,
    BrandName: "Rabeprazole Sodium",
    GenericName: "Rabeprazole Sodium",
  },
  {
    id: 2265,
    BrandName: "Rabies Immune Globulin",
    GenericName: "Rabies Immune Globulin",
  },
  { id: 2266, BrandName: "Rabies Vaccine", GenericName: "Rabies Vaccine" },
  { id: 2267, BrandName: "Radiogardase", GenericName: "Radiogardase" },
  { id: 2268, BrandName: "Raloxifene", GenericName: "Raloxifene" },
  { id: 2269, BrandName: "Raltegravir", GenericName: "Raltegravir" },
  { id: 2270, BrandName: "Ramelteon", GenericName: "Ramelteon" },
  { id: 2271, BrandName: "Ramipril", GenericName: "Ramipril" },
  { id: 2272, BrandName: "Ranexa", GenericName: "Ranexa" },
  { id: 2273, BrandName: "Ranibizumab", GenericName: "Ranibizumab" },
  {
    id: 2274,
    BrandName: "Ranitidine Bismuth Citrate",
    GenericName: "Ranitidine Bismuth Citrate",
  },
  { id: 2275, BrandName: "Ranitidine Hcl", GenericName: "Ranitidine Hcl" },
  {
    id: 2276,
    BrandName: "Ranitidine Hydrochloride",
    GenericName: "Ranitidine Hydrochloride",
  },
  { id: 2277, BrandName: "Ranolazine", GenericName: "Ranolazine" },
  { id: 2278, BrandName: "Rapaflo", GenericName: "Rapaflo" },
  { id: 2279, BrandName: "Rapamune", GenericName: "Rapamune" },
  { id: 2280, BrandName: "Raplon", GenericName: "Raplon" },
  { id: 2281, BrandName: "Raptiva", GenericName: "Raptiva" },
  { id: 2282, BrandName: "Rasagiline", GenericName: "Rasagiline" },
  { id: 2283, BrandName: "Rasburicase", GenericName: "Rasburicase" },
  {
    id: 2284,
    BrandName: "Rattlesnake Antivenin",
    GenericName: "Rattlesnake Antivenin",
  },
  { id: 2285, BrandName: "Raxar", GenericName: "Raxar" },
  { id: 2286, BrandName: "Razadyne Er", GenericName: "Razadyne Er" },
  { id: 2287, BrandName: "Rebetol", GenericName: "Rebetol" },
  { id: 2288, BrandName: "Rebetron", GenericName: "Rebetron" },
  { id: 2289, BrandName: "Rebif", GenericName: "Rebif" },
  { id: 2290, BrandName: "Reclast", GenericName: "Reclast" },
  { id: 2291, BrandName: "Recombinate", GenericName: "Recombinate" },
  { id: 2292, BrandName: "Refacto", GenericName: "Refacto" },
  { id: 2293, BrandName: "Refludan", GenericName: "Refludan" },
  { id: 2294, BrandName: "Regadenoson", GenericName: "Regadenoson" },
  { id: 2295, BrandName: "Reglan", GenericName: "Reglan" },
  { id: 2296, BrandName: "Regranex", GenericName: "Regranex" },
  { id: 2297, BrandName: "Relafen", GenericName: "Relafen" },
  { id: 2298, BrandName: "Relenza", GenericName: "Relenza" },
  { id: 2299, BrandName: "Relistor", GenericName: "Relistor" },
  { id: 2300, BrandName: "Relpax", GenericName: "Relpax" },
  { id: 2301, BrandName: "Remeron", GenericName: "Remeron" },
  { id: 2302, BrandName: "Remicade", GenericName: "Remicade" },
  { id: 2303, BrandName: "Remifentanil", GenericName: "Remifentanil" },
  { id: 2304, BrandName: "Remodulin", GenericName: "Remodulin" },
  { id: 2305, BrandName: "Renagel", GenericName: "Renagel" },
  { id: 2306, BrandName: "Renese", GenericName: "Renese" },
  { id: 2307, BrandName: "Renova", GenericName: "Renova" },
  { id: 2308, BrandName: "Renvela", GenericName: "Renvela" },
  { id: 2309, BrandName: "Reopro", GenericName: "Reopro" },
  { id: 2310, BrandName: "Repaglinide", GenericName: "Repaglinide" },
  { id: 2311, BrandName: "Repronex", GenericName: "Repronex" },
  { id: 2312, BrandName: "Requip", GenericName: "Requip" },
  { id: 2313, BrandName: "Rescriptor", GenericName: "Rescriptor" },
  { id: 2314, BrandName: "Rescula", GenericName: "Rescula" },
  { id: 2315, BrandName: "Restasis", GenericName: "Restasis" },
  { id: 2316, BrandName: "Restoril", GenericName: "Restoril" },
  { id: 2317, BrandName: "Retapamulin", GenericName: "Retapamulin" },
  { id: 2318, BrandName: "Retavase", GenericName: "Retavase" },
  { id: 2319, BrandName: "Reteplase", GenericName: "Reteplase" },
  { id: 2320, BrandName: "Retin-A Micro", GenericName: "Retin-A Micro" },
  { id: 2321, BrandName: "Retisert", GenericName: "Retisert" },
  { id: 2322, BrandName: "Retrovir", GenericName: "Retrovir" },
  { id: 2323, BrandName: "Revatio", GenericName: "Revatio" },
  { id: 2324, BrandName: "Revex", GenericName: "Revex" },
  { id: 2325, BrandName: "Revia", GenericName: "Revia" },
  { id: 2326, BrandName: "Revlimid", GenericName: "Revlimid" },
  { id: 2327, BrandName: "Reyataz", GenericName: "Reyataz" },
  { id: 2328, BrandName: "Rezulin", GenericName: "Rezulin" },
  { id: 2329, BrandName: "Rheumatrex", GenericName: "Rheumatrex" },
  { id: 2330, BrandName: "Rhinocort Aqua", GenericName: "Rhinocort Aqua" },
  { id: 2331, BrandName: "Rhogam", GenericName: "Rhogam" },
  {
    id: 2332,
    BrandName: "Rhogam Ultra-Filtered Plus",
    GenericName: "Rhogam Ultra-Filtered Plus",
  },
  { id: 2333, BrandName: "Rhophylac", GenericName: "Rhophylac" },
  { id: 2334, BrandName: "Riastap", GenericName: "Riastap" },
  { id: 2335, BrandName: "Ribavirin", GenericName: "Ribavirin" },
  { id: 2336, BrandName: "Rifabutin", GenericName: "Rifabutin" },
  { id: 2337, BrandName: "Rifadin", GenericName: "Rifadin" },
  { id: 2338, BrandName: "Rifamate", GenericName: "Rifamate" },
  { id: 2339, BrandName: "Rifampin", GenericName: "Rifampin" },
  {
    id: 2340,
    BrandName: "Rifampin And Isoniazid",
    GenericName: "Rifampin And Isoniazid",
  },
  { id: 2341, BrandName: "Rifapentine", GenericName: "Rifapentine" },
  { id: 2342, BrandName: "Rifater", GenericName: "Rifater" },
  { id: 2343, BrandName: "Rifaximin", GenericName: "Rifaximin" },
  { id: 2344, BrandName: "Rilonacept", GenericName: "Rilonacept" },
  { id: 2345, BrandName: "Rilutek", GenericName: "Rilutek" },
  { id: 2346, BrandName: "Riluzole", GenericName: "Riluzole" },
  { id: 2347, BrandName: "Rimantadine", GenericName: "Rimantadine" },
  { id: 2348, BrandName: "Rimexolone", GenericName: "Rimexolone" },
  { id: 2349, BrandName: "Rimso-50", GenericName: "Rimso-50" },
  {
    id: 2350,
    BrandName: "Ringer'S Solution",
    GenericName: "Ringer'S Solution",
  },
  { id: 2351, BrandName: "Riomet", GenericName: "Riomet" },
  {
    id: 2352,
    BrandName: "Risedronate Sodium",
    GenericName: "Risedronate Sodium",
  },
  { id: 2353, BrandName: "Risperdal", GenericName: "Risperdal" },
  { id: 2354, BrandName: "Risperdal Consta", GenericName: "Risperdal Consta" },
  { id: 2355, BrandName: "Risperidone", GenericName: "Risperidone" },
  { id: 2356, BrandName: "Ritalin", GenericName: "Ritalin" },
  { id: 2357, BrandName: "Ritonavir", GenericName: "Ritonavir" },
  { id: 2358, BrandName: "Rituxan", GenericName: "Rituxan" },
  { id: 2359, BrandName: "Rituximab", GenericName: "Rituximab" },
  {
    id: 2360,
    BrandName: "Rivastigmine Tartrate",
    GenericName: "Rivastigmine Tartrate",
  },
  {
    id: 2361,
    BrandName: "Rizatriptan Benzoate",
    GenericName: "Rizatriptan Benzoate",
  },
  { id: 2362, BrandName: "Robaxin", GenericName: "Robaxin" },
  { id: 2363, BrandName: "Robaxisal", GenericName: "Robaxisal" },
  { id: 2364, BrandName: "Robinul", GenericName: "Robinul" },
  { id: 2365, BrandName: "Robitussin Ac", GenericName: "Robitussin Ac" },
  { id: 2366, BrandName: "Rocaltrol", GenericName: "Rocaltrol" },
  { id: 2367, BrandName: "Rocephin", GenericName: "Rocephin" },
  {
    id: 2368,
    BrandName: "Rocuronium Bromide",
    GenericName: "Rocuronium Bromide",
  },
  { id: 2369, BrandName: "Rofecoxib", GenericName: "Rofecoxib" },
  { id: 2370, BrandName: "Roferon-A", GenericName: "Roferon-A" },
  { id: 2371, BrandName: "Romazicon", GenericName: "Romazicon" },
  { id: 2372, BrandName: "Romidepsin", GenericName: "Romidepsin" },
  { id: 2373, BrandName: "Romiplostim", GenericName: "Romiplostim" },
  { id: 2374, BrandName: "Rondec", GenericName: "Rondec" },
  { id: 2375, BrandName: "Ropinirole", GenericName: "Ropinirole" },
  { id: 2376, BrandName: "Ropinirole Hcl", GenericName: "Ropinirole Hcl" },
  { id: 2377, BrandName: "Ropivacaine Hcl", GenericName: "Ropivacaine Hcl" },
  { id: 2378, BrandName: "Rosanil", GenericName: "Rosanil" },
  {
    id: 2379,
    BrandName: "Rosiglitazone Maleate",
    GenericName: "Rosiglitazone Maleate",
  },
  {
    id: 2380,
    BrandName: "Rosuvastatin Calcium",
    GenericName: "Rosuvastatin Calcium",
  },
  { id: 2381, BrandName: "Rotarix", GenericName: "Rotarix" },
  { id: 2382, BrandName: "Rotateq", GenericName: "Rotateq" },
  {
    id: 2383,
    BrandName: "Rotavirus Vaccine, Live, Oral",
    GenericName: "Rotavirus Vaccine, Live, Oral",
  },
  { id: 2384, BrandName: "Rotigotine", GenericName: "Rotigotine" },
  { id: 2385, BrandName: "Rowasa", GenericName: "Rowasa" },
  { id: 2386, BrandName: "Roxanol", GenericName: "Roxanol" },
  { id: 2387, BrandName: "Roxicet", GenericName: "Roxicet" },
  { id: 2388, BrandName: "Roxicodone", GenericName: "Roxicodone" },
  { id: 2389, BrandName: "Rozerem", GenericName: "Rozerem" },
  { id: 2390, BrandName: "Rubella Vaccine", GenericName: "Rubella Vaccine" },
  { id: 2391, BrandName: "Rufinamide", GenericName: "Rufinamide" },
  { id: 2392, BrandName: "Rythmol", GenericName: "Rythmol" },
  { id: 2393, BrandName: "Rythmol Sr", GenericName: "Rythmol Sr" },
  { id: 2394, BrandName: "Ryzolt", GenericName: "Ryzolt" },
  { id: 2395, BrandName: "Sabril", GenericName: "Sabril" },
  { id: 2396, BrandName: "Sacrosidase", GenericName: "Sacrosidase" },
  { id: 2397, BrandName: "Saizen", GenericName: "Saizen" },
  { id: 2398, BrandName: "Salagen", GenericName: "Salagen" },
  {
    id: 2399,
    BrandName: "Salmeterol Xinafoate",
    GenericName: "Salmeterol Xinafoate",
  },
  { id: 2400, BrandName: "Salsalate", GenericName: "Salsalate" },
  {
    id: 2401,
    BrandName: "Samarium Sm 153 Lexidronam",
    GenericName: "Samarium Sm 153 Lexidronam",
  },
  { id: 2402, BrandName: "Samsca", GenericName: "Samsca" },
  { id: 2403, BrandName: "Sanctura", GenericName: "Sanctura" },
  { id: 2404, BrandName: "Sanctura Xr", GenericName: "Sanctura Xr" },
  { id: 2405, BrandName: "Sancuso", GenericName: "Sancuso" },
  { id: 2406, BrandName: "Sandimmune", GenericName: "Sandimmune" },
  { id: 2407, BrandName: "Sandostatin", GenericName: "Sandostatin" },
  { id: 2408, BrandName: "Sandostatin Lar", GenericName: "Sandostatin Lar" },
  { id: 2409, BrandName: "Sansert", GenericName: "Sansert" },
  { id: 2410, BrandName: "Santyl", GenericName: "Santyl" },
  { id: 2411, BrandName: "Saphris", GenericName: "Saphris" },
  {
    id: 2412,
    BrandName: "Saquinavir Mesylate",
    GenericName: "Saquinavir Mesylate",
  },
  { id: 2413, BrandName: "Sarafem", GenericName: "Sarafem" },
  { id: 2414, BrandName: "Sargramostim", GenericName: "Sargramostim" },
  { id: 2415, BrandName: "Savella", GenericName: "Savella" },
  { id: 2416, BrandName: "Saxagliptin", GenericName: "Saxagliptin" },
  { id: 2417, BrandName: "Scopolamine", GenericName: "Scopolamine" },
  { id: 2418, BrandName: "Seasonale", GenericName: "Seasonale" },
  { id: 2419, BrandName: "Seasonique", GenericName: "Seasonique" },
  {
    id: 2420,
    BrandName: "Secobarbital Sodium",
    GenericName: "Secobarbital Sodium",
  },
  { id: 2421, BrandName: "Seconal Sodium", GenericName: "Seconal Sodium" },
  { id: 2422, BrandName: "Secreflo", GenericName: "Secreflo" },
  { id: 2423, BrandName: "Secretin", GenericName: "Secretin" },
  { id: 2424, BrandName: "Sectral", GenericName: "Sectral" },
  { id: 2425, BrandName: "Selegiline Hcl", GenericName: "Selegiline Hcl" },
  {
    id: 2426,
    BrandName: "Selegiline Hydrochloride",
    GenericName: "Selegiline Hydrochloride",
  },
  {
    id: 2427,
    BrandName: "Selegiline Transdermal System",
    GenericName: "Selegiline Transdermal System",
  },
  { id: 2428, BrandName: "Selenium", GenericName: "Selenium" },
  { id: 2429, BrandName: "Selsun", GenericName: "Selsun" },
  { id: 2430, BrandName: "Selzentry", GenericName: "Selzentry" },
  { id: 2431, BrandName: "Semprex D", GenericName: "Semprex D" },
  { id: 2432, BrandName: "Sensipar", GenericName: "Sensipar" },
  { id: 2433, BrandName: "Sensorcaine", GenericName: "Sensorcaine" },
  { id: 2434, BrandName: "Septocaine", GenericName: "Septocaine" },
  { id: 2435, BrandName: "Septra", GenericName: "Septra" },
  { id: 2436, BrandName: "Serax", GenericName: "Serax" },
  { id: 2437, BrandName: "Serevent Diskus", GenericName: "Serevent Diskus" },
  { id: 2438, BrandName: "Sermorelin", GenericName: "Sermorelin" },
  {
    id: 2439,
    BrandName: "Sermorelin Acetate",
    GenericName: "Sermorelin Acetate",
  },
  { id: 2440, BrandName: "Seroquel", GenericName: "Seroquel" },
  { id: 2441, BrandName: "Seroquel Xr", GenericName: "Seroquel Xr" },
  { id: 2442, BrandName: "Serostim", GenericName: "Serostim" },
  { id: 2443, BrandName: "Serostim Lq", GenericName: "Serostim Lq" },
  {
    id: 2444,
    BrandName: "Sertaconazole Nitrate",
    GenericName: "Sertaconazole Nitrate",
  },
  { id: 2445, BrandName: "Sertraline Hcl", GenericName: "Sertraline Hcl" },
  { id: 2446, BrandName: "Serzone", GenericName: "Serzone" },
  {
    id: 2447,
    BrandName: "Sevelamer Carbonate",
    GenericName: "Sevelamer Carbonate",
  },
  { id: 2448, BrandName: "Sevelamer Hcl", GenericName: "Sevelamer Hcl" },
  { id: 2449, BrandName: "Sevoflurane", GenericName: "Sevoflurane" },
  {
    id: 2450,
    BrandName: "Sibutramine Hydrochloride Monohydrate",
    GenericName: "Sibutramine Hydrochloride Monohydrate",
  },
  {
    id: 2451,
    BrandName: "Sildenafil Citrate",
    GenericName: "Sildenafil Citrate",
  },
  { id: 2452, BrandName: "Silenor", GenericName: "Silenor" },
  { id: 2453, BrandName: "Silodosin", GenericName: "Silodosin" },
  { id: 2454, BrandName: "Silvadene", GenericName: "Silvadene" },
  {
    id: 2455,
    BrandName: "Silver Sulfadiazine",
    GenericName: "Silver Sulfadiazine",
  },
  { id: 2456, BrandName: "Simcor", GenericName: "Simcor" },
  { id: 2457, BrandName: "Simponi", GenericName: "Simponi" },
  { id: 2458, BrandName: "Simulect", GenericName: "Simulect" },
  { id: 2459, BrandName: "Simvastatin", GenericName: "Simvastatin" },
  { id: 2460, BrandName: "Sincalide", GenericName: "Sincalide" },
  { id: 2461, BrandName: "Sinemet", GenericName: "Sinemet" },
  { id: 2462, BrandName: "Sinemet Cr", GenericName: "Sinemet Cr" },
  { id: 2463, BrandName: "Sinequan", GenericName: "Sinequan" },
  { id: 2464, BrandName: "Singulair", GenericName: "Singulair" },
  { id: 2465, BrandName: "Sirolimus", GenericName: "Sirolimus" },
  {
    id: 2466,
    BrandName: "Sitagliptin Metformin Hcl",
    GenericName: "Sitagliptin Metformin Hcl",
  },
  {
    id: 2467,
    BrandName: "Sitagliptin Phosphate",
    GenericName: "Sitagliptin Phosphate",
  },
  { id: 2468, BrandName: "Skelaxin", GenericName: "Skelaxin" },
  { id: 2469, BrandName: "Skelid", GenericName: "Skelid" },
  { id: 2470, BrandName: "Slo-Phyllin", GenericName: "Slo-Phyllin" },
  { id: 2471, BrandName: "Slow-K", GenericName: "Slow-K" },
  { id: 2472, BrandName: "Sodium Acetate", GenericName: "Sodium Acetate" },
  {
    id: 2473,
    BrandName: "Sodium Bicarbonate",
    GenericName: "Sodium Bicarbonate",
  },
  {
    id: 2474,
    BrandName: "Sodium Ferric Gluconate",
    GenericName: "Sodium Ferric Gluconate",
  },
  { id: 2475, BrandName: "Sodium Fluoride", GenericName: "Sodium Fluoride" },
  {
    id: 2476,
    BrandName: "Sodium Hyaluronate",
    GenericName: "Sodium Hyaluronate",
  },
  {
    id: 2477,
    BrandName: "Sodium Iodide I 131",
    GenericName: "Sodium Iodide I 131",
  },
  { id: 2478, BrandName: "Sodium Lactate", GenericName: "Sodium Lactate" },
  { id: 2479, BrandName: "Sodium Oxybate", GenericName: "Sodium Oxybate" },
  {
    id: 2480,
    BrandName: "Sodium Phenylbutyrate",
    GenericName: "Sodium Phenylbutyrate",
  },
  {
    id: 2481,
    BrandName: "Sodium Phosphate Monobasic",
    GenericName: "Sodium Phosphate Monobasic",
  },
  {
    id: 2482,
    BrandName: "Sodium Polystyrene Sulfonate",
    GenericName: "Sodium Polystyrene Sulfonate",
  },
  {
    id: 2483,
    BrandName: "Sodium Sulfacetamide",
    GenericName: "Sodium Sulfacetamide",
  },
  { id: 2484, BrandName: "Solage", GenericName: "Solage" },
  { id: 2485, BrandName: "Solaraze", GenericName: "Solaraze" },
  {
    id: 2486,
    BrandName: "Solifenacin Succinate",
    GenericName: "Solifenacin Succinate",
  },
  { id: 2487, BrandName: "Soliris", GenericName: "Soliris" },
  { id: 2488, BrandName: "Solodyn", GenericName: "Solodyn" },
  { id: 2489, BrandName: "Soltamox", GenericName: "Soltamox" },
  { id: 2490, BrandName: "Solu Cortef", GenericName: "Solu Cortef" },
  { id: 2491, BrandName: "Solu Medrol", GenericName: "Solu Medrol" },
  { id: 2492, BrandName: "Soma", GenericName: "Soma" },
  { id: 2493, BrandName: "Soma Compound", GenericName: "Soma Compound" },
  { id: 2494, BrandName: "Somatrem", GenericName: "Somatrem" },
  { id: 2495, BrandName: "Somatropin", GenericName: "Somatropin" },
  { id: 2496, BrandName: "Somavert", GenericName: "Somavert" },
  { id: 2497, BrandName: "Sonata", GenericName: "Sonata" },
  { id: 2498, BrandName: "Sorafenib", GenericName: "Sorafenib" },
  { id: 2499, BrandName: "Soriatane", GenericName: "Soriatane" },
  { id: 2500, BrandName: "Sotalol", GenericName: "Sotalol" },
  { id: 2501, BrandName: "Sotalol Hcl", GenericName: "Sotalol Hcl" },
  { id: 2502, BrandName: "Sotradecol", GenericName: "Sotradecol" },
  { id: 2503, BrandName: "Sparfloxacin", GenericName: "Sparfloxacin" },
  { id: 2504, BrandName: "Spectazole", GenericName: "Spectazole" },
  { id: 2505, BrandName: "Spectinomycin", GenericName: "Spectinomycin" },
  { id: 2506, BrandName: "Spectracef", GenericName: "Spectracef" },
  { id: 2507, BrandName: "Spiriva", GenericName: "Spiriva" },
  { id: 2508, BrandName: "Spironolactone", GenericName: "Spironolactone" },
  {
    id: 2509,
    BrandName: "Spironolactone And Hydrochlorothiazide",
    GenericName: "Spironolactone And Hydrochlorothiazide",
  },
  { id: 2510, BrandName: "Sporanox", GenericName: "Sporanox" },
  {
    id: 2511,
    BrandName: "Sporanox Oral Solution",
    GenericName: "Sporanox Oral Solution",
  },
  { id: 2512, BrandName: "Sprintec", GenericName: "Sprintec" },
  { id: 2513, BrandName: "Sprix", GenericName: "Sprix" },
  { id: 2514, BrandName: "Sprycel", GenericName: "Sprycel" },
  { id: 2515, BrandName: "Stadol", GenericName: "Stadol" },
  { id: 2516, BrandName: "Stalevo", GenericName: "Stalevo" },
  { id: 2517, BrandName: "Starlix", GenericName: "Starlix" },
  { id: 2518, BrandName: "Staticin", GenericName: "Staticin" },
  { id: 2519, BrandName: "Stavudine", GenericName: "Stavudine" },
  { id: 2520, BrandName: "Stavzor", GenericName: "Stavzor" },
  { id: 2521, BrandName: "Staxyn", GenericName: "Staxyn" },
  { id: 2522, BrandName: "Stelara", GenericName: "Stelara" },
  { id: 2523, BrandName: "Stelazine", GenericName: "Stelazine" },
  { id: 2524, BrandName: "Stimate", GenericName: "Stimate" },
  { id: 2525, BrandName: "Strattera", GenericName: "Strattera" },
  { id: 2526, BrandName: "Streptase", GenericName: "Streptase" },
  { id: 2527, BrandName: "Streptokinase", GenericName: "Streptokinase" },
  { id: 2528, BrandName: "Streptomycin", GenericName: "Streptomycin" },
  { id: 2529, BrandName: "Streptozocin", GenericName: "Streptozocin" },
  { id: 2530, BrandName: "Striant", GenericName: "Striant" },
  { id: 2531, BrandName: "Stromectol", GenericName: "Stromectol" },
  { id: 2532, BrandName: "Strontium-89", GenericName: "Strontium-89" },
  { id: 2533, BrandName: "Suboxone", GenericName: "Suboxone" },
  { id: 2534, BrandName: "Succimer", GenericName: "Succimer" },
  {
    id: 2535,
    BrandName: "Succinylcholine Chloride",
    GenericName: "Succinylcholine Chloride",
  },
  { id: 2536, BrandName: "Sucraid", GenericName: "Sucraid" },
  { id: 2537, BrandName: "Sucralfate", GenericName: "Sucralfate" },
  { id: 2538, BrandName: "Sudafed", GenericName: "Sudafed" },
  { id: 2539, BrandName: "Sufenta", GenericName: "Sufenta" },
  {
    id: 2540,
    BrandName: "Sufentanil Citrate",
    GenericName: "Sufentanil Citrate",
  },
  { id: 2541, BrandName: "Sular", GenericName: "Sular" },
  { id: 2542, BrandName: "Sulconazole", GenericName: "Sulconazole" },
  { id: 2543, BrandName: "Sulfacetamide", GenericName: "Sulfacetamide" },
  {
    id: 2544,
    BrandName: "Sulfadoxine / Pyrimethamine",
    GenericName: "Sulfadoxine / Pyrimethamine",
  },
  { id: 2545, BrandName: "Sulfamethoxazole", GenericName: "Sulfamethoxazole" },
  { id: 2546, BrandName: "Sulfamylon", GenericName: "Sulfamylon" },
  { id: 2547, BrandName: "Sulfasalazine", GenericName: "Sulfasalazine" },
  { id: 2548, BrandName: "Sulfinpyrazone", GenericName: "Sulfinpyrazone" },
  { id: 2549, BrandName: "Sulindac", GenericName: "Sulindac" },
  { id: 2550, BrandName: "Sultrin", GenericName: "Sultrin" },
  { id: 2551, BrandName: "Sumatriptan", GenericName: "Sumatriptan" },
  { id: 2552, BrandName: "Sumavel Dosepro", GenericName: "Sumavel Dosepro" },
  { id: 2553, BrandName: "Sumycin", GenericName: "Sumycin" },
  { id: 2554, BrandName: "Sunitinib Malate", GenericName: "Sunitinib Malate" },
  { id: 2555, BrandName: "Supprelin La", GenericName: "Supprelin La" },
  { id: 2556, BrandName: "Suprane", GenericName: "Suprane" },
  { id: 2557, BrandName: "Suprax", GenericName: "Suprax" },
  { id: 2558, BrandName: "Surmontil", GenericName: "Surmontil" },
  { id: 2559, BrandName: "Survanta", GenericName: "Survanta" },
  { id: 2560, BrandName: "Sustiva", GenericName: "Sustiva" },
  { id: 2561, BrandName: "Sutent", GenericName: "Sutent" },
  { id: 2562, BrandName: "Symbicort", GenericName: "Symbicort" },
  { id: 2563, BrandName: "Symbyax", GenericName: "Symbyax" },
  { id: 2564, BrandName: "Symlin", GenericName: "Symlin" },
  { id: 2565, BrandName: "Symmetrel", GenericName: "Symmetrel" },
  { id: 2566, BrandName: "Synagis", GenericName: "Synagis" },
  { id: 2567, BrandName: "Synalar", GenericName: "Synalar" },
  { id: 2568, BrandName: "Synarel", GenericName: "Synarel" },
  { id: 2569, BrandName: "Synera", GenericName: "Synera" },
  { id: 2570, BrandName: "Synercid", GenericName: "Synercid" },
  { id: 2571, BrandName: "Synthroid", GenericName: "Synthroid" },
  { id: 2572, BrandName: "Synvisc", GenericName: "Synvisc" },
  { id: 2573, BrandName: "Syprine", GenericName: "Syprine" },
  { id: 2574, BrandName: "Tabloid", GenericName: "Tabloid" },
  { id: 2575, BrandName: "Taclonex", GenericName: "Taclonex" },
  { id: 2576, BrandName: "Taclonex Scalp", GenericName: "Taclonex Scalp" },
  { id: 2577, BrandName: "Tacrine", GenericName: "Tacrine" },
  { id: 2578, BrandName: "Tacrolimus", GenericName: "Tacrolimus" },
  { id: 2579, BrandName: "Tadalafil", GenericName: "Tadalafil" },
  { id: 2580, BrandName: "Tagamet", GenericName: "Tagamet" },
  { id: 2581, BrandName: "Talacen", GenericName: "Talacen" },
  { id: 2582, BrandName: "Talwin", GenericName: "Talwin" },
  { id: 2583, BrandName: "Talwin Nx", GenericName: "Talwin Nx" },
  { id: 2584, BrandName: "Tambocor", GenericName: "Tambocor" },
  { id: 2585, BrandName: "Tamiflu", GenericName: "Tamiflu" },
  {
    id: 2586,
    BrandName: "Tamoxifen Citrate",
    GenericName: "Tamoxifen Citrate",
  },
  {
    id: 2587,
    BrandName: "Tamsulosin Hydrochloride",
    GenericName: "Tamsulosin Hydrochloride",
  },
  { id: 2588, BrandName: "Tao", GenericName: "Tao" },
  { id: 2589, BrandName: "Tapazole", GenericName: "Tapazole" },
  { id: 2590, BrandName: "Tapentadol", GenericName: "Tapentadol" },
  { id: 2591, BrandName: "Tarceva", GenericName: "Tarceva" },
  { id: 2592, BrandName: "Targretin", GenericName: "Targretin" },
  { id: 2593, BrandName: "Tarka", GenericName: "Tarka" },
  { id: 2594, BrandName: "Tasigna", GenericName: "Tasigna" },
  { id: 2595, BrandName: "Tasmar", GenericName: "Tasmar" },
  { id: 2596, BrandName: "Taxol", GenericName: "Taxol" },
  { id: 2597, BrandName: "Taxotere", GenericName: "Taxotere" },
  { id: 2598, BrandName: "Tazarotene", GenericName: "Tazarotene" },
  { id: 2599, BrandName: "Tazorac", GenericName: "Tazorac" },
  {
    id: 2600,
    BrandName: "Technetium Tc 99M",
    GenericName: "Technetium Tc 99M",
  },
  {
    id: 2601,
    BrandName: "Tegaserod Maleate",
    GenericName: "Tegaserod Maleate",
  },
  { id: 2602, BrandName: "Tegretol", GenericName: "Tegretol" },
  { id: 2603, BrandName: "Tekturna", GenericName: "Tekturna" },
  { id: 2604, BrandName: "Tekturna Hct", GenericName: "Tekturna Hct" },
  { id: 2605, BrandName: "Telavancin", GenericName: "Telavancin" },
  { id: 2606, BrandName: "Telbivudine", GenericName: "Telbivudine" },
  { id: 2607, BrandName: "Telithromycin", GenericName: "Telithromycin" },
  { id: 2608, BrandName: "Telmisartan", GenericName: "Telmisartan" },
  {
    id: 2609,
    BrandName: "Telmisartan And Hydrochlorothiazide",
    GenericName: "Telmisartan And Hydrochlorothiazide",
  },
  { id: 2610, BrandName: "Temazepam", GenericName: "Temazepam" },
  { id: 2611, BrandName: "Temodar", GenericName: "Temodar" },
  { id: 2612, BrandName: "Temovate", GenericName: "Temovate" },
  { id: 2613, BrandName: "Temovate Scalp", GenericName: "Temovate Scalp" },
  { id: 2614, BrandName: "Temozolomide", GenericName: "Temozolomide" },
  { id: 2615, BrandName: "Temsirolimus", GenericName: "Temsirolimus" },
  { id: 2616, BrandName: "Tenecteplase", GenericName: "Tenecteplase" },
  { id: 2617, BrandName: "Teniposide", GenericName: "Teniposide" },
  {
    id: 2618,
    BrandName: "Tenofovir Disoproxil Fumarate",
    GenericName: "Tenofovir Disoproxil Fumarate",
  },
  { id: 2619, BrandName: "Tenoretic", GenericName: "Tenoretic" },
  { id: 2620, BrandName: "Tenormin", GenericName: "Tenormin" },
  { id: 2621, BrandName: "Tenuate", GenericName: "Tenuate" },
  {
    id: 2622,
    BrandName: "Terazol 3, Terazol 7",
    GenericName: "Terazol 3, Terazol 7",
  },
  { id: 2623, BrandName: "Terazosin Hcl", GenericName: "Terazosin Hcl" },
  { id: 2624, BrandName: "Terbinafine", GenericName: "Terbinafine" },
  {
    id: 2625,
    BrandName: "Terbinafine Hydrochloride",
    GenericName: "Terbinafine Hydrochloride",
  },
  {
    id: 2626,
    BrandName: "Terbutaline Sulfate",
    GenericName: "Terbutaline Sulfate",
  },
  { id: 2627, BrandName: "Terconazole", GenericName: "Terconazole" },
  { id: 2628, BrandName: "Teriparatide", GenericName: "Teriparatide" },
  { id: 2629, BrandName: "Terra-Cortril", GenericName: "Terra-Cortril" },
  { id: 2630, BrandName: "Terramycin", GenericName: "Terramycin" },
  { id: 2631, BrandName: "Teslac", GenericName: "Teslac" },
  { id: 2632, BrandName: "Teslascan", GenericName: "Teslascan" },
  { id: 2633, BrandName: "Tessalon", GenericName: "Tessalon" },
  { id: 2634, BrandName: "Testim", GenericName: "Testim" },
  { id: 2635, BrandName: "Testoderm", GenericName: "Testoderm" },
  { id: 2636, BrandName: "Testolactone", GenericName: "Testolactone" },
  { id: 2637, BrandName: "Testosterone", GenericName: "Testosterone" },
  { id: 2638, BrandName: "Testred", GenericName: "Testred" },
  { id: 2639, BrandName: "Tetanus Vaccine", GenericName: "Tetanus Vaccine" },
  { id: 2640, BrandName: "Tetrabenazine", GenericName: "Tetrabenazine" },
  { id: 2641, BrandName: "Tetracycline", GenericName: "Tetracycline" },
  { id: 2642, BrandName: "Tev-Tropin", GenericName: "Tev-Tropin" },
  { id: 2643, BrandName: "Teveten", GenericName: "Teveten" },
  { id: 2644, BrandName: "Teveten Hct", GenericName: "Teveten Hct" },
  { id: 2645, BrandName: "Thalidomide", GenericName: "Thalidomide" },
  { id: 2646, BrandName: "Thalitone", GenericName: "Thalitone" },
  { id: 2647, BrandName: "Thalomid", GenericName: "Thalomid" },
  { id: 2648, BrandName: "Theo-24", GenericName: "Theo-24" },
  { id: 2649, BrandName: "Theolair", GenericName: "Theolair" },
  { id: 2650, BrandName: "Theophylline", GenericName: "Theophylline" },
  {
    id: 2651,
    BrandName: "Theophylline Anhydrous",
    GenericName: "Theophylline Anhydrous",
  },
  { id: 2652, BrandName: "Theracys", GenericName: "Theracys" },
  { id: 2653, BrandName: "Thiabendazole", GenericName: "Thiabendazole" },
  { id: 2654, BrandName: "Thiethylperazine", GenericName: "Thiethylperazine" },
  { id: 2655, BrandName: "Thioguanine", GenericName: "Thioguanine" },
  {
    id: 2656,
    BrandName: "Thiopental Sodium",
    GenericName: "Thiopental Sodium",
  },
  { id: 2657, BrandName: "Thioridazine", GenericName: "Thioridazine" },
  { id: 2658, BrandName: "Thioridazine Hcl", GenericName: "Thioridazine Hcl" },
  { id: 2659, BrandName: "Thiotepa", GenericName: "Thiotepa" },
  { id: 2660, BrandName: "Thiothixene Hcl", GenericName: "Thiothixene Hcl" },
  { id: 2661, BrandName: "Thorazine", GenericName: "Thorazine" },
  { id: 2662, BrandName: "Thrombin", GenericName: "Thrombin" },
  { id: 2663, BrandName: "Thymalfasin", GenericName: "Thymalfasin" },
  { id: 2664, BrandName: "Thyrel Trh", GenericName: "Thyrel Trh" },
  { id: 2665, BrandName: "Thyro-Tabs", GenericName: "Thyro-Tabs" },
  { id: 2666, BrandName: "Thyrogen", GenericName: "Thyrogen" },
  { id: 2667, BrandName: "Thyroid Tablets", GenericName: "Thyroid Tablets" },
  { id: 2668, BrandName: "Thyrolar", GenericName: "Thyrolar" },
  { id: 2669, BrandName: "Thyrotropin Alfa", GenericName: "Thyrotropin Alfa" },
  {
    id: 2670,
    BrandName: "Tiagabine Hydrochloride",
    GenericName: "Tiagabine Hydrochloride",
  },
  { id: 2671, BrandName: "Tiazac", GenericName: "Tiazac" },
  { id: 2672, BrandName: "Ticarcillin", GenericName: "Ticarcillin" },
  { id: 2673, BrandName: "Ticlid", GenericName: "Ticlid" },
  { id: 2674, BrandName: "Ticlopidine Hcl", GenericName: "Ticlopidine Hcl" },
  { id: 2675, BrandName: "Tigan", GenericName: "Tigan" },
  { id: 2676, BrandName: "Tigecycline", GenericName: "Tigecycline" },
  { id: 2677, BrandName: "Tikosyn", GenericName: "Tikosyn" },
  { id: 2678, BrandName: "Tilade", GenericName: "Tilade" },
  { id: 2679, BrandName: "Timentin", GenericName: "Timentin" },
  { id: 2680, BrandName: "Timolide", GenericName: "Timolide" },
  { id: 2681, BrandName: "Timolol", GenericName: "Timolol" },
  { id: 2682, BrandName: "Timolol Maleate", GenericName: "Timolol Maleate" },
  { id: 2683, BrandName: "Timoptic", GenericName: "Timoptic" },
  { id: 2684, BrandName: "Tindamax", GenericName: "Tindamax" },
  { id: 2685, BrandName: "Tinidazole", GenericName: "Tinidazole" },
  { id: 2686, BrandName: "Tinzaparin", GenericName: "Tinzaparin" },
  { id: 2687, BrandName: "Tioconazole", GenericName: "Tioconazole" },
  {
    id: 2688,
    BrandName: "Tiotropium Bromide",
    GenericName: "Tiotropium Bromide",
  },
  { id: 2689, BrandName: "Tipranavir", GenericName: "Tipranavir" },
  { id: 2690, BrandName: "Tirofiban", GenericName: "Tirofiban" },
  { id: 2691, BrandName: "Tirosint", GenericName: "Tirosint" },
  { id: 2692, BrandName: "Tizanidine", GenericName: "Tizanidine" },
  { id: 2693, BrandName: "Tnkase", GenericName: "Tnkase" },
  { id: 2694, BrandName: "Tobi", GenericName: "Tobi" },
  { id: 2695, BrandName: "Tobradex", GenericName: "Tobradex" },
  { id: 2696, BrandName: "Tobramycin", GenericName: "Tobramycin" },
  { id: 2697, BrandName: "Tobrex", GenericName: "Tobrex" },
  { id: 2698, BrandName: "Tocainide Hcl", GenericName: "Tocainide Hcl" },
  { id: 2699, BrandName: "Tocilizumab", GenericName: "Tocilizumab" },
  { id: 2700, BrandName: "Tofranil", GenericName: "Tofranil" },
  { id: 2701, BrandName: "Tofranil-Pm", GenericName: "Tofranil-Pm" },
  { id: 2702, BrandName: "Tolazamide", GenericName: "Tolazamide" },
  { id: 2703, BrandName: "Tolcapone", GenericName: "Tolcapone" },
  { id: 2704, BrandName: "Tolectin", GenericName: "Tolectin" },
  { id: 2705, BrandName: "Tolinase", GenericName: "Tolinase" },
  { id: 2706, BrandName: "Tolmetin Sodium", GenericName: "Tolmetin Sodium" },
  {
    id: 2707,
    BrandName: "Tolterodine Tartrate",
    GenericName: "Tolterodine Tartrate",
  },
  { id: 2708, BrandName: "Tolvaptan", GenericName: "Tolvaptan" },
  { id: 2709, BrandName: "Tonocard", GenericName: "Tonocard" },
  { id: 2710, BrandName: "Topamax", GenericName: "Topamax" },
  { id: 2711, BrandName: "Topicort", GenericName: "Topicort" },
  { id: 2712, BrandName: "Topiramate", GenericName: "Topiramate" },
  { id: 2713, BrandName: "Topotecan", GenericName: "Topotecan" },
  {
    id: 2714,
    BrandName: "Topotecan Hydrochloride",
    GenericName: "Topotecan Hydrochloride",
  },
  { id: 2715, BrandName: "Toprol Xl", GenericName: "Toprol Xl" },
  { id: 2716, BrandName: "Toradol", GenericName: "Toradol" },
  { id: 2717, BrandName: "Torecan", GenericName: "Torecan" },
  { id: 2718, BrandName: "Toremifene", GenericName: "Toremifene" },
  { id: 2719, BrandName: "Torisel", GenericName: "Torisel" },
  { id: 2720, BrandName: "Torsemide", GenericName: "Torsemide" },
  {
    id: 2721,
    BrandName: "Tositumomab I-131",
    GenericName: "Tositumomab I-131",
  },
  { id: 2722, BrandName: "Totect", GenericName: "Totect" },
  { id: 2723, BrandName: "Toviaz", GenericName: "Toviaz" },
  { id: 2724, BrandName: "Tracleer", GenericName: "Tracleer" },
  { id: 2725, BrandName: "Tracrium", GenericName: "Tracrium" },
  { id: 2726, BrandName: "Tramadol", GenericName: "Tramadol" },
  { id: 2727, BrandName: "Tramadol Hcl", GenericName: "Tramadol Hcl" },
  { id: 2728, BrandName: "Trandate", GenericName: "Trandate" },
  { id: 2729, BrandName: "Trandolapril", GenericName: "Trandolapril" },
  {
    id: 2730,
    BrandName: "Trandolapril And Verapamil",
    GenericName: "Trandolapril And Verapamil",
  },
  { id: 2731, BrandName: "Tranexamic Acid", GenericName: "Tranexamic Acid" },
  { id: 2732, BrandName: "Transderm Nitro", GenericName: "Transderm Nitro" },
  { id: 2733, BrandName: "Transderm Scop", GenericName: "Transderm Scop" },
  { id: 2734, BrandName: "Tranxene", GenericName: "Tranxene" },
  { id: 2735, BrandName: "Tranylcypromine", GenericName: "Tranylcypromine" },
  { id: 2736, BrandName: "Trastuzumab", GenericName: "Trastuzumab" },
  { id: 2737, BrandName: "Trasylol", GenericName: "Trasylol" },
  { id: 2738, BrandName: "Travasol", GenericName: "Travasol" },
  { id: 2739, BrandName: "Travatan", GenericName: "Travatan" },
  { id: 2740, BrandName: "Travoprost", GenericName: "Travoprost" },
  {
    id: 2741,
    BrandName: "Trazodone Hydrochloride",
    GenericName: "Trazodone Hydrochloride",
  },
  { id: 2742, BrandName: "Treanda", GenericName: "Treanda" },
  { id: 2743, BrandName: "Trecator", GenericName: "Trecator" },
  { id: 2744, BrandName: "Trelstar", GenericName: "Trelstar" },
  { id: 2745, BrandName: "Trental", GenericName: "Trental" },
  { id: 2746, BrandName: "Treprostinil", GenericName: "Treprostinil" },
  {
    id: 2747,
    BrandName: "Treprostinil Sodium",
    GenericName: "Treprostinil Sodium",
  },
  { id: 2748, BrandName: "Tretinoin", GenericName: "Tretinoin" },
  { id: 2749, BrandName: "Trexall", GenericName: "Trexall" },
  { id: 2750, BrandName: "Treximet", GenericName: "Treximet" },
  { id: 2751, BrandName: "Tri-Luma", GenericName: "Tri-Luma" },
  { id: 2752, BrandName: "Tri-Sprintec", GenericName: "Tri-Sprintec" },
  { id: 2753, BrandName: "Triamcinolone", GenericName: "Triamcinolone" },
  {
    id: 2754,
    BrandName: "Triamcinolone Acetonide",
    GenericName: "Triamcinolone Acetonide",
  },
  { id: 2755, BrandName: "Triamterene", GenericName: "Triamterene" },
  { id: 2756, BrandName: "Triazolam", GenericName: "Triazolam" },
  { id: 2757, BrandName: "Tribenzor", GenericName: "Tribenzor" },
  { id: 2758, BrandName: "Tricor", GenericName: "Tricor" },
  { id: 2759, BrandName: "Tridione", GenericName: "Tridione" },
  { id: 2760, BrandName: "Trientine", GenericName: "Trientine" },
  { id: 2761, BrandName: "Triesence", GenericName: "Triesence" },
  { id: 2762, BrandName: "Trifluoperazine", GenericName: "Trifluoperazine" },
  { id: 2763, BrandName: "Trifluridine", GenericName: "Trifluridine" },
  { id: 2764, BrandName: "Triglide", GenericName: "Triglide" },
  { id: 2765, BrandName: "Trihexyphenidyl", GenericName: "Trihexyphenidyl" },
  { id: 2766, BrandName: "Trileptal", GenericName: "Trileptal" },
  { id: 2767, BrandName: "Trilipix", GenericName: "Trilipix" },
  { id: 2768, BrandName: "Trilisate", GenericName: "Trilisate" },
  { id: 2769, BrandName: "Trilyte", GenericName: "Trilyte" },
  { id: 2770, BrandName: "Trimethadione", GenericName: "Trimethadione" },
  {
    id: 2771,
    BrandName: "Trimethobenzamide Hydrochloride",
    GenericName: "Trimethobenzamide Hydrochloride",
  },
  { id: 2772, BrandName: "Trimethoprim", GenericName: "Trimethoprim" },
  {
    id: 2773,
    BrandName: "Trimethoprim And Sulfamethoxazole",
    GenericName: "Trimethoprim And Sulfamethoxazole",
  },
  {
    id: 2774,
    BrandName: "Trimetrexate Glucuronate",
    GenericName: "Trimetrexate Glucuronate",
  },
  { id: 2775, BrandName: "Trimipramine", GenericName: "Trimipramine" },
  { id: 2776, BrandName: "Trinessa", GenericName: "Trinessa" },
  {
    id: 2777,
    BrandName: "Triptorelin Pamoate",
    GenericName: "Triptorelin Pamoate",
  },
  { id: 2778, BrandName: "Trisenox", GenericName: "Trisenox" },
  { id: 2779, BrandName: "Tritec", GenericName: "Tritec" },
  { id: 2780, BrandName: "Trivaris", GenericName: "Trivaris" },
  { id: 2781, BrandName: "Trivora-28", GenericName: "Trivora-28" },
  { id: 2782, BrandName: "Trizivir", GenericName: "Trizivir" },
  { id: 2783, BrandName: "Trobicin", GenericName: "Trobicin" },
  { id: 2784, BrandName: "Troleandomycin", GenericName: "Troleandomycin" },
  { id: 2785, BrandName: "Trophamine", GenericName: "Trophamine" },
  { id: 2786, BrandName: "Trospium", GenericName: "Trospium" },
  { id: 2787, BrandName: "Trovafloxacin", GenericName: "Trovafloxacin" },
  { id: 2788, BrandName: "Trovan", GenericName: "Trovan" },
  { id: 2789, BrandName: "Trusopt", GenericName: "Trusopt" },
  { id: 2790, BrandName: "Truvada", GenericName: "Truvada" },
  { id: 2791, BrandName: "Trypan Blue", GenericName: "Trypan Blue" },
  { id: 2792, BrandName: "Tubersol", GenericName: "Tubersol" },
  { id: 2793, BrandName: "Tussionex", GenericName: "Tussionex" },
  { id: 2794, BrandName: "Twinrix", GenericName: "Twinrix" },
  { id: 2795, BrandName: "Twynsta", GenericName: "Twynsta" },
  { id: 2796, BrandName: "Tygacil", GenericName: "Tygacil" },
  { id: 2797, BrandName: "Tykerb", GenericName: "Tykerb" },
  { id: 2798, BrandName: "Tylenol", GenericName: "Tylenol" },
  {
    id: 2799,
    BrandName: "Tylenol With Codeine",
    GenericName: "Tylenol With Codeine",
  },
  { id: 2800, BrandName: "Tylox", GenericName: "Tylox" },
  { id: 2801, BrandName: "Typhoid Vaccine", GenericName: "Typhoid Vaccine" },
  { id: 2802, BrandName: "Tysabri", GenericName: "Tysabri" },
  { id: 2803, BrandName: "Tyvaso", GenericName: "Tyvaso" },
  { id: 2804, BrandName: "Tyzeka", GenericName: "Tyzeka" },
  { id: 2805, BrandName: "Ulesfia", GenericName: "Ulesfia" },
  { id: 2806, BrandName: "Uloric", GenericName: "Uloric" },
  { id: 2807, BrandName: "Ultane", GenericName: "Ultane" },
  { id: 2808, BrandName: "Ultiva", GenericName: "Ultiva" },
  { id: 2809, BrandName: "Ultracet", GenericName: "Ultracet" },
  { id: 2810, BrandName: "Ultram", GenericName: "Ultram" },
  { id: 2811, BrandName: "Ultram Er", GenericName: "Ultram Er" },
  { id: 2812, BrandName: "Ultrase", GenericName: "Ultrase" },
  { id: 2813, BrandName: "Ultravate", GenericName: "Ultravate" },
  { id: 2814, BrandName: "Ultravist", GenericName: "Ultravist" },
  { id: 2815, BrandName: "Unasyn", GenericName: "Unasyn" },
  { id: 2816, BrandName: "Uniphyl", GenericName: "Uniphyl" },
  { id: 2817, BrandName: "Uniretic", GenericName: "Uniretic" },
  { id: 2818, BrandName: "Unithroid", GenericName: "Unithroid" },
  { id: 2819, BrandName: "Univasc", GenericName: "Univasc" },
  {
    id: 2820,
    BrandName: "Unoprostone Isopropyl",
    GenericName: "Unoprostone Isopropyl",
  },
  { id: 2821, BrandName: "Urex", GenericName: "Urex" },
  { id: 2822, BrandName: "Urispas", GenericName: "Urispas" },
  { id: 2823, BrandName: "Urobiotic", GenericName: "Urobiotic" },
  { id: 2824, BrandName: "Urofollitropin", GenericName: "Urofollitropin" },
  { id: 2825, BrandName: "Urokinase", GenericName: "Urokinase" },
  { id: 2826, BrandName: "Uroxatral", GenericName: "Uroxatral" },
  { id: 2827, BrandName: "Urso", GenericName: "Urso" },
  { id: 2828, BrandName: "Ursodiol", GenericName: "Ursodiol" },
  { id: 2829, BrandName: "Ustekinumab", GenericName: "Ustekinumab" },
  { id: 2830, BrandName: "Uvadex", GenericName: "Uvadex" },
  {
    id: 2831,
    BrandName: "Vaccinia Immune Globulin",
    GenericName: "Vaccinia Immune Globulin",
  },
  { id: 2832, BrandName: "Vagifem", GenericName: "Vagifem" },
  { id: 2833, BrandName: "Vagistat-1", GenericName: "Vagistat-1" },
  {
    id: 2834,
    BrandName: "Valacyclovir Hydrochloride",
    GenericName: "Valacyclovir Hydrochloride",
  },
  { id: 2835, BrandName: "Valcyte", GenericName: "Valcyte" },
  { id: 2836, BrandName: "Valdecoxib", GenericName: "Valdecoxib" },
  {
    id: 2837,
    BrandName: "Valganciclovir Hcl",
    GenericName: "Valganciclovir Hcl",
  },
  { id: 2838, BrandName: "Valium", GenericName: "Valium" },
  { id: 2839, BrandName: "Valproate Sodium", GenericName: "Valproate Sodium" },
  { id: 2840, BrandName: "Valproic Acid", GenericName: "Valproic Acid" },
  { id: 2841, BrandName: "Valrubicin", GenericName: "Valrubicin" },
  { id: 2842, BrandName: "Valsartan", GenericName: "Valsartan" },
  {
    id: 2843,
    BrandName: "Valsartan And Hydrochlorothiazide",
    GenericName: "Valsartan And Hydrochlorothiazide",
  },
  { id: 2844, BrandName: "Valstar", GenericName: "Valstar" },
  { id: 2845, BrandName: "Valtrex", GenericName: "Valtrex" },
  { id: 2846, BrandName: "Valtropin", GenericName: "Valtropin" },
  { id: 2847, BrandName: "Valturna", GenericName: "Valturna" },
  {
    id: 2848,
    BrandName: "Vancomycin Hydrochloride",
    GenericName: "Vancomycin Hydrochloride",
  },
  { id: 2849, BrandName: "Vaniqa", GenericName: "Vaniqa" },
  { id: 2850, BrandName: "Vanos", GenericName: "Vanos" },
  { id: 2851, BrandName: "Vantin", GenericName: "Vantin" },
  { id: 2852, BrandName: "Vaprisol", GenericName: "Vaprisol" },
  { id: 2853, BrandName: "Vaqta", GenericName: "Vaqta" },
  { id: 2854, BrandName: "Vardenafil Hcl", GenericName: "Vardenafil Hcl" },
  { id: 2855, BrandName: "Varenicline", GenericName: "Varenicline" },
  {
    id: 2856,
    BrandName: "Varicella Virus Vaccine Live",
    GenericName: "Varicella Virus Vaccine Live",
  },
  { id: 2857, BrandName: "Varivax", GenericName: "Varivax" },
  { id: 2858, BrandName: "Vascor", GenericName: "Vascor" },
  { id: 2859, BrandName: "Vaseretic", GenericName: "Vaseretic" },
  { id: 2860, BrandName: "Vasocidin", GenericName: "Vasocidin" },
  { id: 2861, BrandName: "Vasopressin", GenericName: "Vasopressin" },
  { id: 2862, BrandName: "Vasotec", GenericName: "Vasotec" },
  { id: 2863, BrandName: "Vasovist", GenericName: "Vasovist" },
  { id: 2864, BrandName: "Vectibix", GenericName: "Vectibix" },
  { id: 2865, BrandName: "Vectical", GenericName: "Vectical" },
  { id: 2866, BrandName: "Velcade", GenericName: "Velcade" },
  { id: 2867, BrandName: "Velosulin", GenericName: "Velosulin" },
  { id: 2868, BrandName: "Veltin", GenericName: "Veltin" },
  {
    id: 2869,
    BrandName: "Venlafaxine Hydrochloride",
    GenericName: "Venlafaxine Hydrochloride",
  },
  { id: 2870, BrandName: "Venofer", GenericName: "Venofer" },
  { id: 2871, BrandName: "Ventavis", GenericName: "Ventavis" },
  { id: 2872, BrandName: "Ventolin Hfa", GenericName: "Ventolin Hfa" },
  { id: 2873, BrandName: "Vepesid", GenericName: "Vepesid" },
  { id: 2874, BrandName: "Veramyst", GenericName: "Veramyst" },
  { id: 2875, BrandName: "Verapamil", GenericName: "Verapamil" },
  { id: 2876, BrandName: "Verapamil Hcl", GenericName: "Verapamil Hcl" },
  {
    id: 2877,
    BrandName: "Verapamil Hydrochloride",
    GenericName: "Verapamil Hydrochloride",
  },
  { id: 2878, BrandName: "Verdeso", GenericName: "Verdeso" },
  { id: 2879, BrandName: "Veregen", GenericName: "Veregen" },
  { id: 2880, BrandName: "Verelan Pm", GenericName: "Verelan Pm" },
  { id: 2881, BrandName: "Vermox", GenericName: "Vermox" },
  { id: 2882, BrandName: "Verteporfin", GenericName: "Verteporfin" },
  { id: 2883, BrandName: "Vesanoid", GenericName: "Vesanoid" },
  { id: 2884, BrandName: "Vesicare", GenericName: "Vesicare" },
  { id: 2885, BrandName: "Vexol", GenericName: "Vexol" },
  { id: 2886, BrandName: "Vfend", GenericName: "Vfend" },
  { id: 2887, BrandName: "Viadur", GenericName: "Viadur" },
  { id: 2888, BrandName: "Viagra", GenericName: "Viagra" },
  { id: 2889, BrandName: "Vibativ", GenericName: "Vibativ" },
  { id: 2890, BrandName: "Vibramycin", GenericName: "Vibramycin" },
  {
    id: 2891,
    BrandName: "Vibramycin Intravenous",
    GenericName: "Vibramycin Intravenous",
  },
  { id: 2892, BrandName: "Vicodin", GenericName: "Vicodin" },
  { id: 2893, BrandName: "Vicodin Es", GenericName: "Vicodin Es" },
  { id: 2894, BrandName: "Vicodin Hp", GenericName: "Vicodin Hp" },
  { id: 2895, BrandName: "Vicoprofen", GenericName: "Vicoprofen" },
  { id: 2896, BrandName: "Victoza", GenericName: "Victoza" },
  { id: 2897, BrandName: "Vidarabine", GenericName: "Vidarabine" },
  { id: 2898, BrandName: "Vidaza", GenericName: "Vidaza" },
  { id: 2899, BrandName: "Videx", GenericName: "Videx" },
  { id: 2900, BrandName: "Videx Ec", GenericName: "Videx Ec" },
  { id: 2901, BrandName: "Vigabatrin", GenericName: "Vigabatrin" },
  { id: 2902, BrandName: "Vigamox", GenericName: "Vigamox" },
  { id: 2903, BrandName: "Vimovo", GenericName: "Vimovo" },
  { id: 2904, BrandName: "Vimpat", GenericName: "Vimpat" },
  {
    id: 2905,
    BrandName: "Vinblastine Sulfate",
    GenericName: "Vinblastine Sulfate",
  },
  { id: 2906, BrandName: "Vincasar Pfs", GenericName: "Vincasar Pfs" },
  {
    id: 2907,
    BrandName: "Vincristine Sulfate",
    GenericName: "Vincristine Sulfate",
  },
  {
    id: 2908,
    BrandName: "Vinorelbine Tartrate",
    GenericName: "Vinorelbine Tartrate",
  },
  { id: 2909, BrandName: "Viokase", GenericName: "Viokase" },
  { id: 2910, BrandName: "Vioxx", GenericName: "Vioxx" },
  { id: 2911, BrandName: "Vira-A", GenericName: "Vira-A" },
  { id: 2912, BrandName: "Viracept", GenericName: "Viracept" },
  { id: 2913, BrandName: "Viramune", GenericName: "Viramune" },
  { id: 2914, BrandName: "Virazole", GenericName: "Virazole" },
  { id: 2915, BrandName: "Viread", GenericName: "Viread" },
  { id: 2916, BrandName: "Viroptic", GenericName: "Viroptic" },
  { id: 2917, BrandName: "Visicol", GenericName: "Visicol" },
  { id: 2918, BrandName: "Visionblue", GenericName: "Visionblue" },
  { id: 2919, BrandName: "Visken", GenericName: "Visken" },
  { id: 2920, BrandName: "Vistaril", GenericName: "Vistaril" },
  { id: 2921, BrandName: "Vistide", GenericName: "Vistide" },
  { id: 2922, BrandName: "Visudyne", GenericName: "Visudyne" },
  { id: 2923, BrandName: "Vitamin A", GenericName: "Vitamin A" },
  { id: 2924, BrandName: "Vitamin C", GenericName: "Vitamin C" },
  { id: 2925, BrandName: "Vitamin K1", GenericName: "Vitamin K1" },
  { id: 2926, BrandName: "Vitrasert", GenericName: "Vitrasert" },
  { id: 2927, BrandName: "Vitravene", GenericName: "Vitravene" },
  { id: 2928, BrandName: "Vivactil", GenericName: "Vivactil" },
  { id: 2929, BrandName: "Vivaglobin", GenericName: "Vivaglobin" },
  { id: 2930, BrandName: "Vivelle-Dot", GenericName: "Vivelle-Dot" },
  { id: 2931, BrandName: "Vivitrol", GenericName: "Vivitrol" },
  { id: 2932, BrandName: "Voltaren", GenericName: "Voltaren" },
  {
    id: 2933,
    BrandName: "Voltaren Ophthalmic",
    GenericName: "Voltaren Ophthalmic",
  },
  { id: 2934, BrandName: "Voluven", GenericName: "Voluven" },
  { id: 2935, BrandName: "Voriconazole", GenericName: "Voriconazole" },
  { id: 2936, BrandName: "Vorinostat", GenericName: "Vorinostat" },
  { id: 2937, BrandName: "Vosol Hc", GenericName: "Vosol Hc" },
  { id: 2938, BrandName: "Vospire Er", GenericName: "Vospire Er" },
  { id: 2939, BrandName: "Votrient", GenericName: "Votrient" },
  { id: 2940, BrandName: "Vumon", GenericName: "Vumon" },
  { id: 2941, BrandName: "Vusion", GenericName: "Vusion" },
  { id: 2942, BrandName: "Vytorin", GenericName: "Vytorin" },
  { id: 2943, BrandName: "Vyvanse", GenericName: "Vyvanse" },
  { id: 2944, BrandName: "Warfarin Sodium", GenericName: "Warfarin Sodium" },
  { id: 2945, BrandName: "Welchol", GenericName: "Welchol" },
  { id: 2946, BrandName: "Wellbutrin", GenericName: "Wellbutrin" },
  { id: 2947, BrandName: "Wellbutrin Sr", GenericName: "Wellbutrin Sr" },
  { id: 2948, BrandName: "Wellbutrin Xl", GenericName: "Wellbutrin Xl" },
  { id: 2949, BrandName: "Westcort", GenericName: "Westcort" },
  { id: 2950, BrandName: "Wigraine", GenericName: "Wigraine" },
  { id: 2951, BrandName: "Winstrol", GenericName: "Winstrol" },
  { id: 2952, BrandName: "Xalatan", GenericName: "Xalatan" },
  { id: 2953, BrandName: "Xanax", GenericName: "Xanax" },
  { id: 2954, BrandName: "Xanax Xr", GenericName: "Xanax Xr" },
  { id: 2955, BrandName: "Xeloda", GenericName: "Xeloda" },
  { id: 2956, BrandName: "Xenazine", GenericName: "Xenazine" },
  { id: 2957, BrandName: "Xenical", GenericName: "Xenical" },
  { id: 2958, BrandName: "Xeomin", GenericName: "Xeomin" },
  { id: 2959, BrandName: "Xerese", GenericName: "Xerese" },
  { id: 2960, BrandName: "Xibrom", GenericName: "Xibrom" },
  { id: 2961, BrandName: "Xifaxan", GenericName: "Xifaxan" },
  { id: 2962, BrandName: "Xigris", GenericName: "Xigris" },
  { id: 2963, BrandName: "Xolair", GenericName: "Xolair" },
  { id: 2964, BrandName: "Xolegel", GenericName: "Xolegel" },
  { id: 2965, BrandName: "Xopenex", GenericName: "Xopenex" },
  { id: 2966, BrandName: "Xopenex Hfa", GenericName: "Xopenex Hfa" },
  { id: 2967, BrandName: "Xylocaine", GenericName: "Xylocaine" },
  {
    id: 2968,
    BrandName: "Xylocaine Viscous",
    GenericName: "Xylocaine Viscous",
  },
  { id: 2969, BrandName: "Xyntha", GenericName: "Xyntha" },
  { id: 2970, BrandName: "Xyrem", GenericName: "Xyrem" },
  { id: 2971, BrandName: "Xyzal", GenericName: "Xyzal" },
  { id: 2972, BrandName: "Yasmin", GenericName: "Yasmin" },
  { id: 2973, BrandName: "Yaz", GenericName: "Yaz" },
  {
    id: 2974,
    BrandName: "Yellow Fever Vaccine",
    GenericName: "Yellow Fever Vaccine",
  },
  { id: 2975, BrandName: "Yf-Vax", GenericName: "Yf-Vax" },
  { id: 2976, BrandName: "Yohimbine", GenericName: "Yohimbine" },
  {
    id: 2977,
    BrandName: "Yohimbine Hydrochloride",
    GenericName: "Yohimbine Hydrochloride",
  },
  { id: 2978, BrandName: "Zadaxin", GenericName: "Zadaxin" },
  { id: 2979, BrandName: "Zaditor", GenericName: "Zaditor" },
  { id: 2980, BrandName: "Zafirlukast", GenericName: "Zafirlukast" },
  { id: 2981, BrandName: "Zagam", GenericName: "Zagam" },
  { id: 2982, BrandName: "Zalcitabine", GenericName: "Zalcitabine" },
  { id: 2983, BrandName: "Zaleplon", GenericName: "Zaleplon" },
  { id: 2984, BrandName: "Zanaflex", GenericName: "Zanaflex" },
  { id: 2985, BrandName: "Zanamivir", GenericName: "Zanamivir" },
  { id: 2986, BrandName: "Zanosar", GenericName: "Zanosar" },
  { id: 2987, BrandName: "Zantac", GenericName: "Zantac" },
  { id: 2988, BrandName: "Zarontin", GenericName: "Zarontin" },
  { id: 2989, BrandName: "Zavesca", GenericName: "Zavesca" },
  { id: 2990, BrandName: "Zebeta", GenericName: "Zebeta" },
  { id: 2991, BrandName: "Zegerid", GenericName: "Zegerid" },
  { id: 2992, BrandName: "Zelapar", GenericName: "Zelapar" },
  { id: 2993, BrandName: "Zelnorm", GenericName: "Zelnorm" },
  { id: 2994, BrandName: "Zemaira", GenericName: "Zemaira" },
  { id: 2995, BrandName: "Zemplar", GenericName: "Zemplar" },
  { id: 2996, BrandName: "Zemuron", GenericName: "Zemuron" },
  { id: 2997, BrandName: "Zenapax", GenericName: "Zenapax" },
  { id: 2998, BrandName: "Zenpep", GenericName: "Zenpep" },
  { id: 2999, BrandName: "Zerit", GenericName: "Zerit" },
  { id: 3000, BrandName: "Zestoretic", GenericName: "Zestoretic" },
  { id: 3001, BrandName: "Zestril", GenericName: "Zestril" },
  { id: 3002, BrandName: "Zetia", GenericName: "Zetia" },
  { id: 3003, BrandName: "Zevalin", GenericName: "Zevalin" },
  { id: 3004, BrandName: "Ziac", GenericName: "Ziac" },
  { id: 3005, BrandName: "Ziagen", GenericName: "Ziagen" },
  { id: 3006, BrandName: "Ziana", GenericName: "Ziana" },
  { id: 3007, BrandName: "Ziconotide", GenericName: "Ziconotide" },
  { id: 3008, BrandName: "Zidovudine", GenericName: "Zidovudine" },
  { id: 3009, BrandName: "Zinacef", GenericName: "Zinacef" },
  { id: 3010, BrandName: "Zinc", GenericName: "Zinc" },
  { id: 3011, BrandName: "Zinecard", GenericName: "Zinecard" },
  { id: 3012, BrandName: "Zingo", GenericName: "Zingo" },
  { id: 3013, BrandName: "Ziprasidone", GenericName: "Ziprasidone" },
  { id: 3014, BrandName: "Zirgan", GenericName: "Zirgan" },
  { id: 3015, BrandName: "Zithromax", GenericName: "Zithromax" },
  { id: 3016, BrandName: "Zmax", GenericName: "Zmax" },
  { id: 3017, BrandName: "Zn-Dtpa", GenericName: "Zn-Dtpa" },
  { id: 3018, BrandName: "Zocor", GenericName: "Zocor" },
  { id: 3019, BrandName: "Zofran", GenericName: "Zofran" },
  { id: 3020, BrandName: "Zoledronic Acid", GenericName: "Zoledronic Acid" },
  { id: 3021, BrandName: "Zolinza", GenericName: "Zolinza" },
  { id: 3022, BrandName: "Zolmitriptan", GenericName: "Zolmitriptan" },
  { id: 3023, BrandName: "Zoloft", GenericName: "Zoloft" },
  {
    id: 3024,
    BrandName: "Zolpidem Tartrate",
    GenericName: "Zolpidem Tartrate",
  },
  { id: 3025, BrandName: "Zolpimist", GenericName: "Zolpimist" },
  { id: 3026, BrandName: "Zometa", GenericName: "Zometa" },
  { id: 3027, BrandName: "Zomig", GenericName: "Zomig" },
  { id: 3028, BrandName: "Zonalon", GenericName: "Zonalon" },
  { id: 3029, BrandName: "Zonegran", GenericName: "Zonegran" },
  { id: 3030, BrandName: "Zonisamide", GenericName: "Zonisamide" },
  { id: 3031, BrandName: "Zortress", GenericName: "Zortress" },
  { id: 3032, BrandName: "Zostavax", GenericName: "Zostavax" },
  {
    id: 3033,
    BrandName: "Zoster Vaccine Live",
    GenericName: "Zoster Vaccine Live",
  },
  { id: 3034, BrandName: "Zosyn", GenericName: "Zosyn" },
  { id: 3035, BrandName: "Zovia", GenericName: "Zovia" },
  { id: 3036, BrandName: "Zovirax", GenericName: "Zovirax" },
  { id: 3037, BrandName: "Zyban", GenericName: "Zyban" },
  { id: 3038, BrandName: "Zyclara", GenericName: "Zyclara" },
  { id: 3039, BrandName: "Zydone", GenericName: "Zydone" },
  { id: 3040, BrandName: "Zyflo", GenericName: "Zyflo" },
  { id: 3041, BrandName: "Zyflo Cr", GenericName: "Zyflo Cr" },
  { id: 3042, BrandName: "Zylet", GenericName: "Zylet" },
  { id: 3043, BrandName: "Zyloprim", GenericName: "Zyloprim" },
  { id: 3044, BrandName: "Zymar", GenericName: "Zymar" },
  { id: 3045, BrandName: "Zymaxid", GenericName: "Zymaxid" },
  { id: 3046, BrandName: "Zyprexa", GenericName: "Zyprexa" },
  { id: 3047, BrandName: "Zyprexa Relprevv", GenericName: "Zyprexa Relprevv" },
  { id: 3048, BrandName: "Zyrtec", GenericName: "Zyrtec" },
  { id: 3049, BrandName: "Zyrtec-D", GenericName: "Zyrtec-D" },
  { id: 3050, BrandName: "Zyvox", GenericName: "Zyvox" },
  { id: 3051, BrandName: "Amiodarone", GenericName: "Amiodarone" },
];
