import React, { useEffect } from "react";
import { useState, useContext } from "react";
import moment from "moment";
import {
  Name,
  SelectedOption,
  NameList,
  ShowAdd,
  ChooseTime,
  Times,
  EditMode,
  MedModalOpen,
  TempIndex,
  TempNamers,
  LabModalOpen,
  NotesModalOpen,
  DrainModalOpen,
  FluidsModalOpen,
  PrecModalOpen,
  DietModalOpen,
  DrainList,
  TxtsModalOpen,
} from "./App";
import {
  MdAccessibilityNew,
  MdAccessibleForward,
  MdAccountBalanceWallet,
  MdBedtime,
  MdBloodtype,
  MdConnectWithoutContact,
  MdFastfood,
  MdFavorite,
  MdMasks,
  MdMedicalServices,
  MdMedication,
  MdNoFood,
  MdOutlineEditNote,
  MdOutlineStarBorderPurple500,
  MdOutlineStarPurple500,
  MdOutlineWarning,
  MdPendingActions,
  MdPerson,
  MdStickyNote2,
  MdWarning,
} from "react-icons/md";
import {
  FaPills,
  FaStethoscope,
  FaVial,
  FaBook,
  FaPlus,
  FaRegCheckCircle,
  FaTerminal,
  FaTint,
  FaHeartbeat,
  FaBrain,
  FaLungs,
  FaHandshake,
  FaHandHoldingMedical,
  FaPoop,
  FaHeadSideCough,
  FaHandshakeSlash,
  FaToilet,
  FaHeadSideMask,
  FaHeadSideCoughSlash,
} from "react-icons/fa";

import {
  GiBrainstorm,
  GiEyedropper,
  GiFallingStar,
  GiGasMask,
  GiHeartBeats,
  GiLabCoat,
  GiMedicalDrip,
} from "react-icons/gi";
import { BiBody } from "react-icons/bi";
import { GrStatusWarning } from "react-icons/gr";
import { ReactComponent as PersonDrain } from "./personDots.svg";
import { ReactComponent as SwallowPrecautions } from "./swallowPrecautions.svg";
const CardDrain = ({
  nameCheck,
  names,
  handleCardClick,
  component,
  handleNameChange,
  nameListChange,
  indy,
}) => {
  const [name, setName] = useContext(Name);
  const [selectedOption, setSelectedOption] = useContext(SelectedOption); //default option
  const [change, setChange] = useState(false);
  const [nameList, setNameList] = useContext(NameList);
  const [showAdd, setShowAdd] = useContext(ShowAdd);
  const [chooseTime, setChooseTime] = useContext(ChooseTime);
  const [nameTemp, setNameTemp] = useState("");
  const [times, setTimes] = useContext(Times);
  const [editMode, setEditMode] = useContext(EditMode);
  const [medModalOpen, setMedModalOpen] = useContext(MedModalOpen);
  const [tempIndex, setTempIndex] = useContext(TempIndex);
  const [tempNamers, setTempNamers] = useContext(TempNamers);
  const [labModalOpen, setLabModalOpen] = useContext(LabModalOpen);
  const [notesModalOpen, setNotesModalOpen] = useContext(NotesModalOpen);
  const [drainList, setDrainList] = useContext(DrainList);
  const [fluidsModalOpen, setFluidsModalOpen] = useContext(FluidsModalOpen);
  const [drainModalOpen, setDrainModalOpen] = useContext(DrainModalOpen);
  const [precModalOpen, setPrecModalOpen] = useContext(PrecModalOpen);
  const [dietModalOpen, setDietModalOpen] = useContext(DietModalOpen);
  const [txtsModalOpen, setTxtsModalOpen] = useContext(TxtsModalOpen);
  const [checkTxt, setCheckTxt] = useState([]);
  let index = indy;

  const b = [1, 2, 3, { a: 1 }];

  const thisIsNotes = (index, i) => {
    let x = [...drainList];
    console.log("THISI IS THE drainList Notes");
    console.log(x[indy]);
    //setName(x[indy].name);
    //setSelectedOption("Txt");
    setChange(!change);
    //handleCardClickMeds(index, x[indy].names[index].name, "Txt");
  };
  const showNotesModal = (a, b) => {
    setNotesModalOpen(true);
  };

  const thisIsFluids = (index, i) => {
    console.log(drainList);
    console.log(drainList[indy].selectedOption);
    console.log(
      drainList[indy].selectedOption[
        drainList[indy].selectedOption.findIndex((a) => a.C)
      ]
    );
    console.log(drainList[indy].selectedOption.findIndex((a) => a.C));
    console.log(indy);
    setTempIndex(indy);
    setTempNamers(drainList[indy].name);
    setFluidsModalOpen(true);
  };

  const thisIsPrec = (index, i) => {
    console.log(drainList);
    console.log(drainList[indy].selectedOption);
    console.log(
      drainList[indy].selectedOption[
        drainList[indy].selectedOption.findIndex((a) => a.W)
      ]
    );
    console.log(drainList[indy].selectedOption.findIndex((a) => a.W));
    console.log(indy);
    setTempIndex(indy);
    setTempNamers(drainList[indy].name);
    setPrecModalOpen(true);
  };

  const thisIsDrains = (index, i) => {
    console.log(drainList);
    console.log(drainList[indy].selectedOption);
    console.log(
      drainList[indy].selectedOption[
        drainList[indy].selectedOption.findIndex((a) => a.D)
      ]
    );
    console.log(drainList[indy].selectedOption.findIndex((a) => a.D));
    console.log(indy);
    setTempIndex(indy);
    setTempNamers(drainList[indy].name);
    setDrainModalOpen(true);
  };

  const thisIsDiet = (index, i) => {
    console.log(drainList);
    console.log(drainList[indy].selectedOption);
    console.log(
      drainList[indy].selectedOption[
        drainList[indy].selectedOption.findIndex((a) => a.Di)
      ]
    );
    console.log(drainList[indy].selectedOption.findIndex((a) => a.Di));
    console.log(indy);
    setTempIndex(indy);
    setTempNamers(drainList[indy].name);
    setDietModalOpen(true);
  };

  const thisIsTele = (index, i) => {
    let drain = drainList;
    let tObject = drain[indy].selectedOption.findIndex((a) => a.T);
    let tStuff = drain[indy].selectedOption.findIndex((a) => a == "T");
    drain[indy].selectedOption[tObject] = "";
    drain[indy].selectedOption[tStuff] = "";
    drain[indy].selectedOption = drain[indy].selectedOption.filter(
      (a) => a != ""
    );
    console.log(drain);
    setDrainList(drain);
    setChange(!change);
  };

  const thisIsTxts = (index, i) => {
    console.log(drainList);
    console.log(drainList[indy].selectedOption);
    console.log(
      drainList[indy].selectedOption[
        drainList[indy].selectedOption.findIndex((a) => a.Txts)
      ]
    );
    console.log(drainList[indy].selectedOption.findIndex((a) => a.Txts));
    console.log(indy);
    setTempIndex(indy);
    setTempNamers(drainList[indy].name);
    setTxtsModalOpen(true);
  };

  const txtChecker = () => {
    console.log("THIS IS THE Checker for the names");
    console.log(names);
    console.log("end checker");
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log("ALERRTTTTTTTT");
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].fallRisk
      );
      if (
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].fallRisk == "Yes"
      ) {
        return true;
      }
    }
  };
  const dietChecker = () => {
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].fallRisk
      );
      if (names.selectedOption.filter((a) => a.Txts)[0].Txts[0].diet == "NPO") {
        return true;
      }
    }
  };
  const dietCheckerAm = () => {
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].fallRisk
      );
      if (
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].diet == "NPOAM"
      ) {
        return true;
      }
    }
  };

  const seizurePrecautionsCheck = () => {
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].seizureRisk
      );
      if (
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].seizureRisk ==
        "Yes"
      ) {
        return true;
      }
    }
  };

  const aspirationPrecautionsCheck = () => {
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0]
          .aspirationPrecautions
      );
      if (
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0]
          .aspirationPrecautions == "Yes"
      ) {
        return true;
      }
    }
  };
  const diabetesPrecautionsCheck = () => {
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log("THE GLUCOSE");
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].glucose
      );
      console.log("END GLUCOSSEE");
      if (
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].glucose != "" &&
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].glucose !=
          "N/A" &&
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].glucose
      ) {
        return true;
      }
    }
  };
  const precautionsPrecautionsCheck = () => {
    if (
      names.selectedOption.length > 0 &&
      names.selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log(
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].precautions
      );
      if (
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].precautions !=
          "" &&
        names.selectedOption.filter((a) => a.Txts)[0].Txts[0].precautions !=
          "Standard"
      ) {
        let x = names.selectedOption.filter((a) => a.Txts)[0].Txts[0]
          .precautions;

        switch (x) {
          case "Contact":
            return (
              <button
                className="btn bg-orange-600 rounded-lg  mb-1"
                style={{ flexGrow: 0, flexBasis: "100%" }}
                disabled
              >
                <div className="flex justify-around gap-1 ">
                  <MdPerson size={35} />
                  <MdWarning size={35} />
                </div>
              </button>
            );
            break;
          case "S.Contact":
            return (
              <button
                className="btn bg-orange-600 rounded-lg   mb-1"
                style={{ flexGrow: 0, flexBasis: "100%" }}
                disabled
              >
                <div className="flex justify-around gap-1 ">
                  <FaToilet size={35} />
                  <MdOutlineStarPurple500 size={35} />
                  <MdWarning size={35} />
                </div>
              </button>
            );
            break;
          case "Droplet":
            return (
              <button
                className="btn bg-orange-600 rounded-lg   mb-1"
                style={{ flexGrow: 0, flexBasis: "100%" }}
                disabled
              >
                <div className="flex justify-around gap-1">
                  <FaHeadSideMask size={35} />
                  <MdWarning size={35} />
                </div>
              </button>
            );
            break;
          case "Airborne":
            return (
              <button
                className="btn bg-orange-600 rounded-lg   mb-1"
                style={{ flexGrow: 0, flexBasis: "100%" }}
                disabled
              >
                <div className="flex justify-around gap-1">
                  <MdMasks size={35} />
                  <MdWarning size={35} />
                </div>
              </button>
            );
            break;
        }
      }
    }
  };

  useEffect(() => {
    txtChecker();
  }, []);

  const checkIfAnyPrecautions = () => {
    if (
      precautionsPrecautionsCheck() ||
      txtChecker() ||
      seizurePrecautionsCheck() ||
      dietChecker() ||
      dietCheckerAm() ||
      aspirationPrecautionsCheck() ||
      diabetesPrecautionsCheck()
    ) {
      return true;
    }
  };

  return (
    <div
      className="card-drain border-black border-1 rounded-lg"
      onClick={() => {
        editMode == true && handleCardClick();
      }}
    >
      <div className="card-time border-b-2 border-gray-200 mb-2">
        {names.name}
      </div>

      {names.name != "" &&
        nameList.includes(names.name) == true &&
        !component &&
        names.selectedOption.length > 0 && (
          <div
            key={index}
            className=""
            style={{ background: "transparent", border: 0 }}
          >
            <div className="card-options-container">
              {names.selectedOption.map((option, i) =>
                option == "Txt" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn btn-info"
                      onClick={() => thisIsNotes(index, i)}
                    >
                      <MdOutlineEditNote size={30} />
                    </button>
                  ) : (
                    <button key={i} className="btn btn-info">
                      <MdOutlineEditNote size={30} />
                    </button>
                  )
                ) : option == "Txts" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn rounded-lg bg-teal-500 "
                      onClick={() => thisIsTxts(index, i)}
                      style={{ height: "50px", flexGrow: 0, flexBasis: "100%" }}
                    >
                      <MdStickyNote2 size={40} />
                    </button>
                  ) : (
                    <button
                      key={i}
                      className="btn rounded-lg bg-teal-500 "
                      style={{ height: "50px", flexGrow: 0, flexBasis: "100%" }}
                    >
                      <MdStickyNote2 size={40} />
                    </button>
                  )
                ) : option == "C" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn rounded-lg bg-blue-600 "
                      onClick={() => thisIsFluids(index, i)}
                    >
                      <img src="./images/iv2.png" style={{ width: "35px" }} />
                      {/*<GiMedicalDrip size={25} />*/}
                    </button>
                  ) : (
                    <button key={i} className="btn rounded-lg bg-blue-600 ">
                      <img src="./images/iv2.png" style={{ width: "35px" }} />
                      {/*<GiMedicalDrip size={25} />*/}
                    </button>
                  )
                ) : option == "W" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn rounded-lg bg-yellow-500 "
                      onClick={() => thisIsPrec(index, i)}
                    >
                      <MdOutlineWarning size={25} />
                    </button>
                  ) : (
                    <button key={i} className="btn rounded-lg bg-yellow-500 ">
                      <MdOutlineWarning size={25} />
                    </button>
                  )
                ) : option == "D" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn rounded-lg bg-red-600 "
                      onClick={() => thisIsDrains(index, i)}
                    >
                      <PersonDrain style={{ fill: "white", height: "25px" }} />
                    </button>
                  ) : (
                    <button key={i} className="btn rounded-lg bg-red-600">
                      <PersonDrain style={{ fill: "white", height: "25px" }} />
                      {/*<img
                        src="./images/drains2.png"
                        style={{ width: "30px" }}
                  />*/}
                      {/*<BiBody size={23} />*/}
                    </button>
                  )
                ) : option == "Di" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn rounded-lg bg-gray-500 "
                      onClick={() => thisIsDiet(index, i)}
                    >
                      <MdPendingActions size={25} />
                    </button>
                  ) : (
                    <button key={i} className="btn rounded-lg bg-gray-500 ">
                      <MdPendingActions size={25} />
                    </button>
                  )
                ) : option == "T" ? (
                  editMode == false ? (
                    <button
                      key={i}
                      className="btn rounded-lg bg-red-800 "
                      style={{ color: "white", background: "red" }}
                      onClick={() => thisIsTele(index, i)}
                    >
                      <FaHeartbeat size={25} />
                    </button>
                  ) : (
                    <button
                      key={i}
                      className="btn rounded-lg bg-red-500 "
                      style={{ color: "white", background: "red" }}
                    >
                      <FaHeartbeat size={25} />
                    </button>
                  )
                ) : (
                  option == "CP" && (
                    <button key={i} className="btn btn-success">
                      <FaRegCheckCircle />
                    </button>
                  )
                )
              )}
              {checkIfAnyPrecautions() && (
                <>
                  <hr className="m-1" style={{ width: "100%" }} />
                  <div
                    style={{ width: "100%", fontSize: "20px" }}
                    className="card-time"
                  >
                    Precautions
                  </div>
                </>
              )}
              {precautionsPrecautionsCheck()}
              {txtChecker() && (
                <button className="btn bg-yellow-400 rounded-lg " disabled>
                  <GiFallingStar size={35} color={"black"} />
                </button>
              )}{" "}
              {seizurePrecautionsCheck() && (
                <button className="btn bg-yellow-400 rounded-lg " disabled>
                  <div>
                    <GiBrainstorm size={35} color={"black"} />
                  </div>
                </button>
              )}
              {dietChecker() && (
                <button className="btn bg-yellow-400 rounded-lg " disabled>
                  <MdNoFood size={35} color={"black"} />
                </button>
              )}
              {dietCheckerAm() && (
                <button className="btn  bg-yellow-400 rounded-lg " disabled>
                  <div>
                    <MdNoFood
                      size={35}
                      color={"black"}
                      className="custom-icon"
                    />
                    <MdBedtime
                      size={15}
                      color={"black"}
                      className="custom-icon"
                    />
                  </div>
                </button>
              )}
              {aspirationPrecautionsCheck() && (
                <button className="btn bg-yellow-400 rounded-lg " disabled>
                  <div>
                    <FaHeadSideCough size={35} color={"black"} />
                  </div>
                </button>
              )}
              {diabetesPrecautionsCheck() && (
                <button className="btn bg-yellow-400 rounded-lg " disabled>
                  <div>
                    <MdBloodtype size={35} color={"black"} />
                  </div>
                </button>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default CardDrain;
