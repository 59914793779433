import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
import "./App.css";
import { Radio, QRCode } from "antd";
import {
  MdAccessibilityNew,
  MdAccessibleForward,
  MdAccountBalanceWallet,
  MdBedtime,
  MdBloodtype,
  MdCheck,
  MdClose,
  MdDelete,
  MdEdit,
  MdEditCalendar,
  MdFavorite,
  MdMedicalServices,
  MdMedication,
  MdNewspaper,
  MdNoFood,
  MdOutlineDomainDisabled,
  MdOutlineEditNote,
  MdOutlineWarning,
  MdPendingActions,
  MdPerson,
  MdSave,
  MdSettings,
  MdStickyNote2,
  MdThumbUp,
  MdTimer10Select,
  MdWarning,
} from "react-icons/md";
import Card from "./Card";
import {
  Name,
  Times,
  SelectedOption,
  NameList,
  ShowAdd,
  ChooseTime,
  ModalOpen,
  EditMode,
  FirstTime,
  MedModalOpen,
  TempIndex,
  TempNamers,
  LabModalOpen,
  NotesModalOpen,
  ThePage,
  DrainList,
  FluidsModalOpen,
  PrecModalOpen,
  DrainModalOpen,
  DietModalOpen,
  TxtsModalOpen,
  ScheduledTimes,
  Shift,
  HandoffCheck,
  SettingMenu,
} from "./App";

import {
  FaPills,
  FaStethoscope,
  FaVial,
  FaBook,
  FaSave,
  FaSlidersH,
  FaTextHeight,
  FaTerminal,
  FaCheck,
  FaCheckCircle,
  FaBars,
  FaPlusCircle,
  FaTint,
  FaRegCheckCircle,
  FaLungs,
  FaBrain,
  FaHeadSideCough,
  FaToilet,
  FaHandshakeAltSlash,
  FaHeadSideMask,
  FaHeadSideCoughSlash,
} from "react-icons/fa";

import { ReactComponent as PersonDrain } from "./personDots.svg";
//continuous
import {
  GiBrainstorm,
  GiFallingStar,
  GiGasMask,
  GiHeartBeats,
  GiMedicalDrip,
} from "react-icons/gi";
//warnings
import { GrStatusWarning } from "react-icons/gr";
//drains
import { GiEyedropper } from "react-icons/gi";
//diet
import { MdFastfood } from "react-icons/md";

import {
  Button,
  Modal,
  Alert,
  message,
  List,
  Popconfirm,
  Drawer,
  Empty,
  theme,
} from "antd";
import NavB from "./NavB";
import { GiConsoleController } from "react-icons/gi";
import { Select, Space } from "antd";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { data } from "./medList";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { Form } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { lab } from "./LabTest";
import CardDrain from "./CardDrain";
import { BiBody } from "react-icons/bi";
import pako from "pako";
import { QrReader } from "react-qr-reader";
import MenuPages from "./MenuPages";
import Modalers from "./Modalers";
const Main = () => {
  const [name, setName] = useContext(Name);
  const [selectedOption, setSelectedOption] = useContext(SelectedOption); //default option
  const [change, setChange] = useState(false);
  const [nameList, setNameList] = useContext(NameList);
  const [showAdd, setShowAdd] = useContext(ShowAdd);
  const [chooseTime, setChooseTime] = useContext(ChooseTime);
  const [nameTemp, setNameTemp] = useState("");
  const [isModalOpen, setIsModalOpen] = useContext(ModalOpen);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [modalText, setModalText] = useState("");
  const [times, setTimes] = useContext(Times);
  const [editMode, setEditMode] = useContext(EditMode);
  const [tempChoice, setTempChoice] = useState("");
  const [firstTime, setFirstTime] = useContext(FirstTime);
  const [medModalOpen, setMedModalOpen] = useContext(MedModalOpen);
  const [tempIndex, setTempIndex] = useContext(TempIndex);
  const [tempNamers, setTempNamers] = useContext(TempNamers);
  const [tempMeds, setTempMeds] = useState([]);
  const [tempMed, setTempMed] = useState();
  const [labModalOpen, setLabModalOpen] = useContext(LabModalOpen);
  const [notesModalOpen, setNotesModalOpen] = useContext(NotesModalOpen);
  const [tempLab, setTempLab] = useState();
  const [tempNotes, setTempNotes] = useState();
  const [deleter, setDeleter] = useState(false);
  const [selected, setSelected] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [shift, setShift] = useContext(Shift);

  const [selectedLab, setSelectedLab] = useState("");
  const [inputValueLab, setInputValueLab] = useState("");
  const [filteredDataLab, setFilteredDataLab] = useState([]);
  const [selectedValueLab, setSelectedValueLab] = useState("");

  //drain stuff
  const [thePage, setThePage] = useContext(ThePage);
  const [drainList, setDrainList] = useContext(DrainList);
  const [fluidsModalOpen, setFluidsModalOpen] = useContext(FluidsModalOpen);
  const [precModalOpen, setPrecModalOpen] = useContext(PrecModalOpen);
  const [drainModalOpen, setDrainModalOpen] = useContext(DrainModalOpen);
  const [dietModalOpen, setDietModalOpen] = useContext(DietModalOpen);
  const [txtsModalOpen, setTxtsModalOpen] = useContext(TxtsModalOpen);
  const [tempFluids, setTempFluids] = useState("");
  const [tempDrains, setTempDrains] = useState("");
  const [tempDiet, setTempDiet] = useState("");
  const [tempPrec, setTempPrec] = useState("");

  const [timerSet, setTimerSet] = useState(false);

  const inputRef = useRef(null);
  const inputRefLabs = useRef(null);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleConfirmationModal = (e) => {
    if (e) {
      eraseEverythingShift();
      setConfirmationModal(false);
    } else {
      setConfirmationModal(false);
    }
  };
  useEffect(() => {
    if (confirmationModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflow = ""; // Or 'auto' to explicitly make it scrollable again
    }
  }, [confirmationModal]);

  //fixing names
  const [tempName, setTempName] = useState("");
  //ERRORRRRR
  const [hasError, setHasError] = useState(false);
  const [sError, setSError] = useState("");
  const [pageError, setPageError] = useState(false);

  //notification stuff
  const [messageApi, contextHolder] = message.useMessage();

  //subtraction of notification
  const [subtractionAmount, setSubtractionAmount] = useState(0);

  //changeMenu to Qr code
  const [tempGetHandoff, setTempGetHandoff] = useState(null);
  const [settingMenu, setSettingMenu] = useContext(SettingMenu);
  const [nameQr, setNameQr] = useState(
    nameList.length > 0 ? nameList[0] : "No Names Found"
  );
  const [tempData, setTempData] = useState([]);
  const initQrData = (e) => {
    let name = e;
    let drain = [...drainList];
    let info = drain.find((a) => a.name == name);
    console.log(name);
    console.log("this is the infor");
    console.log(info);
    console.log(name);
    return info;
  };

  const [dataQr, setDataQr] = useState(
    nameList.length > 0 ? initQrData(nameList[0]) : "No Patients Found"
  );

  const qrData = (e) => {
    let name = e;

    let drain = [...drainList];
    let info = drain.find((a) => a.name == name);
    console.log(name);
    console.log("this is the infor");
    console.log(info);
    console.log(name);
    setDataQr(info);
  };

  useEffect(() => {
    console.log("this is the data qr");
    console.log(dataQr);
  }, [dataQr]);
  const [readQrData, setReadQrData] = useState("");

  const handleScanQr = (data) => {
    if (data) {
      alert("something");
      try {
        let decoded = atob(data);
        let jsonData = JSON.parse(decoded);
        console.log(jsonData);
        addQrToPatients(jsonData);
      } catch (error) {
        console.error("Error inflating data:", error);
      }
    }
  };

  const addQrToPatients = (data) => {
    let drain = [...drainList];
    let drainCheck = drain.findIndex((a) => a.name == data.name);
    let name = [...nameList];
    if (drainCheck == -1) {
      setTempGetHandoff(data.name);
      setTempData(data);
    } else {
      setTempData(false);
      setTempGetHandoff(data.name);
    }
  };

  const addQrToPatients2 = () => {
    let data = tempData;
    let drain = [...drainList];
    let drainCheck = drain.findIndex((a) => a.name == data.name);
    let name = [...nameList];
    if (drainCheck == -1) {
      drain.push(data);
      setDrainList(drain);
      name.push(data.name);
      setNameList(name);
      setTempGetHandoff(null);
    }
  };

  const cancelQr = () => {
    setTempGetHandoff(null);
    setTempData();
  };

  const handleError = () => {
    alert("error");
  };
  const [handoffCheck, setHandoffCheck] = useContext(HandoffCheck);

  //END handoff

  //drawer
  const { token } = theme.useToken();
  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };
  //Scheduling Times

  const [scheduledTimes, setScheduledTimes] = useContext(ScheduledTimes);

  const scheduleChanger = (shift) => {
    switch (shift) {
      case "dayShift":
        setScheduledTimes({
          neuroChecks: 7,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 7, shift: shift },
          vitals: 7,
        });
        setTimerSet(true);
        break;
      case "midDay":
        setScheduledTimes({
          neuroChecks: 11,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 11, shift: shift },
          vitals: 11,
        });
        setTimerSet(true);
        break;
      case "nightShift":
        setScheduledTimes({
          neuroChecks: 19,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 19, shift: shift },
          vitals: 19,
        });
        setTimerSet(true);
        break;
      case "eveningShift":
        setScheduledTimes({
          neuroChecks: 15,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 15, shift: shift },
          vitals: 15,
        });
        setTimerSet(true);
        break;
      case "eightEvening":
        setScheduledTimes({
          neuroChecks: 15,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 15, shift: shift },
          vitals: 15,
        });
        setTimerSet(true);
        break;
      case "eightNight":
        setScheduledTimes({
          neuroChecks: 23,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 23, shift: shift },
          vitals: 23,
        });
        setTimerSet(true);
        break;
      case "eightMorning":
        setScheduledTimes({
          neuroChecks: 7,
          bloodGlucose: { AC: [8, 12, 4], HS: 22, Start: 7, shift: shift },
          vitals: 7,
        });
        setTimerSet(true);
        break;
    }
  };

  const [selectedHourNeuro, setSelectedHourNeuro] = useState(0); // Initialize with 0 (midnight)

  useEffect(() => {
    console.log(scheduledTimes);
  }, [scheduledTimes]);

  const incrementHour = () => {
    setTimerSet(true);
    setScheduledTimes({
      ...scheduledTimes,
      neuroChecks:
        scheduledTimes.neuroChecks < 23 ? scheduledTimes.neuroChecks + 1 : 0,
    });
  };

  const decrementHour = () => {
    setTimerSet(true);
    setScheduledTimes({
      ...scheduledTimes,
      neuroChecks:
        scheduledTimes.neuroChecks > 0 ? scheduledTimes.neuroChecks - 1 : 23,
    });
  };

  const [subtractedChange, setSubtractedChange] = useState(false);

  //Scheduling Times End

  //drawer
  useEffect(() => {
    if (thePage == "D") {
      setSelectedOption("C");
      setTempNamers(-1);
    }
    if (thePage == "P") {
      setSelectedOption("B");
      setTempNamers(-1);
    }
  }, [thePage]);

  useEffect(() => {
    let drain = drainList;
    let drainNames = drainList.map((a) => a.name);
    let name = nameList;

    if (name.length >= drain.length) {
      for (let x of name) {
        if (drainNames.includes(x) == false) {
          console.log("this is appending");
          drain = [...drain, { name: x, selectedOption: [] }];
        }
      }
      setDrainList(drain);
      console.log(drain);
    } else if (name.length < drain.length) {
      drain = drain.filter((a) => name.includes(a.name));
      console.log(drain);
      setDrainList(drain);
    }
  }, [nameList]);

  useEffect(() => {
    if (nameList.length == 0) {
      localStorage.removeItem("drains");
    }
  }, [nameList]);

  useEffect(() => {
    console.log("THIS IS DRAIN LIST");
    console.log(drainList);
    console.log("THIS IS NAMELIST");
    console.log(nameList);
  }, [drainList]);

  //Lab INPUT
  const handleInputChangeLab = (event) => {
    setTempLab(event.target.value);
    console.log("THIS IS THE TMEP Lab");
    console.log(event.target.value);
    const inputText = event.target.value;
    //let dat = [...new Set(data)];
    let data = lab;
    const newFilteredData = data.filter((item) =>
      item.name.toLowerCase().includes(inputText.toLowerCase().trim())
    );
    console.log("this is the lab value");
    console.log(event.target.value);
    if (event.target.value == "") {
      setFilteredDataLab([]);
    } else {
      setFilteredDataLab(newFilteredData.slice(0, 5));
    }
    setInputValueLab(inputText);
    setSelectedValueLab("");
  };

  const handleItemClickLab = (item) => {
    setSelectedValueLab(item);
    setInputValueLab("");
    setFilteredDataLab([]);
    addLabs(item);
    inputRefLabs.current.focus();
  };

  const handleInputClickLabs = () => {
    setInputValueLab("");
    setFilteredDataLab([]);
  };

  const handleKeyDownAutoLabs = (event) => {
    if (event.key === "Enter" && filteredData.length > 0) {
      handleItemClickLab(filteredData[0].name);
    }
  };

  //END MEDICATION INPUT

  //MEDICATION INPUT
  const handleInputChange = (event) => {
    setTempMed(event.target.value);
    console.log("THIS IS THE TMEP MED");
    console.log(event.target.value);
    const inputText = event.target.value;
    //let dat = [...new Set(data)];
    let dat = data.filter(
      (a) => a.GenericName.toLowerCase() != a.BrandName.toLowerCase()
    );
    const newFilteredData = data.filter((item) =>
      item.BrandName.toLowerCase().includes(inputText.toLowerCase().trim())
    );
    if (inputText != "") {
      setFilteredData(newFilteredData.slice(0, 5));
    } else {
      setFilteredData([]);
    }
    setInputValue(inputText);
    setSelectedValue("");
  };

  const handleItemClick = (item) => {
    setSelectedValue(item);
    setInputValue("");
    setFilteredData([]);
    addMeds(item);
    inputRef.current.focus();
  };

  const handleInputClick = () => {
    setInputValue("");
    setFilteredData([]);
  };

  const handleKeyDownAuto = (event) => {
    if (event.key === "Enter" && filteredData.length > 0) {
      handleItemClick(
        filteredData[0].BrandName + " | " + filteredData[0].GenericName
      );
    }
  };

  //END MEDICATION INPUT

  useEffect(() => {
    if (times.length > 0) {
      console.log("TIMESSSS ARE SAVINGNGGG");
      localStorage.setItem("times", JSON.stringify(times));
      localStorage.setItem("shift", JSON.stringify(times));
    }
  }, [times]);

  /*useEffect(() => {
    let x = localStorage.getItem("subtractionAmount");
    console.log("THIS IS x");
    console.log(x);
    if (x !== undefined && x !== null && subtractionAmount != 0) {
      alert("does not equal null");
      localStorage.setItem("subtractionAmount", subtractionAmount);
    } else if (x !== undefined && x != null && subtractionAmount != x) {
      setSubtractionAmount(x);
    }
  }, [subtractionAmount]);*/
  //subtracting
  useEffect(() => {
    let x = localStorage.getItem("subtractionAmount");
    if (x) {
      setSubtractionAmount(Number(x));
      setSubtractedChange(true);
    } else {
      setSubtractedChange(true);
    }
  }, []);
  useEffect(() => {
    if (subtractedChange) {
      localStorage.setItem("subtractionAmount", subtractionAmount);
    }
  }, [subtractionAmount]);

  //Subtracting

  useEffect(() => {
    if (drainList.length > 0) {
      console.log("DRAINS ARE SAVINGNGGG");
      localStorage.setItem("drains", JSON.stringify(drainList));
    }
  }, [drainList]);

  useEffect(() => {
    console.log("This is the names please work");
    console.log(times);
    console.log("end names");
  });

  useEffect(() => {
    if (firstTime == true) {
      showModal2();
    }
  }, [firstTime]);

  const confirmTutorial = () => {
    localStorage.setItem("firstTime", JSON.stringify("V1"));
    setIsModalOpen2(false);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const nameListChange = (e) => {
    let x = nameList;
    setNameList([...nameList, e]);
    localStorage.setItem("nameList", JSON.stringify([...x, e]));

    //alert(e);
  };

  const chooseName = (a) => {
    showModal2();
    setTempChoice(a);
  };

  useEffect(() => {
    console.log("this is selected object");
    console.log(selectedOption);
  }, [selectedOption]);

  const handleCardClickDrain = (index, b) => {
    //let selectedOption = b;
    let newTimes = "";
    if (nameList.length == 0) {
      alert(
        "Please add a patient to start. You can add a patient in the Menu."
      );
    }
    let name = drainList[index].name;
    let newDrains = drainList;

    if (newDrains[index].selectedOption.includes(selectedOption) == false) {
      console.log("Not found in selected object");
      newDrains[index].selectedOption = [
        ...newDrains[index].selectedOption,
        selectedOption,
        selectedOption == "T"
          ? { T: [] }
          : selectedOption == "C"
          ? { C: [] }
          : selectedOption == "W"
          ? { W: [] }
          : selectedOption == "D"
          ? { D: [] }
          : selectedOption == "Di"
          ? { Di: [] }
          : selectedOption == "Txts" && {
              Txts: [
                {
                  orientation: "",
                  ambulation: "",
                  fallRisk: "",
                  specialCons: "",
                  diet: "",
                },
              ],
            },
      ];
      console.log("THIS IS NEW DRAINNSSSSSS");
      console.log(newDrains);
    } else {
      if (selectedOption == "T") {
        newDrains[index].selectedOption = newDrains[
          index
        ].selectedOption.filter((a) => !a.T && a != selectedOption);
        console.log("found it ");
        console.log(newDrains);
      }
      console.log("did not add anything to newdrains");
      console.log(newDrains);
    }

    if (name != "") {
      //SECTION IF ITS Fluids
      if (selectedOption == "C" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(name);
        setChange(!change);
        console.log(
          "THIS IS INDeX AND NAMERS BEFORE FLUIDS" + tempIndex + tempIndex
        );
        showFluidsModal();
      }
      //precautions
      if (selectedOption == "W" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(name);
        setChange(!change);
        showPrecModal();
      }
      //Drains
      if (selectedOption == "D" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(name);
        setChange(!change);
        showDrainModal();
      }
      //Diet
      if (selectedOption == "Di" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(name);
        setChange(!change);
        showDietModal();
      }

      if (selectedOption == "Txts" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(name);
        setChange(!change);
        showTxtsModal();
      }
    }
    localStorage.setItem("drains", JSON.stringify(newDrains));
    setChange(!change);
  };

  const handleCardClick = (index, b) => {
    //let selectedOption = b;
    if (nameList.length == 0) {
      alert(
        "Please add a patient to start. You can add a patient in the Menu."
      );
    }
    if (name != "") {
      const newTimes = [...times];

      const namers = newTimes[index].names.map((a) => a.name).indexOf(name);
      //SECTION IF ITS MEDICATIONS
      if (selectedOption == "M" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(namers);
        setChange(!change);
        showMedModal();
      }
      if (selectedOption == "L" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(namers);
        setChange(!change);
        showLabModal();
      }
      if (selectedOption == "Txt" && nameList.length > 0) {
        setTempIndex(index);
        setTempNamers(namers);
        setChange(!change);
        showNotesModal();
      }
      //SECTION IF ITS NOT MEDICATIONS

      if (namers > -1) {
        if (
          newTimes[index].names[namers].selectedOption.includes(
            selectedOption
          ) == false
        ) {
          if (selectedOption == "CP") {
            newTimes[index].names[namers].selectedOption = [selectedOption];
            console.log("This is the CP");
            console.log(newTimes[index].names[namers].selectedOption);
            setChange(!change);
          } else {
            console.log("its going to add something here");
            newTimes[index].names[namers].selectedOption = [
              ...newTimes[index].names[namers].selectedOption,
              selectedOption,
            ];
            //FILTER the CP
            newTimes[index].names[namers].selectedOption = newTimes[
              index
            ].names[namers].selectedOption.filter((a) => a != "CP");
            console.log("greater than -1");
            console.log(newTimes[index].names[namers].selectedOption);
          }
          setChange(!change);
        } else {
          if (
            selectedOption != "Txt" &&
            selectedOption != "M" &&
            selectedOption != "L"
          ) {
            let x = newTimes[index].names[namers].selectedOption.findIndex(
              (a) => a == selectedOption
            );
            console.log("this is the stuff");
            console.log(x);
            console.log("this is the namers");
            console.log(newTimes[index].names[namers]);

            //newTimes[index].names[namers] = [""];
            if (newTimes[index].names[namers].selectedOption.length > 1) {
              newTimes[index].names[namers].selectedOption[x] = [""];
            } else {
              newTimes[index].names[namers] = [""];
            }

            if (newTimes[index].names[namers].selectedOption) {
              newTimes[index].names[namers].selectedOption = newTimes[
                index
              ].names[namers].selectedOption.filter((a) => a != "");
            }
            console.log("NAMERS");
            console.log(newTimes[index].names[namers]);

            setChange(!change);
          }
        }
      } else {
        console.log(namers);
        newTimes[index].names.push({
          name: name,
          selectedOption: [selectedOption],
        });

        setTimes(newTimes);
        console.log(newTimes);
        localStorage.setItem("times", JSON.stringify(newTimes));
        localStorage.setItem("shift", JSON.stringify(newTimes));
        console.log("this is the new times");
        console.log(newTimes);
        console.log(namers);
        console.log(newTimes[index].names[namers]);
        console.log("end times");
        setChange(!change);
      }
    }
  };
  const clock = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  const dayShift = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  const midDay = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  const nightShift = [19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
  const eveningShift = [15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3];
  const eightEvening = [15, 16, 17, 18, 19, 20, 21, 22, 23];
  const eightNight = [23, 0, 1, 2, 3, 4, 5, 6, 7];
  const eightMorning = [7, 8, 9, 10, 11, 12, 13, 14, 15];

  const test = clock
    .map((a) => [{ time: moment().hours(a).minutes(0).seconds(0), names: [] }])
    .flat(1);

  const shiftChanger = (e) => {
    console.log(e);
    setShift(e);
    changeShift();
  };

  const eraseEverythingShift = () => {
    let test = times;
    console.log(test);
    console.log(test.map((a) => a.time));
    let x = 0;
    while (x < test.length) {
      test[x].names = [];
      x++;
    }

    //Delete Drains
    let drain = drainList;
    console.log(drainList);
    let y = 0;
    console.log("drain before");

    while (y < drain.length) {
      drain[y].selectedOption = [];
      y++;
    }
    console.log(drain);

    //Save everythign
    setTimes(test);
    saveEverything();
    handleNotificationClick();
    setConfirmationModal(false);
  };

  const changeShift = (shift) => {
    if (shift == "dayShift") {
      const test = dayShift
        .map((a) => [
          { time: moment().hours(a).minutes(0).seconds(0), names: [] },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("dayShift"));
      setShift("dayShift");
      setTimes(test);
    }
    if (shift == "midDay") {
      const test = midDay
        .map((a) => [
          { time: moment().hours(a).minutes(0).seconds(0), names: [] },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("midDay"));
      setShift("midDay");
      setTimes(test);
    }
    if (shift == "nightShift") {
      const test = nightShift
        .map((a) => [
          {
            time: moment()
              .hours(a)
              .minutes(0)
              .seconds(0)
              .add(a >= 0 && a < 8 ? 1 : 0, "days"),
            names: [],
          },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("nightShift"));
      setShift("nightShift");
      setTimes(test);
    }
    if (shift == "eveningShift") {
      const test = eveningShift
        .map((a) => [
          {
            time: moment()
              .hours(a)
              .minutes(0)
              .seconds(0)
              .add(a >= 0 && a < 8 ? 1 : 0, "days"),
            names: [],
          },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("eveningShift"));
      setShift("eveningShift");
      setTimes(test);
    }
    if (shift == "eightEvening") {
      const test = eightEvening
        .map((a) => [
          { time: moment().hours(a).minutes(0).seconds(0), names: [] },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("eightEvening"));
      setShift("eightEvening");
      setTimes(test);
    }
    if (shift == "eightNight") {
      const test = eightNight
        .map((a) => [
          {
            time: moment()
              .hours(a)
              .minutes(0)
              .seconds(0)
              .add(a >= 0 && a < 8 ? 1 : 0, "days"),
            names: [],
          },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("eightNight"));
      setShift("eightNight");
      setTimes(test);
    }
    if (shift == "eightMorning") {
      const test = eightMorning
        .map((a) => [
          { time: moment().hours(a).minutes(0).seconds(0), names: [] },
        ])
        .flat(1);
      localStorage.setItem("shift", JSON.stringify(test));
      localStorage.setItem("shiftType", JSON.stringify("eightMorning"));
      setShift("eightMorning");
      setTimes(test);
    }
  };

  useEffect(() => {
    if (name == "Add New Patient") {
      setShowAdd(true);
    }
  }, [name]);

  const saveEverything = () => {
    localStorage.setItem("shift", JSON.stringify(times));
    localStorage.setItem("nameList", JSON.stringify(nameList));
    localStorage.setItem("drains", JSON.stringify(drainList));
    //alert("saved");
  };

  useEffect(() => {
    if (nameList.length > 0) {
      localStorage.setItem("nameList", JSON.stringify(nameList));
    } else {
      localStorage.setItem("nameList", JSON.stringify([]));
    }
  }, [nameList]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpen2(false);
    setMedModalOpen(false);
    setSettingMenu("M");
    setTempGetHandoff(null);
    setThePage("P");
  };

  const handleOkPatient = () => {
    setIsModalOpen(false);
    setIsModalOpen2(false);
    setMedModalOpen(false);
    setSettingMenu("M");
    setTempGetHandoff(null);
  };
  const handleOk2 = (a) => {
    setIsModalOpen2(false);
    handleCardClick(tempChoice, a);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpen2(false);
    setMedModalOpen(false);
    setTempNamers(-1);
    setTempMed("");
  };

  useEffect(() => {
    if (medModalOpen) {
      document.body.style.overflowY = "hidden";
      //document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [medModalOpen]);

  //MEDICATION MODAL

  const showMedModal = (a, b) => {
    setMedModalOpen(true);
  };

  const showFluidsModal = (a, b) => {
    setFluidsModalOpen(true);
  };
  const showPrecModal = (a, b) => {
    setPrecModalOpen(true);
  };
  const showDrainModal = (a, b) => {
    setDrainModalOpen(true);
  };
  const showDietModal = (a, b) => {
    setDietModalOpen(true);
  };
  const showTxtsModal = (a, b) => {
    setTxtsModalOpen(true);
  };

  useEffect(() => {
    console.log(tempNamers);
    console.log(tempIndex);
  }, [tempIndex, tempNamers]);

  const addMeds = (ba) => {
    let newTimes = [...times];

    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.M
    );

    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    console.log(namers);
    setTempNamers(namers);
    console.log("THE TEMP IS HERE");
    //IF NO NAME IS FOUND IN CARD
    if (namers == -1) {
      times[tempIndex].names.push({
        name: name,
        selectedOption: [{ M: ba }],
      });
      let namers2 = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
      console.log("WE FOUND THE INDEX 2");
      console.log(namers2);
    } else {
      //IF NAME IS FOUND IN CARD
      if (indy > -1) {
        //IF INDEX IS FOUND FOR MEDICATIONS
        newTimes[tempIndex].names[namers].selectedOption[indy] = {
          M: [...newTimes[tempIndex].names[namers].selectedOption[indy].M, ba],
        };
      } else {
        newTimes[tempIndex].names[namers].selectedOption = [
          ...newTimes[tempIndex].names[namers].selectedOption,
          {
            M: [ba],
          },
        ];
      }
    }
    console.log("What is this temporary bullshit");
    setTimes(newTimes);
    console.log(newTimes);
    localStorage.setItem("times", JSON.stringify(newTimes));
    localStorage.setItem("shift", JSON.stringify(newTimes));
    console.log("this is the new times");
    console.log(newTimes);
    setInputValue("");
    setFilteredData([]);
    setChange(!change);
    setTempMed("");
  };

  const confirmMed = () => {
    setMedModalOpen(false);
    setTempNamers(-1);
    setTempMed("");
    setInputValue("");
  };

  const deleteMedication = (ind, na) => {
    let newTimes = [...times];
    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.M
    );
    console.log("this is the filtered name");
    console.log(na);
    console.log("this is the filter");
    console.log(
      newTimes[tempIndex].names[namers].selectedOption[indy].M.filter(
        (b) => b != na
      )
    );
    let it = newTimes[tempIndex].names[namers].selectedOption[indy].M.filter(
      (b) => b != na
    );
    newTimes[tempIndex].names[namers].selectedOption[indy].M = it;
    setTimes(newTimes);
    setChange(!change);
    console.log(newTimes);
  };

  const completeMedication = () => {
    setMedModalOpen(false);
    let newTimes = [...times];
    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.M
    );
    let indy2 = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b == "M"
    );

    console.log("COMPLETING MEDICATION");
    console.log(newTimes[tempIndex].names[namers].selectedOption);

    console.log("THIS IS INDYYYY");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    console.log("THIS IS INDY 2");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy2]);
    if (newTimes[tempIndex].names[namers].selectedOption[indy]) {
      console.log("BEFORE INDY");
      console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
      newTimes[tempIndex].names[namers].selectedOption[indy] = "CP";
      console.log("AFTER INDY");
      console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    }
    if (newTimes[tempIndex].names[namers].selectedOption[indy2]) {
      console.log("before indy 2");
      console.log(newTimes[tempIndex].names[namers].selectedOption[indy2]);
      newTimes[tempIndex].names[namers].selectedOption[indy2] = [""];
      console.log("after indy 2");
      console.log(newTimes[tempIndex].names[namers].selectedOption[indy2]);
    }

    if (!newTimes[tempIndex].names[namers].selectedOption[indy]) {
      newTimes[tempIndex].names[namers].selectedOption[indy2] = "CP";
    }

    newTimes[tempIndex].names[namers].selectedOption = newTimes[
      tempIndex
    ].names[namers].selectedOption.filter((a) => a != "");
    console.log("this is hte full selected option");
    console.log(newTimes[tempIndex].names[namers].selectedOption);
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);

    newTimes[tempIndex].names[namers].selectedOption = [
      ...new Set(newTimes[tempIndex].names[namers].selectedOption),
    ];
    console.log("after set");
    console.log(newTimes[tempIndex].names[namers].selectedOption);
    setTimes(newTimes);
  };

  const deleteList = (b) => {
    let tim = [...times];

    console.log(tempIndex);
    console.log(tempNamers);
    console.log(tim[tempIndex].names[tempNamers]);

    tim[tempIndex].names[tempNamers].selectedOption = tim[tempIndex]?.names[
      tempNamers
    ]?.selectedOption.filter((a) => Object.keys(a) != b && a != b);
    setTimes(tim);
    setMedModalOpen(false);
    setLabModalOpen(false);
    setNotesModalOpen(false);
    console.log(tim);
  };

  const deleteDrainList = (b) => {
    setFluidsModalOpen(false);
    setDrainModalOpen(false);
    setDietModalOpen(false);
    setNotesModalOpen(false);
    setPrecModalOpen(false);
    setTxtsModalOpen(false);
    let drain = drainList;
    console.log(drain[tempIndex].selectedOption);
    drain[tempIndex].selectedOption = drain[tempIndex].selectedOption.filter(
      (a) => Object.keys(a) != b && a != b && a != ""
    );
    console.log(drain[tempIndex].selectedOption);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
  };

  useEffect(() => {
    console.log("This is the temp index and temp namers");
    console.log(tempIndex);
    console.log(tempNamers);
    console.log("End Temp index temp namers");
  }, [tempIndex, tempNamers]);

  //END MEDICATION MODAL

  //NOTE MODAL

  const showNotesModal = (a, b) => {
    setNotesModalOpen(true);
  };

  //Adding FLuidssssssss
  const addFluids = (a) => {
    let drain = drainList;
    let indOfFluids = drainList[tempIndex].selectedOption.findIndex(
      (a) => typeof a == "object" && a.C != undefined
    );
    console.log("this is a ");
    console.log(a);
    console.log("this is drain in addfluids");
    console.log(drain);
    console.log("this is the index of it ");
    console.log(
      drainList[tempIndex].selectedOption.findIndex(
        (a) => typeof a == "object" && a.C != undefined
      )
    );
    console.log(drain[tempIndex].selectedOption[indOfFluids].C);
    drain[tempIndex].selectedOption[indOfFluids].C = [
      ...drain[tempIndex].selectedOption[indOfFluids].C,
      tempFluids,
    ];
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setTempFluids("");
    setChange(!change);
  };
  //Adding Fluidssss

  //Adding Prec
  const addPrec = (a) => {
    let drain = drainList;
    let indOfFluids = drainList[tempIndex].selectedOption.findIndex(
      (a) => typeof a == "object" && a.W != undefined
    );
    console.log("this is a ");
    console.log(a);
    console.log("this is drain in addprec");
    console.log(drain);
    console.log("this is the index of it ");
    console.log(
      drainList[tempIndex].selectedOption.findIndex(
        (a) => typeof a == "object" && a.W != undefined
      )
    );
    console.log(drain[tempIndex].selectedOption[indOfFluids].W);
    drain[tempIndex].selectedOption[indOfFluids].W = [
      ...drain[tempIndex].selectedOption[indOfFluids].W,
      tempPrec,
    ];
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setTempPrec("");
    setChange(!change);
  };
  //Adding Prec

  //Adding Drains
  const addDrains = (a) => {
    let drain = drainList;
    let indOfFluids = drainList[tempIndex].selectedOption.findIndex(
      (a) => typeof a == "object" && a.D != undefined
    );
    console.log("this is a ");
    console.log(a);
    console.log("this is drain in addDrains");
    console.log(drain);
    console.log("this is the index of it ");
    console.log(
      drainList[tempIndex].selectedOption.findIndex(
        (a) => typeof a == "object" && a.D != undefined
      )
    );
    console.log(drain[tempIndex].selectedOption[indOfFluids].D);
    drain[tempIndex].selectedOption[indOfFluids].D = [
      ...drain[tempIndex].selectedOption[indOfFluids].D,
      tempDrains,
    ];
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setTempDrains("");
    setChange(!change);
  };
  //Adding Drains

  //Adding Diet
  const addDiet = (a) => {
    let drain = drainList;
    let indOfFluids = drainList[tempIndex].selectedOption.findIndex(
      (a) => typeof a == "object" && a.Di != undefined
    );
    console.log("this is a ");
    console.log(a);
    console.log("this is drain in addDrains");
    console.log(drain);
    console.log("this is the index of it ");
    console.log(
      drainList[tempIndex].selectedOption.findIndex(
        (a) => typeof a == "object" && a.Di != undefined
      )
    );
    console.log(drain[tempIndex].selectedOption[indOfFluids].Di);
    drain[tempIndex].selectedOption[indOfFluids].Di = [
      ...drain[tempIndex].selectedOption[indOfFluids].Di,
      tempDiet,
    ];
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setTempDiet("");
    setChange(!change);
  };
  //Adding Diet

  const addNotes = () => {
    let newTimes = [...times];

    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.Txt
    );

    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    console.log(namers);
    setTempNamers(namers);
    console.log("THE TEMP IS HERE");
    //IF NO NAME IS FOUND IN CARD
    if (namers == -1) {
      times[tempIndex].names.push({
        name: name,
        selectedOption: [{ Txt: tempNotes }],
      });
      let namers2 = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
      console.log("WE FOUND THE INDEX 2");
      console.log(namers2);
    } else {
      //IF NAME IS FOUND IN CARD
      if (indy > -1) {
        //IF INDEX IS FOUND FOR MEDICATIONS
        newTimes[tempIndex].names[namers].selectedOption[indy] = {
          Txt: [
            ...newTimes[tempIndex].names[namers].selectedOption[indy].Txt,
            tempNotes,
          ],
        };
      } else {
        newTimes[tempIndex].names[namers].selectedOption = [
          ...newTimes[tempIndex].names[namers].selectedOption,
          {
            Txt: [tempNotes],
          },
        ];
      }
    }
    console.log("What is this temporary bullshit");
    setTimes(newTimes);
    console.log(newTimes);
    localStorage.setItem("times", JSON.stringify(newTimes));
    localStorage.setItem("shift", JSON.stringify(newTimes));
    console.log("this is the new times");
    console.log(newTimes);
    setTempNotes("");
    setChange(!change);
  };

  const confirmNotes = () => {
    setNotesModalOpen(false);
    setTempNamers(-1);
    setTempNotes("");
    console.log("Setting the otes model open to fasle");
  };

  const confirmFluids = () => {
    setFluidsModalOpen(false);
    setTempNamers(-1);
    setTempFluids("");
    console.log("Setting the fluids model open to fasle");
    localStorage.setItem("drains", JSON.stringify(drainList));
  };

  const confirmPrec = () => {
    setPrecModalOpen(false);
    setTempNamers(-1);
    setTempPrec("");
    console.log("Setting the prec model open to fasle");
    localStorage.setItem("drains", JSON.stringify(drainList));
  };

  const confirmDrains = () => {
    setDrainModalOpen(false);
    setTempNamers(-1);
    setTempDrains("");
    console.log("Setting the drain model open to fasle");
    localStorage.setItem("drains", JSON.stringify(drainList));
  };

  const confirmDiet = () => {
    setDietModalOpen(false);
    setTempNamers(-1);
    setTempDiet("");
    console.log("Setting the diet model open to fasle");
    localStorage.setItem("drains", JSON.stringify(drainList));
  };
  const confirmTxts = () => {
    setTxtsModalOpen(false);
    setTempNamers(-1);
    setTempNotes("");
    console.log("Setting the Txts model open to fasle");
    localStorage.setItem("drains", JSON.stringify(drainList));
  };

  const deleteFluids = (ind, na) => {
    let drain = drainList;
    console.log(drain);
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.C);
    console.log("this is delete fludis");
    console.log(indy);
    let deleteList = drain[tempIndex].selectedOption[indy].C.filter(
      (a) => a != na
    );
    drain[tempIndex].selectedOption[indy].C = deleteList;
    console.log(deleteList);
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setChange(!change);
  };

  const deletePrec = (ind, na) => {
    let drain = drainList;
    console.log(drain);
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.W);
    console.log("this is delete Prec");
    console.log(indy);
    let deleteList = drain[tempIndex].selectedOption[indy].W.filter(
      (a) => a != na
    );
    drain[tempIndex].selectedOption[indy].W = deleteList;
    console.log(deleteList);
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setChange(!change);
  };

  const deleteDrains = (ind, na) => {
    let drain = drainList;
    console.log(drain);
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.D);
    console.log("this is delete Drains");
    console.log(indy);
    let deleteList = drain[tempIndex].selectedOption[indy].D.filter(
      (a) => a != na
    );
    drain[tempIndex].selectedOption[indy].D = deleteList;
    console.log(deleteList);
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setChange(!change);
  };

  const deleteDiet = (ind, na) => {
    let drain = drainList;
    console.log(drain);
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.Di);
    console.log("this is delete Drains");
    console.log(indy);
    let deleteList = drain[tempIndex].selectedOption[indy].Di.filter(
      (a) => a != na
    );
    drain[tempIndex].selectedOption[indy].Di = deleteList;
    console.log(deleteList);
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setChange(!change);
  };

  const deleteNotes = (ind, na) => {
    let newTimes = [...times];
    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.Txt
    );
    console.log("this is the filtered name");
    console.log(na);
    console.log("this is the filter");
    console.log(
      newTimes[tempIndex].names[namers].selectedOption[indy].Txt.filter(
        (b) => b != na
      )
    );
    let it = newTimes[tempIndex].names[namers].selectedOption[indy].Txt.filter(
      (b) => b != na
    );
    newTimes[tempIndex].names[namers].selectedOption[indy].Txt = it;
    setTimes(newTimes);
    setChange(!change);

    console.log(newTimes);
  };

  const completeFluids = () => {
    setFluidsModalOpen(false);
    let drain = drainList;
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.C);
    let indy2 = drain[tempIndex].selectedOption.findIndex((a) => a == "C");
    console.log("indexes are" + indy + " " + indy2);
    drain[tempIndex].selectedOption[indy] = ["CP"];
    drain[tempIndex].selectedOption[indy2] = "";
    drain[tempIndex].selectedOption = drain[tempIndex].selectedOption.filter(
      (a) => a != ""
    );
    console.log("this is completed Fluids");
    console.log(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
    setDrainList(drain);
  };

  const completePrec = () => {
    setPrecModalOpen(false);
    let drain = drainList;
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.W);
    let indy2 = drain[tempIndex].selectedOption.findIndex((a) => a == "W");
    console.log("indexes are" + indy + " " + indy2);
    drain[tempIndex].selectedOption[indy] = ["CP"];
    drain[tempIndex].selectedOption[indy2] = "";
    drain[tempIndex].selectedOption = drain[tempIndex].selectedOption.filter(
      (a) => a != ""
    );
    console.log("this is completed Prec");
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
  };

  const completeDrains = () => {
    setDrainModalOpen(false);
    let drain = drainList;
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.D);
    let indy2 = drain[tempIndex].selectedOption.findIndex((a) => a == "D");
    console.log("indexes are" + indy + " " + indy2);
    drain[tempIndex].selectedOption[indy] = ["CP"];
    drain[tempIndex].selectedOption[indy2] = "";
    drain[tempIndex].selectedOption = drain[tempIndex].selectedOption.filter(
      (a) => a != ""
    );
    console.log("this is completed Drains");
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
  };

  const completeDiet = () => {
    setDietModalOpen(false);
    let drain = drainList;
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.Di);
    let indy2 = drain[tempIndex].selectedOption.findIndex((a) => a == "Di");
    console.log("indexes are" + indy + " " + indy2);
    drain[tempIndex].selectedOption[indy] = ["CP"];
    drain[tempIndex].selectedOption[indy2] = "";
    drain[tempIndex].selectedOption = drain[tempIndex].selectedOption.filter(
      (a) => a != ""
    );
    console.log("this is completed Diet");
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
  };

  const completeTxts = () => {
    setTxtsModalOpen(false);
    let drain = drainList;
    let indy = drain[tempIndex].selectedOption.findIndex((a) => a.Txts);
    let indy2 = drain[tempIndex].selectedOption.findIndex((a) => a == "Txts");
    console.log("indexes are" + indy + " " + indy2);
    drain[tempIndex].selectedOption[indy] = ["CP"];
    drain[tempIndex].selectedOption[indy2] = "";
    drain[tempIndex].selectedOption = drain[tempIndex].selectedOption.filter(
      (a) => a != ""
    );
    console.log("this is completed Txts");
    console.log(drain);
    setDrainList(drain);
    localStorage.setItem("drains", JSON.stringify(drain));
  };

  const completeNotes = () => {
    setNotesModalOpen(false);
    let newTimes = [...times];
    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.Txt
    );
    let indy2 = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b == "Txt"
    );

    console.log("THIS IS INDYYYY");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    console.log("THIS IS INDY 2");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy2]);
    if (newTimes[tempIndex].names[namers].selectedOption[indy]) {
      newTimes[tempIndex].names[namers].selectedOption[indy] = "CP";
    }
    if (newTimes[tempIndex].names[namers].selectedOption[indy2]) {
      newTimes[tempIndex].names[namers].selectedOption[indy2] = "";
    }
    if (!newTimes[tempIndex].names[namers].selectedOption[indy]) {
      newTimes[tempIndex].names[namers].selectedOption[indy2] = "CP";
    }

    newTimes[tempIndex].names[namers].selectedOption = newTimes[
      tempIndex
    ].names[namers].selectedOption.filter((a) => a != "");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    newTimes[tempIndex].names[namers].selectedOption = [
      ...new Set(newTimes[tempIndex].names[namers].selectedOption),
    ];
    setTimes(newTimes);
  };

  //END Notes MODAL

  //LAB MODAL

  const showLabModal = (a, b) => {
    setLabModalOpen(true);
  };

  const addLabs = (a) => {
    let newTimes = [...times];
    console.log("Testing add labs");

    let tempLab = a;

    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.L
    );

    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    console.log(namers);
    setTempNamers(namers);
    console.log("THE TEMP IS HERE");
    //IF NO NAME IS FOUND IN CARD
    if (namers == -1) {
      times[tempIndex].names.push({
        name: name,
        selectedOption: [{ L: tempLab }],
      });
      let namers2 = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
      console.log("WE FOUND THE INDEX 2");
      console.log(namers2);
    } else {
      //IF NAME IS FOUND IN CARD
      if (indy > -1) {
        //IF INDEX IS FOUND FOR MEDICATIONS
        newTimes[tempIndex].names[namers].selectedOption[indy] = {
          L: [
            ...newTimes[tempIndex].names[namers].selectedOption[indy].L,
            tempLab,
          ],
        };
      } else {
        newTimes[tempIndex].names[namers].selectedOption = [
          ...newTimes[tempIndex].names[namers].selectedOption,
          {
            L: [tempLab],
          },
        ];
      }
    }
    console.log("What is this temporary bullshit");
    setTimes(newTimes);
    console.log(newTimes);
    localStorage.setItem("times", JSON.stringify(newTimes));
    localStorage.setItem("shift", JSON.stringify(newTimes));
    console.log("this is the new times");
    console.log(newTimes);
    setChange(!change);
    setTempLab("");
  };

  const confirmLab = () => {
    setLabModalOpen(false);
    setTempNamers(-1);
    setTempLab("");
    setInputValueLab("");
  };

  const deleteLab = (ind, na) => {
    let newTimes = [...times];
    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.L
    );
    console.log("this is the filtered name");
    console.log(na);
    console.log("this is the filter");
    console.log(
      newTimes[tempIndex].names[namers].selectedOption[indy].L.filter(
        (b) => b != na
      )
    );
    let it = newTimes[tempIndex].names[namers].selectedOption[indy].L.filter(
      (b) => b != na
    );
    newTimes[tempIndex].names[namers].selectedOption[indy].L = it;
    setTimes(newTimes);
    setChange(!change);
    console.log(newTimes);
  };

  const completeLab = () => {
    setLabModalOpen(false);
    let newTimes = [...times];
    let namers = newTimes[tempIndex].names.map((a) => a.name).indexOf(name);
    let indy = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b.L
    );
    let indy2 = newTimes[tempIndex].names[namers].selectedOption.findIndex(
      (b) => b == "L"
    );

    console.log("THIS IS INDYYYY");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);
    console.log("THIS IS INDY 2");
    console.log(newTimes[tempIndex].names[namers].selectedOption[indy2]);
    if (newTimes[tempIndex].names[namers].selectedOption[indy]) {
      newTimes[tempIndex].names[namers].selectedOption[indy] = "CP";
    }
    if (newTimes[tempIndex].names[namers].selectedOption[indy2]) {
      newTimes[tempIndex].names[namers].selectedOption[indy2] = "";
    }
    if (!newTimes[tempIndex].names[namers].selectedOption[indy]) {
      console.log("not found ");
      newTimes[tempIndex].names[namers].selectedOption[indy2] = "CP";
    }
    newTimes[tempIndex].names[namers].selectedOption = newTimes[
      tempIndex
    ].names[namers].selectedOption.filter((a) => a != "");

    console.log(newTimes[tempIndex].names[namers].selectedOption[indy]);

    newTimes[tempIndex].names[namers].selectedOption = [
      ...new Set(newTimes[tempIndex].names[namers].selectedOption),
    ];

    setTimes(newTimes);
  };

  //END LAB MODAL

  const addPat = () => {
    setModalText();
    showModal();
  };

  useEffect(() => {
    console.log("this is timesssss");
    console.log(times);
    console.log("end times");
  }, [times]);

  const removePatient = (e) => {
    console.log(e);
    setNameList(nameList.filter((a) => a != e));
    let x = [...times];

    let y = x.map((a) => a.names.map((b) => b));

    console.log(times);
    setTimes(
      x
        .map((a, index) => [
          { time: a.time, names: a.names.filter((a) => a.name != e) },
        ])
        .flat(1)
    );
    console.log("unchecking filter");
  };

  useEffect(() => {
    console.log("THIS IS NAMERS AND INDEXX");
    console.log(tempIndex);
    console.log(tempNamers);
  }, [tempIndex, tempNamers]);

  const handleKeyDown = (e) => {
    console.log("this is tempmed");
    console.log(tempMed);
    console.log("end temp med");
    if (
      (e.key == "Enter" || e.key == "Return") &&
      tempMed != "" &&
      tempMed != undefined &&
      tempMed != null &&
      hasNoLettersNumbersOrSymbols(tempMed)
    ) {
      filteredData[0]?.GenericName
        ? addMeds(filteredData[0].GenericName)
        : addMeds(tempMed.charAt(0).toUpperCase() + tempMed.slice(1));
      console.log("THIS IS E on key down");
      console.log(e.target.value);
      e.target.value = "";
      setInputValue("");
      setFilteredData([]);
      inputRef.current.focus();
    }
  };
  const handleKeyDownLabs = (e) => {
    if (
      (e.key == "Enter" || e.key == "Return") &&
      tempLab != "" &&
      tempLab != undefined &&
      tempLab != null &&
      hasNoLettersNumbersOrSymbols(tempLab)
    ) {
      console.log(filteredDataLab[0]?.name);
      filteredDataLab[0]?.name
        ? addLabs(filteredDataLab[0].name)
        : addLabs(tempLab.charAt(0).toUpperCase() + tempLab.slice(1));
      e.target.value = "";
      setInputValueLab("");
      setFilteredDataLab([]);
      inputRefLabs.current.focus();
    }
  };
  const handleKeyDownNotes = (e) => {
    try {
      if (e.key == "Enter" || e.key == "Return") {
        hasNoLettersNumbersOrSymbols(tempNotes) && addNotes();
        e.target.value = "";
      }
    } catch {
      console.log("key press error");
    }
  };

  const handleKeyDownFluids = (e, a) => {
    if (e.key == "Enter" || e.key == "Return") {
      console.log("this is temp fluids");
      console.log(tempFluids);
      console.log(hasNoLettersNumbersOrSymbols(tempFluids));
      hasNoLettersNumbersOrSymbols(tempFluids) && addFluids(a);
      e.target.value = "";
    }
  };

  const handleKeyDownPrec = (e, a) => {
    if (e.key == "Enter" || e.key == "Return") {
      hasNoLettersNumbersOrSymbols(tempPrec) && addPrec(a);
      e.target.value = "";
    }
  };
  const handleKeyDownDrains = (e, a) => {
    if (e.key == "Enter" || e.key == "Return") {
      hasNoLettersNumbersOrSymbols(tempDrains) && addDrains(a);
      e.target.value = "";
    }
  };

  const handleKeyDownDiet = (e, a) => {
    if (e.key == "Enter" || e.key == "Return") {
      hasNoLettersNumbersOrSymbols(tempDiet) && addDiet(a);
      e.target.value = "";
    }
  };

  /*useEffect(() => {
    if (tempIndex || tempNamers) {
      console.log("This is the temp index and Temp Namers");
      try {
        console.log(
          times[tempIndex]?.names[tempNamers]?.selectedOption[
            times[tempIndex].names[
              times[tempIndex].names.map((a) => a.name).indexOf(name)
            ].selectedOption.findIndex((b) => b.M)
          ]?.M.length
        );
      } catch {
        console.log("error in tempindex useeffect");
      }
    } else {
      console.log("THere is no temp index and temp namers");
    }
  }, [tempIndex, tempNamers]);*/

  const [nameModalOpen, setNameModalOpen] = useState(false);
  // const [cardIndex, setCardIndex] = useState();
  const nameModalClose = () => setNameModalOpen(false);
  const openModalName = () => setNameModalOpen(true);
  const [nameChange, setNameChange] = useState(false);
  const cardIndex = useRef();
  const changeName = (b) => {
    setName(b);
    setNameChange(!nameChange);
    nameModalClose();
  };
  const handleCardClickStart = (index) => {
    cardIndex.current = index;
    setChange(!change);
    openModalName();
  };

  /*useEffect(() => {
    if (name && cardIndex.current) {
      handleCardClick(cardIndex.current);
    }
  }, [nameChange]);*/

  const [tempIndexOfText, setTempIndexOfText] = useState(0);

  /* useEffect(() => {
    if (selectedOption == "C") {
      setTempIndexOfText(
        drainList[tempIndex].selectedOption.findIndex(
          (a) => typeof a == "object" && a.C != undefined
        )
      );
      setChange(!change);
    }
    if (selectedOption == "W") {
      setTempIndexOfText(
        drainList[tempIndex].selectedOption.findIndex(
          (a) => typeof a == "object" && a.W != undefined
        )
      );
      setChange(!change);
    }
    if (selectedOption == "D") {
      setTempIndexOfText(
        drainList[tempIndex].selectedOption.findIndex(
          (a) => typeof a == "object" && a.DW != undefined
        )
      );
      setChange(!change);
    }
    if (selectedOption == "Di") {
      setTempIndexOfText(
        drainList[tempIndex].selectedOption.findIndex(
          (a) => typeof a == "object" && a.Di != undefined
        )
      );
      setChange(!change);
    }
    if (selectedOption == "Txt") {
      setTempIndexOfText(
        drainList[tempIndex].selectedOption.findIndex(
          (a) => typeof a == "object" && a.Txt != undefined
        )
      );
      setChange(!change);
    }
    console.log("This is C");
    if (tempIndex) {
      console.log("THIS IS THE DRAIN OBJECTTTT");
      console.log(
        drainList[tempIndex].selectedOption[
          drainList[tempIndex].selectedOption.findIndex(
            (a) => typeof a == "object" && a.C != undefined
          )
        ]?.C
      );
    }
  }, [drainList, tempIndex]);*/

  const changeGenStatus = (e, value) => {
    let drain = drainList;
    let theSelection =
      drain[tempIndex].selectedOption[
        drainList[tempIndex]?.selectedOption.findIndex((a) => a.Txts)
      ].Txts[0];

    switch (value) {
      case "orientation":
        theSelection.orientation = e;
        theSelection.orientationOther = "";
        break;
      case "orientation/other":
        theSelection.orientationOther = e;
        break;
      case "ambulation":
        theSelection.ambulation = e;
        theSelection.ambulationOther = "";
        break;
      case "ambulation/other":
        theSelection.ambulationOther = e;
        break;
      case "fallRisk":
        theSelection.fallRisk = e;
        theSelection.fallRiskOther = "";
        break;
      case "fallRisk/other":
        theSelection.fallRiskOther = e;
        break;

      case "specialCons":
        theSelection.specialCons = e;
        break;
      case "diet":
        theSelection.diet = e;
        theSelection.dietOther = "";
        break;
      case "diet/other":
        theSelection.dietOther = e;
        break;
      case "vitals":
        theSelection.vitals = e;
        theSelection.vitalsOther = "";
        break;
      case "vitals/other":
        theSelection.vitalsOther = e;
        break;
      case "glucose":
        theSelection.glucose = e;
        theSelection.glucoseOther = "";
        break;
      case "glucose/other":
        theSelection.glucoseOther = e;
        break;
      case "seizureRisk":
        theSelection.seizureRisk = e;
        break;
      case "aspirationPrecautions":
        theSelection.aspirationPrecautions = e;
        break;
      case "neuroChecks":
        theSelection.neuroChecks = e;
        break;
      case "neuroChecksFrequencyOther":
        theSelection.neuroChecksFrequencyOther = e;
        break;
      case "neuroChecksFrequency":
        theSelection.neuroChecksFrequencyOther = "";
        theSelection.neuroChecksFrequency = e;
        break;
      case "precautions":
        theSelection.precautions = e;
        break;
    }
    drain[tempIndex].selectedOption[
      drainList[tempIndex]?.selectedOption.findIndex((a) => a.Txts)
    ].Txts[0] = theSelection;
    setDrainList(drain);
    setChange(!change);
    console.log(theSelection);
    localStorage.setItem("drains", JSON.stringify(drain));
  };

  //testing everything

  useEffect(() => {
    console.log("This is drainlist");
    console.log(drainList);
    console.log("this is namelist");
    console.log(nameList);
    console.log("this is the selected Option");
    console.log(selectedOption);
    console.log("This is the temp index");
    console.log(tempIndex);
    console.log("this is the temp name");
    console.log(tempNamers);
    console.log("this is the name");
    console.log(name);
  }, [drainList, nameList, selectedOption, tempIndex, tempNamers, name]);

  function hasNoLettersNumbersOrSymbols(string) {
    // Check if the string is empty
    if (string.trim().length === 0) {
      return false;
    }

    // Use a regular expression to match at least one letter, number, or symbol
    const pattern = /[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]/;

    // Check if the string contains at least one letter, number, or symbol and is not just spaces
    return pattern.test(string.replace(/\s/g, ""));
  }

  useEffect(() => {
    let sortedItems = nameList.sort((a, b) => {
      let numberA = parseInt(a.match(/\d+/));
      let numberB = parseInt(b.match(/\d+/));

      // Check if both items have numbers
      if (!isNaN(numberA) && !isNaN(numberB)) {
        return numberA - numberB;
      }

      // Sort items without numbers alphabetically
      return a.localeCompare(b);
    });
    console.log("SORTED LIST");
    console.log(sortedItems);
    setNameList(sortedItems);
  }, [nameList]);

  /*
  useEffect(() => {
    let sortedItems = drainList.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;

      const isNumericA = /^\d+$/.test(nameA);
      const isNumericB = /^\d+$/.test(nameB);

      if (isNumericA && isNumericB) {
        return parseInt(nameA) - parseInt(nameB);
      }

      return nameA.localeCompare(nameB);
    });
    console.log("SORTED drainlist");
    console.log(sortedItems);
    setDrainList(sortedItems);
  }, [drainList]);*/

  //SORTING DRAINLIST

  useEffect(() => {
    let sortedItems = drainList.sort((a, b) => {
      let numberA = parseInt(a.name.match(/\d+/));
      let numberB = parseInt(b.name.match(/\d+/));
      console.log("number a and b");
      console.log(numberA);
      console.log(numberB);
      console.log(a);
      console.log(b);

      // Check if both items have numbers
      if (!isNaN(numberA) && !isNaN(numberB)) {
        console.log("returning numa-b");
        return numberA - numberB;
      }

      // Sort items without numbers alphabetically
      console.log("returning locale compae");
      return a.name.localeCompare(b.name);
    });
    console.log("SORTED drainlist");
    console.log(sortedItems);
    setDrainList(sortedItems);
  }, [drainList]);

  //SORTING TIMESSSS

  useEffect(() => {
    let t = times;

    try {
      let sortTimes = (x) => {
        let sortedItems = t[x].names.sort((a, b) => {
          let numberA = parseInt(a.name.match(/\d+/));
          let numberB = parseInt(b.name.match(/\d+/));

          // Check if both items have numbers
          if (!isNaN(numberA) && !isNaN(numberB)) {
            console.log("returning numa-b");
            return numberA - numberB;
          }

          // Sort items without numbers alphabetically
          console.log("returning locale compae");
          return a.name.localeCompare(b.name);
        });
        console.log("RETURNING SORTED TIMES");
        console.log(sortedItems);
        t[x].names = sortedItems;
        console.log("this is the txnames");
        console.log(t[x].names);
        return sortedItems;
      };
      let x = 0;

      while (x < t.length) {
        sortTimes(x);
        x++;
      }
      console.log("THIS IS THE FINAL SORT TIMES");
      console.log(t);
      setTimes(t);
      setChange(!change);
    } catch {
      console.log("error in times sorting");
    }
  }, [times]);

  const modalCheck = (x) => {
    if (tempNamers !== -1) {
      if (tempIndex !== undefined) {
        if (
          tempNamers != -1 &&
          tempIndex != undefined &&
          times[tempIndex]?.names[tempNamers]?.selectedOption &&
          times[tempIndex]?.names[tempNamers]?.selectedOption[
            times[tempIndex].names[
              times[tempIndex].names.map((a) => a.name).indexOf(name)
            ]?.selectedOption.findIndex((b) =>
              x == "M" ? b.M : x == "Txt" ? b.Txt : x == "L" && b.L
            )
          ]
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  //end modal check patients

  //modal check drains

  const modalCheckDrains = (e) => {
    if (
      tempNamers != -1 &&
      tempIndex != undefined &&
      drainList[tempIndex]?.selectedOption.length > 0
    ) {
      if (
        drainList[tempIndex]?.selectedOption.findIndex((a) =>
          e == "Txts"
            ? a.Txts
            : e == "Di"
            ? a.Di
            : e == "D"
            ? a.D
            : e == "W"
            ? a.W
            : e == "C" && a.C
        ) != -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  //end modal check drains

  useEffect(() => {
    console.log("THIS IS TIMES");
    console.log(moment(times[0].time).format("h:mm a"));
  }, [times]);

  useEffect(() => {
    document.body.height = "100px";
  }, []);

  //NOTIFICATIONS
  /*
  document.addEventListener("deviceready", () => {
    if (window.cordova && window.cordova.plugins.notification) {
      const list = times;
      list.forEach((item) => {
        const notificationTime = moment(item.time, "h:mm A").toDate();
        const options = {
          id: item.name,
          title: "NurseSync",
          text: `You have tasks scheduled for ${moment(item.time).format(
            "h:mm a"
          )}`,
          at: notificationTime,
          foreground: true, // Display the notification while the app is in the foreground
        };

        window.cordova.plugins.notification.local.schedule(options);
      });
    }
  });*/

  //NOTIFICATIONS TEST
  const symbolCheck = (a) => {
    switch (a) {
      case "M":
        return "Medication";
        break;
      case "L":
        return "Labs";
        break;
      case "B":
        return "Assess";
        break;
      case "V":
        return "Vitals";
        break;
      case "Txt":
        return "See Notes";
        break;
      case "CP":
        return "Completed";
        break;
      /* default:
        componentToRender = <DefaultComponent />;
        break;*/
    }
  };

  const getAll = () => {
    let list = times;
    //removing empty times
    let filteredList = [];
    //tasks organized
    let completedFilter = [];
    console.log("THIS IS TIMES GET ALL");
    console.log(list);

    //filter out empties
    filteredList = list.filter((a) => a.names.length > 0);
    console.log(filteredList);
    //make a list of list

    console.log(filteredList);

    let fFiltered = [];
    filteredList.forEach((a) => {
      fFiltered = [
        ...fFiltered,
        {
          time: a.time,
          tasks: a.names
            .map(
              (b) =>
                b.selectedOption?.filter((c) => c != "CP").length > 0 &&
                `Room ${b.name}: ${b.selectedOption
                  ?.filter((d) => d != "CP" && !d.M && !d.L && !d.Txt)
                  .map((a) => symbolCheck(a))}`
            )
            ?.filter((f) => f != false),
        },
      ];
    });
    console.log("full filtered");
    console.log(fFiltered);
    return fFiltered;
  };

  const scheduledNotifications = [];

  function scheduleNotifications() {
    if (window.cordova && window.cordova.plugins.notification.local) {
      window.cordova.plugins.notification.local.cancelAll();
      scheduledNotifications.length = 0; // Clear the list of scheduled notifications
      console.log("All scheduled notifications deleted.");
      scheduleNotifications2();
    } else {
      console.log("Notification plugin not available.");
    }
  }

  const scheduleNotifications2 = () => {
    let list = getAll();
    let list2 = [];

    if (window.cordova && window.cordova.plugins.notification.local) {
      /*function removeNotification(notificationId) {
        cordova.plugins.notification.local.cancel(notificationId);
        scheduledNotifications.delete(notificationId); // Remove notification ID from the set
      }*/
      list.forEach((item, index) => {
        if (item.tasks.length > 0) {
          const notificationTime = moment(item.time).toDate();
          const notificationId = moment(item.time).format("HH");
          const testId = moment(item.time).format("H"); // Use item name as the notification ID

          // Check if notification with the same ID has already been scheduled
          //handle subtraction
          const handleSubtraction = () => {
            const notificationId = moment(item.time).format("HH:mm");
            const currentTime = moment(notificationId, "HH:mm");

            const subtractedTime = currentTime.subtract(
              subtractionAmount,
              "minutes"
            );

            return subtractedTime;
          };

          const subtractedMoment = handleSubtraction();

          const options = {
            id: index,
            title: "NurseSync",
            text:
              `You have the following tasks for ${moment(item.time).format(
                "H:mm"
              )}\n` + item.tasks.join("\n"),
            foreground: true, // Display the notification while the app is in the foreground
            trigger: {
              every: {
                hour: Number(subtractedMoment.format("H")),
                minute: Number(subtractedMoment.format("m")),
              },
            },
          };
          list2.push(options);

          scheduledNotifications.push(notificationId.toString());
        }
      });

      //long latitude
      const options2 = {
        id: 3000,
        title: "Welcome Home Master!",
        trigger: {
          type: "location",
          center: [40.336632765018564, -74.26666647596379],
          radius: 15,
          notifyOnEntry: true,
        },
      };
      list2.push(options2);

      const options3 = {
        id: 3001,
        title: "Have a good day out!",
        trigger: {
          type: "location",
          center: [40.336632765018564, -74.26666647596379],
          radius: 15,
          notifyOnExit: true,
        },
      };
      list2.push(options3);

      //alert(JSON.stringify(list2));
      window.cordova.plugins.notification.local.schedule(list2);
    } else {
      console.log("nada");
    }
  };

  //WILLL NOT NEED AFTER NOTIFICATIONS WORK

  /*useEffect(() => {
    let list = getAll();
    list.forEach((item, index) => {
      const notificationTime = moment(item.time).toDate();
      const notificationId = moment(item.time).format("HH");
      const testId = moment(item.time).format("H"); // Use item name as the notification ID

      // Check if notification with the same ID has already been scheduled

      const options = {
        id: index,
        title: "NurseSync",
        text: item.tasks.join("\n"),
        foreground: true, // Display the notification while the app is in the foreground
        trigger: {
          every: {
            hour: Number(moment(item.time).format("H")),
            minute: 0,
          },
        },
      };
      console.log("I HAVE OPTIONS");
      console.log(options);
    });
  });
*/

  //WILLL NOT NEED AFTER NOTIFICATIONS WORK

  useEffect(() => {
    console.log("this is hte type of");
    console.log(typeof moment(times[0].time).format("H"));
    console.log(moment(times[0].time).format("H"));
    console.log(Number(moment(times[0].time).format("H")));
  }, []);
  function viewNotifications() {
    if (window.cordova && window.cordova.plugins.notification.local) {
      window.cordova.plugins.notification.local.getScheduled(
        (notifications) => {
          // Handle the list of notifications
          //alert(JSON.stringify(notifications));
          console.log(notifications);
        }
      );
    }
  }

  document.addEventListener("deviceready", () => {
    if (window.cordova && window.cordova.plugins.notification.local) {
      scheduleNotifications();
    }
  });

  useEffect(() => {
    if (times.length > 0) {
      scheduleNotifications();
      viewNotifications();
    }
  }, [times, subtractionAmount]);

  //NOTIFICATIONS TEST

  //FIX NAMELIST

  useEffect(() => {
    if (nameList.includes(name) == false) {
      setName(nameList[0]);
    }
  }, [nameList]);

  //NOTIFICATION IF BUTTON CLICKED

  const handleNotificationClick = () => {
    let deletedList = () => {
      if (window.cordova && window.cordova.plugins.notification) {
        window.cordova.plugins.notification.local.schedule({
          title: "NurseSync",
          text: "Tasks Erased",
          foreground: true,
        });
      }
    };
    deletedList();
  };

  useEffect(() => {
    if (
      medModalOpen === true ||
      labModalOpen === true ||
      notesModalOpen === true ||
      isModalOpen === true ||
      isModalOpen2 === true ||
      drainModalOpen === true ||
      txtsModalOpen === true ||
      dietModalOpen === true ||
      precModalOpen == true ||
      fluidsModalOpen == true
    ) {
      const body = document.body;
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        body.scrollTop;

      // Save the current scroll position
      const previousScrollPosition = scrollPosition;

      const gridContainers = document.getElementsByClassName("grid-container");

      // Loop through all elements with the class "grid-container" and apply the style
      for (let i = 0; i < gridContainers.length; i++) {
        Object.assign(gridContainers[i].style, { overflowY: "hidden" });
      }

      console.log("THIS IS GRID CONTAINER");
      console.log("");

      // Disable scrolling in the background
      //body.style.position = "fixed";

      //body.style.top = `${previousScrollPosition}px`;
      body.style.width = "100%";

      // Enable scrolling when the Drawer is closed
      const enableScroll = () => {
        body.style.position = "";
        body.style.top = "";
        body.style.width = "";
        window.scrollTo(0, previousScrollPosition);
      };

      // Cleanup on unmount
      return () => {
        enableScroll();
      };
    } else {
      console.log("none");
    }
  }, [
    medModalOpen,
    labModalOpen,
    notesModalOpen,

    drainModalOpen,
    txtsModalOpen,
    dietModalOpen,
    fluidsModalOpen,
    precModalOpen,
  ]);

  //END NOTIFICATIONS

  //KEYBOARD OPEN
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  /*useEffect(() => {
    const handleResize = () => {
      const currentHeight = window.innerHeight;
      const keyboardHeight =
        document.documentElement.clientHeight - currentHeight;
      setKeyboardHeight(keyboardHeight > 0 ? keyboardHeight : 0);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);*/
  //KEYBOARD OPEN
  const getContainer = () => {
    console.log("THIS IS THE GET CONTAINER");
    console.log(document.getElementById("containerDrawer"));
    return document.getElementById("containerDrawer"); // Replace 'my-custom-container' with the ID of your custom container
  };

  if (pageError) {
    return <div>{sError}</div>;
  }

  //NameLIst Update to sort

  //CHECKING PRECAUTIONSSS
  const checkPrecautions = () => {
    let x = times;
    let drain = drainList;

    let findIndexofName = drain.findIndex((a) => a.name == name);
    let tempItems = [];
    let listtoReturn = {};
    let componentList = [];

    if (
      findIndexofName != -1 &&
      drain[findIndexofName].selectedOption.length > 0 &&
      drain[findIndexofName].selectedOption.filter((a) => a.Txts).length > 0
    ) {
      console.log("THESE ARRE THE ITEMS");
      console.log(drain[findIndexofName].selectedOption);
      tempItems = drain[findIndexofName].selectedOption.filter((a) => a.Txts)[0]
        .Txts[0];
      if (tempItems.precautions != "" && tempItems.precautions != "Standard") {
        listtoReturn.precautions = tempItems.precautions;
        let y;
        console.log("This is the temp ITEMS");
        console.log(tempItems);
        console.log("END TEMP ITEMS");
        switch (tempItems.precautions) {
          case "Contact":
            y = (
              <button
                className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <FaHandshakeAltSlash size={25} />
                </div>
              </button>
            );
            break;
          case "S.Contact":
            y = (
              <button
                className="bg-yellow-500 flex justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <FaToilet size={25} />
                </div>
              </button>
            );
            break;
          case "Droplet":
            y = (
              <button
                className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <FaHeadSideMask size={25} />
                </div>
              </button>
            );
            break;
          case "Airborne":
            y = (
              <button
                className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
                disabled
              >
                <div>
                  <GiGasMask size={25} />
                </div>
              </button>
            );
            break;
        }
        componentList = [...componentList, y];
      }
      //IF FALLRISK
      if (tempItems.fallRisk == "Yes") {
        listtoReturn.fallRisk = true;
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <GiFallingStar size={35} />
          </button>,
        ];
      }

      //IF NPO PRECAUTIONS
      if (tempItems.diet == "NPO") {
        listtoReturn.diet = "NPO";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <MdNoFood size={35} />
          </button>,
        ];
      }

      //IF NPOAM PRECAUTIONS
      if (tempItems.diet == "NPOAM") {
        listtoReturn.diet = "NPOAM";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <MdNoFood size={35} className="custom-icon" />
              <MdBedtime size={15} className="custom-icon" />
            </div>
          </button>,
        ];
      }
      //IF ASPIRATION PRECAUTIONS
      if (tempItems.aspirationPrecautions == "Yes") {
        listtoReturn.aspirationPrecautions = true;
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <FaHeadSideCough size={25} />
            </div>
          </button>,
        ];
      }
      if (tempItems.seizureRisk == "Yes") {
        listtoReturn.seizureRisk = "Yes";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <GiBrainstorm size={25} />
            </div>
          </button>,
        ];
      }
      if (tempItems.glucose != "N/A" && tempItems.glucose != "") {
        listtoReturn.glucose = "Yes";
        componentList = [
          ...componentList,
          <button
            className="bg-yellow-500 flex flex-col justify-center items-center rounded-lg m-1 w-full p-1"
            disabled
          >
            <div>
              <MdBloodtype size={25} />
            </div>
          </button>,
        ];
      }
    }
    console.log(listtoReturn);
    console.log(componentList);

    if (componentList.length < 1) {
      return false;
    }
    return componentList;
  };

  return (
    <div
      className={`w-full min-h-[75vh] p-2 pt-4 ${confirmationModal && "fixed"}`}
    >
      <Modal
        closable={false}
        open={confirmationModal}
        onOk={null}
        onCancel={() => setConfirmationModal(false)}
        width="100%"
        style={{ overscrollBehavior: "contain", overflow: "auto" }}
        footer={null}
      >
        <div>Delete All Tasks?</div>
        <div className="flex justify-around items-center w-full mt-2">
          <button
            className="mr-2 bg-red-500 p-2 w-full rounded-lg "
            onClick={eraseEverythingShift}
          >
            Yes
          </button>
          <button
            className="bg-blue-500 p-2 w-full rounded-lg "
            onClick={() => setConfirmationModal(false)}
          >
            No
          </button>
        </div>
      </Modal>
      <div
        className="fixed bottom-0 left-0 w-full flex justify-around bg-blue-500 z-100"
        style={{ zIndex: 100 }}
      >
        {" "}
        <button
          className="p-2 pb-4 bg-blue-500 flex w-full flex-col justify-center items-center"
          onClick={() => setSettingMenu("H")}
        >
          {" "}
          <MdNewspaper size={30} color="white" />
          <div className="text-white font-bold">Handoff</div>
        </button>
        <button
          className="p-2 pb-4 bg-blue-500 flex w-full flex-col justify-center items-center"
          id="erase-tasks"
          onClick={() => setConfirmationModal(true)}
        >
          {" "}
          <MdDelete size={30} color="white" />
          <div className="text-white font-bold">Erase Tasks</div>
        </button>
        <button
          className="p-2 pb-4 bg-blue-500 flex w-full flex-col justify-center items-center"
          onClick={handleOk}
        >
          {" "}
          <MdCheck size={30} color="white" />
          <div className="text-white font-bold">Ok</div>
        </button>
      </div>

      <div className={`${settingMenu != "M" && "hidden"}`}>
        <div
          style={{
            padding: "auto",
            margin: "auto",
            justifyContent: "center",
            alignContent: "center",
            display: "block",
          }}
        >
          <div className="flex pb-1">
            <button
              className="bg-blue-500 text-white rounded-lg flex justify-center items-center flex-col w-full mr-1 p-2"
              onClick={() => {
                setThePage("P");
                handleOk();
              }}
            >
              <MdEditCalendar size={28} /> Task Schedule
            </button>
            <button
              className="bg-blue-500 text-white rounded-lg flex justify-center items-center flex-col w-full"
              onClick={() => {
                setThePage("D");
                handleOkPatient();
              }}
            >
              <MdPerson size={28} /> Patient Details
            </button>
          </div>
          <hr />
          <div
            className="rounded-lg bg-gray-200 p-2"
            style={{ marginTop: "4px" }}
          >
            <h4 className="p-1 font-bold">Change Shift</h4>
            <Select
              defaultValue={shift}
              className="pb-1 "
              style={{
                width: "100%",
              }}
              size="large"
              dropdownStyle={{ height: "200px" }}
              onChange={(value) => {
                changeShift(value);
                scheduleChanger(value);
              }}
              options={[
                {
                  value: "dayShift",
                  label: "7a-7p",
                },
                {
                  value: "midDay",
                  label: "11a-11p",
                },
                {
                  value: "nightShift",
                  label: "7p-7a",
                },
                {
                  value: "eveningShift",
                  label: "3p-3a",
                },
                {
                  value: "eightEvening",
                  label: "3p-11p",
                },
                {
                  value: "eightNight",
                  label: "11p-7a",
                },
                {
                  value: "eightMorning",
                  label: "7a-3p",
                },
              ]}
            />
            {/*<select
      style={{ width: "100%" }}
      class="form-select"
      onChange={({ target }) => changeShift(target.value)}
      aria-label="Default select example"
    >
      <option selected>Select Your Shift</option>
      <option value="dayShift">7a-7p</option>
      <option value="midDay">11a-11p</option>
      <option value="nightShift">7p-7a</option>
      <option value="eveningShift">3p-3a</option>
      <option value="eightEvening">3p-11p</option>
      <option value="eightNight">11p-7a</option>
      <option value="eightMorning">7a-3p</option>
    </select>*/}
          </div>
          <div class="bg-gray-200 rounded-lg p-2 mt-1">
            <h4 className="p-1 font-bold">Add Patients</h4>
            <input
              style={{ width: "100%", marginBottom: 0, marginTop: 0 }}
              type="text"
              id="orientationInput"
              onChange={({ target }) => setTempName(target.value.toUpperCase())}
              onKeyPress={(e) => {
                if (e.key == "Enter" || e.key == "Return") {
                  nameList.includes(tempName.trim()) == false &&
                    nameList.length < 100 &&
                    hasNoLettersNumbersOrSymbols(tempName.trim()) &&
                    setNameList([...nameList, tempName.trim()]);
                  e.target.value = "";
                  setTempName("");
                  console.log("this is the name");
                  console.log(name);
                }
              }}
              onClick={(e) => setTempName("")}
              placeholder="Input Room #"
              autoCorrect="off"
              value={tempName}
            />
            <button
              className="bg-blue-500 rounded-lg w-full text-white p-2 mt-1 justify-center items-center"
              onClick={(e) => {
                nameList.includes(tempName.trim()) == false &&
                  nameList.length < 100 &&
                  hasNoLettersNumbersOrSymbols(tempName.trim()) &&
                  setNameList([...nameList, tempName.trim()]);
                setTempName("");
                console.log("this is the name");
                console.log(name);
              }}
            >
              Add Patient
            </button>
          </div>
        </div>

        {nameList.length > 0 && (
          <>
            <div className="bg-gray-200 p-2 rounded-lg mt-1">
              <h4 className="p-1 font-bold">Remove Patients</h4>
              <div className="grid grid-cols-3 gap-1">
                {nameList.map((a) => (
                  <button
                    key={a} // Add a unique key for each button when mapping over an array.
                    className="bg-red-500 p-2 text-white text-lg rounded-lg mb-1 "
                    onClick={() => removePatient(a)}
                  >
                    {a.slice(0, 5)}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}

        <div className="bg-gray-200 p-2 rounded-lg mt-1">
          <h4 className="p-1 font-bold">Choose Notification Preference</h4>
          <Select
            defaultValue={subtractionAmount}
            style={{
              width: "100%",
            }}
            size="large"
            dropdownStyle={{ height: "auto" }}
            onChange={(value) => setSubtractionAmount(value)}
            options={[
              {
                value: 0,
                label: "On Time",
              },
              {
                value: 15,
                label: "15 minutes before",
              },
              {
                value: 30,
                label: "30 minutes before",
              },
              {
                value: 60,
                label: "1 hour before",
              },
              {
                value: 90,
                label: "1.5 hours before",
              },
            ]}
          />
        </div>
        <div className="h-[200px]"></div>
      </div>
      <div className={settingMenu == "H" && "h-full"}>
        {settingMenu == "H" && (
          <div>
            <div className="flex justify-around p-1 border-b-2">
              <button
                className={`${
                  handoffCheck != "G" && "opacity-50"
                } w-full rounded-lg bg-blue-500 text-white p-4 mr-1`}
                onClick={() => setHandoffCheck("G")}
              >
                Give Handoff
              </button>
              <button
                className={`${
                  handoffCheck != "R" && "opacity-50"
                } w-full rounded-lg bg-blue-500 text-white p-4 mr-1`}
                onClick={() => setHandoffCheck("R")}
              >
                Receive Handoff
              </button>
            </div>

            {
              //Receive Handoff
            }
            {handoffCheck == "R" && (
              <>
                <QrReader
                  delay={300}
                  onError={handleError}
                  //onScan={handleScanQr}
                  style={{ width: "100%" }}
                  onResult={handleScanQr}
                  facingMode="environment"
                />

                {tempGetHandoff && tempData ? (
                  <div className="flex flex-col justify-center text-center font-bold bg-gray-200 p-3">
                    <div classname="p-1">Add {tempGetHandoff}</div>
                    <div className="flex justify-around gap-1">
                      <button
                        className="w-full bg-green-500 p-2 rounded-lg"
                        onClick={addQrToPatients2}
                      >
                        Yes
                      </button>
                      <button
                        className="w-full bg-red-500 p-2 rounded-lg"
                        onClick={cancelQr}
                      >
                        No
                      </button>
                    </div>
                  </div>
                ) : (
                  !tempData &&
                  tempGetHandoff && (
                    <div className="flex flex-col justify-center text-center font-bold bg-gray-200 p-3">
                      <div classname="p-1">
                        <div className="text-red-500">
                          User:{tempGetHandoff} Duplicate patient found. Delete
                          patient prior to Handoff.
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
            {
              //GIVE HANDOFF
            }
            {handoffCheck == "G" && (
              <div>
                <div className="text-center font-bold p-1 text-lg">
                  Choose a Patient
                </div>
                <Select
                  defaultValue={nameQr}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  dropdownStyle={{ height: "auto" }}
                  onChange={(value) => {
                    setNameQr(value);
                    qrData(value);
                  }}
                  options={nameList.map((a) => ({
                    value: a,
                    label: a,
                  }))}
                />
                <div className="flex justify-center items-center p-2">
                  <QRCode
                    value={btoa(JSON.stringify(dataQr))}
                    size={300}
                    className="w-full"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
