import { useState, useEffect, useRef } from "react";

function useScrollPosition(componentId) {
  const scrollRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    if (scrollRef.current) {
      setScrollPosition(scrollRef.current.scrollTop);
    }
  };

  useEffect(() => {
    // Read scroll position from sessionStorage on component mount
    const storedPosition = sessionStorage.getItem(componentId);
    if (storedPosition && scrollRef.current) {
      scrollRef.current.scrollTop = parseInt(storedPosition, 10);
    }

    // Persist scroll position to sessionStorage on scroll
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [componentId, scrollRef]);

  return [scrollRef, scrollPosition];
}

export default useScrollPosition;
